
const BilleteraNavButtonComponent = ({text, isActive,setActualOpcionPago}) => {
  return (
    <div 
    className={`p-2 
    rounded-md md:rounded-r-none
    font-thin w-full 
    flex flex-col items-center justify-center 
    border-y-2 border-l-2 border-r-2 md:border-r-0
    ${isActive ? "bg-[rgb(6,56,82)] text-[#D9D9D9]  border-[#D9D9D9]" : "hover:bg-[#D9D9D9] hover:text-[#063852]" } 
    cursor-pointer
    `}
    onClick={()=>setActualOpcionPago(text)}
    >
        {text}      
    </div>
  )
}

export default BilleteraNavButtonComponent

import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundImage from "../assets/img/fondomw.png";
import { useNavigate } from "react-router-dom";
import userService from "../services/userService"; // Import the userService module
import Button from "@mui/material/Button";
import EditUserInfoModal from "./EditUserInfoModal";

function UserProfile() {
  const navigate = useNavigate();
  const [dataUsuarios, setDataUsuarios] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      navigate("/");
    } else {
      fetchUserData(storedUserId);
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      const data = await userService.getUserId(userId);
      setDataUsuarios(data);
    } catch (error) {
      console.error("Error al obtener los datos del usuario", error);
    }
  };

  return (
    <div
    className="h-screen"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ThemeProvider theme={createTheme()}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Datos del usuario
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="user_names"
                    name="user_names"
                    required
                    fullWidth
                    id="user_names"
                    value={dataUsuarios.user_names }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="user_lastnames"
                    name="user_lastnames"
                    autoComplete="user_lastnames"
                    value={dataUsuarios.user_lastnames }
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="user_email"
                    name="user_email"
                    autoComplete="user_email"
                    value={dataUsuarios.user_email }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="user_cellPhone"
                    name="user_cellPhone"
                    autoComplete="user_cellPhone"
                    value={dataUsuarios.user_cellPhone }
                    placeholder="Número de teléfono"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="user_cargo"
                    name="user_cargo"
                    autoComplete="user_cargo"
                    value={dataUsuarios.user_cargo }
                    placeholder="Cargo de la empresa"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleEditClick}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Editar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      {/* Renderiza el modal */}
      <EditUserInfoModal
        userData={dataUsuarios}
        open={modalOpen}
        onClose={handleCloseModal}
        onUpdate={setDataUsuarios}
      />
    </div>
  );
}

export default UserProfile;

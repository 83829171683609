import React from 'react';
import { useEffect, useRef } from 'react';
import fondo from '../../assets/img/candidate.jpg'
import logoMW from '../../assets/img/MATCHWORKING-07.png'

const arrayToText = (arr) => {
  return arr.map(item => `${item}`).join(", ");
};

const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(' ');
  let line = '';
  let yPos = y;

  words.forEach(word => {
    let testLine = line + word + ' ';
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;

    if (testWidth > maxWidth && line !== '') {
      ctx.fillText(line, x, yPos);
      line = word + ' ';
      yPos += lineHeight;
    } else {
      line = testLine;
    }
  });

  ctx.fillText(line, x, yPos);
};

function CanvasPosition({data}) {
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const scale = window.devicePixelRatio;

    // Tamaño del canvas
    canvas.width = 400;
    canvas.height = 400;

    // Definir el máximo ancho para el texto
    const maxWidth = 300;    

    // Fondo Blanco
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Imagen de fondo
    const bgImage = new Image();
    bgImage.src = fondo; // Reemplaza con la ruta de la imagen
    bgImage.onload = () => {
      ctx.globalAlpha = 0.3; // Opacidad de la imagen de fondo
      ctx.drawImage(bgImage, 0, 105, 400, 255); // Ajusta la posición y tamaño
      ctx.globalAlpha = 1; // Restablecer opacidad
    };

    const logo = new Image();
    logo.src = logoMW; // Reemplaza con la ruta de la imagen
    logo.onload = () => {      
      ctx.drawImage(logo, 290, 5, 50, 50); // Ajusta la posición y tamaño      
    };

    // Fondo azul para el título y subtítulo
    ctx.fillStyle = "#26455b"; // Color azul oscuro
    ctx.fillRect(0, 0, canvas.width, 50);

    // Fondo azul para el título y subtítulo
    ctx.fillStyle = "#F0810F"; // Color azul oscuro
    ctx.fillRect(0, 50, canvas.width, 55);

    // Texto de "Oferta de Empleo"
    ctx.font = "bold 16px Arial";
    ctx.fillStyle = "#f4f4f4";
    ctx.fillText("¡E S T A M O S", 10, 20);

    // Texto de "Oferta de Empleo"
    ctx.font = "bold 16px Arial";
    ctx.fillStyle = "#f4f4f4";
    ctx.fillText("C O N T R A T A N D O!", 10, 40);

    // Texto del puesto
    ctx.font = "bold 18px Arial";
    ctx.fillText(data.profile_positionToHire, 100, 70);

    // Sección de requisitos
    ctx.font = "bold 12px Arial";
    ctx.fillStyle = "#000000";
    ctx.fillText("REQUISITOS:", 20, 120);

    ctx.font = "bold 10px Arial";
    ctx.fillText("FORMACION:", 20, 140);
    ctx.font = "10px Arial";
    ctx.fillText(data.profile_education.title, 20, 155);

    ctx.font = "bold 10px Arial";
    ctx.fillText("EXPERIENCIA:", 20, 170);
    ctx.font = "10px Arial";
    ctx.fillText(data.profile_experience.profile_timeExperience, 20, 185);
    
    // Unir competencias
    const competencies = [
      ...data.profile_competencies.personalSkills.map(skill => skill.nombre),
      ...data.profile_competencies.technicalSkills.map(skill => skill.nombre)
    ];
    const competenciesJoin = `${competencies.join(', ')}`;
    ctx.font = "bold 10px Arial";
    ctx.fillText("CONOCIMIENTOS:", 20, 205);
    ctx.font = "10px Arial";
    wrapText(ctx,(competenciesJoin),20, 220, 380, 20)
    // ctx.fillText(formattedKnowledge, 20, 210);

    // Sección de beneficios
    ctx.font = "bold 10px Arial";
    ctx.fillText("BENEFICIOS:", 20, 280);
    ctx.font = "10px Arial";
    wrapText(ctx,arrayToText(data.profile_companyBenefits),20, 300, 380, 20)
    // jobData.benefits.forEach((benefit, index) => {
    //   ctx.fillText(`• ${benefit}`, 20, 360 + index * 20);
    // });

    // Fondo azul para la agencia y ciudad
    ctx.fillStyle = "#F0810F";
    ctx.fillRect(0, 360, canvas.width, 60);

    // Texto de la agencia
    ctx.font = "bold 10px Arial";
    ctx.fillStyle = "#ffffff";
    ctx.fillText("Aplica a: reclutamiento@match-working.com", 20, 380);

  }, [data]);

  return (
    <div style={{ overflow: 'auto', height: '200vh' }}>
      <canvas ref={canvasRef} className='w-full h-auto text-black'></canvas>
    </div>
  );
}


export default CanvasPosition
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import mwServices from '../../services/mw_servicesS';

Modal.setAppElement('#root');

const MWServicesTable = () => {
    const [servicesList, setServicesList] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newService, setNewService] = useState(false);
    const [newServiceData, setNewServiceData] = useState({
        name: "",
        state: "",
        terms_id: 0,
        description: "",
        buttontext: "",
        slug: "",
        price: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewServiceData({
            ...newServiceData,
            [name]: value
        });        
    };

    const getServices = async () => {
        const allServices = await mwServices.getAll();        
        const formattedServices = allServices.map((item) => ({
            id: item.mw_services_ID,
            terms_id: item.terms_id,
            serviceName: item.mw_services_name,
            serviceDescription: item.mw_services_description,
            buttontext: item.buttontext,
            price: item.mw_services_price,
            endpoint: item.mw_services_slug,
            state: item.mw_services_state,
            creationDate: item.createdAt,
            updateDate: item.updatedAt
        }));
        console.log(formattedServices)
        setServicesList(formattedServices);
    };

    useEffect(() => {

        getServices();
    }, []);

    const handleEditClick = (term) => {
        setSelectedService(term);
        setShowModal(true);
    };
    const handleNewService = () => {
        setNewService(true);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setSelectedService(null);
        setShowModal(false);
        setNewService(false);
    };

    const handleSave = async () => {
        const updateTerms = {
            mw_services_name: selectedService.serviceName,
            mw_services_state: selectedService.state,
            terms_id: selectedService.terms_id,
            mw_services_description: selectedService.serviceDescription,
            buttontext: selectedService.buttontext,
            mw_services_slug: selectedService.endpoint,
            mw_services_price: selectedService.price,

        }
        await mwServices.update(selectedService.id, updateTerms)
            .then(async data => {
                console.log("Editado ", data);
                getServices();
            })
            .catch(error => {
                console.error('Error :', error);
            });

        // console.log(updateTerms);
        // Actualizar términos en la lista localmente (opcional)
        setServicesList(servicesList.map(term => term.id === selectedService.id ? selectedService : term));
        handleModalClose();
    };

    const handleCreateService = async () => {
    
        const dataService = {
            mw_services_name: newServiceData.name,
            mw_services_state: newServiceData.state,
            terms_id: newServiceData.terms_id,
            mw_services_description: newServiceData.description,
            buttontext: newServiceData.buttontext,
            mw_services_slug: newServiceData.slug,
            mw_services_price: newServiceData.price,
        }
        
        await mwServices.create(dataService)
            .then(async data => {
                console.log("Creado ", data);
                getServices();
            })
            .catch(error => {
                console.error('Error :', error);
            });

        handleModalClose();
    };

    return (
        <div className="p-3">
            <h1 className="text-3xl font-bold mb-4">Servicios de Matchworking</h1>
            <p className="text-lg font-bold mb-4">Carrousel de página inicial</p>
            <button
                className="bg-[#011A27] text-white py-2 px-4 rounded mr-2 hover:bg-amber-600"
                onClick={handleNewService}
            >
                Crear nuevo servicio
            </button>
            <br></br>
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Nombre del Servicio</th>
                        <th className="py-2 px-4 border-b">Descripción del Servicio</th>
                        <th className="py-2 px-4 border-b">Texto del botón</th>
                        <th className="py-2 px-4 border-b">Precio</th>
                        <th className="py-2 px-4 border-b">Endpoint</th>
                        <th className="py-2 px-4 border-b text-center">Terms Id</th>
                        <th className="py-2 px-4 border-b">Estado</th>
                        <th className="py-2 px-4 border-b">Fecha de Creación</th>
                        <th className="py-2 px-4 border-b">Fecha de Actualización</th>
                        <th className="py-2 px-4 border-b">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {servicesList.map((row) => (
                        <tr key={row.id}>
                            <td className="py-2 px-4 border-b">{row.serviceName}</td>
                            <td className="py-2 px-4 border-b">{row.serviceDescription}</td>
                            <td className="py-2 px-4 border-b">{row.buttontext}</td>
                            <td className="py-2 px-4 border-b">{row.price}</td>
                            <td className="py-2 px-4 border-b">{row.endpoint}</td>
                            <td className="py-2 px-4 border-b">{row.terms_id}</td>
                            <td className="py-2 px-4 border-b">{row.state === 'Activo' ? 'Activo' : 'Inactivo'}</td>
                            <td className="py-2 px-4 border-b">{new Date(row.creationDate).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">{new Date(row.updateDate).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">
                                <button onClick={() => handleEditClick(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                isOpen={showModal}
                onRequestClose={handleModalClose}
                contentLabel="Editar Servicios"
                className="bg-white rounded-lg shadow-lg max-w-lg w-full p-4"
                overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
            >
                {selectedService && (
                    <div>
                        <h2 className="text-xl font-semibold mb-4">Editar Servicios Matchworking</h2>
                        <div className="mb-2">
                            <label className="block text-gray-700">Nombre del Servicio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.serviceName}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, serviceName: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Descripción del Servicio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.serviceDescription}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, serviceDescription: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Texto del botón</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.buttontext}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, buttontext: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Precio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.price}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, price: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Endpoint</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.endpoint}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, endpoint: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">ID de términos y condiciones</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.terms_id}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, terms_id: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Estado</label>
                            <select
                                className="w-full px-3 py-2 border rounded"
                                value={selectedService.state}
                                onChange={(e) =>
                                    setSelectedService({ ...selectedService, state: e.target.value })
                                }
                            >
                                <option value="Activo">Activo</option>
                                <option value="Inactivo">Inactivo</option>
                            </select>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                                onClick={handleModalClose}
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-green-500 text-white py-2 px-4 rounded"
                                onClick={handleSave}
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                )}
                {newService && (
                    <div>
                        <h2 className="text-xl font-semibold mb-4">Crear nuevo servicio Matchworking</h2>
                        <div className="mb-2">
                            <label className="block text-gray-700">Nombre del Servicio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                name="name"
                                value={newServiceData.name || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Descripción del Servicio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                name="description"
                                value={newServiceData.description || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Texto del botón</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                name="buttontext"
                                value={newServiceData.buttontext || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Precio</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                name="buttontext"
                                value={newServiceData.price || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Endpoint</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded"
                                name="slug"
                                value={newServiceData.slug || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">ID de términos y condiciones</label>
                            <input
                                type="number"
                                className="w-full px-3 py-2 border rounded"
                                name="terms_id"
                                value={newServiceData.terms_id || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700">Estado</label>
                            <select
                                className="w-full px-3 py-2 border rounded"
                                name="state"
                                value={newServiceData.state || ""}
                                onChange={handleChange}
                            >
                                <option value="Activo">Activo</option>
                                <option value="Inactivo">Inactivo</option>
                            </select>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                                onClick={handleModalClose}
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-green-500 text-white py-2 px-4 rounded"
                                onClick={handleCreateService}
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default MWServicesTable;

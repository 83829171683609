import React, { useState, useEffect } from 'react';
import companyService from '../../services/companyService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../VariablesContext';
import { getUserToCompanyByUserId } from '../../services/companyUserValidationService';
import { useNavigate } from 'react-router-dom';
import UpdateCompany from './update.Company';
import showAlert from '../../utilities/alerts';
import Swal from 'sweetalert2';

const TableCompanies = () => {
    const [companies, setCompanies] = useState([]);
    const [companyData, setCompanyData] = useState(null);
    const [companyId, setCompanyId] = useState('');
    const { userId } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem("userId");
        if (!storedUserId) {
            // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
            navigate("/");
        } else if (userId !== null) {
            const fetchCompanies = async () => {
                try {
                    const fetchCompanies = await getUserToCompanyByUserId(userId)
                    if (fetchCompanies.success) {
                        setCompanies(fetchCompanies.companyDetailsArray)                        
                    } else {
                        // Si no tiene registrada una empresa, redirige a company-page
                        navigate("/company-page");
                    }
                } catch (error) {
                    console.error("Error al obtener los datos del usuario", error);
                }
            };
            // Llama a la función para obtener los datos del usuario
            fetchCompanies();
        }


    }, [userId])
    if (!userId) {
        // Si no hay userId, no renderizar el componente
        return null;
    }
    const handleClickEdit = async (id) => {
        const companyEdit = await companyService.getCompanyId(id)
        if (companyEdit) {
            setCompanyData(companyEdit)
            setCompanyId(id)
            // console.log(companyEdit)
        } else {
            return null;
        }
    }

    const handleClickDelete = async (id) => {
        //Función para mostrar un mensaje de confirmación de borrado
        Swal.fire({
            title: "¿Seguro que quieres eliminar esta empresa?",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "Borrar empresa",
            denyButtonText: `No Borrar`,
            confirmButtonColor: "#ef4444",
            denyButtonColor: "#011A27"
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('eliminado', id) //servicio para eliminar empresas  
                companyService
                    .delete(id)
                    .then(async (data) => {                                                                        
                        Swal.fire({
                            title: "Empresa eliminada",
                            text: "Tu empresa se ha borrado exitosamente",
                            icon: "success"
                        });

                    })
                    .catch((error) => {
                        console.error("Error", error);
                        showAlert.error(
                            "Error al conectar con el servidor. Por favor, inténtelo de nuevo más tarde."
                        );
                    });                           
            }
        });
    }

    const handleOnBack = () => {
        setCompanyData(false)
    }
    return (
        <div>
            {companyData ? (
                <div>
                    <h2 className="mb-4 text-[#063852]">Edita tu empresa</h2>
                    <UpdateCompany onBack={handleOnBack} data={companyData} id={companyId} />
                </div>
            ) : (
                <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                    <table className="w-full text-lg text-left rtl:text-right text-gray-300 dark:text-gray-300">
                        <thead className="text-lg text-gray-400 uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-300" >
                            <tr>
                                <th scope="col" className="px-6 py-3">N°</th>
                                <th scope="col" className="px-6 py-3"></th>
                                <th scope="col" className="px-6 py-3">Nombre de empresa</th>
                                <th scope="col" className="pl-3 py-3 text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies ? (
                                companies.map((item, index) => (
                                    <tr key={index}
                                        className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                                        <td className="px-6 text-2xl py-4">{index + 1}</td>
                                        <td className="px-6 py-4"></td>
                                        <td className="px-6 text-2xl py-4">{item.company_tradename}</td>
                                        <td className="flex item-center justify-center space-x-3 pl-3 py-4">
                                            <button
                                                onClick={() => handleClickEdit(item.company_ID)}
                                                className="flex item-center justify-center text text-center "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileEdit}
                                                    size="2x"
                                                    className="mr-2 text-amber-500 cursor-pointer transition-transform duration-200 hover:scale-125" />
                                            </button>
                                            {/* <button
                                                onClick={() => handleClickDelete(item.company_ID)}
                                                className="flex item-center justify-center text text-center "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    size="2x"
                                                    className="mr-2 text-red-500 cursor-pointer transition-transform duration-200 hover:scale-125"
                                                />

                                            </button> */}
                                        </td>
                                    </tr>
                                ))) : (
                                <tr
                                    className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                                    <td colSpan="4" className="px-6 py-4 text-center">No hay datos disponibles o aún no tienes empresas creadas :c</td>
                                </tr>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}




export default TableCompanies;
import React, { useState, useEffect, useRef } from "react";
import "./Form_company.css";
import Select from "react-select";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";
import { validateRUC } from "../utilities/Validation";
import { useUser } from ".././VariablesContext";
import companyService from "../services/companyService";
import { postUserToCompanyByUserId } from "../services/companyUserValidationService";
import { useNavigate } from "react-router-dom";
const showAlert = require("../utilities/alerts");

function Form_company() {
  const navigate = useNavigate();
  const initialValues = {
    company_ruc: "",
    company_tradename: "",
    company_description: "",
    company_businessname: "",
    company_economic_activity: "",
    company_email: "",
    company_phone: "",
    company_creationdate: "",
    company_website: "",
    company_number_employees: "",
    company_type: "",
    company_subtype: "",
    company_Logo: "",
    company_WorkLocation: {
      company_City: "",
    },
    company_address: "",
  };

  const [values, setValue] = useState(initialValues);
  const { userId } = useUser();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#F0810F" : "transparent",
      color: state.isFocused ? "#00000" : "#011A27",
    }),
  };

  const rucRef = useRef(null);
  const tradenameRef = useRef(null);
  const descriptionRef = useRef(null);
  const businessnameRef = useRef(null);
  // const economicActivityRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const creationDateRef = useRef(null);
  const employeesRef = useRef(null);
  const typeRef = useRef(null);
  const subtypeRef = useRef(null);
  const cityRef = useRef(null);
  const addressRef = useRef(null);
  const logoRef = useRef(null);

  //Countries and cities

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const [cityAlert, setCityAlert] = useState(false);

  useEffect(() => {
    showAlert.success(
      "A continuación se mostrarán campos requeridos y necesarios para registrar tu empresa, estos datos facilitarán la generación y visualización de distintos servicios que puedes adquirir."
    );
  }, []);

  const handleCityChange = (selectedOption) => {
    setCityAlert(!selectedOption);
    setSelectedCity(selectedOption);

    const cityValue = selectedOption ? selectedOption.value : null;

    setValue((prevValues) => ({
      ...prevValues,
      company_WorkLocation: {
        ...prevValues.company_WorkLocation,
        company_City: cityValue,
      },
    }));
  };

  //end for conuntries

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      showAlert.error(
        "Por favor, seleccione un archivo con extensión jpg o png."
      );
      return;
    }

    const fileUrl = URL.createObjectURL(selectedFile);
    // console.log(fileUrl)
    setValue((prevValues) => ({
      ...prevValues,
      company_Logo: fileUrl,
    }));
    setFile(selectedFile);
  };

  const handleInputChange = (eventOrOption, name, isSelect = false) => {
    const value = isSelect ? eventOrOption.value : eventOrOption.target.value;
    setValue((prevValues) => {
      if (name === "company_City") {
        return {
          ...prevValues,
          company_WorkLocation: {
            ...prevValues.company_WorkLocation,
            company_City: value,
          },
        };
      } else {
        return {
          ...prevValues,
          [name]: value,
        };
      }
    });
  };
  

  const resetForm = () => {
    setValue(initialValues);
    setFile(null);
    setCities([]);
    setSelectedCity(null);
    setCityAlert(false);
  };

  const handleForm = async (event) => {
    event.preventDefault();

    //console.log("Formulario enviado", values);

    const requiredFields = [
      { fieldName: "company_ruc", label: "RUC de la Empresa" },
      { fieldName: "company_tradename", label: "Nombre de la Empresa" },
      // { fieldName: "company_description", label: "¿Qué actividades realiza la Empresa?" },
      {
        fieldName: "company_businessname",
        label: "Razón Social de la Empresa",
      },
      // { fieldName: "company_economic_activity", label: "Actividad económica de la Empresa" },
      { fieldName: "company_email", label: "Email de la Empresa" },
      { fieldName: "company_phone", label: "Contacto de la Empresa" },
      // { fieldName: "company_creationdate", label: "Fecha de creación de la Empresa" },
      // { fieldName: "company_website", label: "Sitio Web" },
      {
        fieldName: "company_number_employees",
        label: "Selecciona el rango de Empleados",
      },
      { fieldName: "company_type", label: "Tipo de Empresa" },
      { fieldName: "company_subtype", label: "Subtipo de Empresa" },
      { fieldName: "company_Logo", label: "Logo Empresarial" },
      { fieldName: "company_address", label: "Dirección de la empresa" },
    ];

    for (const field of requiredFields) {
      if (!values[field.fieldName]) {
        showAlert.error(`Por favor, complete el campo ${field.label}.`);
        switch (field.fieldName) {
          case "company_ruc":
            rucRef.current.focus();
            break;
          case "company_tradename":
            tradenameRef.current.focus();
            break;
          case "company_description":
            descriptionRef.current.focus();
            break;
          case "company_businessname":
            businessnameRef.current.focus();
            break;
          case "company_email":
            emailRef.current.focus();
            break;
          case "company_phone":
            phoneRef.current.focus();
            break;
          case "company_creationdate":
            creationDateRef.current.focus();
            break;
          case "company_number_employees":
            employeesRef.current.focus();
            break;
          case "company_type":
            typeRef.current.focus();
            break;
          case "company_subtype":
            subtypeRef.current.focus();
            break;
          case "company_Logo":
            logoRef.current.focus();
            break;
          case "company_address":
            logoRef.current.focus();
            break;

          default:
            break;
        }
        return;
      }
    }
    if (!values.company_Logo) {
      showAlert.error(
        `Por favor, complete el campo Logo Empresarial o seleccione otro logo.`
      );
      logoRef.current.focus();
      return;
    }
    if (!values.company_WorkLocation.company_City) {
      showAlert.error("Por favor, ingrese una ciudad.");
      cityRef.current.focus();
      return;
    }

    const requireRUC = validateRUC(values.company_ruc);

    if (!requireRUC.isValid) {
      showAlert.error(requireRUC.errorMessage);
      return;
    }

    //console.log(values);
    showAlert.confirmation("Gracias", "Presiona Acepto").then((result) => {
      if (result) {
        //console.log("Acepto");
        companyService
          .createCompany(values)
          .then(async (data) => {
            //console.log("Creado", data);

            setFile(null);
            //console.log("company_ID", data.company_ID);
            //console.log("user_ID", userId);
            //----------llamar al endpoint  nuevo-------
            const jsonUserCompany = {
              userId: userId,
              companyId: [data.company_ID],
            };
            //console.log(jsonUserCompany);
            if (data.message) {
              if (data.message.trim().toLowerCase() === "error") {
                showAlert.error("La empresa ya existe");
              }
            } else {
              resetForm();
              showAlert.success("Empresa creada exitosamente.");

              const companyUserValidation = await postUserToCompanyByUserId(
                jsonUserCompany
              );
              if (window.location.pathname === "/regulation-page") {
                // Estás en la página específica
                window.location.reload();
              } else {
                navigate("/requirement-page");
              }
            }
          })
          .catch((error) => {
            console.error("Error", error);
            showAlert.error(
              "Error al conectar con el servidor. Por favor, inténtelo de nuevo más tarde."
            );
          });
      }
    });
  };
  const numberEmployeesOptions = [
    { value: "one_Employee", label: "1 Empleado(Emprendimiento)" },
    { value: "one_to_nine_Employees", label: "De 1 a 9 Empleados" },
    { value: "ten_to_fortyNine_Employees", label: "De 10 a 49 Empleados" },
    {
      value: "fifty_to_oneHundredNintyNine_Employees",
      label: "De 50 a 199 Empleados",
    },
    { value: "more_twoHundred_Employees", label: "De 200 o más Empleados" },
  ];
  const companyTypeOptions = [
    {
      value: "A",
      label: "AGRICULTURA, GANADERÍA, SILVICULTURA Y PESCA",
    },
    {
      value: "B",
      label: "EXPLOTACIÓN DE MINAS Y CANTERAS",
    },
    {
      value: "C",
      label: "INDUSTRIAS MANUFACTURERAS",
    },
    {
      value: "D",
      label: "SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIREACONDICIONADO",
    },
    {
      value: "E",
      label:
        "DISTRIBUCIÓN DE AGUA; ALCANTARILLADO, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO",
    },
    {
      value: "F",
      label: "CONSTRUCCIÓN",
    },
    {
      value: "G",
      label:
        "COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS",
    },
    {
      value: "H",
      label: "TRANSPORTE Y ALMACENAMIENTO",
    },
    {
      value: "I",
      label: "ACTIVIDADES DE ALOJAMIENTO Y DE SERVICIO DE COMIDAS",
    },
    {
      value: "J",
      label: "INFORMACIÓN Y COMUNICACIÓN.",
    },
    {
      value: "K",
      label: "ACTIVIDADES FINANCIERAS Y DE SEGUROS",
    },
    {
      value: "L",
      label: "ACTIVIDADES INMOBILIARIAS",
    },
    {
      value: "M",
      label: "ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS",
    },
    {
      value: "N",
      label: "ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO",
    },
    {
      value: "O",
      label:
        "ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
    },
    {
      value: "P",
      label: "ENSEÑANZA",
    },
    {
      value: "Q",
      label:
        "ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL",
    },
    {
      value: "R",
      label: "ARTES, ENTRETENIMIENTO Y RECREACIÓN",
    },
    {
      value: "S",
      label: "OTRAS ACTIVIDADES DE SERVICIOS",
    },
    {
      value: "T",
      label:
        "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO",
    },
    {
      value: "U",
      label: "ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES",
    },
  ];
  const companySubTypeOptions = [
    {
      literal: "A",
      value: "A01",
      label: "AGRICULTURA, GANADERÍA, CAZA Y ACTIVIDADES DE SERVICIOS CONEXAS.",
    },
    {
      literal: "A",
      value: "A02",
      label: "SILVICULTURA Y EXTRACCIÓN DE MADERA",
    },
    {
      literal: "A",
      value: "A03",
      label: "PESCA Y ACUICULTURA",
    },
    {
      literal: "B",
      value: "B05",
      label: "EXTRACCIÓN DE CARBÓN DE PIEDRA Y LIGNITO.",
    },
    {
      literal: "B",
      value: "B06",
      label: "EXTRACCIÓN DE PETRÓLEO CRUDO Y GAS NATURAL.",
    },
    {
      literal: "B",
      value: "B07",
      label: "EXTRACCIÓN DE MINERALES METALÍFEROS",
    },
    {
      literal: "B",
      value: "B08",
      label: "EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS",
    },
    {
      literal: "B",
      value: "B09",
      label:
        "ACTIVIDADES DE SERVICIOS DE APOYO PARA LA EXPLOTACIÓN DE MINAS Y CANTERAS",
    },
    {
      literal: "C",
      value: "C10",
      label: "ELABORACIÓN DE PRODUCTOS ALIMENTICIOS",
    },
    {
      literal: "C",
      value: "C11",
      label: "ELABORACIÓNDE BEBIDAS",
    },
    {
      literal: "C",
      value: "C12",
      label: "ELABORACIÓN DE PRODUCTOS DE TABACO",
    },
    {
      literal: "C",
      value: "C13",
      label: "FABRICACIÓN DE PRODUCTOS TEXTILES",
    },
    {
      literal: "C",
      value: "C14",
      label: "FABRICACIÓN DE PRENDAS DE VESTIR",
    },
    {
      literal: "C",
      value: "C15",
      label: "FABRICACIÓN DE CUEROS Y PRODUCTOS CONEXOS",
    },
    {
      literal: "C",
      value: "C16",
      label:
        "PRODUCCIÓN DE MADERA Y FABRICACIÓN DE PRODUCTOS DE MADERA Y CORCHO,EXCEPTO MUEBLES; FABRICACIÓN DE ARTÍCULOS DE PAJA Y DE MATERIALES TRENZABLES",
    },
    {
      literal: "C",
      value: "C17",
      label: "FABRICACIÓN DE PAPEL Y DE PRODUCTOS DE PAPEL",
    },
    {
      literal: "C",
      value: "C18",
      label: "IMPRESIÓN Y REPRODUCCIÓN DE GRABACIONES",
    },
    {
      literal: "C",
      value: "C19",
      label:
        "FABRICACIÓN DE COQUE Y DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO",
    },
    {
      literal: "C",
      value: "C20",
      label: "FABRICACIÓN DE SUBSTANCIAS Y PRODUCTOS QUÍMICOS",
    },
    {
      literal: "C",
      value: "C21",
      label:
        "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO",
    },
    {
      literal: "C",
      value: "C22",
      label: "FABRICACIÓN DE PRODUCTOS DE CAUCHO Y PLÁSTICO",
    },
    {
      literal: "C",
      value: "C23",
      label: "FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS",
    },
    {
      literal: "C",
      value: "C24",
      label: "FABRICACIÓN DE METALES COMUNES.",
    },
    {
      literal: "C",
      value: "C25",
      label:
        "FABRICACIÓN DE PRODUCTOS ELABORADOS DE METAL,EXCEPTO MAQUINARIA Y EQUIPO",
    },
    {
      literal: "C",
      value: "C26",
      label: "FABRICACIÓN DE PRODUCTOS DE INFORMÁTICA, ELECTRÓNICA Y ÓPTICA",
    },
    {
      literal: "C",
      value: "C27",
      label: "FABRICACIÓN DE EQUIPO ELÉCTRICO",
    },
    {
      literal: "C",
      value: "C28",
      label: "FABRICACIÓN DEMAQUINARIA YEQUIPOON.C.P.",
    },
    {
      literal: "C",
      value: "C29",
      label: "FABRICACIÓN DE VEHÍCULOS AUTOMOTORES, REMOLQUES Y SEMIREOQUES",
    },
    {
      literal: "C",
      value: "C30",
      label: "FABRICACIÓN DE OTROS TIPOS DE EQUIPOS DE TRANSPORTE.",
    },
    {
      literal: "C",
      value: "C31",
      label: "FABRICACIÓN DE MUEBLES.",
    },
    {
      literal: "C",
      value: "C32",
      label: "OTRAS INDUSTRIAS MANUFACTURERAS.",
    },
    {
      literal: "C",
      value: "C33",
      label: "REPARACIÓN E INSTALACIÓN DE MAQUINNARIA Y EQUIPO.",
    },
    {
      literal: "D",
      value: "D35",
      label: "SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICCIONADO",
    },
    {
      literal: "E",
      value: "E36",
      label: "CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA",
    },
    {
      literal: "E",
      value: "E37",
      label: "EVACUACIÓN DE AGUAS RESIDUALES",
    },
    {
      literal: "E",
      value: "E38",
      label:
        "RECOLECCIÓN, TRATAMIENTO Y ELIMINACIÓN DE DESECHOS, RECUPERACIÓN DE MATERIALES",
    },
    {
      literal: "E",
      value: "E39",
      label:
        "ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS",
    },
    {
      literal: "F",
      value: "F41",
      label: "CONSTRUCCIÓN DE EDIFICIOS",
    },
    {
      literal: "F",
      value: "F42",
      label: "OBRAS DE IGNIERÍA CIVIL",
    },
    {
      literal: "F",
      value: "F43",
      label: "ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN",
    },
    {
      literal: "G",
      value: "G45",
      label: "COMERCIO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS",
    },
    {
      literal: "G",
      value: "G46",
      label:
        "COMERCIO AL POR MAYOR, EXCEPTO EL DE VEHÍCULOS AUTOMOTORES Y MOTOOCICLETAS",
    },
    {
      literal: "G",
      value: "G47",
      label:
        "COMERCIO AL POR MENOR, EXCEPTO EL DE VEHÍCULO SAUTOMOTORES Y MOTTOCICLETAS",
    },
    {
      literal: "H",
      value: "H49",
      label: "TRANSPORTE POR VÍA TERRESTRE Y POR TUBERÍAS",
    },
    {
      literal: "H",
      value: "H50",
      label: "TRANSPORTE POR VÍA ACUÁTICA",
    },
    {
      literal: "H",
      value: "H51",
      label: "TRANSPORTE PORVÍA AÉREA",
    },
    {
      literal: "H",
      value: "H52",
      label: "ALMACENAMIENTO Y ACTIVIDADES DE APOYO AL TRANSPORTE",
    },
    {
      literal: "H",
      value: "H53",
      label: "ACTIVIDADES POSTALES Y DE MENSAJERÍA",
    },
    {
      literal: "I",
      value: "I55",
      label: "ACTIVIDADES DE ALOJAMIENTO",
    },
    {
      literal: "I",
      value: "I56",
      label: "SERVICIO DE ALIMENTO Y BEBIDA",
    },
    {
      literal: "J",
      value: "J58",
      label: "ACTIVIDADES DE PUBLICACIÓN",
    },
    {
      literal: "J",
      value: "J59",
      label:
        "ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN, GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA",
    },
    {
      literal: "J",
      value: "J60",
      label: "ACTIVIDADES DE PROGRAMACIÓN Y TRANSMISIÓN",
    },
    {
      literal: "J",
      value: "J61",
      label: "TELECOMUNICACIONES",
    },
    {
      literal: "J",
      value: "J62",
      label:
        "PROGRAMACIÓN INFORMÁTICA, CONSULTORÍA DE INFORMÁTICA Y ACTIVIDADES CONEXAS",
    },
    {
      literal: "J",
      value: "J63",
      label: "ACTIVIDADES DE SERVICIOS DE INFORMACIÓN.",
    },
    {
      literal: "K",
      value: "K64",
      label:
        "ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES",
    },
    {
      literal: "K",
      value: "K65",
      label:
        "SEGUROS, REASEGUROS Y FONDOS DE PENSIONES, EXCEPTO LOS PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
    },
    {
      literal: "K",
      value: "K66",
      label:
        "ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS",
    },
    {
      literal: "L",
      value: "L68",
      label: "ACTIVIDADES INMOBILIARIAS",
    },
    {
      literal: "M",
      value: "M69",
      label: "ACTIVIDADES JURÍDICAS Y DE CCONTABILIDAD",
    },
    {
      literal: "M",
      value: "M70",
      label:
        "ACTIVIDADES DE OFICINAS PRINCIPALES; ACTIVIDADES DE CONSULTORÍA DE GESTIÓN",
    },
    {
      literal: "M",
      value: "M71",
      label:
        "ACTIVIDADES DE ARQUITECTURA E INGENIERÍA; ENSAYOS Y ANÁLISIS TÉCNICOS",
    },
    {
      literal: "M",
      value: "M72",
      label: "INVESTIGACIÓN CIENTÍFICA Y DESARROLLO",
    },
    {
      literal: "M",
      value: "M73",
      label: "PUBLICIDAD Y ESTUDIOS DE MERCADO",
    },
    {
      literal: "M",
      value: "M74",
      label: "OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS",
    },
    {
      literal: "M",
      value: "M75",
      label: "ACTIVIDADES VETERINARIAS.",
    },
    {
      literal: "N",
      value: "N77",
      label: "ACTIVIDADES DE ALQUILER Y ARRENDAMIENTO",
    },
    {
      literal: "N",
      value: "N78",
      label: "ACTIVIDADES DE EMPLEO",
    },
    {
      literal: "N",
      value: "N79",
      label:
        "ACTIVIDADES DE AGENCIAS DE VIAJES, OPERADORES TURÍSTICOS, SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS",
    },
    {
      literal: "N",
      value: "N80",
      label: "ACTIVIDADES DE SEGURIDAD E INVESTIGACIÓN",
    },
    {
      literal: "N",
      value: "N81",
      label: "ACTIVIDADES DE SERVICIOS A EDIFICIOS Y PAISAJISMO",
    },
    {
      literal: "N",
      value: "N82",
      label:
        "ACTIVIDADES ADMINISTRATIVAS Y DE APOYO DE OFICINA Y OTRAS ACTIVIDADES DE APOYO A LAS EMPRESAS",
    },
    {
      literal: "I",
      value: "O84",
      label:
        "ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
    },
    {
      literal: "P",
      value: "P85",
      label: "ENSEÑANZA.",
    },
    {
      literal: "Q",
      value: "Q86",
      label: "ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA",
    },
    {
      literal: "Q",
      value: "Q87",
      label: "ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES",
    },
    {
      literal: "Q",
      value: "Q88",
      label: "ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO",
    },
    {
      literal: "R",
      value: "R90",
      label: "ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO",
    },
    {
      literal: "R",
      value: "R91",
      label:
        "ACTIVIDADES DE BIBLIOTECAS, ARCHIVOS, MUSEOS Y OTRAS ACTIVIDADES CULTURALES",
    },
    {
      literal: "R",
      value: "R92",
      label: "ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS",
    },
    {
      literal: "R",
      value: "R93",
      label: "ACTIVIDADES DEPORTIVAS, DE ESPARCIMIENTO Y RECREATIVAS",
    },
    {
      literal: "S",
      value: "S94",
      label: "ACTIVIDADES DE ASOCIACIONES",
    },
    {
      literal: "S",
      value: "S95",
      label:
        "REPARACIÓN DE COMPUTADORES Y DE EFECTOS PERSONALES Y ENSERES DOMÉSTICOS",
    },
    {
      literal: "S",
      value: "S96",
      label: "OTRAS ACTIVIDADE DE SERVICIOS PERSONALES",
    },
    {
      literal: "T",
      value: "T97",
      label:
        "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO",
    },
    {
      literal: "T",
      value: "T98",
      label:
        "ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO",
    },
    {
      literal: "U",
      value: "U99",
      label: "ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES",
    },
  ];
  const handleClickGoBack = () => {
    if (window.location.pathname === "/regulation-page") {
      // Estás en la página específica
      window.location.reload();
      console.log("Estás en regulation");
    } else {
      navigate("/requirement-page");
    }
  };

  return (
    <>
      <form
        onSubmit={handleForm}
        className="flex justify-center items-center w-full"
      >
        <div className="container mx-auto bg-[#011A27] text-white p-6 rounded-lg shadow-lg">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Columna Única para Móviles - Izquierda para pantallas grandes */}
            <div className="space-y-4">
              <div>
                <label className="block mb-1 font-semibold">
                  Ruc de la Empresa <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="number"
                  name="company_ruc"
                  value={values.company_ruc}
                  placeholder="1111111111001"
                  ref={rucRef}
                  onChange={(e) => handleInputChange(e, "company_ruc")}
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Razón Social <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="text"
                  name="company_businessname"
                  value={values.company_businessname}
                  placeholder="EMPLEAECUADOR S.A.S."
                  ref={businessnameRef}
                  onChange={(e) => handleInputChange(e, "company_businessname")}
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Nombre Comercial <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="text"
                  name="company_tradename"
                  value={values.company_tradename}
                  placeholder="MatchWorking"
                  ref={tradenameRef}
                  onChange={(e) => handleInputChange(e, "company_tradename")}
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Email Empresarial <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="email"
                  name="company_email"
                  value={values.company_email}
                  placeholder="matchworking@matchworking.ec"
                  ref={emailRef}
                  onChange={(e) => handleInputChange(e, "company_email")}
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Teléfono <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="number"
                  name="company_phone"
                  value={values.company_phone}
                  placeholder="099999999"
                  ref={phoneRef}
                  onChange={(e) => handleInputChange(e, "company_phone")}
                />
              </div>
            </div>

            {/* Columna Única para Móviles - Derecha para pantallas grandes */}
            <div className="space-y-4">
              <div>
                <label className="block mb-1 font-semibold">
                  Selecciona el Rango <span className="text-red-500">*</span>
                </label>
                <Select
                  options={numberEmployeesOptions}
                  value={
                    numberEmployeesOptions.find(
                      (option) =>
                        option.value === values.company_number_employees
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleInputChange(
                      selectedOption,
                      "company_number_employees",
                      true
                    )
                  }
                  placeholder="El rango de Empleados"
                  isSearchable
                  styles={customStyles}
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Actividad Económica <span className="text-red-500">*</span>
                </label>
                <Select
                  options={companyTypeOptions}
                  value={
                    companyTypeOptions.find(
                      (option) => option.value === values.company_type
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, "company_type", true)
                  }
                  placeholder="Seleccione su tipo de Empresa"
                  isSearchable
                  styles={customStyles}
                />
              </div>
              {values.company_type && (
                <div>
                  <label className="block mb-1 font-semibold">
                    Subactividad Económica{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    options={companySubTypeOptions.filter(
                      (option) => option.literal === values.company_type
                    )}
                    value={
                      companySubTypeOptions.find(
                        (option) => option.value === values.company_subtype
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(selectedOption, "company_subtype", true)
                    }
                    placeholder="Seleccione su subtipo de Empresa"
                    isSearchable
                    styles={customStyles}
                  />
                </div>
              )}
              <div>
                <label className="block mb-1 font-semibold">
                  Ciudad <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="text"
                  name="company_City"
                  value={values.company_WorkLocation.company_City}
                  placeholder="Ingrese su Ciudad"
                  ref={cityRef}
                  onChange={(e) => handleInputChange(e, "company_City")}
                />
              </div>

              <div>
                <label className="block mb-1 font-semibold">
                  Dirección de la Empresa{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 text-black"
                  type="text"
                  name="company_address"
                  value={values.company_address || ""}
                  placeholder="Av. Siempre Viva"
                  ref={addressRef}
                  onChange={(e) => handleInputChange(e, "company_address")}
                />
              </div>

              <div>
                <label className="block mb-1 font-semibold">
                  Logo Empresarial <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md"
                  type="file"
                  name="company_logo"
                  ref={logoRef}
                  onChange={handleFileChange}
                />
                {values.company_Logo && (
                  <div className="mt-3 flex justify-center">
                    <img
                      src={values.company_Logo}
                      alt="Logo de la empresa"
                      className="w-40 h-40 object-contain"
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Botones */}
            <div className="col-span-1 lg:col-span-2 flex justify-center space-x-4 mt-6">
              <button
                type="button"
                className="bg-gray-500 text-white px-5 py-2 rounded-lg hover:bg-gray-600"
                onClick={() => navigate("/requirement-page")}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="bg-amber-600 text-white px-5 py-2 rounded-lg hover:bg-amber-500"
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default Form_company;

import { Fab, Slider } from '@mui/material';
import { useState } from 'react';
const PointsPonderationInput = ({ label, value, onChange, field }) => {
    const [circleScaleNumber, setCircleScaleNumber] = useState(value);
    const numberOptions = [1, 2, 3, 4, 5];

    const handleScaleChange = (e, newValue) => {
        setCircleScaleNumber(newValue);
        onChange(field, newValue);
    };

    const handleButtonChange = (number) => {
        setCircleScaleNumber(number);
        onChange(field, number);
    };

    const scaleButtonStyle = (number) => ({
        color: 'white',
        backgroundColor: circleScaleNumber >= number ? '#F0810F' : '#011A27',
        border: '2px solid #F0810F',
        margin: '0 12px',
        '&:hover': {
            backgroundColor: '#E6DF44',
            color: 'black',
        },
        position: 'absolute',
        left: `${(number / numberOptions.length) * 100 - 10}%`,
        zIndex: 2,
        transform: 'translateX(-50%)',
        '@media (max-width: 600px)': {
            width: '30px',
            height: '30px',
            fontSize: '12px',
            transform: 'translateX(-90%)', // Ajuste para pantallas pequeñas
        },
        '@media (min-width: 601px) and (max-width: 960px)': {
            width: '40px',
            height: '40px',
            fontSize: '14px',
            transform: 'translateX(-75%)', // Ajuste para pantallas medianas
        },
        '@media (min-width: 961px)': {
            width: '56px',
            height: '56px',
            fontSize: '16px',
            transform: 'translateX(-50%)', // Ajuste para pantallas grandes
        },
    });

    return (
        <div className='w-full flex flex-col 
        items-center justify-center
        text-2xl md:text-3xl'>
            <div className="flex flex-col lg:flex-row w-full my-3 font-thin items-center">
                <div className=" w-full lg:w-1/5
                text-center
                lg:text-left ">{label}</div>
                <div className="w-full lg:w-4/5 flex flex-col items-center">
                <div className="w-full my-3 relative h-[56px]" >
                        {numberOptions.map((num) => (
                            <Fab
                                key={num}
                                aria-label={num.toString()}
                                sx={scaleButtonStyle(num)}
                                onClick={() => handleButtonChange(num)}
                                size="large"
                            >
                                {num}
                            </Fab>
                        ))}
                    </div>
                    <Slider
                        onChange={handleScaleChange}
                        aria-label={label}
                        defaultValue={0}
                        shiftStep={1}
                        step={1}
                        marks
                        min={0}
                        max={numberOptions.length}
                        value={circleScaleNumber}
                        sx={{
                            width: '100%',
                            color: '#F0810F',
                            '& .MuiSlider-thumb': {
                                backgroundColor: '#F0810F',
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: '#F0810F',
                            },
                            '& .MuiSlider-rail': {
                                backgroundColor: '#E6DF44',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PointsPonderationInput;

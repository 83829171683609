// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
.required{
    color: red;
}
.button {
  background-color: #007BFF;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
}
@media (min-width: 768px) {
  .custom-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    justify-content: center;
    
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/Form_company.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;AACF;IACI,UAAU;AACd;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,eAAe;EACf,eAAe;EACf,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;AACpB;AACA;EACE;IACE,qBAAqB;IACrB,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,yCAAyC;IACzC,uBAAuB;;EAEzB;AACF","sourcesContent":["\n.container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: auto;\n  }\n.required{\n    color: red;\n}\n.button {\n  background-color: #007BFF;\n  color: #fff;\n  padding: 10px;\n  font-size: 18px;\n  cursor: pointer;\n  border: none;\n  width: fit-content;\n}\n@media (min-width: 768px) {\n  .custom-container {\n    --bs-gutter-x: 1.5rem;\n    --bs-gutter-y: 0;\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: calc(-1 * var(--bs-gutter-y));\n    justify-content: center;\n    \n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

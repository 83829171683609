import CVUploadComponent from '../../components/CVUploadComponents/CVUploadComponent';
import { ArrowUpward } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const CVUploadPage = ({ id,setPage,setProcessedComplete }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 650) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 425, behavior: 'smooth' });
  };
  return (
    <>

      <CVUploadComponent id={id} setPage={setPage} setProcessedComplete={setProcessedComplete} />

      {showButton && (
        <button
          onClick={scrollToTop}
          className='bg-[#F0810F] rounded-full p-3 fixed bottom-4 left-4 z-50 hover:scale-110 transition-transform'
        >
          <ArrowUpward style={{ color: 'white' }} />
        </button>
      )}
    </>
  
  )
}

export default CVUploadPage

import React, { useEffect, useState } from 'react';
import { useUser } from "../../VariablesContext";
import userService from "../../services/userService";
import { getUserToCompanyByUserId } from "../../services/companyUserValidationService";
import companyService from "../../services/companyService";
import { useNavigate } from 'react-router-dom';
import FormCompany from '../Form_company';
import Swal from "sweetalert2";
const showAlert = require("../../utilities/alerts");
// import RequirementForm from '../../components/RequirementForm';

const FormRegulation = ({ regulationData, setRegulationData, onNextStep, onPreviousStep }) => {
    const navigate = useNavigate();
    const [showStep2, setShowStep2] = useState(false);
    const { userId } = useUser();
    const [dataUsuarios, setDataUsuarios] = useState("");
    const [dataCompany, setdataCompany] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedRegulation, setSelectedRegulation] = useState("");
    const [error, setError] = useState('');
    // const [internalRegulation, setInternalRegulation] = useState(false);
    // const [securityRegulation, setSecurityRegulation] = useState(false);
    const [showAdditionalData, setShowAdditionalData] = useState([]);
    const [addCompanyData, setAddCompanyData] = useState({});
    const [companyWorkCenters, setCompanyWorkCenters] = useState(null); //company-workCenter desde la base para rellenar en formulario
    const [showLabelAnimation, setShowLabelAnimation] = useState(false);
    let previusData;
    // console.log('showadd', addCompanyData)
    const [errors, setErrors] = useState({
        idResponsible: '',
        nameResponsible: '',
        chargeResponsible: '',
        idSST: '',
        nameSST: '',
        chargeSST: '',
        workCenters: '',
    });

    //Datos adicionales de Reglamentos
    const [formData, setFormData] = useState({
        idResponsible: '',
        nameResponsible: '',
        chargeResponsible: '',
        idSST: '',
        nameSST: '',
        chargeSST: ''
    });
    // console.log(formData)
    const [workCenters, setWorkCenters] = useState({
        workCenters: '',
    }); //Nuevo valor ingresado desde formulario
    // console.log('workCenters', workCenters)
    const [loading, setLoading] = useState(true);

    // Validación de chargeResponsible y chargeSST (mismo patrón que nombre)
    const validateLetters = (data) => {
        const regex = /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ\s]{1,30}$/;
        return regex.test(data);
    };

    // Validación de idResponsible y idSST
    const validateNumbers = (data) => {
        return /^[0-9]{10,13}$/.test(data);
    };
    // Validación de workCenters
    const validateThreeDigitNumbers = (data) => {
        return /^[0-9]{1,3}$/.test(data);
    };
    const handleChangeCenters = (e) => {
        const { name, value } = e.target;
        setCompanyWorkCenters({
            ...companyWorkCenters,
            [name]: value || ''
        });
        setWorkCenters({
            ...workCenters,
            [name]: value || ''
        });
        if (!validateThreeDigitNumbers(value)) {
            setErrors({ ...errors, [name]: 'Solo se puede ingresar hasta 3 dígitos en los Centros de Trabajo' });
        } else {
            setErrors({ ...errors, [name]: '' });
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Verificar si el valor no está vacío para activar la animación
        setShowLabelAnimation(value !== '');
        setAddCompanyData({
            ...addCompanyData,
            [name]: ''
        })
        setFormData({
            ...formData,
            [name]: value || ''
        });
        // eslint-disable-next-line default-case
        switch (name) {
            case 'idResponsible':
                if (!validateNumbers(value)) {
                    setErrors({ ...errors, [name]: 'La identificación debe entre 10 a 13 dígitos' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
            case 'nameResponsible':
                if (!validateLetters(value)) {
                    setErrors({ ...errors, [name]: 'Solo se puede ingresar letras con máximo 30 caracteres' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
            case 'chargeResponsible':
                if (!validateLetters(value)) {
                    setErrors({ ...errors, [name]: 'Solo se puede ingresar letras con máximo 30 caracteres' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
            case 'idSST':
                if (!validateNumbers(value)) {
                    setErrors({ ...errors, [name]: 'La identificación debe entre 10 a 13 dígitos' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
            case 'nameSST':
                if (!validateLetters(value)) {
                    setErrors({ ...errors, [name]: 'Solo se puede ingresar letras con máximo 30 caracteres' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
            case 'chargeSST':
                if (!validateLetters(value)) {
                    setErrors({ ...errors, [name]: 'Solo se puede ingresar letras con máximo 30 caracteres' });
                } else {
                    setErrors({ ...errors, [name]: '' });
                }
                break;
        }
    };

    useEffect(() => {
        // Muestra el mensaje de carga al iniciar el proceso
        // showAlert.loanding();
        const storedUserId = localStorage.getItem("userId");
        if (!storedUserId) {
            // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
            navigate("/");
        } else {
            const fetchUserData = async () => {
                try {
                    const dataUser = await userService.getUserId(userId);
                    //console.log(dataUser);
                    // Actualiza el estado con los datos del usuario
                    setDataUsuarios(dataUser);
                } catch (error) {
                    console.error("Error al obtener los datos del usuario", error);
                }
            };
            // Llama a la función para obtener los datos del usuario
            fetchUserData();
        }
    }, [navigate, userId])
    // if (!userId) {
    //     // Si no hay userId, no renderizar el componente
    //     return null;
    // }

    useEffect(() => {
        const dataSelected = regulationData.selectedRegulation
        
        if (regulationData.formData){            
            // eslint-disable-next-line react-hooks/exhaustive-deps
            previusData = regulationData.formData[1]
            // console.log(previusData)
        }
        const regulationStep = dataSelected || previusData   //Guarda el valor del paso anterior o paso siguiente
        setSelectedRegulation(regulationStep);
        const nameDoc = regulationStep.map((data) => data.regulation_name).filter(Boolean);
        setShowAdditionalData(nameDoc);

        //Obtiene empresas por por id de usuario
        const companies = async () => {
            Swal.fire({
                title: "Cargando....",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            const userToCompany = await getUserToCompanyByUserId(userId);
            // console.log(userToCompany)
            if (userToCompany.success) {
                // const data = await companyService.getCompanyId();
                const data = userToCompany.companyDetailsArray
                setdataCompany(data)
                Swal.close();
                // console.log('Compañias', data)
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Sin empresas registradas',
                    text: 'Si aún no ha registrado una empresa, registre una ahora..',
                });
                
                
            }
        };
        companies();
    }, []);

    useEffect(() => {
        const fetchRegulations = async () => {
            if (selectedCompany) {
                try {
                    Swal.fire({
                        title: "Cargando empresa....",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const data = await companyService.getCompanyId(selectedCompany);
                    const company_workCenters = data.company_WorkCenters
                    const addData = data.company_AdditionalData
                    const company_AdditionalData = {
                        idResponsible: addData.idResponsible || '',
                        nameResponsible: addData.nameResponsible || '',
                        chargeResponsible: addData.chargeResponsible || '',
                        idSST: addData.idSST || '',
                        nameSST: addData.nameSST || '',
                        chargeSST: addData.chargeSST || ''
                    }
                    setAddCompanyData(company_AdditionalData)

                    setFormData({
                        idResponsible: addData.idResponsible || '',
                        nameResponsible: addData.nameResponsible || '',
                        chargeResponsible: addData.chargeResponsible || '',
                        idSST: addData.idSST || '',
                        nameSST: addData.nameSST || '',
                        chargeSST: addData.chargeSST || ''
                    });
                    setCompanyWorkCenters(company_workCenters)
                    setWorkCenters({
                        workCenters: company_workCenters || '',
                    })
                    Swal.close();
                    showAlert.success("A continuación se mostrarán campos requeridos para generar tu reglamento, llénalos para poder visualizar tu reglamento.");

                } catch (error) {
                    console.error('Error al obtener los reglamentos:', error);
                    Swal.close();
                }
            }
        };
        if (selectedCompany) {
            fetchRegulations();
        }

    }, [selectedCompany])

    const validateForm = () => {
        const { idSST, nameSST, chargeSST, idResponsible, nameResponsible, chargeResponsible } = formData;
        // const { workCenters } = workCenters;

        const validateWorkRegulation = () => {
            if (!idResponsible || !nameResponsible || !chargeResponsible) {
                showAlert.error("Por favor, complete todos los campos");
                // setValidationError('Campo obligatorio');
                // setIsInvalid(true);
                return false;
            }
            if (!validateNumbers(idResponsible)) {
                showAlert.error("Revisa que este lleno correctamente tus campos de identificación");
                // setValidationError('El RUC o número de identificación debe tener de 10 a 13 dígitos');
                // setIsInvalid(true);
                return false;
            }
            if (!validateLetters(nameResponsible) || !validateLetters(chargeResponsible)) {
                showAlert.error("Revisa que este lleno correctamente tus campos de texto");
                // setValidationError('Solo se puede ingresar letras con máximo 30 caracteres.');
                // setIsInvalid(true);
                return false;
            }
            return true;
        };
        const validateHygieneAndSafety = () => {
            if (!idSST || !nameSST || !chargeSST || !workCenters.workCenters) {
                showAlert.error("Por favor, complete todo los campos");
                // setValidationError('Campo obligatorio');
                // setIsInvalid(true);
                return false;
            }
            if (!validateNumbers(idSST)) {
                showAlert.error("Revisa que este lleno correctamente tus campos de identificación de SST");
                // setValidationError('El RUC o número de identificación debe tener de 10 a 13 dígitos');
                // setIsInvalid(true);
                return false;
            }
            if (!validateLetters(nameSST) || !validateLetters(chargeSST)) {
                showAlert.error("Revisa que este lleno correctamente tus campos de texto de SST");
                // setValidationError('Solo se puede ingresar letras con máximo 30 caracteres.');
                // setIsInvalid(true);
                return false;
            }
            if (!validateThreeDigitNumbers(workCenters.workCenters)) {
                showAlert.error("Revisa que este lleno correctamente los centros de trabajo");
                // setValidationError("Solo se puede ingresar hasta 3 dígitos en los Centros de Trabajo");
                // setIsInvalid(true);
                return false;
            }
            return true;
        };


        // const includesHygieneAndSafety = showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional");
        // const includesWorkRegulation = showAdditionalData.includes("Reglamento Interno de Trabajo");

        // if (showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional") && showAdditionalData.includes("Reglamento Interno de Trabajo")) {
        //     return validateHygieneAndSafety() && validateWorkRegulation();
        // } else 
        if (showAdditionalData.includes("Reglamento Interno de Trabajo") && !showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional")) {
            return validateWorkRegulation();
        } else if (showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional")) {
            return validateHygieneAndSafety() && validateWorkRegulation();
        }

        return true;
    };


    const handlePreviousStep = (e) => {
        e.preventDefault();
        onPreviousStep();
    }

    const handleClickNext = async (e) => {
        e.preventDefault();
        // console.log('Datos del formulario:', formData);
        if (!selectedCompany) {
            setError('Por favor, seleccione una empresa antes de continuar.');

            return; // Detener la ejecución si no se ha seleccionado una opción
        }
        //Validations ---
        if (!validateForm()) {
            return;
        }
        const company = await companyService.getCompanyId(selectedCompany);
        // console.log('dataCompanyselected: ',company)
        // console.log('compania seleccionada', selectedCompany)



        if (dataCompany && company) {
            // const data = [company, formData]

            if (selectedCompany) {
                const regulationData = { company_AdditionalData: formData, company_WorkCenters: workCenters.workCenters }
                // console.log('Regulationform', regulationData)
                try {
                    const res = await companyService.putCompanyId(selectedCompany, regulationData);
                    // console.log(res);
                    const data = [res, selectedRegulation]
                    setRegulationData({ ...regulationData, formData: data });

                } catch (error) {
                    console.error('Error al ingresar los nuevos datos', error);
                }
            }
            showAlert.success("Generando Reglamento...");
            onNextStep();
        }
    };
    const handleSelectChange = (event) => {
        setSelectedCompany(event.target.value);
        setError('');
    };



    const handleNewCompany = () => {
        // setShowNewCompany(true);
        setShowStep2(true);
    };
    // function handleInput(input) {
    //     const label = input.parentNode.querySelector('label');
    //     if (input.value) {
    //         label.classList.add('text-xs', 'mt-0', 'ml-1', '-translate-y-2', '-translate-x-3');
    //     } else {
    //         label.classList.remove('text-xs', 'mt-0', 'ml-1', '-translate-y-2', '-translate-x-3');
    //     }
    // }

    function internalWorkRegulationForm() {
        return (
            <div className=''>
                <div className='border border-gray-300 rounded-lg p-3 bg-opacity-[.75] bg-[rgb(1,26,39)] shadow-[0_1px_15px_-1px_rgba(240,129,15,0.3)]'>
                    <p className='text text-center text-white pt-4 pb-1 font-semibold'>Datos del representante legal</p>
                    <div className='relative mt-6'>
                        <label
                            className='text-sm text-white'
                            htmlFor="idResponsible">Número de identificación</label>
                        <input
                            placeholder="1777777777"
                            className={`w-full bg-gray-50 border ${errors.idResponsible ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                            type="number" id="idResponsible"
                            name="idResponsible"
                            value={formData.idResponsible || addCompanyData.idResponsible || ''}
                            onChange={handleChange} />
                        {errors.idResponsible && (
                            <p className="text-red-500 text-xs">{errors.idResponsible}</p>
                        )}
                    </div>
                    <div className='relative mt-3'>
                        <label
                            className='text-sm text-white'
                            htmlFor="nameResponsible">Nombre del responsable</label>
                        <input placeholder="Miguel Pérez"
                            className={`w-full bg-gray-50 border ${errors.nameResponsible ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                            type="text" id="nameResponsible"
                            name="nameResponsible"
                            value={formData.nameResponsible || addCompanyData.nameResponsible || ''}
                            onChange={handleChange} />
                        {errors.nameResponsible && (
                            <p className="text-red-500 text-xs">{errors.nameResponsible}</p>
                        )}
                    </div>
                    <div className='relative mt-3'>
                        <label
                            className='text-sm text-white'
                            htmlFor="chargeResponsible">Cargo del representante</label>
                        <input placeholder="Gerente General"
                            className={`w-full bg-gray-50 border ${errors.chargeResponsible ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                            type="email" id="chargeResponsible"
                            name="chargeResponsible"
                            value={formData.chargeResponsible || addCompanyData.chargeResponsible || ''}
                            onChange={handleChange} />
                        {errors.chargeResponsible && (
                            <p className="text-red-500 text-xs">{errors.chargeResponsible}</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function internalSecurityRegulationForm() {
        return (
            <div className='border border-gray-300 rounded-lg p-3 bg-opacity-[.75] bg-[rgb(1,26,39)] shadow-[0_1px_15px_-1px_rgba(240,129,15,0.3)]' >
                <p className='text text-center pt-2 font-semibold text-white'
                >Responsable de seguridad y salud del trabajo (SST)</p>
                <div className="relative mt-6">
                    <label
                        id="inputLabel"
                        htmlFor="idSST"
                        className='text-sm text-white'
                    // className={`absolute text-sm top-0 left-0 px-3 py-2 transition-transform origin-top text-gray-500 ${formData.idSST ? 'text-xs mt-0 ml-1 -translate-y-2 -translate-x-3' : ''}`}
                    >
                        Número de identificación
                    </label>
                    <input
                        type="number"
                        id="idSST" name="idSST"
                        placeholder="1777777777"
                        className={`w-full bg-gray-50 border ${errors.idSST ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                        // onInput={(event) => handleInput(event.target)}
                        value={formData.idSST || addCompanyData.idSST || ''}
                        onChange={handleChange}
                    />
                    {errors.idSST && (
                        <p className="text-red-500 text-xs">{errors.idSST}</p>
                    )}
                </div>
                <div className='relative mt-3'>
                    <label
                        htmlFor="nameSST"
                        className='text-sm text-white'
                    >
                        Nombre del Técnico SST:
                    </label>
                    <input
                        placeholder="Enrique Díaz"
                        className={`w-full bg-gray-50 border ${errors.nameSST ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                        // onInput={(event) => handleInput(event.target)}
                        type="text" id="nameSST" name="nameSST"
                        value={formData.nameSST || addCompanyData.nameSST || ''}
                        onChange={handleChange} />
                    {errors.nameSST && (
                        <p className="text-red-500 text-xs">{errors.nameSST}</p>
                    )}
                </div>
                <div className='relative mt-3'>
                    <label
                        className='text-sm text-white'
                        htmlFor="chargeSST">Cargo del Técnico SST:</label>
                    <input
                        placeholder="Supervisor de Departamento"
                        className={`w-full bg-gray-50 border ${errors.chargeSST ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                        // onInput={(event) => handleInput(event.target)}
                        type="text" id="chargeSST" name="chargeSST"
                        value={formData.chargeSST || addCompanyData.chargeSST || ''}
                        onChange={handleChange} />
                    {errors.chargeSST && (
                        <p className="text-red-500 text-xs">{errors.chargeSST}</p>
                    )}
                </div>
            </div>
        )
    }



    return (

        <div className="h-full max-h-screen w-full max-w-screen pb-8 px-3 mt-4">
            {showStep2 ? (<div>
                <h2 className='text text-center'>Registra los datos de tu nueva empresa</h2>
                <FormCompany /></div>

            ) : (

                <div> {dataCompany ? (
                    <div>
                        <div>
                            <div className="max-w-2xl mx-auto ">
                                <label htmlFor="underline_select" className="font-medium">Selecciona una empresa</label>
                                <select id="underline_select" onChange={handleSelectChange} value={selectedCompany} className="bg-[#3e505a] border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[rgb(61,79,89)] dark:border-gray-600 dark:placeholder-gray-500 dark:text-white dark:focus:ring-amber-600 dark:focus:border-amber-600">
                                    <option value="" >Escoge una empresa</option>
                                    {dataCompany && dataCompany.map((company, index) => (

                                        <option key={index} value={company.company_ID}>{company.company_tradename}</option>
                                    ))}

                                </select>
                            </div>

                        </div>
                        <div className='text-center'>
                            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Mostrar el mensaje de error si existe */}
                        </div>
                        <div className='max-w-2xl mx-auto'>
                            {selectedCompany && (
                                <form onSubmit={handleClickNext} className='py-2'>
                                    {showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional") && <div className='border border-gray-300 rounded-lg p-3 mb-2 bg-opacity-[.75] bg-[rgb(1,26,39)] shadow-[0_1px_15px_-1px_rgba(240,129,15,0.3)]'>
                                        <div className='relative'>
                                            <label
                                                className='text-sm text-white'
                                                htmlFor="workCenters">Centros de Trabajo</label>
                                            <input placeholder="10"
                                                className={`w-full bg-gray-50 border ${errors.workCenters ? 'border-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-amber-600 dark:focus:ring-amber-600 dark:focus:border-amber-600`}
                                                // onInput={(event) => handleInput(event.target)}
                                                type="number" id="workCenters"
                                                name="workCenters"
                                                value={workCenters.workCenters || companyWorkCenters || ''}
                                                onChange={handleChangeCenters} />
                                            {errors.workCenters && (
                                                <p className="text-red-500 text-xs">{errors.workCenters}</p>
                                            )}
                                        </div>
                                    </div>}

                                    {showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional") ? (<div className={`grid md:grid-cols-2 gap-2`}>
                                        {internalWorkRegulationForm()}
                                        {internalSecurityRegulationForm()}
                                    </div>) : null}
                                    {showAdditionalData.includes("Reglamento Interno de Trabajo") && showAdditionalData.length < 2 ? (<div className='grid grid-cols-1 gap-2'>
                                        {internalWorkRegulationForm()}
                                    </div>) : null}

                                    <div className='max-w-sm mx-auto p-2 flex justify-center'>
                                        <div className='text text-center px-1'>
                                            <button
                                                onClick={handlePreviousStep}
                                                className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-3 rounded"
                                            >Anterior</button>
                                        </div>
                                        <div className='text text-center px-1'>
                                            <button
                                                type='submit'
                                                onClick={handleClickNext}
                                                className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-3 rounded"
                                            >Siguiente</button>
                                        </div>
                                    </div>

                                </form>

                            )}
                            <div className='text text-center p-2'>
                                <button
                                    type="button"
                                    className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-3 rounded"
                                    onClick={handleNewCompany}>Registrar otra empresa</button>
                            </div>
                        </div>
                    </div>
                ) : (<div>
                    <h1>Registra los datos de tu nueva empresa</h1>
                    <FormCompany />
                </div>)}
                </div>
            )}

        </div>
    );
}

export default FormRegulation;
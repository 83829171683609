export const facturaInitialState = {
    nombre: "",
    correo: "",
    telefono: "",
    id: ""
};
export const ReadyToProcessBuyInitialState = false;
export const UploadFileinitialState = { 
    file: null 
};
export const PageInitialState = {
    actualPage: 1,
};
export const ViewinitialState = {
    actualView: 'Planes',
};
export const TokenInitState = ""
export const FacturaDataValidateInitState = false;
export const SuscriptionInitialValue = {};
export const FacturaValidValueInitState = false;
export const SelectedArticuloInitState = {
    producto: "",
    precio: 0,
    tipo: "",
    unidades:0
}


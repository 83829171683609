import { Box } from '@mui/material';
import React, { useState } from 'react';
import { FaBackward } from 'react-icons/fa';
import InformacionPostulante from '../../pages/rankingCVs/informacionPostulante';
import Swal from 'sweetalert2';
import { IoDocument } from 'react-icons/io5';
import reportService from '../../services/reportExcel';

function TablaRankingMobile({ datos }) {

  const [selectedDato, setSelectedDato] = useState(null);

  const handleInformacionPostulante = (dato) => {
    setSelectedDato(dato);
  };

  const downloadDocument = (data) => {    
    console.log(data)
    reportService.reportGenerate(data)
  }
  if (datos){
    Swal.close();   
  }


  return selectedDato ?
  (
    <div className='flex flex-col'>     
    <Box>
      <button
        onClick={()=>handleInformacionPostulante(null)}
        className='hover:scale-105 flex flex-row items-center justify-center gap-3'>
        <FaBackward /> Ver Ranking
      </button>
    </Box>
    <InformacionPostulante dato={selectedDato} />
  </div>
  )
  :
  (
    <section className='mt-4 flex gap-6 p-4 w-screen justify-center flex-wrap'>
      <div>
       <div 
        className='cursor-pointer text-right pb-2'
        onClick={() => downloadDocument(datos)}>
        <div className='inline-block align-middle mr-2 min-lg:hidden'>
          <IoDocument className='rounded-full text-3xl text-greenIcon' />
        </div>
        <span className='font-bold'>
          Descargar en Excel
        </span>
      </div>
      {datos.map((dato, index) => (
        <div
          key={index}
          onClick={() => handleInformacionPostulante(dato)}
          className='bg-greenBgFila grap p-4 rounded-2xl shadow-xl cursor-pointer min-w-80'>
          <p className='text-2xl'><strong>Nº {index + 1}</strong></p>
          <p><strong>Nombre: </strong>{dato.nombre}</p>
          <p><strong>Título: </strong>{dato.titulo}</p>
          <p><strong>Cargo Postulado: </strong>{dato.cargoPostulado}</p>
          <p><strong>Departamento: </strong>{dato.departamento}</p>
          <p><strong>CV Modificado: </strong>{dato.modificaCV}</p>
          <p><strong>Experiencia: </strong>{dato.experiencia} %</p>
          <p><strong>Formación: </strong>{dato.formacion} %</p>
          <p><strong>Habilidades: </strong>{dato.habilidades} %</p>
          <p><strong>Similitud Total: </strong>{dato.similitudTotal} %</p>
        </div>
      ))}
</div>
    </section>
  );
}

export default TablaRankingMobile;

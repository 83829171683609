const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.walletUrl + "";

const walletService = {

  async getToken() {
    const responseToken = await fetch(`${api_enviroment_dev}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "username": "usuario1", "password": "123456" }),
    });
    return responseToken.json();
  },

  // Obetner monedas del usuario
  async getTotalCoinsById(id, token) {
    const response = await fetch(`${api_enviroment_dev}/coins/balance/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.json();
  },

  //crear transaccion 
  async createtransaction(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/transactions/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  //actualizar transacción
  async updatetransaction(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/transactions/update/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // Obtener datos transacción
  async getTransactionData(transactionId, token) {
    const response = await fetch(`${api_enviroment_dev}/transactions/by/transaction/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.json();
  },

  //actualizar estado de transaccion
  async updateStateTransaction(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/transactions/update-state/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  //Enviar correo aprobatorio
  async sendPurchaseEmail(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/coins/send/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // recarga de monedas
  async rechargeCoins(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/coins/recharge/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // comprar servicios
  async buyService(id, token, data) {
    const response = await fetch(`${api_enviroment_dev}/coins/consume/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  //obtener historial de monedas
  async getPurchaseHistory(id, token) {

    const response = await fetch(`${api_enviroment_dev}/coins/balance/history/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.json();
  },

  //obtener datos de servicio por id
  async getServiceData(id, token) {

    const response = await fetch(`${api_enviroment_dev}/service/by/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.json();
  },

  //subir imagen de confirmación de pago
  async uploadTransactionDocument(id, token, file) {

    const formData = new FormData();
    formData.append('image', file);
    const response = await fetch(`${api_enviroment_dev}/transactions/upload-image/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData,
    });
    return response.json();
  },

};

export default walletService;

import { WhatsApp } from '@mui/icons-material'
import React from 'react'

const WhatsappButtonComponent = () => {

    const wsContact = 'https://wa.me/+593987009405?text=Hola,%20tengo%20dudas%20sobre%20la%20billetera%20Matchworking,%20necesito%20ayuda%20con%20un%20pago.'
    return (
        <a 
            href={wsContact} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <button className='border-3 border-[#FF8201] rounded-3xl px-2
            flex flex-row items-center justify-center
            hover:text-white hover:bg-[#FF8201]
            md:text-lg lg:text-xl
            transition-all duration-300 ease-in-out
        '
            >
                <div className='bg-[#FF8201] rounded-full p-1 md:mr-1 '>
                    <WhatsApp style={{
                        color: 'white',
                        width: '20px',
                        height: '20px',
                        display: 'flex'
                    }} />
                </div>

                <div className='hidden md:flex flex-col items-center justify-center'>
                    ¿Necesitas ayuda?
                </div>
            </button>
        </a>

    )
}

export default WhatsappButtonComponent

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh; /* Asegura que el fondo cubra toda la pantalla */
  }
.title{
    padding-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}
.subtitle{
    margin-top: 0%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}
.content{
    margin-left: 5%;
    margin-right: 5%;
}
.matchWorkingLogo{
    display: flex;
    justify-content: right;
}
.matchWorkingLogo img{
    max-height: 150px;
    padding-top: 5%;
    padding-right: 15%;
}

.buttonSection {
    text-align: center;
  }
.button{
    background-color: #F0810F;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    max-width: 200px; /* Puedes ajustar el ancho máximo según tus necesidades */
    width: 100%;
    
}`, "",{"version":3,"sources":["webpack://./src/pages/requirements/requirementPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,iBAAiB,EAAE,gDAAgD;EACrE;AACF;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;EACpB;AACF;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,eAAe;IACf,eAAe;IACf,YAAY;IACZ,gBAAgB,EAAE,yDAAyD;IAC3E,WAAW;;AAEf","sourcesContent":[".bg-cover {\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    min-height: 100vh; /* Asegura que el fondo cubra toda la pantalla */\n  }\n.title{\n    padding-top: 5%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n.subtitle{\n    margin-top: 0%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n.content{\n    margin-left: 5%;\n    margin-right: 5%;\n}\n.matchWorkingLogo{\n    display: flex;\n    justify-content: right;\n}\n.matchWorkingLogo img{\n    max-height: 150px;\n    padding-top: 5%;\n    padding-right: 15%;\n}\n\n.buttonSection {\n    text-align: center;\n  }\n.button{\n    background-color: #F0810F;\n    color: #fff;\n    padding: 10px;\n    font-size: 18px;\n    cursor: pointer;\n    border: none;\n    max-width: 200px; /* Puedes ajustar el ancho máximo según tus necesidades */\n    width: 100%;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/img/fondomw.png"
import TableCompanies from "../../components/companyPanelComponents/listCompany";

const CompanyPanelPage = () => {
    const navigate = useNavigate();    
    // const handleItemsChange = (newItems) => {
    //   setItemsFromDynamicList(newItems);
     
    // };

    return (
      <div className="bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}>      
        <div className="row">
          <div className="col-md-9">
            <div className="title">
              <h1 className="mb-4 text-[#063852]">Tus empresas</h1>
            </div>
          </div>          
        </div>
  
        <div className="content">
          <div className="flex justify-content-center align-items-center">
            <div className="w-full max-w-screen ">
             <TableCompanies/>
  
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default CompanyPanelPage;
  
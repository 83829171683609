import React, { useState, useEffect } from "react";
import "./formCompanyPages.css";
import matchWorkingLogo from "../../assets/img/MATCHWORKING-07.png";
import FormCompany from "../../components/Form_company";
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../../assets/img/fondomw.png"

const FormCompanyPage = () => {
  const navigate = useNavigate();
  const [itemsFromDynamicList, setItemsFromDynamicList] = useState([]);

  const handleItemsChange = (newItems) => {
    setItemsFromDynamicList(newItems);
   
  };
  useEffect(() => {
    //console.log(itemsFromDynamicList);
  }, [itemsFromDynamicList]);
  return (
    <div className="bg-cover"
    style={{ backgroundImage: `url(${backgroundImage})` }}>      
      <div className="row">
        {/* <div className="d-flex justify-center mt-10">
          <div className="flex justify-evenly mt-10 ">
            <button
              type="button"
              className="button rounded-lg"
              onClick={() => (navigate("/profile-form"))}
            >
              Menú Principal
            </button>
          </div>
        </div> */}
        <div className="col-md-9">
          <div className="title">
            <h1 className="mb-4 text-[#063852]">Registro de Empresa</h1>
          </div>
          <div className="subtitle">
            <h3 className="text-[#063852]">
              Por favor llena los campos del formulario detallando la
              información de la Empresa:
            </h3>
          </div>

          <br></br>
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-center">
          <img style={{ maxHeight: "150px" }} src={matchWorkingLogo} alt="" />
        </div>
      </div>

      <div className="content">
        <div className="d-md-flex justify-content-center align-items-center">
          <div className="col-md-7">
           <FormCompany/> 
           {/* < Home/>  */}
           

          {/*<DynamicList onItemsChange={handleItemsChange}/>*/}

          </div>
        </div>
      </div>
    </div>
  );
};

export default FormCompanyPage;

import React from 'react';
import { useEffect, useRef } from 'react';
//import { AiOutlineFullscreen } from "react-icons/ai";
import BgPerfilCargo from '../assets/img/bg-profileCanvas.png'

function CanvasPerfilCargo({ editedProfile }) {
  const canvasRef = useRef(null);
  const backgroundRef = useRef(null);
  const { position, mission, requirements, education,
    workingConditions, benefits, tools, responsibilities,
    technicalSkills,personalSkills, languages, courses
  } = editedProfile;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = 794;
    canvas.height = calculateCanvasHeight(); // Altura dinámica

    const background = new Image();
    background.src = BgPerfilCargo;

    background.onload = () => {
      backgroundRef.current = background;
      drawCanvas(context, canvas, background);
    };    
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (backgroundRef.current) {
      drawCanvas(context, canvas, backgroundRef.current);
    }
    // console.log('2-UE',editedProfile)
  }, [editedProfile]);

  const calculateCanvasHeight = () => {
    let baseHeight = 1800; // Altura base
    let extraHeight = 0;

    // Calcular altura adicional en función del contenido
    extraHeight += responsibilities.length * 60;
    extraHeight += technicalSkills.length * 40;
    extraHeight += personalSkills.length * 40;
    extraHeight += courses.length * 20;

    return baseHeight + extraHeight;
  };

  const drawCanvas = (context, canvas, background) => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(background, 0, 0, canvas.width, canvas.height);

    drawText(context, canvas, background);
  };

  const drawText = (context, canvas, background) => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(background, 0, 0, canvas.width, canvas.height);

    context.font = 'bold 16px Arial';
    const title = 'Perfil de Cargo';
    const titleWidth = context.measureText(title).width;
    context.fillText(title, (canvas.width - titleWidth) / 2, 30);

    // Datos del cargo
    context.font = 'bold 14px Arial';
    context.fillText('Datos del Cargo', 105, 65);
    context.font = 'bold 12px Arial';
    context.fillText('• Nombre del Cargo: ', 130, 90);
    context.fillText('• País: ', 130, 110);
    context.fillText('• Departamento: ', 130, 130);
    context.fillText('• Ciudad: ', 130, 150);
    context.fillText('• Cargo Jefe Inmediato: ', 130, 170);
    context.font = '12px Arial';
    context.fillText(`${position.name}`, 250, 90);
    context.fillText(`${position.country}`, 170, 110);
    context.fillText(`${position.department}`, 225, 130);
    context.fillText(`${position.city}`, 185, 150);
    context.fillText(`${position.chief}`, 270, 170);

    // Misión del Cargo
    context.font = 'bold 14px Arial';
    context.fillText('Misión del Cargo', 105, 200);
    context.font = '12px Arial';
    saltoLineaTexto(context, mission, 105, 220, 590, 18)
    // context.fillText(`${mission}`, 105, 220);

    // Requisitos del Cargo
    context.font = 'bold 14px Arial';
    context.fillText('Requisitos del Cargo', 105, 310);
    context.font = 'bold 12px Arial';
    context.fillText('• Tiempo de Experiencia: ', 130, 330);
    context.fillText('• Detalle de Experiencia: ', 130, 350);
    context.font = '12px Arial';
    context.fillText(`${requirements.experienceTime}`, 280, 330);
    saltoLineaTexto(context,requirements.experienceDetail, 105, 370, 590, 18 )
    // context.fillText(`${requirements.detalleExperiencia}`, 130, 320);

    // Educación
    context.font = 'bold 14px Arial';
    context.fillText('Educación', 105, 430);
    context.font = 'bold 12px Arial';
    context.fillText('• Nivel: ', 130, 450);
    context.fillText('• Título: ', 130, 470);
    context.font = '12px Arial';
    context.fillText(`${education.level}`, 180, 450);
    context.fillText(`${education.title}`, 180, 470);

    // Condiciones de Trabajo
    context.font = 'bold 14px Arial';
    context.fillText('Condiciones de Trabajo', 105, 490);
    context.font = 'bold 12px Arial';
    context.fillText('• Tipo de Contrato: ', 130, 510);
    context.fillText('• Modalidad: ', 130, 530);
    context.fillText('• Salario: ', 130, 550);
    context.font = '12px Arial';
    context.fillText(`${workingConditions.contract}`, 250, 510);
    context.fillText(`${workingConditions.modality}`, 250, 530);
    context.fillText(`${workingConditions.salary}`, 250, 550);

    // Beneficios
    context.font = 'bold 14px Arial';
    context.fillText('Beneficios', 105, 580);
    context.font = '12px Arial';
    benefits.forEach((benefit, index) => {
      context.fillText(`- ${benefit}`, 120, 600 + (index * 20));
    });

    // Herramientas
    let currentY = 675;
    context.font = 'bold 14px Arial';
    context.fillText('Herramientas', 105, currentY);
    let spaceTools = 0    
    tools.forEach((tool, index) => {
      context.font = '12px Arial';
      context.fillText(`- ${tool}`, 120, currentY + 20 + (index * 20));
      spaceTools += index * 20;
    });
    currentY += spaceTools + 40 ;
    // Idiomas
    context.font = 'bold 14px Arial';
    context.fillText('• Idiomas:', 105, currentY);    
    context.fillText('• Nivel: ', 105, currentY + 20);
    context.font = '12px Arial';
    context.fillText(`${languages.language}`, 250, currentY);
    context.fillText(`${languages.level}`, 250, currentY + 20);    
    // Responsabilidades
    currentY += 60
    context.font = 'bold 14px Arial';
    context.fillText('Responsabilidades', 105, currentY);
    context.font = 'bold 12px Arial';
    const columnTitles = ['Función', 'Resultados', 'Tareas'];
    const columnWidths = [200, 200, 200];
    const startX = 105;

    // Dibuja encabezados de columnas
    columnTitles.forEach((title, i) => {
      context.fillText(title, startX + i * columnWidths[i], currentY + 20);
    });

    currentY += 40; // Espacio para los encabezados
    context.font = '12px Arial';
    const funciones = responsibilities.map(item => item.funcion);
    const resultados = responsibilities.map(item => item.resultados);
    const tareas = responsibilities.map(item => item.tareas);
    // Encuentra la mayor cantidad de filas necesarias
    const maxRows = Math.max(funciones.length, resultados.length, tareas.length);

    // altura de las filas
    const rowHeight = 130; 

    // responsibilities en columnas
    for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
      const yPosition = currentY + rowIndex * rowHeight;

      const funcion = funciones[rowIndex] || '';
      saltoLineaTexto(context, funcion, startX, yPosition, columnWidths[0] - 20, 18);

      const resultado = resultados[rowIndex] || '';
      saltoLineaTexto(context, resultado, startX + columnWidths[0], yPosition, columnWidths[1] - 20, 18);

      const tarea = tareas[rowIndex] || '';
      saltoLineaTexto(context, tarea, startX + columnWidths[0] + columnWidths[1], yPosition, columnWidths[2] - 20, 18);
    }
    // Ajusta la altura después de responsibilities
    const totalRows = Math.ceil(maxRows);
    currentY += totalRows * rowHeight + 0;


    // Competencias Técnicas
    context.font = 'bold 14px Arial';
    context.fillText('Competencias Técnicas', 100, currentY + 40);
    currentY += 50; // Espacio para el encabezado
    drawTableWithHeaders(context, 100, currentY, technicalSkills);

    // Competencias Personales
    const competenciasPersonalesY = currentY + technicalSkills.length * 40 + 100; // Espacio adicional
    context.font = 'bold 14px Arial';
    context.fillText('Competencias Personales', 100, competenciasPersonalesY);
    drawTableWithHeaders(context, 100, competenciasPersonalesY + 20, personalSkills);

    // Cursos
    const cursosY = competenciasPersonalesY + personalSkills.length * 40 + 150; // Espacio adicional
    context.font = 'bold 14px Arial';
    context.fillText('Cursos', 100, cursosY);
    courses.forEach((curso, index) => {
      context.font = '12px Arial';
      context.fillText(`- ${curso}`, 100, cursosY + 20 + (index * 20));
    });
  };

  const drawTableWithHeaders = (context, x, y, data) => {
    const cellPadding = 10;
    const headerHeight = 40;  // Altura de la fila del encabezado
    const rowHeight = 30;     // Altura de las filas de datos
    const rowSpacing = 30;    // Espacio entre las filas
    const columnTitles = ['Nombre', 'Nivel', 'Definicion'];
    const titleWidths = [200, 180, 180];  // Anchos títulos
    const columnWidths = [200, 100, 350]; // Anchos de datos
    const startX = x;

    context.font = 'bold 12px Arial';
    columnTitles.forEach((title, i) => {
        context.fillText(title, startX + i * titleWidths[i] + cellPadding, y + headerHeight / 2);
    });

    y += headerHeight;

    // Mapea los datos en columnas
    const nombres = data.map(item => item.nombre || '');
    const niveles = data.map(item => item.nivel || '');
    const definiciones = data.map(item => item.definicion || item.definición || '');
    const maxRows = Math.max(nombres.length, niveles.length, definiciones.length);

    // Dibuja las filas de datos
    context.font = '12px Arial';
    for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
      const rowY = y + rowIndex * (rowHeight + rowSpacing);

        const tipo = nombres[rowIndex] || '';
        saltoLineaTexto(context, tipo, startX, rowY, columnWidths[0] - cellPadding, rowHeight - cellPadding);

        const nivel = niveles[rowIndex] || '';
        saltoLineaTexto(context, nivel, startX + columnWidths[0], rowY, columnWidths[1] - cellPadding, rowHeight - cellPadding);

        const definicion = definiciones[rowIndex] || '';
        saltoLineaTexto(context, definicion, startX + columnWidths[0] + columnWidths[1], rowY, columnWidths[2] - cellPadding, rowHeight - cellPadding);
    }
  };

  const saltoLineaTexto = (context, texto, x, y, maxAncho, alturaLinea) => {
    const palabras = texto.split(' ');
    let linea = '';
    let pruebaLinea;
    let metrica;
    let anchoPrueba;
    let yOffset = y;

    palabras.forEach((palabra, index) => {
      pruebaLinea = linea + palabra + ' ';
      metrica = context.measureText(pruebaLinea);
      anchoPrueba = metrica.width;
      if (anchoPrueba > maxAncho && index > 0) {
        context.fillText(linea, x, yOffset);
        linea = palabra + ' ';
        yOffset += alturaLinea;
      } else {
        linea = pruebaLinea;
      }
    });

    if (linea) {
      context.fillText(linea, x, yOffset);
    }

    return yOffset; // Devuelve la última posición vertical
  };
  return (
    <div style={{ overflow: 'auto', height: '100vh' }}>
      <canvas ref={canvasRef} className='w-full h-auto text-black'></canvas>
    </div>
  );
}


export default CanvasPerfilCargo;

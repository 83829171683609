const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/emailW";

const emailConfigServiceW = {
  async createEmailConfigW(data) {
    try {
      const response = await fetch(`${api_enviroment_dev}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Error al crear la configuración de correo');
      }
      return response.json();
    } catch (error) {
      console.error('Error al crear la configuración de correo:', error);
      throw error;
    }
  },

  async getAllEmailConfigsW() {
    try {
      const response = await fetch(`${api_enviroment_dev}`);
      if (!response.ok) {
        throw new Error('Error al obtener las configuraciones de correo');
      }
      return response.json();
    } catch (error) {
      console.error('Error al obtener las configuraciones de correo:', error);
      throw error;
    }
  },

  async getEmailConfigByIdW(id) {
    try {
      const response = await fetch(`${api_enviroment_dev}/${id}`);
      if (!response.ok) {
        throw new Error('Error al obtener la configuración de correo');
      }
      return response.json();
    } catch (error) {
      console.error('Error al obtener la configuración de correo:', error);
      throw error;
    }
  },

  async updateEmailConfigW(id, data) {
    try {
      const response = await fetch(`${api_enviroment_dev}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Error al actualizar la configuración de correo');
      }
      return response.json();
    } catch (error) {
      console.error('Error al actualizar la configuración de correo:', error);
      throw error;
    }
  },
};

export default emailConfigServiceW;
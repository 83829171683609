import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [mostrarBotonEmpresa, setMostrarBotonEmpresa] = useState(true);
  const [logged, setLogged] = useState(null);

  // Recuperar userId del localStorage al cargar la página
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedLogged = localStorage.getItem('logged');
    //console.log('Valor leído de localStorage:', storedLogged);
    if (storedLogged) {
      setLogged(storedLogged);
    }
    if (storedUserId) {
      setUserId(storedUserId);
      setMostrarBotonEmpresa(true);
    }
  }, []);
  const setLoggedGuardian = (props)=>{
    setLogged(props);
    localStorage.setItem('logged', props);
  }

  const setLoggedInUserId = (id) => {
    // Actualizar userId en el estado y en localStorage
    setUserId(id);
    localStorage.setItem('userId', id);
  };
  const setBotonEmpresa = (mostrar) => {
    setMostrarBotonEmpresa(mostrar);
    localStorage.setItem('mostrarBotonEmpresa', mostrar);
  };

  return (
    <UserContext.Provider value={{ userId, setLoggedInUserId, mostrarBotonEmpresa,setBotonEmpresa, logged, setLoggedGuardian  }}>
      {children}
    </UserContext.Provider>
  );
};

  export const useUser = () => {
    return useContext(UserContext);
  };
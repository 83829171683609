import React, { useEffect, useState } from 'react'
import { PRECIO_MONEDAS } from '../../../utilities/billeteraViews';
import { CircularProgress } from '@mui/material';
const RecargaButtonComponent = ({ cantidad, onClick, selected, preloadedImage }) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (selected && cantidad + " MWC" === selected.producto) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [selected, cantidad]);
    return (
        <div className='flex flex-col items-center justify-center
    '>
            <button className={`border-3 border-white rounded-2xl p-4 m-2
                w-44 h-44
                md:w-36 md:h-36
                lg:w-48 lg:h-48
                ${isActive ? "bg-[#FF8201]" : "hover:bg-[#FF8201] hover:scale-110"}
                cursor-pointer transition-all duration-300 ease-in-out
                flex flex-col items-center justify-center
            `}
                onClick={onClick}
                disabled={!preloadedImage}
            >
                {preloadedImage ? (
                    <>
                        <div className='flex flex-row items-center justify-center'>

                            <div className='text-5xl mr-2 font-sans font-semibold'>{cantidad}</div>
                            <img
                                src={preloadedImage}
                                alt="Matchworking Logo"
                                className='rounded-full h-8'
                            />
                        </div>
                        <div className='flex flex-col items-center justify-center font-sans font-semibold
                            text-3xl text-[#E6DF44]'>
                            ${PRECIO_MONEDAS * cantidad}
                        </div>
                    </>

                ) : (
                    <CircularProgress className='w-20 h-20 text-center' />
                )}


            </button>
        </div>
    )
}

export default RecargaButtonComponent
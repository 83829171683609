import React, { useState } from "react";
import MWServicesTable from "../../components/list-data-admin/mwServicesTable";


const Tab = ({ label, isActive, onClick }) => (
    <div className={`tab ${isActive ? "active-tab" : ""}`} onClick={onClick}>
        {label}
    </div>
);

const Content = ({ children }) => <div className="content">{children}</div>;

const MWServicesPage = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    return (
        <div className="">
            <div>
                <div className="flex gap-4 ml-5">
                    <Tab
                        label="Lista de servicios"
                        isActive={activeTab === 0}
                        onClick={() => handleTabClick(0)}
                    />
                    {/* <Tab
                        label="Nuevo usuario registrado"
                        isActive={activeTab === 1}
                        onClick={() => handleTabClick(1)}
                    /> */}


                    {/* Agrega más pestañas según sea necesario */}
                </div>
                <Content>
                    {activeTab === 0 && (
                        <div>
                            {/* <h1 className="text-2xl font-bold">
                            Servicios de Matchworking</h1> */}
                                <MWServicesTable/>
                        </div>
                    )}
                    {/* {activeTab === 1 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Nuevo usuario registrado</h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )} */}

                    {/* Agrega más contenido según sea necesario */}
                </Content>
            </div>
        </div>
    );
};

export default MWServicesPage;


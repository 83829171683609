import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';
import recommendationCvService from '../services/recommendationCv';
import SatisfactionSurvey from './Satisfaction_survey';
import Swal from 'sweetalert2';

// Estilo de Markdown
const MarkdownWrapper = styled('div')({
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  overflowX: 'auto',
  whiteSpace: 'pre-wrap',
  color: '#333',
  zIndex: 1,
  position: 'relative',
  '& ol, & ul, & li': {
    textAlign: 'justify',
    padding: '2px 4px',
    paddingRight: '8px',
    margin: '0 4px'
  },
});

// Estilo fondo de pantalla
const Background = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: -1,
});


const RecommendationCv = ({ candidate }) => {
  const [markdownContent, setMarkdownContent] = useState('');
  const [showSurvey, setShowSurvey] = useState(false);

  const candidateId = candidate.candidateID;
  const handleSurveyClose = () => {
    setShowSurvey(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSurvey(true);
    }, 25000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchRecommendation = async () => {
      try {
        Swal.fire({
          title: "Generando recomendaciones..",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const data = await recommendationCvService.getRecommendationById(candidateId);

        if (data && data.cv_recomendations) {
          setMarkdownContent(data.cv_recomendations);
        } else {
          setMarkdownContent('No hay contenido disponible.');
        }
        Swal.close()
      } catch (error) {
        console.error('Error al cargar la recomendación:', error);
        Swal.close()
      }
    };

    fetchRecommendation();
  }, [candidateId]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <div className='border-b border-[#F0810F] my-4'>
        <h1>¡¡Uff!! fue difícil, pero está listo, te deseamos éxito en tu búsqueda de empleo</h1>
      </div>
      <div className='pb-10 mb-4'>
        <Background />
        <Container maxWidth="lg" style={{ padding: '1px', position: 'relative', zIndex: 1 }}>
          <MarkdownWrapper>
            <ReactMarkdown>
              {markdownContent}
            </ReactMarkdown>
          </MarkdownWrapper>
        </Container>
      </div>
      <div>
        {showSurvey && <SatisfactionSurvey data={candidateId} onClose={handleSurveyClose} />}
      </div>
      
    </div>
  );
};

export default RecommendationCv;

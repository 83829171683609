export const baseStyle = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '30px',
  borderWidth: 2,
  borderRadius: 4,
  marginTop: '10px',
  marginBottom: '5px',
  borderColor: '#ffffff',
  backgroundColor: '#011A27',
  color: '#aaaaaa',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

export const focusedStyle = {
  borderColor: '#2196f3'
};

export const acceptStyle = {
  borderColor: '#00e676'
};

export const rejectStyle = {
  borderColor: '#ff1744'
};

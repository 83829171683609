const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev=enviroment_dev.databaseUrl+"/admin";


const adminService = {
    
    // Método para enviar codigo de recuperación
    async sendCode(email) {
      const data ={"email":email};
      const response = await fetch(`${api_enviroment_dev}/recuperar-contrasena/send-code`, {
        method: 'POST',
        headers: {  
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return response.json();
    },

    // Método para validar codigo de recuperación
    async validateCode(codeSend,codeUser) {
        const data ={"codeSend":codeSend, "codeUser":codeUser};
        const response = await fetch(`${api_enviroment_dev}/recuperar-contrasena/validate-code`, {
          method: 'POST',
          headers: {  
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        return response.json();
      },
  };
  
  export default adminService;
  
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/list";
const listDataService = {
    /*******   HIRING REASON   **** */
    async createHiringReason(data) {
      const response = await fetch(`${api_enviroment_dev}/hiring-reason`,{
          method:'POST',
          headers:{
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
           });
                return response.json() ;   
    },

    async getAllHiringReason() {
        const response = await fetch(`${api_enviroment_dev}/hiring-reason`);
        return response.json();
    },
    async getAllHiringReasonByState(state) {
        const response = await fetch(`${api_enviroment_dev}/hiring-reason/search/${state}`);
        return response.json();
    },
    async editHiringReason(id,data) {
        const response = await fetch(`${api_enviroment_dev}/hiring-reason/${id}`,{
            method:'PUT',
            headers:{
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },

    /*******   EDUCATION LEVEL   **** */
    async createEducationLevel(data) {
        const response = await fetch(`${api_enviroment_dev}/education-level`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
             });
                  return response.json() ;   
      },
    async getAllEducationLevel() {
        const response = await fetch(`${api_enviroment_dev}/education-level`);
        return response.json();
    },
    async getAllEducationLevelByState(state) {
        const response = await fetch(`${api_enviroment_dev}/education-level/search/${state}`);
        return response.json();
    },

    async editEducationLevel(id,data) {
        const response = await fetch(`${api_enviroment_dev}/education-level/${id}`,{
            method:'PUT',
            headers:{
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },

    /*******   REPLACE REASON   **** */

    async getAllReplaceReason() {
        const response = await fetch(`${api_enviroment_dev}/replace-reason`);
        return response.json();
    },
    async createReplaceReason(data) {
        const response = await fetch(`${api_enviroment_dev}/replace-reason`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },
    async getAllReplaceReasonByState(state) {
        const response = await fetch(`${api_enviroment_dev}/replace-reason/search/${state}`);
        return response.json();
    },
    async editReplaceReason(id,data) {
        const response = await fetch(`${api_enviroment_dev}/replace-reason/${id}`,{
            method:'PUT',
            headers:{
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },


    /*******   Employment Type   **** */
      
    async getAllEmploymentType() {
        const response = await fetch(`${api_enviroment_dev}/employment-type`);
        return response.json();
    },
    async createEmploymentType(data) {
        const response = await fetch(`${api_enviroment_dev}/employment-type`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },
    async getAllEmploymentTypeByState(state) {
        const response = await fetch(`${api_enviroment_dev}/employment-type/search/${state}`);
        return response.json();
    },
    async editEmploymentType(id,data) {
        const response = await fetch(`${api_enviroment_dev}/employment-type/${id}`,{
            method:'PUT',
            headers:{
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },

    /*******   Work Mode   **** */
      
    async getAllWorkMode() {
        const response = await fetch(`${api_enviroment_dev}/work-mode`);
        return response.json();
    },
    async createWorkMode(data) {
        const response = await fetch(`${api_enviroment_dev}/work-mode`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },
    async getAllWorkModeByState(state) {
        const response = await fetch(`${api_enviroment_dev}/work-mode/search/${state}`);
        return response.json();
    },
    async editWorkMode(id,data) {
        const response = await fetch(`${api_enviroment_dev}/work-mode/${id}`,{
            method:'PUT',
            headers:{
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json() ;   
    },
  
  };
  
  export default listDataService;
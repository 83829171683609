import React from 'react'
import mwLogo from '../../../assets/img/LOGOMW.png'
import useImagePreloader from '../../../hooks/useImagePreloader';
const ArticuloCompraDetailComponent = ({nombre,precio}) => {
    useImagePreloader(mwLogo)

    return (
        <div className='w-full flex flex-row items-center justify-between'>
            <div className='flex flex-row items-center justify-center'>
                <img
                    src={mwLogo}
                    alt="Producto"
                    className='w-14 h-14'
                />
                <div>{nombre}</div>
            </div>
            <div>${precio}</div>
        </div>
    )
}

export default ArticuloCompraDetailComponent

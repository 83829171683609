const Swal = require('sweetalert2');
const acceptButtonColor="#F0810F";//"#E6DF44" ;
const cancelButtonColor="#063852";//"#011A27";
const showAlert = {
  success: (message,title = "Todo Listo") => {
    return Swal.fire({ 
      icon: 'success',
      title: title,
      text: message,
      confirmButtonColor: acceptButtonColor
    });
  },
  error: (message) => {
    Swal.fire({ 
      icon: 'error',
      title: 'Oops...',
      text: message,
      confirmButtonColor: acceptButtonColor,
    });
  },
  confirmation:(titleText,messageText) =>{
    let alertInstance; // Almacena la instancia de la alerta

    const promise = new Promise((resolve) => {
      alertInstance = Swal.fire({
            title: titleText,
            text: messageText,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: acceptButtonColor,
            cancelButtonColor: cancelButtonColor,
            focusConfirm: false,
            confirmButtonText: `
                <i class="fa fa-thumbs-up"></i> Acepto!
            `,
    
            cancelButtonText: `
                <i class="fa fa-thumbs-down"></i>
            `,
            cancelButtonAriaLabel: "Thumbs down"
          }).then((result) => {
            if (result.isConfirmed) {
                resolve(true); 
              } else {
                resolve(false); 
              }
          });
    });
    promise.closeAlert = () => {
      if (alertInstance) {
        Swal.close();
      }
    };

    return promise;
  },
  loanding:() =>{
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }
};

module.exports = showAlert;

import { useEffect, useState } from 'react';

const useImagePreloader = (src) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setIsLoaded(true);
        };
    }, [src]);

    return isLoaded;
};

export default useImagePreloader;

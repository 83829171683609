import React from 'react';
import AnalizeCVForm from '../../components/analizer/analize-CV';


 
const AnalizerPage = () => {
  return (
    <div >
      <AnalizeCVForm/>
    </div>
  );
}

export default AnalizerPage;

import { SelectedArticuloInitState } from "./BilleteraInitState";

export const SelectedArticuloReducer = (state,action) => {
    switch (action.type) {
        case "SET":
            state = action.payload
            return state;
        case "RESET":
            state = {
                ...SelectedArticuloInitState
            }
            return state;
        default:
            return false;
    }
}
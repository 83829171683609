import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import termsService from '../services/termsService';
import showAlert from '../utilities/alerts';
import mwServices from '../services/mw_servicesS';

const TermsModal = ({ onClose, type }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [termsId, setTermsId] = useState("");  //Asiga el id de los términos y condiciones ya creados, a cada servicio por página
  const [terms, setTerms] = useState('');

  const location = useLocation().pathname;

  // const pathname = window.location.pathname //Obtiene el nombre de la página donde se usa el modal de términos y condiciones

  useEffect(() => {
    const getServices = async () => {
      try {
        const services = await mwServices.getByState();
        // Actualiza el estado con los datos del usuario
        if (location === '/sign-up' || location.startsWith('/form-cv') || location.startsWith('/reclutamiento') ) {   // Si el endpoint es igual a "sign-up asigna el termsId por defecto"        
          setTermsId(type === 'privacy' ? 5 : 1);
        } else {
          const filtered = services.filter(item => item.mw_services_slug === location);
          setTermsId(filtered[0].terms_id);
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario", error);
      }
      // Realiza la comparación aquí

    };
    getServices();
    //switch para guardar el termsId según el sitio donde se encuentre
    // switch (location) {
    //   case "/sign-up":
    //     setTermsId(1);
    //     break;
    //   case "/regulation-page":
    //     setTermsId(2);
    //     break;
    //   case "/perfil-cargo-form":
    //     setTermsId(3);
    //     break;
    //   case "/requirement-page":
    //     setTermsId(4);
    //     break;
    //   default:
    //     setTermsId(1);
    // }
  }, [location]);


  useEffect(() => {
    if (termsId) {
      const fetchTerms = async () => {
        try {
          const data = await termsService.getTermsByID(termsId);
          // console.log('Fetched data:', data);
          setTerms(data.terms_text || '');
        } catch (error) {
          showAlert.error('Ocurrió un error al obtener los términos y condiciones, intentalo más tarde..');
          console.log(error);
        }
      };

      fetchTerms();
    }
  }, [termsId]);

  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Llama a la función onClose proporcionada por el padre
  };

  return (
    // Renderiza la ventana emergente solo si isOpen es true
    isOpen && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full">
          <div className="p-4 border-b border-gray-400">
            {termsId === 5 ? (
              <h2 className="text-2xl text-gray-700 font-bold">Políticas de privacidad</h2>
            ) : (
              <h2 className="text-2xl text-gray-700 font-bold">Términos y condiciones</h2>
            )}

          </div>
          <div className="max-h-96 overflow-y-auto p-4">
            {!terms ? (
              <p className='text-black'>Cargando...</p>
            ) : (
              <div
                className="text-gray-700 text-start whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: terms }}
              />
            )}
          </div>
          <div className="p-4 border-t flex justify-end">
            <button
              className="px-4 py-2 bg-[#F0810F] text-white rounded hover:bg-red-900 focus:outline-none"
              onClick={handleClose}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default TermsModal;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoCheckmarkCircle, IoDocument } from "react-icons/io5";
import InformacionPostulante from '../../pages/rankingCVs/informacionPostulante';
import { Box } from '@mui/material';
import { FaBackward } from 'react-icons/fa';
import { useUser } from '../../VariablesContext';
import userService from '../../services/userService';
import reportService from '../../services/reportExcel';
import Swal from 'sweetalert2';

function TablaRanking({ datos }) {
  const { userId } = useUser();
  const [dataUsuarios, setDataUsuarios] = useState("");
  const navigate = useNavigate();
  const [showSurvey, setShowSurvey] = useState(false);

  let numeroRanking = 1
  const [scrolled, setScrolled] = useState(false)
  const tableRef = useRef(null)
  const [selectedDato, setSelectedDato] = useState(null);

  const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 });

  const handleMouseEnter = async (event, inf) => {
    const alert = inf
    let tooltipContent = '';

    if (alert.alertActive) {
      let alerts = alert.description
      const observations = await alerts.map((alerta) => {
        return alerta.description
      })
      tooltipContent = `Observaciones: ${observations.join(', ')}`;
    }
    setTooltip({
      visible: true,
      content: tooltipContent,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  const handleInformacionPostulante = (dato) => {
    setSelectedDato(dato);
  };

  // const handleSurveyClose = () => {
  //   setDownloadEnabled(true);
  //   setShowSurvey(false);
  // };
  useEffect(() => {
    // setShowSurvey(true) // Corregir encuesta: guardar si el user ya lleno la encuesta en localStorage
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      navigate("/");
    } else {
      const fetchUserData = async () => {
        try {
          const dataUser = await userService.getUserId(userId);
          // console.log(dataUser);
          // Actualiza el estado con los datos del usuario.                    
          setDataUsuarios(dataUser);
        } catch (error) {
          console.error("Error al obtener los datos del usuario", error);
        }
      };
      // Llama a la función para obtener los datos del usuario
      fetchUserData();
    }


  }, [userId])

  useEffect(() => {
    const tableElement = tableRef.current;

    if (tableElement) {
      const handleScrollEvent = () => {
        if (tableElement.scrollTop > 0) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      tableElement.addEventListener('scroll', handleScrollEvent);

      return () => {
        tableElement.removeEventListener('scroll', handleScrollEvent);
      };
    }
  }, [])

  const downloadDocument = (data) => {
    reportService.reportGenerate(data)
  }
  return !selectedDato ? (
    <div ref={tableRef} className='my-4 mx-3 px-3 bg-grayMatch rounded-2xl overflow-y-auto no-scrollbar' >
      <div
        className='cursor-pointer text-right pb-2'
        onClick={() => downloadDocument(datos)}>
        <div className='inline-block align-middle mr-2 min-lg:hidden'>
          <IoDocument className='rounded-full text-3xl text-greenIcon' />
        </div>
        <span className='font-bold'>
          Descargar en Excel
        </span>
      </div>
      <div ref={tableRef}>
        <table className='border-separate border-spacing-y-3'>
          <thead className={`${scrolled ? 'sticky top-0 bg-grayMatch z-10 h-9' : ''}`}>
            <tr>
              <th className='w-10'>Nº</th>
              <th className='w-48'>Nombre</th>
              <th className='w-48'>Título</th>
              <th className='w-48'>Cargo Postulado</th>
              <th className='w-40'>Departamento</th>
              <th className='w-40'>CV Modificado</th>
              <th className='px-3'>Experiencia</th>
              <th className='px-3'>Formación</th>
              <th className='px-3'>Habilidades</th>
              <th className='px-3'>Similitud Total</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((dato, index) => (
              <tr
                key={index}
                className='bg-greenBgFila hover:bg-[#bbbbbb] cursor-pointer'
                title={`${numeroRanking}. ${dato.nombre} - ${dato.similitudTotal} %`}
                onClick={() => handleInformacionPostulante(dato)}
              // onMouseEnter={(e) => handleMouseEnter(e, dato.analisis.alertas)}
              // onMouseLeave={handleMouseLeave}
              >
                <td className='bg-grayMatch text-center'>{numeroRanking++}</td>
                <td className='rounded-l-2xl text-left'>
                  <div className='inline-block align-middle mr-2 min-lg:hidden'>
                    <IoCheckmarkCircle className='rounded-full text-3xl text-greenIcon' />
                  </div>
                  <span className='min-[1000px]:ml-2'>{dato.nombre}</span>
                </td>
                <td className='text-center'>{dato.titulo.length > 1 ? dato.titulo.join(' / ') : dato.titulo}</td>
                <td className='text-center'>{dato.cargoPostulado}</td>
                <td className='text-center'>{dato.departamento}</td>
                <td className='text-center'>{dato.modificaCV}</td>
                <td className='text-center'>{dato.experiencia} %</td>
                <td className='text-center'>{dato.formacion} %</td>
                <td className='text-center'>{dato.habilidades} %</td>
                <td className='rounded-r-2xl text-center'>{dato.similitudTotal} %</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {tooltip.visible && (
          <div className="absolute bg-gray-900 min-w-10 bg-opacity-70 text-white p-1 rounded pointer-events-none z-50" style={{ left: tooltip.x, top: tooltip.y }}>
            {tooltip.content}
          </div>
        )} */}
      </div>
      <div>
        {/* {showSurvey && <SatisfactionSurvey data={dataUsuarios} onClose={handleSurveyClose} />} */}
      </div>
    </div>
  ) : (
    <div className='flex flex-col'>
      <Box>
        <button
          onClick={() => handleInformacionPostulante(null)}
          className='hover:scale-105 flex flex-row items-center justify-center gap-3'>
          <FaBackward /> Ver Ranking
        </button>
      </Box>
      <InformacionPostulante dato={selectedDato} />
    </div>
  )
}

export default TablaRanking
import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/report";

const reportService = {
    reportGenerate: async (data) => {
        try {
            const response = await axios.post(`${api_enviroment_dev}`, data, {
                responseType: 'blob'
            });            
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
            const url = window.URL.createObjectURL(blob);
            
            const link = document.createElement('a');
            link.href = url;

            // Definir nombre de archivo de descarga
            link.setAttribute('download', 'Reporte.xlsx');
            
            document.body.appendChild(link);
            link.click();

            // Limpiar el DOM
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al generar el documento:', error);            
        }
    },
}
export default reportService;
import React from 'react'
import PayPhoneButton from '../../payphone/PayPhoneButtonP'
import { useSelectedArticuloValue } from '../BilleteraState/BilleteraContext'

const MainPayphoneComponent = () => {
    const selected = useSelectedArticuloValue()

    return (
        <div className='w-[400px] py-3 px-2'>
            <div className='w-full text-left pl-5 border-b-2
        border-gray-200 mb-1 text-3xl font-light 
        '>
                Payphone
            </div>
            <PayPhoneButton
                reference={selected.producto}
                priceWithoutTax={"" + selected.precio}
                priceWithTax=""
                taxPercentage="0"
            />
        </div>
    )
}

export default MainPayphoneComponent

import { FaRegUserCircle } from "react-icons/fa";
import { useEffect, useState } from 'react';

function InformacionPostulante({ dato }) {  
  // const [alert, setAlert] = useState(null);    
  // useEffect(() => {
  //   const alerts = dato?.analisis?.alertas?.alertActive || false;
  //   if (alerts) {
  //     const observations = dato?.analisis?.alertas?.description || [];
  //     setAlert(observations.map((obs) => obs.description || ''));
  //   }
  // }, [dato]);

  const studies = dato.titulo.map((t, index) => `${t} | ${dato.institucion[index] || ''}`);

  return (
    <div className='my-8 grid grid-cols-4 grid-rows-[auto,auto] gap-4 max-[844px]:flex max-[844px]:flex-col max-[844px]:my-24 max-[844px]:mx-4'>
      <section className='row-span-2 bg-[#d6dfea] rounded-2xl p-4 flex flex-col items-center text-xs max-[844px]:text-lg '>
        {/* {alert && <div className='flex flex-col mb-2 border-2 border-dashed border-[#FF8201] bg-white rounded-lg w-full text-center items-center hover:scale-110 transition-all duration-300 ease-in-out'>
          <p className="m-0 mt-1 text-base font-medium">Observaciones:</p>
          {alert.map((obs, index) => (
            <p className="items-center text-black p-2 pt-0 my-auto text-xs" key={index}>- {obs}</p>
          ))}
          </div>} */}
        <FaRegUserCircle className='text-7xl bg-white rounded-full mb-3' />
        <article className='flex flex-col items-center mb-3 w-full'>
          <p className='text-xl'><strong>{dato.nombre}</strong></p>
          {dato.titulo ? dato.titulo.map((t, index) => (
            <p key={index}>{t}</p>
          )) : (
            <p></p>
          )}
          <p>{dato.email}</p>
          <p>{dato.phone}</p>
          <p>{dato.direccion}</p>
        </article>
        <article className='flex flex-col mb-3 '>
          <p><strong>Cargo Postulado:</strong> {dato.cargoPostulado}</p>
          <p><strong>Departamento:</strong> {dato.departamento}</p>
          <p><strong>Fecha de modificacion CV:</strong> {dato.modificaCV}</p>
          <p><strong>Experiencia:</strong> {dato.anios_experiencia} años</p>
        </article>
        <article className='flex flex-col items-center w-full mb-3 text-center'>
          <p><strong>Estudios Realizados</strong></p>
          {studies ? (studies.map((dato, idx) => (
            <p key={idx}>{dato}</p>
          ))) : (
            <p>{dato.titulo || ''} | {dato.institucion || ''}</p>
          )
          }
        </article>
        <article className='flex flex-col items-center mb-3 text-center'>
          <p><strong>Certificados</strong></p>
          {dato.certificados ? (
            <ul>
              {dato.certificados.map((certificado, index) => (
                <li key={index}><strong>-</strong> {certificado}</li>
              ))}
            </ul>
          ) : (
            <p>No hay certificados</p>
          )}
        </article>
        <article className='flex flex-col items-center mb-3'>
          <p><strong>Idiomas</strong></p>
          {dato.idiomas ? (
            <ul>
              {dato.idiomas.map((idioma, index) => (
                <li key={index}><strong>-</strong> {idioma.lenguaje}: {idioma.nivelLenguaje}</li>
              ))}
            </ul>
          ) : (
            <p>No hay idiomas</p>
          )}
        </article>
        <a href={dato.pdfUrlf}
          target='_blank'
          download
          className='bg-descargarCV py-2 px-4 bg-white rounded-lg shadow-md font-bold'>
          Descargar Hoja de Vida
        </a>
        {/*<button className='bg-descargarCV py-2 px-4 rounded-lg shadow-md font-bold'>Descargar Hoja de Vida</button>*/}
      </section>
      <section className='bg-[#d6dfea] rounded-2xl p-4'>
        <p className='text-lg'><strong>Estadísticas de Experiencia</strong></p>
        <div className='flex my-4 text-sm max-[844px]:text-lg'>
          <article className='mr-2'>
            <p><strong>Experiencia en cargos similares</strong></p>
            <p>{dato.anios_experiencia} años</p>
          </article>
          <article className=''>
            <p><strong>Compatibilidad con el puesto</strong></p>
            <p className='text-center text-4xl my-1'>{dato.experiencia} %</p>
          </article>
        </div>
        <article className='text-sm max-[844px]:text-lg'>
          <p>{dato.experiencia_motivo}</p>
        </article>
      </section>
      <section className='bg-[#d6dfea] rounded-2xl p-4'>
        <p className='text-lg'><strong>Estadísticas de Formación Acádemica</strong></p>
        <div className='flex my-4 text-sm max-[844px]:text-lg'>
          <article className='mr-2'>
            <p><strong>Nivel Educativo</strong></p>
            <p>{dato.titulo}</p>
          </article>
          <article className=''>
            <p><strong>Compatibilidad con el puesto</strong></p>
            <p className='text-center text-4xl my-1'>{dato.formacion} %</p>
          </article>
        </div>
        <article className='text-sm max-[844px]:text-lg'>
          <p>{dato.nivelEducacion_motivo}</p>
        </article>
      </section>
      <section className='bg-[#d6dfea] rounded-2xl p-4'>
        <p className='text-lg '><strong>Estadísticas de Habilidades</strong></p>
        <div className='flex my-4 text-sm max-[844px]:text-lg'>
          <article className='mr-2'>
            <p><strong>Certificados Relevantes</strong></p>
            {dato.certificados ? (
              <ul>
                {dato.certificados.map((certificado, index) => (
                  <li key={index}><strong>-</strong> {certificado}</li>
                ))}
              </ul>
            ) : (
              <p>No hay certificados</p>
            )}
          </article>
          <article className=''>
            <p><strong>Compatibilidad con el puesto</strong></p>
            <p className='text-center text-4xl my-1'>{dato.habilidades} %</p>
          </article>
        </div>
        <article className='text-sm max-[844px]:text-lg'>
          <p>{dato.habilidades_motivo}</p>
        </article>
      </section>
      <section className='col-span-3 bg-[#d6dfea] rounded-2xl p-4 max-h-fit'>
        <p className='text-lg'><strong>Resumen y Resultado General</strong></p>
        <div className='flex my-4 text-sm justify-between max-[844px]:text-lg'>
          <article className='mr-2'>
            <p><strong>Porcentaje general de compatibilidad</strong></p>
            <p>Total de Puntos Posibles: 3</p>
            <p>Total de Puntos Logrados: 2.7 ({dato.similitudTotal}% de 3)</p>
          </article>
          <article className=''>
            <p><strong>COMPATIBILIDAD</strong></p>
            <p className='text-center text-6xl my-1 text-resultadoFinal max-[844px]:text-5xl'>{dato.similitudTotal} %</p>
          </article>
        </div>
        <article className='text-sm max-[844px]:text-lg'>
          <p><strong>Conclusión</strong></p>
          <p>{dato.conclusion}</p>
        </article>
      </section>
    </div>
  );
}

export default InformacionPostulante;
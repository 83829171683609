import './regulationPage.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectRegulation from '../../components/regulationComponents/SelectRegulation';
import FormRegulation from '../../components/regulationComponents/Form_regulation';
import GenerateRegulation from '../../components/regulationComponents/GenerateRegulation';
import FilesRegulation from '../../components/regulationComponents/FilesRegulation';
import backgroundImage from "../../assets/img/fondomw.png";

const RegulationPage = () => {
  const navigate = useNavigate();
  const [regulationData, setRegulationData] = useState({
    selectedRegulation: null,
    formData: null,
    generatedData: null,
    paymentData: null,
    filesData: null
  });

  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div
      className="bg-cover min-h-screen"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Asegura que cubra toda la pantalla
    }}
    >
      <div className="border-spacing-1">
        <div className="flex justify-center items-center ">
          {currentStep === 1 && (
            <SelectRegulation
              regulationData={regulationData}
              setRegulationData={setRegulationData}
              onNextStep={handleNextStep}
            />
          )}
          {currentStep === 2 && (
            <FormRegulation
              regulationData={regulationData}
              setRegulationData={setRegulationData}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
            />
          )}
          {currentStep === 3 && (
            <GenerateRegulation
              regulationData={regulationData}
              setRegulationData={setRegulationData}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
            />
          )}
          {currentStep === 4 && (
            <FilesRegulation
              regulationData={regulationData}
              setRegulationData={setRegulationData}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegulationPage;

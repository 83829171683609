import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const esLocale = require('dayjs/locale/es');
dayjs.locale(esLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Guayaquil');
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint } from '@fortawesome/free-solid-svg-icons';

const DocumentSecurity = ({ data }) => (
    <Document>
        <Page style={styles.body} wrap>
            <Text style={styles.title}>REGLAMENTO INTERNO HIGIENE Y SEGURIDAD OCUPACIONAL DE LA EMPRESA {data.companyName.toUpperCase()}</Text>
            <Text style={styles.text}>CAPÍTULO I DATOS GENERALES DE LA EMPRESA.....................................................................2</Text>
            <Text style={styles.textRight}>DEFINICIONES...............................................................................................................................2</Text>
            <Text style={styles.textRight}>1.	Objetivos específicos.................................................................................................................2</Text>
            <Text style={styles.textRight}>2.	Ámbito de aplicación..................................................................................................................2</Text>
            <Text style={styles.text}>CAPÍTULO II DISPOSICIONES REGLAMENTARIAS.....................................................................3</Text>
            <Text style={styles.textRight}>1.	Obligaciones generales del empleador......................................................................................3</Text>
            <Text style={styles.textRight}>2.	Obligaciones generales y derechos de los colaboradores/as...................................................3</Text>
            <Text style={styles.textRight}>3.	Prohibiciones del empleador y colaboradores/as......................................................................3</Text>
            <Text style={styles.textRight}>4.	Obligaciones y responsabilidades.............................................................................................3</Text>
            <Text style={styles.text}>CAPÍTULO III  GESTIÓN DE PREVENCIÓN DE RIESGOS EN EL TRABAJO.................................4</Text>
            <Text style={styles.textRight}>1.	Organismos Paritarios; Conformación y Funciones (comité, subcomité y/o delegados)...............4</Text>
            <Text style={styles.textRight}>2.	Gestión de riesgos laborales propios de la empresa:..................................................................4</Text>
            <Text style={styles.textRight}>3.	De los trabajos de alto riesgo y/o especiales.................................................................................4</Text>
            <Text style={styles.textRight}>4.	Prevención de amenazas naturales y riesgos antrópicos:............................................................4</Text>
            <Text style={styles.textRight}>5.	Documentos técnicos de Higiene y Seguridad:.............................................................................4</Text>
            <Text style={styles.textRight}>6.	Gestión de Salud en el Trabajo.....................................................................................................4</Text>
            <Text style={styles.text}>CAPÍTULO IV PROGRAMAS DE PREVENCIÓN.............................................................................5</Text>
            <Text style={styles.textRight}>1.	Programa de Prevención al uso y consumo de drogas en espacios laborales..............................5</Text>
            <Text style={styles.textRight}>2.	Programa de Prevención de Riesgos Psicosociales....................................................................5</Text>
            <Text style={styles.text}>CAPÍTULO V REGISTRO, INVESTIGACIÓN Y NOTIFICACIÓN DE ACCIDENTES DE TRABAJO, ENFERMEDADES PROFESIONALES E INCIDENTES..................................................................................7</Text>
            <Text style={styles.textRight}>1.	Registro y estadísticas...............................................................................................................7</Text>
            <Text style={styles.textRight}>2.	Procedimiento en caso de accidente de trabajo y enfermedades profesionales.......................7</Text>
            <Text style={styles.textRight}>3.	Investigación..............................................................................................................................7</Text>
            <Text style={styles.textRight}>4.	Notificación.................................................................................................................................7</Text>
            <Text style={styles.textRight}>5.	Readecuación, reubicación y reinserción de colaboradores/as...................................................7</Text>        
            <Text style={styles.text}>CAPÍTULO VI INFORMACIÓN, CAPACITACIÓN, CERTIFICACIÓN DE COMPETENCIAS Y ENTRENAMIENTO EN PREVENCIÓN DE RIESGOS LABORALES..................................................................9</Text>
            <Text style={styles.textRight}>1.	Información...............................................................................................................................9</Text>
            <Text style={styles.textRight}>2.	Capacitación..............................................................................................................................9</Text>
            <Text style={styles.text}>CAPÍTULO VII INCUMPLIMIENTOS Y SANCIONES.....................................................................10</Text>
            <Text style={styles.textRight}>1.	Incumplimientos.......................................................................................................................10</Text>
 
        </Page>
        <Page style={styles.body} wrap>
            <Text style={styles.subtitle}>CAPITULO I: DATOS GENERALES DE LA EMPRESA</Text>
            <Text style={styles.text}>{data.city} - {data.address}</Text>
            <Text style={styles.text}>{data.businessName}</Text>
            <Text style={styles.text}>{data.economicActivity}</Text>
            <Text style={styles.text}>Centro de trabajo: {data.workCenters}</Text>
            <Text style={styles.text}>RUC: {data.ruc}</Text>
            <Text style={styles.header}>Definiciones</Text>
            <Text style={styles.text}>Peligro:  Toda fuente con capacidad de daño</Text>
            <Text style={styles.text}>Riesgo: Probabilidad y consecuencia de la exposición a un factor de riesgo ocasione lesión o perturbación funcional.</Text>
            <Text style={styles.text}>Empleador: La persona o entidad, de cualquier clase que fuere, por cuenta u orden de la cual se ejecuta la obra o a quien se presta el servicio. </Text>
            <Text style={styles.text}>Colaborador/a: Término utilizado en LA EMPRESA para referirnos a la persona que se obliga a la prestación del servicio o a la ejecución de la obra se denomina colaborador/a y puede ser empleado u obrero. </Text>
            <Text style={styles.text}>Colaborador/a calificado o competente: Aquel colaborador/a que, a más de los conocimientos y experiencia en el campo de su actividad específica, los tuviera en la prevención de riesgos dentro de su ejecución.</Text>
            <Text style={styles.text}>Partes Interesadas: Instituciones o personas que realizan actividades con la institución.</Text>
            <Text style={styles.text}>Sistema gestión de la Higiene y Seguridad en el trabajo: Es el conjunto de elementos interrelacionados e interactivo que tienen por objeto establecer una política y objetivos de Higiene y Seguridad en el trabajo y la forma de alcanzarlos. </Text>
            <Text style={styles.text}>Política de Seguridad: Compromiso de la institución por medio de la Alta Dirección.</Text>
            <Text style={styles.text}>Comité Paritario de Higiene y Seguridad: Organización conformada de igual número y participación por representantes de trabajadores y empleadores.</Text>
            <Text style={styles.text}>Delegados de Seguridad y Salud: Personas elegidas por sus compañeros en sitios donde no se llegue a número mínimo para conformar Comité o Subcomité de Seguridad.</Text>
            <Text style={styles.text}>Ambiente insalubre: Riesgo presente en Área de trabajo que puede afectar la salud de los trabajadores.</Text>
            <Text style={styles.text}>Acto Subestándar: Falla humana por no cumplir estándares o normas de seguridad.</Text>
            <Text style={styles.text}>Condición Subestándar: Falla técnica no inherente al trabajador que incumple estándares o normas de seguridad.</Text>
            {/* <Text style={styles.text}>Inspecciones: Verificación de condiciones en el sitio de trabajo.</Text> */}
            {/* <Text style={styles.text}>Protección Colectiva: Protección General establecida en el diseño de infraestructura, máquinas o herramientas.</Text> */}

        </Page>
        <Page style={styles.body} wrap>
            <Text style={styles.text}>Quedan incorporadas al presente REGLAMENTO DE HIGIENE Y SEGURIDAD, todas las disposiciones contenidas en la Constitución del Ecuador, el Código de Trabajo, sus reglamentos complementarios, los reglamentos y normas aplicables sobre seguridad y salud ocupacional en general, las resoluciones y disposiciones emitidas por el IESS y las normas internacionales de obligatorio cumplimiento en el País, las mismas que prevalecerán en todo caso.</Text>
            <Text style={styles.header}>DISPOSICIONES FINALES</Text>
            <Text style={styles.text}>El presente Reglamento de Higiene y Seguridad entrará en vigencia a partir de la aprobación por parte del Director Regional del Trabajo y Servicio Público.
                Dado en la ciudad de Quito, el día {dayjs().format('DD [de] MMMM [del] YYYY')}.
            </Text>
            <Text style={styles.text}>
            </Text>
            <Text style={styles.text}>Firma</Text>
            <Text style={styles.text}>{data.nameSST}</Text>
            <Text style={styles.textBold}>{data.chargeSST}</Text>
            <Text style={styles.text}>{data.idSST}</Text>
            <Text style={styles.text}>
            </Text>
            <Text style={styles.text}>Firma</Text>
            <Text style={styles.text}>{data.nameResponsible}</Text>
            <Text style={styles.textBold}>{data.chargeResponsible}</Text>
            <Text style={styles.text}>{data.idResponsible}</Text>
        </Page>
    </Document>
);
const DocumentInternal = ({ data }) => (

    <Document>
        <Page style={styles.body} wrap>
            <Text style={styles.title}>REGLAMENTO INTERNO DE TRABAJO DE LA EMPRESA {data.companyName.toUpperCase()} S.A</Text>
            <Text style={styles.text}>CAPÍTULO I.....................................................................................................................................2</Text>
            <Text style={styles.textRight}>GENERALIDADES...........................................................................................................................2</Text>
            <Text style={styles.text}>CAPÍTULO II....................................................................................................................................3</Text>
            <Text style={styles.textRight}>REQUISITOS A LOS QUE DEBEN SUJETARSE LOS TRABAJADORES.......................................3</Text>
            <Text style={styles.text}>CAPÍTULO III...................................................................................................................................4</Text>
            <Text style={styles.textRight}>DE LAS REMUNERACIONES..........................................................................................................4</Text>
            <Text style={styles.text}>CAPÍTULO IV...................................................................................................................................5</Text>
            <Text style={styles.textRight}>HORARIOS DE TRABAJO...............................................................................................................5</Text>
            <Text style={styles.text}>CAPÍTULO V....................................................................................................................................7</Text>
            <Text style={styles.textRight}>OBLIGACIONES GENERALES DE LOS TRABAJADORES............................................................7</Text>
            <Text style={styles.text}>CAPÍTULO VI...................................................................................................................................9</Text>
            <Text style={styles.textRight}>DE LAS VACACIONES ANUALES, PERMISOS Y DÍAS NO LABORABLES...................................9</Text>
            <Text style={styles.text}>CAPÍTULO VII................................................................................................................................10</Text>
            <Text style={styles.textRight}>DERECHOS, OBLIGACIONES Y PROHIBICIONES DE LOS TRABAJADORES...........................10</Text>
            <Text style={styles.text}>CAPÍTULO VIII...............................................................................................................................18</Text>
            <Text style={styles.textRight}>SEGURIDAD E HIGIENE EN EL TRABAJO...................................................................................18</Text>
            <Text style={styles.text}>CAPÍTULO IX.................................................................................................................................19</Text>
            <Text style={styles.textRight}>LUGAR LIBRE DE ACOSO............................................................................................................19</Text>
            <Text style={styles.text}>CAPÍTULO X..................................................................................................................................20</Text>
            <Text style={styles.textRight}>FACULTADES, OBLIGACIONES Y PROHIBICIONES DEL EMPLEADOR....................................20</Text>
            <Text style={styles.text}>CAPÍTULO XI.................................................................................................................................23</Text>
            <Text style={styles.text}>CAPÍTULO XII................................................................................................................................23</Text>
            <Text style={styles.textRight}>DISPOSICIONES DISCIPLINARIAS Y FORMA DE APLICARLAS.................................................23</Text>
            <Text style={styles.text}>CAPÍTULO XIII...............................................................................................................................24</Text>
            <Text style={styles.textRight}>DISPOSICIONES GENERALES...................................................................................................24</Text>
        </Page>
        <Page style={styles.body} wrap>
            <Text style={styles.subtitleTwo}>CAPÍTULO I</Text>
            <Text style={styles.subtitleUnderline}>GENERALIDADES</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 1. </Text>El presente Reglamento Interno de Trabajo, tiene por objetivo establecer con claridad y en forma detallada las reglas obligatorias de orden técnico y administrativo que normarán y regularán la relación laboral entre La empresa y sus Trabajadores. </Text>
            <Text style={styles.text}>Sus disposiciones obligan tanto al Empleador, como a todos sus Trabajadores y a todas sus dependencias actualmente existentes en el país. </Text>
            <Text style={styles.text}>Este Reglamento hace parte de las disposiciones legales, los contratos individuales de trabajo celebrados o que se celebren con todos los Trabajadores, las funciones generales o específicas de los Trabajadores, las políticas, los programas, las circulares, carteleras y en general, todas las instrucciones impartidas por el Empleador.</Text>
            <Text style={styles.text}>La Empresa, está domiciliada en la ciudad de {data.address} y tiene como objeto {data.companyDescription}.</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 2. </Text>Para los efectos de aplicación del presente Reglamento y de acuerdo con lo establecido en el Código del Trabajo vigente se entenderá por:</Text>
            <Text style={styles.sectionSelect}>        a)	“La Empresa”</Text>
            <Text style={styles.sectionSelect}>        b)	“Empleador”: “La Empresa”  </Text>
            <Text style={styles.sectionSelect}>        c)	“Representante Legal”: El presidente, Gerente General y en general las personas que ejercen funciones de dirección de la Empresa.</Text>
            <Text style={styles.sectionSelect}>        d)	“Trabajador” o “Trabajadores”: según sea el caso, para hacer referencia al personal que en virtud de un contrato de trabajo presta sus servicios a la empresa.</Text>
            <Text style={styles.sectionSelect}>        e)	“Reglamento” “RIT”: para hacer mención del presente Reglamento Interno de Trabajo y sus reformas.</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 3. </Text>Según la relación de trabajo que lo vincula con el Empleador, el personal se clasifica en:</Text>
            <Text style={styles.sectionSelect}>        a)	Personal Permanente: son los Trabajadores que han celebrado contratos de trabajo con la Empresa, por tiempo indefinido, prestando sus servicios a la empresa para actividades regulares.</Text>
            <Text style={styles.sectionSelect}>        b)	Personal Temporal o Eventual: son los Trabajadores que se contratan para efectuar labores de carácter transitorio, temporal o eventual, reconociéndosele todas las prestaciones que indican las leyes laborales.</Text>
            <Text style={styles.text}>El Empleador está facultado a contratar Trabajadores bajo cualquier modalidad permitida por las leyes ecuatorianas.</Text>


        </Page>
        <Page style={styles.body} wrap>
            <Text style={styles.text}>La enumeración de las medidas disciplinarias señaladas en el presente Artículo establece una graduación de severidad, lo que no significa que deban aplicarse correlativa o sucesivamente.</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 49. </Text>En la aplicación de medidas disciplinarias, se tomará en cuenta lo siguiente: </Text>
            <Text style={styles.sectionSelect}>        a)	Actuar con oportunidad y con observancia a los antecedentes del colaborador. </Text>
            <Text style={styles.sectionSelect}>        b)	Considerar la naturaleza de la falta, antecedentes y circunstancias en las que fue cometida. </Text>
            <Text style={styles.sectionSelect}>        c)	Considerar la responsabilidad del colaborador, sus antecedentes y la gravedad de la falta, así como el perjuicio ocasionado a LA EMPRESA </Text>
            <Text style={styles.subtitleTwo}>CAPÍTULO XII</Text>
            <Text style={styles.subtitleUnderline}>DISPOSICIONES GENERALES</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 50. </Text>El presente Reglamento Interno de Trabajo regirá para todos los Trabajadores del Empleador, una vez que sea aprobado por la Dirección Regional del Trabajo del Ministerio del Trabajo.</Text>
            <Text style={styles.text}><Text style={styles.textBold}>Art. 51. </Text>El Empleador se reserva el derecho de presentar a la aprobación de la Dirección Regional del Trabajo del Ministerio del Trabajo las reformas o adiciones que estimare convenientes al presente Reglamento Interno de Trabajo. Una vez aprobadas las reformas o adiciones, la Empresa dará a conocer a los Trabajadores. </Text>
            <Text style={styles.text}>
            </Text>
            <Text style={styles.text}>{data.nameResponsible}</Text>
            <Text style={styles.textBold}>{data.chargeResponsible}</Text>
            <Text style={styles.text}>{data.idResponsible}</Text>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    body: {
        paddingTop: 50,
        paddingBottom: 50,
        paddingHorizontal: 55,
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        padding: 10,
        size: "A4"
    },
    sectionSelect: {
        margin: 5,
        fontSize: 11,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        lineHeight: 1.5,
    },
    title: {
        fontSize: 14,
        margin: 10,
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        lineHeight: 1.5,
    },
    subtitle: {
        fontSize: 16,
        margin: 5,
        fontFamily: 'Times-Roman',
        lineHeight: 1.5,
    },
    subtitleTwo: {
        fontSize: 16,
        margin: 6,
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        textDecoration: 'underline',
        lineHeight: 1.5,
    },
    subtitleUnderline: {
        fontSize: 16,
        margin: 6,
        textAlign: 'center',
        fontFamily: 'Times-Roman',
        textDecoration: 'underline',
        lineHeight: 1.5,
    },
    header: {
        fontSize: 14,
        margin: 4,
        fontFamily: 'Times-Bold'
    },
    text: {
        margin: 5,
        fontSize: 11,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        lineHeight: 1.5,
    },
    textBold: {
        margin: 5,
        fontSize: 11,
        textAlign: 'justify',
        fontFamily: 'Times-Bold',
        lineHeight: 1.5,
    },
    textRight: {
        margin: 5,
        fontSize: 11,
        textAlign: 'right',
        fontFamily: 'Times-Roman',
        lineHeight: 1,
    }
});

// const DownloadLink = ({ data }) => (
//     <PDFDownloadLink
//         document={<MyDocument data={data} />}
//         fileName='reglamentos.pdf'
//     >
//         {({ blob, url, loading, error }) =>
//             loading ? 'Loading document...' : (
//                 <>
//                     <FontAwesomeIcon icon={faPrint} color="white" className="text-2xl transition duration-300 ease-in-out hover:text-amber-600" />
//                 </>
//             )
//         }
//     </PDFDownloadLink>
// );

export { DocumentInternal, DocumentSecurity };
import React, { useState, useEffect } from 'react';
import './carWallet.css'; // Importa el archivo CSS
import ModalWallet from './ModalWallet'; // Importa el componente ModalWallet

import walletService from '../services/walletService';
import walletFunctions from '../utilities/wallet-function';

import wallet from '../assets/img/wallet.png'
import { CircularProgress } from '@mui/material';
import suscriptionService from '../services/suscriptionService';
import { useUser } from '../VariablesContext';
import { useSuscriptionDispatch } from './BIlleteraComponents/BilleteraState/BilleteraContext';

const CarWallet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [coinsTotalUser, setCoinsTotalUser] = useState();
  const [tokenUser, setTokenUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [suscription, setSuscription] = useState();
  const { userId } = useUser();
  const dispatchSuscription = useSuscriptionDispatch()

  useEffect(() => {
    const getSuscription = async () => {
      try {
        const suscriptionData = await suscriptionService.getSuscriptionPlan(userId)
        if (suscriptionData && suscriptionData.currentSuscription) {
          setSuscription(suscriptionData.currentSuscription.name)
          dispatchSuscription({type:"SET",payload:{
            ...suscriptionData
          }})
        }
      } catch (error) {
        setSuscription(null)
      }

    }
    userId && getSuscription()
  }, [userId])

  useEffect(() => {
    const fetchData = async () => {

      const token = await loadToken();
      loadTotalCoinsByUser(token);
      setTokenUser(token);
      setLoading(false);

    };

    fetchData();
  }, [coinsTotalUser]);

  const loadToken = async () => {
    const data = await walletService.getToken();
    return data.token;
  }
  const loadTotalCoinsByUser = async (token) => {
    try {
      const data = await walletService.getTotalCoinsById(localStorage.getItem("userId"), token);
      setCoinsTotalUser(data.data.totalCoins || data.data.wallet_balance_user_id);

    } catch (error) {
      console.error("Error al obtener los datos del usuario", error);
    }
  }
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const formatCoins = (coins) => {

    if (coins < 100) {
      return { text: coins, size: 'text-4xl' };
    } else if (coins < 1000) {
      return { text: coins, size: 'text-3xl' };
    } else if (coins < 10000) {
      const formatted = (coins / 1000).toFixed(1) + 'K';
      return { text: formatted, size: 'text-3xl' };
    } else if (coins > 10000) {
      return { text: '+10K', size: 'text-3xl' };
    } else {
      return { text: '', size: 'text-sm' };
    }
  };
  const { text, size } = formatCoins(coinsTotalUser);

  const handlebuyservice = async () => {
    const pago = await walletFunctions.servicePayment("1", tokenUser);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleBuyMoreCoins = () => {
    handleModalClose();
  };

  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-2"
    >
      <button onClick={handleModalOpen}
        className="flex flex-col items-center
      hover:scale-110 hover:opacity-85
      transition-all duration-300 ease-in-out
      md:translate-x-10
      ">
        {
          suscription && (
            <div className=' absolute p-2 
          rounded-sm border-2 border-[#ff8100]
          text-[#ffffff] bg-[#ff8000]
          -translate-x-24 translate-y-3 font-bold
          '>
              PRO
            </div>
          )
        }
        <div style={{ position: 'relative' }}>
          <img src={wallet} alt="Wallet" className="w-32 h-22 mb-1" />
          <span className={`absolute top-1/2 left-1/2 
        transform -translate-x-10 
        -translate-y-7 font-semibold
        text-white ${size}`}>
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : text}
          </span>
          <span className='absolute text-white text-sm
        -translate-y-7 translate-x-3'>
            Coins
          </span>
        </div>

      </button>

      <ModalWallet isOpen={modalOpen} onClose={handleModalClose} coinsTotal={coinsTotalUser} />
    </div>

  );
};

export default CarWallet;

import { useUser } from ".././VariablesContext";
import React, { useState, useEffect, useRef } from "react";
import userService from "../services/userService";
import "./ProfileForm.css";
import { useNavigate } from "react-router-dom";
import { getUserToCompanyByUserId } from "../services/companyUserValidationService";
import backgroundPersonal from "../assets/img/FondoMP.jpg"
import mwServices from "../services/mw_servicesS";
import { useSearchParams } from 'react-router-dom';
import showAlert from "../utilities/alerts";
import walletService from "../services/walletService";
import suscriptionService from "../services/suscriptionService";
import { useTutorial } from '../utilities/TutorialStepsContext';

function ProfileForm() {
  const navigate = useNavigate();
  const { userId } = useUser();  
  const [transactionId, setTransactionId] = useState(null);
  const [clientTransactionId, setClientTransactionId] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionIdParam = urlParams.get('id');
    const clientTransactionIdParam = urlParams.get('clientTransactionId');

    if (transactionIdParam && clientTransactionIdParam) {
      setTransactionId(transactionIdParam);
      setClientTransactionId(clientTransactionIdParam);

      const url = new URL('https://5tf1gj3nwl.execute-api.sa-east-1.amazonaws.com/api/v1/payphone/transaction');
      url.searchParams.append('id', transactionIdParam);
      url.searchParams.append('clientTransactionId', clientTransactionIdParam);

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json())
        .then(data => {
          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          url.search = '';
          window.history.replaceState({}, document.title, url);
          setTransactionId(null);
          setClientTransactionId(null);
          switch (data.result.statusCode) {
            case 3:
              if (data.result.transactionStatus === "Approved") {
                showAlert.success("Tu transacción ha sido aprobada.", "¡Gracias por tu Pago!");

                const transactionDataSS = sessionStorage.getItem('transactionData');
                const transaction = JSON.parse(transactionDataSS)
                let id = transaction.transaction_id
                let token = transaction.token

                walletService.getTransactionData(
                  id,
                  token
                ).then(data => {
                  const recoveredTransaction = data.data[0]
                  let transaction_id = recoveredTransaction.wallet_transaction_id

                  const updateBody = {
                    ...recoveredTransaction,
                    wallet_transaction_confirmed: true,
                    wallet_transaction_coins: transaction.selectedArticulo.unidades,
                    wallet_transaction_amount: transaction.selectedArticulo.precio
                  }


                  //UPDATE Transaction (id_transaccion) con datos actualizados de factura,precio, cantidad, etc
                  walletService.updatetransaction(
                    transaction_id,
                    token,
                    updateBody
                  ).then(res => {
                    //console.log(res)
                  }).catch(e => {
                    console.log("Error updating transference data: ", data)
                  })

                  switch (transaction.selectedArticulo.tipo) {
                    case 'MW Coin':

                      const bodyMWCoinRecharge = {
                        coinsToConsume: transaction.selectedArticulo.unidades,
                        transaction_id: transaction.transactionId,
                        origen: "",
                        transaction_by: transaction.factura.nombre || "User"
                      }
                      let id = recoveredTransaction.wallet_transaction_user_id
                      //Recarga de monedas a cuenta del usuario
                      walletService.rechargeCoins(
                        id,
                        token,
                        bodyMWCoinRecharge
                      ).then(res => {
                        if (res.code === "200") {
                          showAlert.success("Tus monedas se recargaran inmediatamente", "¡Gracias por confiar en nosotros!");
                          window.location.reload();
                        }
                      }
                      ).catch(e => {
                        console.log("Error recharging coins", e)
                      })
                      break;
                    case 'subscription':
                      const bodyAddSuscription = {
                        name: transaction.selectedArticulo.producto,
                      }
                      let userid = recoveredTransaction.wallet_transaction_user_id

                      suscriptionService.addSuscriptionPlan(
                        userid,
                        bodyAddSuscription
                      ).then(res => {
                        if (res.user_actual_suscription) {
                          showAlert.success("Tu plan se activará inmediatamente", "¡Gracias por confiar en nosotros!");
                          window.location.reload();

                        }

                      }
                      ).catch(e => {
                        console.log("Error setting plan", e)
                      })
                      break;
                    default:
                      break;
                  }

                })
                  .catch(e => {
                    console.log("Error getting Transaction data", e)
                  })

              }
              break;
            case 2:
              if (data.result.transactionStatus === "Canceled") {
                //dispatchPayphoneCompleted({type:"COMPLETE"})
                showAlert.error("Parece que hubo un problema, la transacción fue cancelada. Intentalo de nuevo mas tarde");
              }
              break;
            default:
              console.log("Estado de transacción desconocido");
          }
        })
        .catch(error => {
          console.error('Error confirming payment:', error);
        })
        .finally(() => {
          sessionStorage.removeItem('transactionData');
        });
    } else {
      sessionStorage.removeItem('transactionData');
    }
  }, [])

  const [dataUsuarios, setDataUsuarios] = useState("");

  // Cartas del carousel y redireccionamiento..
  const [slider, setSlider] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

  const totalCards = slider.length;
  const { startTutorial } = useTutorial();
  const { firstTime } = useTutorial();

  const phrases = [
    "¡Elige el servicio que revolucionará tu gestión del talento humano!",
    "¡Transforma tu empresa con nuestra solución innovadora!",
    "¡Gestiona tu empresa de manera eficiente y efectiva!"
  ];

  useEffect(() => {
    if (charIndex < phrases[phraseIndex].length) {
      const timeout = setTimeout(() => {
        setDisplayedText(displayedText + phrases[phraseIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, 50); // Velocidad de la animación
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setDisplayedText("");
        setCharIndex(0);
        setPhraseIndex((phraseIndex + 1) % phrases.length);
      }, 5000); // Intervalo entre frases
      return () => clearTimeout(timeout);
    }
  }, [charIndex, phraseIndex]);

  const startAutoScroll = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }, 5000);
  };

  const stopAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleScrollRight = () => {
    setCurrentIndex(currentIndex + 1); // Incrementar currentIndex
  };

  const handleScrollLeft = () => {
    setCurrentIndex(currentIndex - 1); // Decrementar currentIndex
  };

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, [totalCards]);

  useEffect(() => {
    if (carouselRef.current) {
      if (currentIndex >= totalCards * 2) {
        setCurrentIndex(totalCards);
        carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
      } else if (currentIndex < totalCards) {
        setCurrentIndex(totalCards * 2 - 1);
        carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3 * 2;
      } else {
        const scrollPosition = carouselRef.current.scrollWidth / (totalCards * 3) * currentIndex;
        carouselRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [currentIndex, totalCards]);

  const clonedCards = [...slider.slice(-totalCards), ...slider, ...slider.slice(0, totalCards)];

  useEffect(() => {
    //Función para obtener los recursos
    const carouselResources = async () => {
      try {
        const dataCarousel = await mwServices.getByState();
        // Actualiza el estado con los datos del usuario
        setSlider(dataCarousel);
      } catch (error) {
        console.error("Error al obtener los datos del usuario", error);
      }
    };
    carouselResources();

    const storedUserId = localStorage.getItem("userId");

    if (!storedUserId) {
      // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
      navigate("/");
    } else if (userId !== null) {
      const fetchUserData = async () => {
        try {
          const dataUser = await userService.getUserId(userId);
          //console.log(dataUser);
          // Actualiza el estado con los datos del usuario
          setDataUsuarios(dataUser);

        } catch (error) {
          console.error("Error al obtener los datos del usuario", error);
        }
      };
      // Llama a la función para obtener los datos del usuario
      fetchUserData();

    }

    const loadBotpressScripts = () => {
      // Crea el primer script
      const script1 = document.createElement("script");
      script1.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
      script1.async = true;
      script1.onload = () => {
        // Cuando se cargue el primer script, crea y carga el segundo script
        const script2 = document.createElement("script");
        script2.src = "https://mediafiles.botpress.cloud/16148785-e8e4-479e-9125-b0550b82ff15/webchat/config.js";
        script2.defer = true;
        script2.async = true;
        document.head.appendChild(script2);
      };
      // Añade el primer script al DOM
      document.head.appendChild(script1);
    };
    loadBotpressScripts();
    return () => {
      const script1 = document.querySelector('script[src="https://cdn.botpress.cloud/webchat/v1/inject.js"]');
      const script2 = document.querySelector('script[src="https://mediafiles.botpress.cloud/16148785-e8e4-479e-9125-b0550b82ff15/webchat/config.js"]');
      if (script1) {
        script1.remove();
      }
      if (script2) {
        script2.remove();
      }
    };
  }, [userId]);
  if (!userId) {
    // Si no hay userId, no renderizar el componente
    return null;
  }
  const onclickFormCompany = async () => {
    const userToCompany = await getUserToCompanyByUserId(userId);
    if (userToCompany.success) {
      // Si tiene registrada una empresa, redirige a profile-form
      navigate("/requirement-page");
      //console.log(userToCompany);
    } else {
      // Si no tiene registrada una empresa, redirige a company-page
      navigate("/company-page");
    }
  };


  return (
    <div className="flex flex-col h-screen"
    // onMouseOver={firstTime}
    >
      <main className="relative bg-cover bg-center h-full"
        style={{ backgroundImage: `url(${backgroundPersonal})` }}>
        <div className="absolute inset-0 bg-black opacity-50 z-10 pointer-events-none"></div>
        <div className="relative z-20 py-6 ">
          <div className="text-center h-max py-10">
            <button className="text-4xl md:text-6xl font-extrabold text-white"
              onClick={startTutorial}
            >Bienvenido</button>
            <p className="mt-4 text-2xl md:text-4xl leading-6 text-white min-h-[72px] md:min-h-[108px]">
              {displayedText}
            </p>
          </div>
          <section className="step-1 marker:py-8"
            onMouseEnter={stopAutoScroll}
            onMouseLeave={startAutoScroll}
          >
            <div className="flex py-3 px-2 bg-white bg-opacity-50 shadow-xl">
              <div className="flex items-center">
                <span className="cursor-pointer bg-white text-black rounded-full shadow-lg" onClick={handleScrollLeft}>
                  <i className="fas fa-chevron-left p-2 text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </span>
              </div>
              <div className="relative overflow-hidden mx-2">
                <div
                  ref={carouselRef}
                  className="flex overflow-x-auto space-x-8 scrollbar-hide"
                  onWheel={(e) => {
                    e.currentTarget.scrollLeft += e.deltaY;
                    // Prevenir el scroll vertical
                    // if (e.deltaY !== 0) {
                    //   e.preventDefault();
                    //   e.currentTarget.scrollLeft += e.deltaY;
                    // }
                  }}
                >
                  {clonedCards.map((card, index) => (
                    <div
                      key={index}
                      className={`relative flex-none bg-[rgb(6,56,82)] shadow-md rounded-lg p-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 min-w-[calc(25%-16px)]`}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div className={`transition-opacity duration-500 ${hoveredIndex === index ? 'opacity-0' : 'opacity-100'}`}>
                        <h3 className="text-white text-2xl sm:text-2xl md:text-3xl font-bold mb-4 text-center py-3 sm:py-4 lg:py-5">
                          {card.mw_services_name}
                        </h3>
                        <button
                          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 mb-2 rounded block w-full"
                          onClick={() => {
                            if (card.mw_services_ID === 2) {
                              onclickFormCompany();
                            } else {
                              navigate(card.mw_services_slug);
                            }
                          }}
                        >
                          {card.buttontext}
                        </button>
                        {card.mw_services_ID !== 1 &&
                          <div className="text-center text-white">
                            <span className="font-bold">{card.mw_services_price}</span>
                          </div>
                        }
                      </div>
                      <div className={`absolute inset-0 transition-opacity duration-500 bg-gradient-to-t from-[rgb(6,56,82)] to-[rgb(12,98,142)] rounded-lg p-4 ${hoveredIndex === index ? 'opacity-100' : 'opacity-0'}`}>
                        <p className="text-white text-sm sm:text-xs md:text-base lg:text-lg max-h-28 overflow-auto">
                          {card.mw_services_description}
                        </p>
                        <button
                          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-12 mb-2 rounded block w-full mt-1"
                          onClick={() => {
                            if (card.mw_services_ID === 2) {
                              onclickFormCompany();
                            } else {
                              navigate(card.mw_services_slug);
                            }
                          }}
                        >
                          {card.buttontext}
                        </button>
                        {card.mw_services_ID !== 1 &&
                          <div className="text-center text-white">
                            <span className="font-bold">{card.mw_services_price}</span>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center w-fit">
                <span className="cursor-pointer bg-white text-black rounded-full shadow-lg" onClick={handleScrollRight}>
                  <i className="fas fa-chevron-right p-2 text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </span>
              </div>
            </div>

          </section>
        </div>
      </main>
      <footer className="bg-[rgb(6,56,82)] text-white mt-auto">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between">
          <div className='flex flex-col'>
            <div className="text-center">
              <p className='font-semibold text-lg sm:text-lg md:text-2xl xl:text-2xl'>Redes Sociales:</p>
              <div className="flex space-x-4">
                <a href="http://www.linkedin.com/company/matchworking/?viewAsMember=true"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </a>
                <a href="http://www.tiktok.com/@matchworking"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-tiktok text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </a>
                <a href="http://www.facebook.com/matchworking"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook  text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </a>
                <a href="https://www.instagram.com/matchworking_ec/"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram  text-lg sm:text-lg md:text-xl xl:text-2xl"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="hidden md:inline text-sm md:text-sm xl:text-lg">© Copyright 2024 Matchworking</div>
          <div className='step-2 flex flex-col'>
            <div className="text-center">
              <p className='font-semibold text-sm sm:text-sm md:text-lg  xl:text-xl'>Soporte técnico:</p>
              <p className="text-sm sm:text-sm md:text-lg xl:text-xl">info@match.working.com</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default ProfileForm;

import RecargaButtonComponent from "./RecargaButtonComponent"
import ReturnPlanButtonComponent from "./ReturnPlanButtonComponent"
import { PRECIO_MONEDAS } from "../../../utilities/billeteraViews";
import { useNavPageDispatch, useSelectedArticuloDispatch, useSelectedArticuloValue } from "../BilleteraState/BilleteraContext";
import { incrementPage } from "../BilleteraState/NavigationNumberReducer";

const MainRecargasComponent = ({preloadedImage, recargasData }) => {
    const dispatchArticulo = useSelectedArticuloDispatch()
    const dispatchNavActualPage = useNavPageDispatch()
    const selected = useSelectedArticuloValue()

    const handleSelectOption = (recarga) => {
        if (selected) {
            if (selected.producto === recarga + " MWC") {
                dispatchArticulo({type:"RESET"})

            } else {

                dispatchArticulo({type:"SET",payload:{
                    producto: recarga + " MWC",
                    precio: PRECIO_MONEDAS * Number(recarga),
                    tipo: "MW Coin",
                    unidades: recarga
                }})
                dispatchNavActualPage(incrementPage())

            }
        } else {

            dispatchArticulo({type:"SET",payload:{
                producto: recarga + " MWC",
                precio: PRECIO_MONEDAS * Number(recarga),
                tipo: "MW Coin",
                unidades: recarga
            }})
            dispatchNavActualPage(incrementPage())

        }
    }

    return (
        <div className="w-full h-full text-white
        bg-[#063852]
        m-0 border-r-2 border-l-2 md:border-l-0 
        border-y-2 border-[#D9D9D9]
        md:py-1 
        lg:py-2
        px-3
        ">
                <div className="col-span-2 md:col-span-3 grid grid-cols-2 md:grid-cols-3">
                    {
                        recargasData.map(recarga => (
                            <RecargaButtonComponent
                                key={recarga}
                                cantidad={recarga}
                                onClick={() => handleSelectOption(recarga)}
                                selected={selected}
                                preloadedImage={preloadedImage}
                            />
                        ))
                    }
                    <div className="col-span-2 md:col-span-3">
                        <ReturnPlanButtonComponent />
                    </div>
                </div>
        </div >
    )
}

export default MainRecargasComponent
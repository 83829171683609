// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button{
    background-color: #F0810F;
    color: #fff;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    max-width: 200px; /* Puedes ajustar el ancho máximo según tus necesidades */
    width: 100%;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Ajusta el tamaño y otros estilos según sea necesario */
    width: 400px;
    padding: 20px;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/list-data-admin/listDataAdmin.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,eAAe;IACf,YAAY;IACZ,gBAAgB,EAAE,yDAAyD;IAC3E,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yDAAyD;IACzD,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".button{\n    background-color: #F0810F;\n    color: #fff;\n    padding: 5px;\n    font-size: 18px;\n    cursor: pointer;\n    border: none;\n    max-width: 200px; /* Puedes ajustar el ancho máximo según tus necesidades */\n    width: 100%;\n}\n.modal-content {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    /* Ajusta el tamaño y otros estilos según sea necesario */\n    width: 400px;\n    padding: 20px;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

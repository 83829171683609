import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
    IconButton,
    Divider,
    createTheme,
    ThemeProvider,
    CssBaseline,
    Container
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { uploadCandidate } from '../../enviroment/enviroment-dev';
import axios from "axios";
import showAlert from "../../utilities/alerts";
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import { lambdaCandidate } from "../../enviroment/internal-lambdas";


const defaultTheme = createTheme({
});

const FormCV = ({ onNext, setCandidate, candidate }) => {
    const profileData = candidate?.candidateData;
    const candidate_academic_formation = profileData?.candidate_academic_formation || '';
    const candidate_experience = profileData?.candidate_experience || [];
    const candidate_job_categories = profileData?.candidate_job_categories || [];
    const candidate_languages = profileData?.candidate_languages || [];
    const candidate_personal_data = profileData?.candidate_personal_data || '';
    const candidate_references = profileData?.candidate_references || [];
    // const candidate_supplementary_education = profileData.candidate_supplementary_education

    const [errors, setErrors] = useState({});
    console.log('Form', candidate.candidateData)

    const [profileForm] = useState({
        candidate_photo: profileData?.candidate_photo || '',
        candidate_job_position_profile: 'none',
        candidate_personal_data: {
            candidate_full_names: {
                candidate_first_name: candidate_personal_data?.candidate_full_names?.candidate_first_name || '',
                candidate_middle_name: candidate_personal_data?.candidate_full_names?.candidate_middle_name || '',
                candidate_last_name: candidate_personal_data?.candidate_full_names?.candidate_last_name || '',
                candidate_second_last_name: candidate_personal_data?.candidate_full_names?.candidate_second_last_name || ''
            },
            candidate_identification: candidate_personal_data?.candidate_identification || '',
            candidate_phone: candidate_personal_data?.candidate_phone || '',
            candidate_address: {
                candidate_city: candidate_personal_data?.candidate_address?.candidate_city || '',
                candidate_country: candidate_personal_data?.candidate_address?.candidate_country || '',
                candidate_postalCode: candidate_personal_data?.candidate_address?.candidate_postalCode || ''
            },
            candidate_email: candidate_personal_data?.candidate_email || '',
            candidate_civil_status: candidate_personal_data?.candidate_civil_status || '',
            candidate_age: candidate_personal_data?.candidate_age || '',
            candidate_birth_date: candidate_personal_data?.candidate_birth_date || '',
            candidate_gender: candidate_personal_data?.candidate_gender || ''
        },
        candidate_professional_profile: profileData?.candidate_professional_profile,
        candidate_academic_formation: {
            candidate_degree: candidate_academic_formation?.candidate_degree || '',
            candidate_institution_name: candidate_academic_formation?.candidate_institution_name || '',
            candidate_education_level: candidate_academic_formation?.candidate_education_level || '',
            candidate_graduation_date: {
                day: candidate_academic_formation?.candidate_graduation_date?.day || '',
                month: candidate_academic_formation?.candidate_graduation_date?.month || '',
                year: candidate_academic_formation?.candidate_graduation_date?.year || ''
            }
        },
        candidate_supplementary_education: profileData?.candidate_supplementary_education.map(supp => ({
            ...supp,
            course_title: supp.course_title || '',
            course_institution_name: supp.course_institution_name || '',
            course_completion_date: {
                day: supp.day || '',
                month: supp.month || '',
                year: supp.year || ''
            },
            course_duration: ""

        })) || [{
            course_title: "Nuevo Curso",
            course_institution_name: "Nueva Institución",
            course_completion_date: {
                day: 1,
                month: 1,
                year: 1
            },
            course_duration: ""
        }],
        candidate_experience: candidate_experience.map(exp => ({
            ...exp,
            candidate_responsibilities: exp?.candidate_responsibilities ?? [],
            candidate_achievements: exp?.candidate_achievements ?? [],
        })) || {
            candidate_position: "",
            candidate_position_area: "", //
            candidate_position_subarea: "", //
            candidate_company_name: "",
            candidate_company_activity: "", //
            candidate_company_country: "", //
            candidate_experience_level: "", //
            candidate_period: {
                start_date: {
                    day: '',
                    month: '',
                    year: ''
                },
                end_date: {
                    day: '',
                    month: '',
                    year: ''
                }
            },
            candidate_responsibilities: [
                '',
                '',
                '',
                ''
            ],
            candidate_achievements: [
                '',
                '',
                '',
            ],
            candidate_people_charge: "0", //
            candidate_annual_budget_management: false //

        },        

        candidate_salary_expectation: {
            minimum: '',
            maximum: ''
        },
        candidate_skills: profileData?.candidate_skills ?? [],
        candidate_languages: candidate_languages.map(lang => ({
            ...lang,
            language: lang.language || '',
            proficiency: lang.proficiency || '',
            institution: lang.institution || ''
        })) || [{
            language: "",
            proficiency: "",
            institution: ""
        }],
        candidate_references: candidate_references.map(ref => ({
            ...ref,
            company_name: ref.company_name || '',
            reference_name: ref.reference_name || '',
            phone: ref.phone || '',
            reference_position: ref.reference_position || ''
        })) || [{
            company_name: "",
            reference_name: "",
            phone: "",
            reference_position: ""
        }],
        candidate_job_categories: candidate_job_categories || [''],
        candidate_termsAccepted: true
    })
    const [formData, setFormData] = useState(profileForm);    

    // Función para manejar cambios en los campos del formulario
    const handleChange = (field, subfield, index, value) => {
        setFormData(prevState => {
            const newState = { ...prevState };

            // Campo dentro de un array
            if (Array.isArray(newState[field])) {
                if (subfield) {
                    newState[field][index][subfield] = value;
                } else {
                    newState[field][index] = value;
                }
            }
            // Campo dentro de un objeto anidado
            else if (typeof newState[field] === 'object' && subfield) {
                newState[field][subfield] = value;
            }
            // Campo simple
            else {
                newState[field] = value;
            }

            return newState;
        });
    };

    const instance = axios.create();


    useEffect(() => {
        validateForm()
    }, [formData]);
    // Función para enviar los datos
    const handleSubmit = () => {
        if (validateForm()) {
            console.log("Formulario enviado con éxito:", formData);
            try {
                Swal.fire({
                    title: "Generando recomendaciones.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,                    
            html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                console.log('payload',formData)
                lambdaCandidate.createWOjobId(formData)
                    .then(response => {
                        Swal.fire("Formulario enviado exitosamente!", "", "success");
                        console.log('Respuesta del servidor Candidate:', response);

                        console.log(response)
                        console.log(response.data.candidate_id)
                        setCandidate({ ...setCandidate, candidateID: response.data.candidate_id })
                        onNext();
                    })
                    .catch(error => {
                        console.error("Error:", error)
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Llena todos los campos necesarios"
                        });
                        Swal.close();
                    })
            } catch (error) {
                console.log(error)
                Swal.close();
            }
        } else {
            console.log("Errores en el formulario:", formData);
            showAlert.error("Llena todos los campos necesarios")
        }
    };
    const handleChangeNested = (level1, level2, level3, level4, level5, value) => {
        // console.log(value)
        setFormData(prevState => {
            const newState = { ...prevState };

            if (level5) {
                // 5 niveles de profundidad
                if (newState[level1] && newState[level1][level2] && newState[level1][level2][level3][level4]) {
                    newState[level1][level2][level3][level4][level5] = value;
                }
            } else if (level4) {
                // 4 niveles de profundidad
                if (newState[level1] && newState[level1][level2] && newState[level1][level2][level3]) {
                    newState[level1][level2][level3][level4] = value;
                }
            } else if (level3) {
                // 3 niveles de profundidad
                if (newState[level1] && newState[level1][level2]) {
                    newState[level1][level2][level3] = value;
                }
            } else if (level2) {
                // 2 niveles de profundidad
                if (newState[level1]) {
                    newState[level1][level2] = value;
                    console.log('name', level2)
                }
            } else if (level1) {
                // 2 niveles de profundidad
                if (newState[level1]) {
                    newState[level1] = value;
                    console.log('name', level1)
                }
            }

            return newState;
        });
    };

    const handleAddNested = (keys, newItem) => {
        setFormData((prevState) => {
            const updateNestedField = (obj, keys, value) => {
                if (keys.length === 1) {
                    obj[keys[0]] = [...(obj[keys[0]] || []), value];
                } else {
                    obj[keys[0]] = updateNestedField(
                        { ...(obj[keys[0]] || {}) },
                        keys.slice(1),
                        value
                    );
                }
                return obj;
            };

            return updateNestedField({ ...prevState }, keys, newItem);
        });
    };

    const handleRemove = (field, index) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index) // Elimina el elemento en el índice del campo especificado
        }));
    };

    // console.log(formData)
    const validateForm = () => {
        const newErrors = {};
        // Validación de datos personales
        const personalData = formData.candidate_personal_data;
        if (!personalData.candidate_full_names.candidate_first_name) newErrors.candidate_first_name = "Campo obligatorio.";
        
        if (!personalData.candidate_full_names.candidate_last_name) newErrors.candidate_last_name = "Campo obligatorio.";
        if (!personalData.candidate_phone) newErrors.candidate_phone = "Campo obligatorio.";
        if (!personalData.candidate_address.candidate_city) newErrors.candidate_city = "Campo obligatorio.";
        if (!personalData.candidate_address.candidate_country) newErrors.candidate_country = "Campo obligatorio.";
        if (!personalData.candidate_email) newErrors.candidate_email = "Campo obligatorio.";
        
        const academicFormation = formData.candidate_academic_formation;
        if (!academicFormation.candidate_degree) newErrors.candidate_degree = "Campo obligatorio.";
        if (!academicFormation.candidate_institution_name) newErrors.candidate_institution_name = "Campo obligatorio.";
        // Validación de educación suplementaria
        formData.candidate_supplementary_education.forEach((item, index) => {
        });

        // Validación de experiencia
        formData.candidate_experience.forEach((item, index) => {
            if (!item.candidate_position) newErrors[`candidate_position_${index}`] = "Campo obligatorio.";
            if (!item.candidate_company_name) newErrors[`candidate_company_name_${index}`] = "Campo obligatorio.";
            if (!item.candidate_period.start_date.day || !item.candidate_period.start_date.month || !item.candidate_period.start_date.year) newErrors[`candidate_period_start_date_${index}`] = "Fecha de inicio completa obligatoria.";
            if (!item.candidate_period.end_date.day || !item.candidate_period.end_date.month || !item.candidate_period.end_date.year) newErrors[`candidate_period_end_date_${index}`] = "Fecha de fin completa obligatoria.";
        });
        formData.candidate_languages.forEach((item, index) => {
            if (!item.language || !item.proficiency || !item.institution) newErrors[`candidate_languages${index}`] = "Todos los campos son obligatorios.";
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    return (
        <div className="bg-cover min-h-screen pt-10 rounded-xl shadow-md">
            <div className="p-2 border-b pt-6 flex justify-between gap-4 mt-6 border-white border-4">
                <h2 className="font-semibold">Edita tus datos para obtener las recomendaciones</h2>
            </div>
            <ThemeProvider theme={defaultTheme} >
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <Box component="form" sx={{ spaceY: 6 }}>
                        {/* Datos Personales */}
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Datos Personales</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        variant="outlined"
                                        value={formData.candidate_personal_data?.candidate_full_names?.candidate_first_name}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_full_names",
                                                "candidate_first_name",
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        error={!!errors.candidate_first_name}
                                        helperText={errors.candidate_first_name || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Apellido"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_full_names.candidate_last_name}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_full_names",
                                                "candidate_last_name",
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        error={!!errors.candidate_last_name}
                                        helperText={errors.candidate_last_name || ""}
                                    />
                                    
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Identificación"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_identification}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_identification",
                                                null,
                                                null,
                                                null,
                                                e.target.value
                                            )
                                        }
                                        error={!!errors.candidate_identification}
                                        helperText={errors.candidate_identification || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_phone}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_phone",
                                                null,
                                                null,
                                                null,
                                                e.target.value)
                                        }
                                        error={!!errors.candidate_phone}
                                        helperText={errors.candidate_phone || ""}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Correo Electrónico"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_email}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_email",
                                                null,
                                                null,
                                                null,
                                                e.target.value)
                                        }
                                        error={!!errors.candidate_email}
                                        helperText={errors.candidate_email || ""}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Ciudad"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_address.candidate_city}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_address",
                                                "candidate_city",
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        error={!!errors.candidate_city}
                                        helperText={errors.candidate_city || ""}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="País"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_address.candidate_country}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_address",
                                                "candidate_country",
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        error={!!errors.candidate_country}
                                        helperText={errors.candidate_country || ""}
                                    />

                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Edad"
                                        type="number"
                                        variant="outlined"
                                        value={formData.candidate_personal_data.candidate_age}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_personal_data",
                                                "candidate_age",
                                                null,
                                                null,
                                                null,
                                                e.target.value)
                                        }
                                        error={!!errors.candidate_age}
                                        helperText={errors.candidate_age || ""}
                                    />

                                </Grid>
                            </Grid>
                        </Box>

                        {/* Perfil Profesional */}
                        {/* <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Perfil Profesional</Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Perfil Profesional"
                                variant="outlined"
                                value={formData.candidate_professional_profile || ''}
                                onChange={(e) =>
                                    handleChange(
                                        "candidate_professional_profile",
                                        null,
                                        null,
                                        e.target.value)
                                }
                                error={!!errors.candidate_professional_profile}
                                helperText={errors.candidate_professional_profile || ""}
                            />

                        </Box> */}

                        {/* Formación Académica */}
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Formación Académica</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Título"
                                        variant="outlined"
                                        value={formData.candidate_academic_formation.candidate_degree}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_academic_formation",
                                                "candidate_degree",
                                                null,
                                                null,
                                                null,
                                                e.target.value
                                            )
                                        }
                                        error={!!errors.candidate_degree}
                                        helperText={errors.candidate_degree || ""}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Institución"
                                        variant="outlined"
                                        value={formData.candidate_academic_formation.candidate_institution_name}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_academic_formation",
                                                "candidate_institution_name",
                                                null,
                                                null,
                                                null,
                                                e.target.value
                                            )
                                        }
                                        error={!!errors.candidate_institution_name}
                                        helperText={errors.candidate_institution_name || ""}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Año de Graduación"
                                        type="number"
                                        variant="outlined"
                                        value={formData.candidate_academic_formation.candidate_graduation_date.year}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_academic_formation",
                                                "candidate_graduation_date",
                                                "year",
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        error={!!errors.candidate_graduation_date}
                                        helperText={errors.candidate_graduation_date || ""}
                                    />

                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Cursos</Typography>
                            {formData.candidate_supplementary_education.map((education, index) => (
                                <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
                                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Título del Curso"
                                                variant="outlined"
                                                value={education.course_title}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_supplementary_education",
                                                        index,
                                                        "course_title",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`course_title_${index}`]}
                                                helperText={errors[`course_title_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Institución"
                                                variant="outlined"
                                                value={education.course_institution_name}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_supplementary_education",
                                                        index,
                                                        "course_institution_name",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`course_institution_name${index}`]}
                                                helperText={errors[`course_institution_name${index}`]}
                                            />

                                        </Grid>
                                        
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Año de Finalización"
                                                type="number"
                                                variant="outlined"
                                                value={education.course_completion_date.year}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_supplementary_education",
                                                        index,
                                                        "course_completion_date",
                                                        "year",
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`course_completion_date_${index}`]}
                                                helperText={errors[`course_completion_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Horas del Curso"
                                                variant="outlined"
                                                value={education.course_duration}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_supplementary_education",
                                                        index,
                                                        "course_duration",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`course_duration_${index}`]}
                                                helperText={errors[`course_duration_${index}`]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        alignItems="center"
                                    >
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemove("candidate_supplementary_education", index)}
                                        >
                                            <DeleteIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAddNested(
                                ['candidate_supplementary_education'], {
                                course_title: "Nuevo Curso",
                                course_institution_name: "Nueva Institución",
                                course_completion_date: {
                                    day: 1,
                                    month: 1,
                                    year: 2025
                                },
                                course_duration: "100 hours"
                            }
                            )}>
                                Añadir
                            </Button>
                        </Box>
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Experiencia Profesional</Typography>
                            {formData.candidate_experience.map((experience, index) => (
                                <Box key={index} sx={{ spaceY: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Posición"
                                                variant="outlined"
                                                value={experience.candidate_position}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_position",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_position_${index}`]}
                                                helperText={errors[`candidate_position_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Nombre de la empresa"
                                                variant="outlined"
                                                value={experience.candidate_company_name}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_company_name",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_company_name_${index}`]}
                                                helperText={errors[`candidate_company_name_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Área"
                                                variant="outlined"
                                                value={experience.candidate_position_area}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_position_area",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_position_area_${index}`]}
                                                helperText={errors[`candidate_position_area_${index}`]}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Día de Inicio"
                                                variant="outlined"
                                                value={experience.candidate_period.start_date.day}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "start_date",
                                                        "day",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_start_date_${index}`]}
                                                helperText={errors[`candidate_period_start_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Mes de Inicio"
                                                variant="outlined"
                                                value={experience.candidate_period.start_date.month}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "start_date",
                                                        "month",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_start_date_${index}`]}
                                                helperText={errors[`candidate_period_start_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Año de Inicio"
                                                variant="outlined"
                                                value={experience.candidate_period.start_date.year}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "start_date",
                                                        "year",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_start_date_${index}`]}
                                                helperText={errors[`candidate_period_start_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Día de Finalización"
                                                variant="outlined"
                                                value={experience.candidate_period.end_date.day}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "end_date",
                                                        "day",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_end_date_${index}`]}
                                                helperText={errors[`candidate_period_end_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Mes de Finalización"
                                                variant="outlined"
                                                value={experience.candidate_period.end_date.month}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "end_date",
                                                        "month",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_end_date_${index}`]}
                                                helperText={errors[`candidate_period_end_date_${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="Año de Finalización"
                                                variant="outlined"
                                                value={experience.candidate_period.end_date.year}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_period",
                                                        "end_date",
                                                        "year",
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_period_end_date_${index}`]}
                                                helperText={errors[`candidate_period_end_date_${index}`]}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <Typography variant="h6" fontWeight="bold" mb={2}>Responsabilidades</Typography>
                                            {experience.candidate_responsibilities.map((responsibility, respIndex) => (
                                                <Box key={`responsibility-${index}-${respIndex}`} sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
                                                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                key={respIndex}
                                                                fullWidth
                                                                multiline
                                                                maxRows={4}
                                                                // label={`Responsabilidad ${respIndex + 1}`}
                                                                variant="outlined"
                                                                value={responsibility}
                                                                onChange={(e) =>
                                                                    handleChangeResponsibility(index, respIndex, e.target.value)}
                                                                sx={{ mb: 1, '& .MuiOutlinedInput-root': { padding: '8.5px 14px' } }} // Añade margen entre campos
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid >
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleRemoveResponsibility(index, respIndex)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                            ))}
                                            <Button
                                                variant="contained"
                                                onClick={() => handleAddResponsibility(index)}
                                            >
                                                Añadir Responsabilidad
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="h6" fontWeight="bold" mb={2}>Logros Profesionales</Typography>
                                            {experience.candidate_achievements.map((achievement, achIndex) => (
                                                <Box key={achIndex} sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
                                                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                maxRows={4}
                                                                variant="outlined"
                                                                value={achievement || ''}
                                                                onChange={(e) =>
                                                                    handleChangeAchievement(index, achIndex, e.target.value)
                                                                }
                                                                sx={{ mb: 1, '& .MuiOutlinedInput-root': { padding: '8.5px 14px' } }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleRemoveAchievement(index, achIndex)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            <Button
                                                variant="contained"
                                                onClick={() => handleAddAchievement(index)}
                                            >
                                                Añadir Logro
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Typography variant="h6" fontWeight="bold" mb={2}>Personas a cargo</Typography>
                                            <TextField
                                                fullWidth
                                                label="Personas a cargo"
                                                variant="outlined"
                                                value={experience.candidate_people_charge || ''}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_experience",
                                                        index,
                                                        "candidate_people_charge",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }


                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Manejo de presupuesto anual propio</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-label="candidate_annual_budget_management"
                                                    name="candidate_annual_budget_management"
                                                    value={experience.candidate_annual_budget_management || false}
                                                    onChange={(e) =>
                                                        handleChangeNested(
                                                            "candidate_experience",
                                                            index,
                                                            "candidate_annual_budget_management",
                                                            null,
                                                            null,
                                                            e.target.value,
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid> */}

                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            backgroundColor: "lightgray",
                                            padding: 1,
                                            cursor: "pointer",
                                            width: "100%",
                                            borderRadius: 2,
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                            '&:hover': {
                                                transform: "scale(1.05)",
                                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                            }
                                        }}
                                    >
                                        <Typography variant="subtitle1" fontWeight="bold">Eliminar Experiencia</Typography>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemove("candidate_experience", index)}
                                        >
                                            <DeleteIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Divider sx={{ my: 2, opacity: 1 }} variant="middle" />
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAddNested(
                                ['candidate_experience'], {
                                candidate_position: "Posición",
                                candidate_position_area: "", //
                                candidate_position_subarea: "", //
                                candidate_company_name: "",
                                candidate_company_activity: "", //
                                candidate_company_country: "", //
                                candidate_experience_level: "", //
                                candidate_period: {
                                    start_date: {
                                        day: '',
                                        month: '',
                                        year: ''
                                    },
                                    end_date: {
                                        day: '',
                                        month: '',
                                        year: ''
                                    }
                                },
                                candidate_responsibilities: [],
                                candidate_achievements: [],
                                candidate_people_charge: "0", //
                                candidate_annual_budget_management: false //
                            }
                            )}>
                                Añadir
                            </Button>
                        </Box>

                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Expectativas Salariales</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Mínimo"
                                        variant="outlined"
                                        value={formData.candidate_salary_expectation.minimum}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_salary_expectation",
                                                "minimum",
                                                null,
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        // error={!!errors.candidate_salary_expectation_minimum}
                                        // helperText={errors.candidate_salary_expectation_minimum || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Máximo"
                                        variant="outlined"
                                        value={formData.candidate_salary_expectation.maximum}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_salary_expectation",
                                                "maximum",
                                                null,
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                        // error={!!errors.candidate_salary_expectation_maximum}
                                        // helperText={errors.candidate_salary_expectation_maximum || ""}
                                    />

                                </Grid>
                            </Grid>
                        </Box>

                        {/* <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Aptitudes</Typography>
                            {formData.candidate_skills.map((skill, index) => (
                                <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        // label={`Habilidad`}
                                        variant="outlined"
                                        value={skill}
                                        onChange={(e) =>
                                            handleChange(
                                                "candidate_skills",
                                                null,
                                                index,
                                                e.target.value)
                                        }
                                        error={!!errors.candidate_skills}
                                        helperText={errors.candidate_skills || ""}
                                    />
                                    <IconButton
                                        color="error"
                                        onClick={() => handleRemove("candidate_skills", index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAddNested(["candidate_skills"], '')}>
                                Añadir
                            </Button>
                        </Box> */}
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Idiomas</Typography>
                            {formData.candidate_languages.map((language, index) => (
                                <Box key={index} sx={{ spaceY: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Idioma"
                                                variant="outlined"
                                                value={language.language}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_languages",
                                                        index,
                                                        "language",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_languages${index}`]}
                                                helperText={errors[`candidate_languages${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Nivel"
                                                variant="outlined"
                                                value={language.proficiency}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_languages",
                                                        index,
                                                        "proficiency",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_languages${index}`]}
                                                helperText={errors[`candidate_languages${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Institución"
                                                variant="outlined"
                                                value={language.institution}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_languages",
                                                        index,
                                                        "institution",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors[`candidate_languages${index}`]}
                                                helperText={errors[`candidate_languages${index}`]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>

                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            backgroundColor: "lightgray",
                                            padding: 1,
                                            mb: 2,
                                            cursor: "pointer",
                                            width: "100%",
                                            borderRadius: 2,
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                            '&:hover': {
                                                transform: "scale(1.05)",
                                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                            }
                                        }}
                                    >
                                        <Typography variant="subtitle1" fontWeight="bold">Eliminar idioma</Typography>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemove("candidate_languages", index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAddNested(
                                ['candidate_languages'], {
                                language: "",
                                proficiency: "",
                                institution: ""
                            }
                            )}>
                                Añadir otro idioma
                            </Button>
                        </Box>
                        <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Referencias</Typography>
                            {formData.candidate_references.map((reference, index) => (
                                <Box key={index} sx={{ spaceY: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Nombre de la Compañía"
                                                variant="outlined"
                                                value={reference.company_name}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_references",
                                                        index,
                                                        "company_name",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Nombre de la Referencia"
                                                variant="outlined"
                                                value={reference.reference_name}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_references",
                                                        index,
                                                        "reference_name",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Teléfono"
                                                variant="outlined"
                                                value={reference.phone}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_references",
                                                        index,
                                                        "phone",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Posición de la Referencia"
                                                variant="outlined"
                                                value={reference.reference_position}
                                                onChange={(e) =>
                                                    handleChangeNested(
                                                        "candidate_references",
                                                        index,
                                                        "reference_position",
                                                        null,
                                                        null,
                                                        e.target.value,
                                                    )
                                                }
                                                error={!!errors.candidate_references}
                                                helperText={errors.candidate_references || ""}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            backgroundColor: "lightgray",
                                            padding: 1,
                                            my: 2,
                                            cursor: "pointer",
                                            width: "100%",
                                            borderRadius: 2,
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                            '&:hover': {
                                                transform: "scale(1.05)",
                                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                            }
                                        }}
                                    >
                                        <Typography variant="subtitle1" fontWeight="bold">Eliminar referencia</Typography>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemove("candidate_references", index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAddNested(
                                ["candidate_references"], {
                                company_name: "",
                                reference_name: "",
                                phone: "",
                                reference_position: ""
                            }
                            )}>
                                Añadir referencia
                            </Button>
                        </Box>
                        {/* <Box sx={{ spaceY: 4, mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" mb={2}>Categorías de Trabajo</Typography>
                            {formData.candidate_job_categories.map((category, index) => (
                                <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        // label={`Categoría ${index + 1}`}
                                        variant="outlined"
                                        value={category || ''}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_job_categories",
                                                index,
                                                null,
                                                null,
                                                null,
                                                e.target.value)
                                        }
                                    />
                                    <IconButton
                                        color="error"
                                        onClick={() => handleRemove("candidate_job_categories", index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button variant="contained" onClick={() => handleAdd("candidate_job_categories")}>
                                Añadir Categoria
                            </Button>
                        </Box> */}
                        {/* <Box sx={{ spaceY: 4, mb: 2 }}>                            
                            <Grid item xs={12} md={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Acepta</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="candidate_termsAccepted"
                                        name="candidate_termsAccepted"
                                        value={formData.candidate_Acceptances.termsConditions}
                                        onChange={(e) =>
                                            handleChangeNested(
                                                "candidate_termsAccepted",
                                                "termsConditions",
                                                null,
                                                null,
                                                null,
                                                e.target.value,
                                            )
                                        }
                                    >
                                        <FormControlLabel value={'true'} control={<Radio />} label="Si" />
                                        <FormControlLabel value={'false'} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Box> */}
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Button
                                // type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{
                                    mt: 4,
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    '&:hover': {
                                        transform: "scale(1.05)",
                                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                    }
                                }}
                            >
                                Guardar
                            </Button>
                        </Grid>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
};

export default FormCV;


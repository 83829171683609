import { useEffect, useState } from "react";
import backgroundImage from "../../assets/img/fondomw.png"
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import CVUploadPage from "../ocr/CVUploadPage";
import { PerfilCargoFormComponent } from "../../components/perfilCargoFormComponents/PerfilCargoFormComponent";
import PonderationComponent from "../../components/PonderationFormComponents/PonderationComponent";
import RankingCVs from "../rankingCVs/rankingCVs";
import Swal from "sweetalert2";
import PerfilCargoCircularNavigation from "../../components/perfilCargoFormComponents/PerfilCargoCircularNavigation";
import { FaSpinner } from "react-icons/fa";

const PerfilCargoPage = () => {
    const [page, setPage] = useState(1);
    const [profile, setProfile] = useState(null);
    const [profile_id, setProfile_id] = useState("")
    const [profileUpdate, setProfileUpdate] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [processedComplete, setProcessedComplete] = useState(false)
    const [disallowBack, setDisallowBack] = useState(false);    
    const [refresh, setRefresh] = useState(false);    

    const showProcessingModal = () => {
        Swal.fire({
            title: 'Procesando resultados',
            text: 'Por favor, espere.',
            allowOutsideClick: false,
            allowEscapeKey: false,            
            html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
            didOpen: () => {
                Swal.showLoading();
            }
        });

    };

    const handleProcessing = async () => {
        showProcessingModal();
        setProcessing(true);
        setDisallowBack(true)
        setRefresh(!refresh)
        setProcessing(false);
        // Swal.close();    
    };

    useEffect(() => {
        if (page === 4) {
            handleProcessing();
        }
    }, [page]);

    return (
        <div className="bg-cover"
            style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='row'>
                <PerfilCargoCircularNavigation
                    disallowBack={disallowBack}
                    page={page} setPage={setPage}
                    processedComplete={processedComplete}
                    handleProcessing={handleProcessing}
                />
            </div>

            <div className='content mt-4 mx-2'>
                <div className='row'>
                    <div className="col-12">
                        {
                            page === 1 && <PerfilCargoFormComponent                                
                                setPage={setPage}          
                                setProfile={setProfile}    
                                id={profile_id}           
                                setProfileUpdate={setProfileUpdate}
                                />
                        }
                        {
                            page === 2 && <PonderationComponent
                                setProfile_id={setProfile_id}
                                id={profile_id}
                                profile={profile}
                                profileUpdate={profileUpdate}
                                setPage={setPage} 
                                />
                        }
                        {
                            page === 3 && <CVUploadPage
                                id={profile_id}
                                setPage={setPage}
                                setProcessedComplete={setProcessedComplete} />
                        }
                        {page === 4 && processing && (
                            <div>
                                <FaSpinner className="spinner" /> Loading ...
                            </div>
                        )}
                        {page === 4 && !processing && <RankingCVs id={profile_id} refresh={refresh}/>}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerfilCargoPage
import { DoDisturb } from "@mui/icons-material";

const RejectedFileComponent = ({ file }) => {
    const transformSizeUnits = (size) => {
        switch (true) {
            case size < 1024: return `${size} bytes`;
            case size < 1024 * 1024: return `${(size / 1024).toFixed(2)} KB`;
            case size < 1024 * 1024 * 1024: return `${(size / (1024 * 1024)).toFixed(2)} MB`;
            default: return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    }
    return (
        <li className="p-2 flex flex-row items-center w-full">
            <div className="p-1 flex items-center">
                <DoDisturb />
            </div>
            <div className="w-full flex flex-col">
                <span className="mb-0 flex flex-row items-center justify-between">
                    <div>
                        {file.name} ( {transformSizeUnits(file.size)} )
                    </div>
                </span>
            </div>

        </li>
    )
}

export default RejectedFileComponent
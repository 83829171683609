import React, { useEffect, useState } from 'react';
import HistoryServiceRegulation from './HistoryServiceRegulation';
import HistoryServiceProfiles from './HistoryServiceProfiles';
import HistoryServiceAnalizer from './HistoryServiceAnalizer';
import mwServices from '../../services/mw_servicesS';

function HistoryUser() {
  const [historialActual, setHistorialActual] = useState(null);
  const [services, setServices] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const fetchData = await mwServices.getByState();
        // Actualiza el estado con los datos del usuario
        setServices(fetchData);
      } catch (error) {
        console.error("Error al obtener los datos del usuario", error);
      }
    };
    fetchServices();
  }, []);

  const charge = (slug) => {
    setHistorialActual(slug);
    setIsClicked(true);
  };

  const soon = () => {
    return (
      <div>
        <h2>Proximamente</h2>
        <div className='overflow-x-auto shadow-md sm:rounded-lg'>
          <table className="w-full text-base md:text-lg text-left rtl:text-right text-gray-300 dark:text-gray-300">
            <thead className="text-sm md:text-base text-gray-400 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-300">
              <tr>
                <th scope="col" className="px-8 py-4">N°</th>
                <th scope="col" className="px-8 py-4">Nombre</th>
                <th scope="col" className="px-8 py-4">Fecha de creación</th>
                <th scope="col" className="px-8 py-4 text-center">Acciones</th>                
              </tr>
            </thead>
            <tbody>
              <tr
                className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                <td colSpan="4" className="px-6 py-4 text-center">No hay datos disponibles</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )

  }

  return (
    <div className='space-x-8'>
      {services.map((item, index) => (
        item.mw_services_slug !== '/proximamente' && (
        <button
          key={index}
          onClick={() => {
            charge(item.mw_services_slug)
          }}
          className='border-2 w-fit mb-2 bg-[#F0810F] border-[#F0810F] text-white px-4 py-2 rounded-lg'>
          {item.mw_services_name}
        </button>
      )
      ))}

      {historialActual === '/regulation-page' && <HistoryServiceRegulation />}
      {historialActual === '/requirement-page' && <HistoryServiceProfiles />}
      {historialActual === '/perfil-cargo-form' && <HistoryServiceAnalizer />}
      {historialActual === '/proximamente' && soon()}
    </div>
  );
}

export default HistoryUser;
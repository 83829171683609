import React from 'react';
import PptxGenJS from 'pptxgenjs';
import { FaDownload } from 'react-icons/fa';
import './ad-pptx.css';
// import matchWorkingLogo from '../assets/img/LOGO_BAJA.jpeg';
// import Carousel from 'react-spring-3d-carousel';
import bgGirl from '../assets/pptx-generate/bg-girl.png';
import bgMen from '../assets/pptx-generate/bg-men.png';
import logoMW from '../assets/img/LOGOMW.png';
import logowhiteMW from '../assets/img/MATCHWORKING-07.png';
import pptx1 from '../assets/pptx-generate/pptx1.png';
import pptx2 from '../assets/pptx-generate/pptx2.png';

import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const esLocale = require('dayjs/locale/es');
dayjs.locale(esLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Guayaquil');

// let blueberry = '063852';
// let darknavy = '011A27';
// let tangerine = '[F0810F]';
// let daffonil = 'E6DF44';
// let blanco = 'ffffff';

const PptxGenerator = ({ profile }) => {
  const { profile_responsibilities,   // profile_listOfResponsibilities
    // profile_competencies,  // technicalSkills -  personalSkills
    profile_companyBenefits,
    profile_workLocation,  // profile_city
    profile_department,
    profile_positionToHire,
    profile_education,
    // profile_workMode,
    // profile_observations,
    // profile_courses,
    // profile_companyTools,
    // profile_companyID,
    profile_experience  //profile_timeExperience
  } = profile;  

  function addTextWithLineBreaks(slide, text, options) {
    const maxLineWidth = 45;  // Máximo de caracteres por línea
    const words = text.split(' ');
    let currentLine = '';
    let finalText = '';

    words.forEach(word => {
      // Salto de línea
      if ((currentLine + word).length > maxLineWidth) {
        finalText += currentLine.trim() + '\n';
        currentLine = word + ' ';
      } else {
        currentLine += word + ' ';
      }
    });

    finalText += currentLine.trim();    
    slide.addText(finalText, {
      x: options.x,
      y: options.y,
      fontSize: options.fontSize || 12,
      color: options.color || '000000',
    });
  }

  const createPPTONE = () => {
    let pptx = new PptxGenJS();

    // Ajustar el tamaño de la diapositiva
    pptx.defineLayout({ name: '4x3', width: 9, height: 8 });
    pptx.layout = '4x3'

    let slide = pptx.addSlide();

    // Imagen de fondo
    slide.addImage({ path: bgGirl, x:5 , y: 1.5, w: '45%', h: '75%', });

    // Rectángulo encabezado
    slide.addShape(pptx.shapes.RECTANGLE, { x: 0, y: 1, w: '100%', h: 1, fill: { color: 'f0810f' } });  //063852

    // Rectángulo pie de página
    slide.addShape(pptx.shapes.RECTANGLE, { x: 0, y: 7, w: '100%', h: 1, fill: { color: 'f0810f' } });

    slide.addText('!E S T A M O S', { x: 0.3, y: 0.3, fontSize: 20, color: '063852', bold: true });
    slide.addText('C O N T R A T A N D O!', { x: 0.3, y: 0.7, fontSize: 20, color: '063852', bold: true });
    slide.addText(profile_positionToHire.toUpperCase(), { x: 0, y: 1.2, w:'100%',fontSize: 18, color: '063852', bold: true, align: 'center'});
    slide.addText(`PARA ${profile_department.toUpperCase()}`, { x: 0, y: 1.5, w:'100%', fontSize: 16, color: '063852', bold: true, align: 'center' });
    slide.addText(profile_workLocation.profile_city.toUpperCase(), { x: 0, y: 1.8, w:'100%', fontSize: 16, color: 'ffffff', align: 'center'});

    slide.addText('Requisitos:', { x: 0.3, y: 2.2, fontSize: 16, color: '063852', bold: true });

    slide.addText('Formación:', { x: 0.3, y: 2.5, fontSize: 16, color: '063852', bold: true });
    addTextWithLineBreaks(slide, profile_education.title, { x: 0.5, y: 2.8, fontSize: 16, color: '063852' });    

    slide.addText('Experiencia:', { x: 0.3, y: 3.2, fontSize: 16, color: '063852', bold: true });
    slide.addText(profile_experience.profile_timeExperience, { x: 0.5, y: 3.5, fontSize: 16, color: '063852' });

    slide.addText('CONOCIMIENTOS', { x: 0.3, y: 3.9, fontSize: 16, color: '063852', bold: true });
    // Unir las funciones de las responsabilidades
    const responsibilitiesString = profile_responsibilities.profile_listOfResponsibilities.map(item => item.funcion).join(', ');
    addTextWithLineBreaks(slide, responsibilitiesString, { x: 0.5, y: 4.6, fontSize: 16, color: '063852' });        

    slide.addText('BENEFICIOS', { x: 0.3, y: 5.4, fontSize: 16, color: '063852', bold: true });
    // Unir los beneficios
    const benefitsString = profile_companyBenefits.join(', ');    
    addTextWithLineBreaks(slide,benefitsString, { x: 0.5, y: 5.9, fontSize: 16, color: '063852' });    

    slide.addText('Aplica a: reclutamiento@match-working.com', { x: 0, y: 7.5, w:'100%', fontSize: 20, color: '063852', bold: true, align: 'center' });

    // Imagen esquina superior derecha
    slide.addImage({ path: logoMW, x: 8, y: 0, w: 1.1, h: 1.1 });    

    // Exportar el PowerPoint
    pptx.writeFile({ fileName: `${profile_positionToHire}.pptx` });
  };

  const createPPTTWO = () => {
    let pptx = new PptxGenJS();

    // Ajustar el tamaño de la diapositiva
    pptx.defineLayout({ name: '4x3', width: 9, height: 8 });
    pptx.layout = '4x3'

    let slide = pptx.addSlide();

  // Imagen de fondo
    slide.addImage({ path: bgMen, x:0 , y: 1.5, w: '100%', h: '75%', });

    // Rectángulos encabezado
    slide.addShape(pptx.shapes.RECTANGLE, { x: 0, y: 0, w: '100%', h: 1, fill: { color: '063852' } });
    slide.addShape(pptx.shapes.RECTANGLE, { x: 0, y: 1, w: '100%', h: 1, fill: { color: 'f0810f' } });

    // Rectángulo pie de página
    slide.addShape(pptx.shapes.RECTANGLE, { x: 0, y: 7, w: '100%', h: 1, fill: { color: 'f0810f' } });

    slide.addText('!E S T A M O S', { x: 0.3, y: 0.3, fontSize: 20, color: 'ffffff', bold: true });
    slide.addText('C O N T R A T A N D O!', { x: 0.3, y: 0.7, fontSize: 20, color: 'ffffff', bold: true });
    slide.addText(profile_positionToHire.toUpperCase(), { x: 0, y: 1.2, w:'100%',fontSize: 18, color: '063852', bold: true, align: 'center'});
    slide.addText(`PARA ${profile_department.toUpperCase()}`, { x: 0, y: 1.5, w:'100%', fontSize: 16, color: '063852', bold: true, align: 'center' });
    slide.addText(profile_workLocation.profile_city.toUpperCase(), { x: 0, y: 1.8, w:'100%', fontSize: 16, color: 'ffffff', align: 'center'});

    slide.addText('Requisitos:', { x: 0.3, y: 2.2, fontSize: 16, color: 'ffffff', bold: true });

    slide.addText('Formación:', { x: 0.3, y: 2.5, fontSize: 16, color: 'ffffff', bold: true });
    addTextWithLineBreaks(slide, profile_education.title, { x: 0.5, y: 2.8, fontSize: 16, color: 'ffffff' });    

    slide.addText('Experiencia:', { x: 0.3, y: 3.2, fontSize: 16, color: 'ffffff', bold: true });
    slide.addText(profile_experience.profile_timeExperience, { x: 0.5, y: 3.5, fontSize: 16, color: 'ffffff' });

    slide.addText('CONOCIMIENTOS', { x: 0.3, y: 3.9, fontSize: 16, color: 'ffffff', bold: true });
    // Unir las funciones de las responsabilidades
    const responsibilitiesString = profile_responsibilities.profile_listOfResponsibilities.map(item => item.funcion).join(', ');
    addTextWithLineBreaks(slide, responsibilitiesString, { x: 0.5, y: 4.6, fontSize: 16, color: 'ffffff' });        

    slide.addText('BENEFICIOS', { x: 0.3, y: 5.4, fontSize: 16, color: 'ffffff', bold: true });
    // Unir los beneficios
    const benefitsString = profile_companyBenefits.join(', ');    
    addTextWithLineBreaks(slide,benefitsString, { x: 0.5, y: 5.9, fontSize: 16, color: 'ffffff' });    

    slide.addText('Aplica a: reclutamiento@match-working.com', { x: 0, y: 7.5, w:'100%', fontSize: 20, color: '063852', bold: true, align: 'center' });

    // Imagen esquina superior derecha
    slide.addImage({ path: logowhiteMW, x: 8, y: 0, w: 1.1, h: 1.1 });    

    // Exportar
    pptx.writeFile({ fileName: `${profile_positionToHire}.pptx` });
  };

  const DownloadableImage = ({ imageSrc, createPPTX }) => {
    return (
      <div className="relative group w-64 h-64 flex justify-center items-center">        
        <img
          src={imageSrc}
          alt='person'        
          className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-50"
        />        
        <div className="absolute inset-0 flex justify-center items-center opacity-100 group-hover:opacity-100 transition-opacity duration-300 z-10 pointer-events-none">
          <button
            onClick={createPPTX}
            className="bg-inhert hover:bg-[#f0810f] p-2 w-full flex justify-center items-center text-blue-500 hover:text-blue-700 pointer-events-auto"
          >
            <FaDownload size={34} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex space-x-4 justify-center">
      {/* Primera imagen */}
      <DownloadableImage imageSrc={pptx1} createPPTX={createPPTONE} />
      
      {/* Segunda imagen */}
      <DownloadableImage imageSrc={pptx2} createPPTX={createPPTTWO} />
    </div>
  );
};

export { PptxGenerator };
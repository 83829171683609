import React, { useState } from 'react';

import adminService from '../services/adminService';
import { useNavigate } from 'react-router-dom';

const showAlert = require('../utilities/alerts');

function Step1({ onNext }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerification, setShowVerification] = useState(false);
    const [codeSend, setCodeUser] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email && !showVerification) {
            adminService.sendCode(email)
            .then( async data => {
                setShowVerification(true);
                setCodeUser(data["data"]["recoverCode"]);
                //console.log(data);
            })
            .catch(error => {
                console.error('Error :', error);
            });
        } else if (verificationCode && showVerification) {
            adminService.validateCode(codeSend,verificationCode)
            .then( async data => {
                //console.log(data);
                if(data["code"] === "0" && data["data"]["valide-code"] === true ){
                    showAlert.success("Codigo de recuperación correcto.");
                    //navigate('/new-password');
                    navigate(`/new-password?email=${encodeURIComponent(email)}`);
                }else{
                    showAlert.error("El codigo de recuperación es incorrecto.")
                }
            })
            .catch(error => {
                console.error('Error :', error);
            });
            
        } else {
            alert('Por favor, completa los campos.');
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full sm:w-3/4 md:w-1/2 lg:w-1/3">
                <h1 className="text-2xl mb-4" style={{ color: '#011A27' }}>Recuperación de Contraseña</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2" style={{ color: '#011A27' }}>Correo Electrónico</label>
                        <input type="email" id="email" name="email" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    {showVerification && (
                        <div className="mb-4">
                            <label htmlFor="verification-code" className="block text-gray-700 font-bold mb-2" style={{ color: '#011A27' }}>Código de Verificación</label>
                            <input type="text" id="verification-code" name="verification-code" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" required value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                        </div>
                    )}
                    <button type="submit" className="bg-[#F0810f] text-white px-4 py-2 rounded-md hover:bg-[#E6DF44]">
                        {!showVerification ? 'Enviar Código de Verificación' : 'Enviar'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Step1;
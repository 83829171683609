import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/regulations";

const regulationTypeService = {
    createRegulation: async (regulationData) => {
      try {
        const response = await axios.post(`${api_enviroment_dev}/`, regulationData);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  
    getAllRegulations: async () => {
      try {
        const response = await axios.get(`${api_enviroment_dev}/`);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  
    getRegulationById: async (id) => {
      try {
        const response = await axios.get(`${api_enviroment_dev}/${id}`);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  
    getRegulationsByState: async (state) => {
      try {
        const response = await axios.get(`${api_enviroment_dev}/state/${state}`);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  
    updateRegulation: async (id, regulationData) => {
      try {
        const response = await axios.put(`${api_enviroment_dev}/${id}`, regulationData);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  
    deleteRegulation: async (id) => {
      try {
        const response = await axios.delete(`${api_enviroment_dev}/${id}`);
        return response.data;
      } catch (error) {
        throw error.response.data.message;
      }
    }
  };
  
  export default regulationTypeService;
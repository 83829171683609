import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import walletListDataService from '../../services/walletListDataService';
import walletService from '../../services/walletService';
import './listDataAdmin.css';

const GeneralParamForm = () => {
  const [listGeneralParam, setListGeneralParam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('add'); // 'add' o 'edit'
  const [selectedRow, setSelectedRow] = useState(null);
  const [tokenUser, setTokenUser] = useState("");
  const [newGeneralParams, setNewGeneralParams] = useState({
    name: '',
    description: '',
    value: '',
    recommendedValues: [],
    state: 'A'
  });
  const options = [
    { value: 'A', label: 'Activo' },
    { value: 'I', label: 'Inactivo' },
  ];
  const loadToken = async () => {
    const data = await walletService.getToken();
    return data.token;
  }
  const fetchData = async (token) => {
    try {
      const allGeneralParams = (await walletListDataService.getAllGenerals(token)).data;
      setListGeneralParam(
        allGeneralParams.map((item) => ({
          id: item.wallet_general_id,
          name: item.wallet_general_name,
          description: item.wallet_general_description,
          value: item.wallet_general_value,
          recommendedValues: item.wallet_general_recommended_values,
          state: item.wallet_general_state,
          creationDate: item.wallet_general_creationDate,
          updateDate: item.wallet_general_updateDate
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const token = await loadToken();
      setTokenUser(token);
      fetchData(token);
    };
    fetch();
  }, []);
  const handleEdit = (row) => {
    // Configura el modal para la edición
    setModalAction('edit');
    setSelectedRow(row);
    setNewGeneralParams({
      name: row.name,
      description: row.description,
      value: row.value,
      recommendedValues: (row.recommendedValues || []).map(Number),
      state: row.state
    });
    setIsModalOpen(true);
  };
  const handleAdd = () => {
    // Configura el modal para agregar
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      name: '',
      description: '',
      value: '',
      recommendedValues: [],
      state: 'A'
    });
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    // Cierra el modal y reinicia los campos del formulario
    setIsModalOpen(false);
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      name: '',
      description: '',
      value: '',
      recommendedValues: [],
      state: 'A'
    });
  };

  const handleSave = () => {
    const newGeneral = {
      "wallet_general_name": newGeneralParams.name,
      "wallet_general_description": newGeneralParams.description,
      "wallet_general_value": newGeneralParams.value,
      "wallet_general_recommended_values": newGeneralParams.recommendedValues,
      "wallet_general_state": newGeneralParams.state
    };

    if (modalAction === 'add') {
      //console.log('Agregar elemento:', newGeneralParams);
      walletListDataService.createGeneral(tokenUser, newGeneral)
        .then(async data => {
          fetchData(tokenUser);
        })
        .catch(error => {
          console.error('Error :', error);
        });
    } else if (modalAction === 'edit') {
      //console.log('Editar elemento:', selectedRow.id, newGeneralParams);
      walletListDataService.editGeneral(selectedRow.id, tokenUser, newGeneral)
        .then(async data => {
          fetchData(tokenUser);
        })
        .catch(error => {
          console.error('Error :', error);
        });
    }

    // Cierra el modal y reinicia los campos del formulario
    handleModalClose();
    fetchData(tokenUser);
  };
  return (
    <div>
      <div className="flex justify-content-center mt-3">
        <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleAdd}>Agregar</button>
      </div>
      {loading ? (
        <div className="text-center mt-3" >Cargando...</div>
      ) : (
        <div className="overflow-x-auto mt-4">
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">Nombre</th>
                <th className="border px-4 py-2">Descripción</th>
                <th className="border px-4 py-2">Valor</th>
                {/*  <th className="border px-4 py-2">Valores Recomendados</th>*/}
                <th className="border px-4 py-2">Estado</th>
                <th className="border px-4 py-2">Fecha de creación</th>
                <th className="border px-4 py-2">Fecha de actualización</th>
                <th className="border px-4 py-2">Editar</th>
              </tr>
            </thead>
            <tbody>
              {listGeneralParam.map((row) => (
                <tr key={row.id} className="bg-gray-100">
                  <td className="border px-4 py-2">{row.id}</td>
                  <td className="border px-4 py-2">{row.name}</td>
                  <td className="border px-4 py-2">{row.description}</td>
                  <td className="border px-4 py-2">{row.value}</td>
                  {/*<td className="border px-4 py-2">{row.recommendedValues ? row.recommendedValues.join(', ') : ''}</td> */}
                  <td className="border px-4 py-2">{row.state}</td>
                  <td className="border px-4 py-2">{row.creationDate}</td>
                  <td className="border px-4 py-2">{row.updateDate}</td>
                  <td className="border px-4 py-2">
                    <button onClick={() => handleEdit(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Modal para agregar/editar */}
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        contentLabel={modalAction === 'add' ? 'Agregar Nuevo Parametro' : 'Editar Parametro'}
        className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto"
      >
        <h2 className="text-lg font-semibold mb-4">{modalAction === 'add' ? 'Agregar Nuevo Parametro' : 'Editar Parametro'}</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Nombre:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="name"
              value={newGeneralParams.name}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, name: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Descripción:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="description"
              value={newGeneralParams.description}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, description: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="values" className="block text-sm font-bold mb-2">Valores:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="values"
              value={newGeneralParams.value ?? ''}
              onChange={(e) => {
                const inputValue = e.target.value;
                const numericValues = inputValue.split(',').map(val => {
                  const num = parseFloat(val.trim());
                  return isNaN(num) ? '' : num;
                }).filter(val => val !== '');

                setNewGeneralParams({
                  ...newGeneralParams,
                  value: inputValue,
                  recommendedValues: numericValues
                });
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-bold mb-2">Estado:</label>
            <select
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              value={newGeneralParams.state}
              onChange={(e) =>
                setNewGeneralParams({
                  ...newGeneralParams,
                  state: e.target.value,
                })
              } >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Agrega otros campos según tus necesidades */}
          <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>
            {modalAction === 'add' ? 'Agregar' : 'Guardar Cambios'}
          </button>
        </form>
      </Modal>
    </div>
  );
};
export default GeneralParamForm;

import { createContext, useContext, useReducer } from "react"
import { FacturaDetailReducer } from "./FacturaDetailReducer"
import { ReadyToProcessBuyReducer } from "./ReadyToProcessBuyReducer";
import { FileUploadReducer } from "./FileUploadReducer";
import { NavPageReducer} from "./NavigationNumberReducer";
import { BilleteraViewReducer } from "./BilleteraNavReducer";
import { TokenReducer } from "./TokenReducer";
import { facturaInitialState, FacturaValidValueInitState, PageInitialState, ReadyToProcessBuyInitialState, SelectedArticuloInitState, SuscriptionInitialValue, TokenInitState, UploadFileinitialState, ViewinitialState } from "./BilleteraInitState";
import { SuscriptionReducer } from "./PayphoneTransactionCompletedReducer";
import { FacturaValidateReducer } from "./FacturaValidateReducer";
import { SelectedArticuloReducer } from "./SelectedArticuloReducer";

const BilleteraContext = createContext()

export const BilleteraContextProvider = (props) => {
    const [facturaDetailValue, dispatchFacturaDetail] = useReducer(FacturaDetailReducer, facturaInitialState)
    const [ReadyToProcessBuyValue, dispatchReadyToProcessBuy] = useReducer(ReadyToProcessBuyReducer, ReadyToProcessBuyInitialState)
    const [UploadFileValue, dispatchUploadFile] = useReducer(FileUploadReducer, UploadFileinitialState)
    const [PageValue, dispatchNavPage] = useReducer(NavPageReducer, PageInitialState)
    const [BilleteraViewValue, dispatchBilleteraView] = useReducer(BilleteraViewReducer, ViewinitialState)
    const [TokenValue, dispatchToken] = useReducer(TokenReducer, TokenInitState)
    const [SuscriptionValue, dispatchSuscriptionValue] = useReducer(SuscriptionReducer, SuscriptionInitialValue) 
    const [FacturaDataValidValue, dispatchFacturaDataValid] = useReducer(FacturaValidateReducer, FacturaValidValueInitState)
    const [SelectedArticuloValue, dispatchSelectedArticulo] = useReducer(SelectedArticuloReducer, SelectedArticuloInitState)
    return (
        <BilleteraContext.Provider value={[
            facturaDetailValue,
            dispatchFacturaDetail,
            ReadyToProcessBuyValue,
            dispatchReadyToProcessBuy,
            UploadFileValue,
            dispatchUploadFile,
            PageValue,
            dispatchNavPage,
            BilleteraViewValue,
            dispatchBilleteraView,
            TokenValue,
            dispatchToken,
            SuscriptionValue,
            dispatchSuscriptionValue,
            FacturaDataValidValue,
            dispatchFacturaDataValid,
            SelectedArticuloValue,
            dispatchSelectedArticulo
        ]}>
            {props.children}
        </BilleteraContext.Provider>
    )
}

export const useFacturaDetailValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[0]
}

export const useFacturaDetailDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[1]
}

export const useReadyToProcessBuyValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[2]
}

export const useReadyToProcessBuyDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[3]
}

export const useUploadFileValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[4]
}

export const useUploadFileDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[5]
}

export const useNavPageValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[6]
}

export const useNavPageDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[7]
}

export const useViewValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[8]
}

export const useViewDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[9]
}

export const useTokenValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[10]
}

export const useTokenDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[11]
}

export const useSuscriptionValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[12]
}

export const useSuscriptionDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[13]
}

export const useFacturaValidValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[14]
}

export const useFacturaValidDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[15]
}

export const useSelectedArticuloValue = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[16]
}

export const useSelectedArticuloDispatch = () => {
    const fullContext = useContext(BilleteraContext)
    return fullContext[17]
}




export default BilleteraContext
import { useEffect, useState } from 'react'
import TablaRanking from '../../components/rankingCvsConponents/tablaRanking'
import TablaRankingMobile from '../../components/rankingCvsConponents/tablaRankingMobile';
import { lambdaAnalysis, lambdaCandidate } from '../../enviroment/internal-lambdas.js';
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import Swal from 'sweetalert2';
import { copyToClipboardProfile } from '../../enviroment/enviroment-dev.js';
import CopyLink from '../../utilities/copyToClipboard.js';

function RankingCVs({ id, refresh }) {
  const [datos, setDatos] = useState([])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 884);

  const handleInformacionPostulante = async () => {
    Swal.fire({
      title: 'Obteniendo resultados',
      text: 'Por favor, espere.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: `
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
          </div>
      `,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    lambdaAnalysis.getByProfile(id).then(response => {
      if (response) {
        const processedData = response.data.analysis.map(item => ({
          ...item,
          analysis_candidates_info: item.analysis_candidates_info.map(candidate => ({
            ...candidate,
            analisis: JSON.parse(candidate.analisis),
            // candidateId: candidate.analisis_candidate_id
          }))
        }));
        fetchCandidates(processedData);
        // setDatos(processedData);
      }
    })
  }

  const fetchCandidates = async (items) => {
    const batchSize = 6; // Tamaño del lote
    const candidatesInfo = items[0].analysis_candidates_info;
    const responses = [];

    const processBatch = async (batch) => {
        const promises = batch.map(async (item) => {
            try {
                const response = await lambdaCandidate.getById(item.analisis_candidate_id);
                return {
                    ...item,
                    candidateData: response,
                };
            } catch (error) {
                // Manejo de errores específicos
                if (error.statusCode === 404) {
                    console.warn(`Candidate not found for ID: ${item.analisis_candidate_id}`);
                    return null; // Retorna null para los candidatos no encontrados
                } else {
                    console.error(`Error fetching candidate ID ${item.analisis_candidate_id}:`, error);
                    return null; // Retorna null para otros errores también
                }
            }
        });
        return await Promise.all(promises);
    };

    try {
        for (let i = 0; i < candidatesInfo.length; i += batchSize) {
            const batch = candidatesInfo.slice(i, i + batchSize);
            const batchResponses = await processBatch(batch);
            // Filtra las respuestas nulas (404 y otros errores) antes de agregar
            responses.push(...batchResponses.filter(response => response !== null));
        }

        setDatos(responses);              
        return responses;
    } catch (error) {
        console.error('Error fetching candidates:', error);
    }
};


  useEffect(() => {
    handleInformacionPostulante()
  }, [id, refresh])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 884);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const datosRankeados = datos.map(candidate => ({
    ...candidate,
    nombre: candidate.candidateData.candidate_personal_data.candidate_full_names.candidate_first_name + ' ' + candidate.candidateData.candidate_personal_data.candidate_full_names.candidate_last_name,
    titulo: candidate.candidateData?.candidate_academic_formation?.map(item => item.candidate_degree || ''),
    cargoPostulado: candidate.analisis.perfilCargoPostulado,
    departamento: candidate.analisis.perfilDepartamento,
    experiencia: candidate.analisis.porcentajeExperiencia.porcentaje_experiencia,
    formacion: candidate.analisis.porcentajeEducacion.porcentaje_educacion,
    habilidades: candidate.analisis.porcentajeCursos.porcentaje_cursos,
    similitudTotal: candidate.analisis.porcentajeGeneral.porcentajeGeneral,
    modificaCV: candidate.candidateData.candidate_updateDate,

    // Datos de la informacion del postulante
    experiencia_motivo: candidate.analisis.porcentajeExperiencia.motivo,
    anios_experiencia: candidate.analisis.porcentajeExperiencia.anios_experiencia,
    nivelEducacion_motivo: candidate.analisis.porcentajeEducacion.motivo,
    habilidades_motivo: candidate.analisis.porcentajeCursos.motivo,
    certificados: candidate.candidateData?.candidate_supplementary_education?.map(edu => edu.course_title) || '',
    email: candidate.candidateData.candidate_personal_data.candidate_email,
    phone: candidate.candidateData.candidate_personal_data.candidate_phone,
    direccion: candidate.candidateData.candidate_personal_data.candidate_address.candidate_city + ', ' + candidate.candidateData.candidate_personal_data.candidate_address.candidate_country,
    institucion: candidate.candidateData?.candidate_academic_formation?.map(item => item.candidate_institution_name || ''),    
    conclusion: candidate.analisis.porcentajeGeneral.porcentajeGeneralDescripcion,
    idiomas: candidate.candidateData?.candidate_languages?.map(lang => ({
      lenguaje: lang.language || '',
      nivelLenguaje: lang.proficiency || ''
    })) || [],
    pdfUrlf: candidate.analisis_pdf_url || ''
  })
  );
  const candidatesRank = datosRankeados.sort((a, b) => b.similitudTotal - a.similitudTotal);

  if (candidatesRank) {
    Swal.close();
  }

  return (
    <div className='flex justify-center h-screen -translate-y-18 '>
      <div>
        <div className="items-center mr-7">
          <CopyLink link={`${copyToClipboardProfile}/${id}`} />
        </div>
        {isMobile
          ? <TablaRankingMobile datos={candidatesRank} />
          : <TablaRanking datos={candidatesRank} />}
      </div>
    </div>
  );
}

export default RankingCVs;
import React, { useEffect, useState } from 'react';
import walletService from "../../services/walletService";
import { useFacturaDetailValue, useSelectedArticuloValue } from '../BIlleteraComponents/BilleteraState/BilleteraContext';
const showAlert = require("../../utilities/alerts");

const TOKEN_PAYPHONE = process.env.REACT_APP_TOKEN_PAYPHONE;
const STORE_ID_PAYPHONE = process.env.REACT_APP_STORE_ID_PAYPHONE;

const PayPhoneButton = ({ reference, priceWithoutTax, priceWithTax, taxPercentage  }) => {
  const [showPaymentBox, setShowPaymentBox] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [clientTransactionId, setClientTransactionId] = useState(null);

  const factura = useFacturaDetailValue()
  const selectedArticulo = useSelectedArticuloValue()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionIdParam = urlParams.get('id');
    const clientTransactionIdParam = urlParams.get('clientTransactionId');

    if (transactionIdParam && clientTransactionIdParam) {
      setTransactionId(transactionIdParam);
      setClientTransactionId(clientTransactionIdParam);

      const url = new URL('https://5tf1gj3nwl.execute-api.sa-east-1.amazonaws.com/api/v1/payphone/transaction');
      url.searchParams.append('id', transactionIdParam);
      url.searchParams.append('clientTransactionId', clientTransactionIdParam);

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = '';
        window.history.replaceState({}, document.title, url);
        setTransactionId(null);
        setClientTransactionId(null);
        switch (data.result.statusCode) {
          case 3:
            if (data.result.transactionStatus === "Approved") {
              //http://localhost:3000/profile-form?id=34322019&clientTransactionId=150
              showAlert.success("Tu transacción ha sido aprobada.","¡Gracias por tu Pago!");
            }
            break;
          case 2:
            if (data.result.transactionStatus === "Canceled") {
              showAlert.error("Parece que hubo un problema, la transacción fue cancelada. Intentalo de nuevo mas tarde");
            }
            break;
          default:
            console.log("Estado de transacción desconocido");
        }
      })
      .catch(error => {
        console.error('Error confirming payment:', error);
      });
    }
  }, []);

  useEffect(()=>{
    const payphoneBtn = document.getElementById('payphone-btn')
    payphoneBtn.click()
  },[])

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.js';
      script.type = 'module';
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load script'));
      document.body.appendChild(script);
    });
  };

  const handlePaymentButtonClick = () => {
    if (!transactionId && !clientTransactionId) {
      loadTokenAndPaymentBox();
    } else {
      //console.log('Transaction already exists:', transactionId, clientTransactionId);
    }
  };

  const loadTokenAndPaymentBox = async () => {

    let transaction_id;
    const percentageTax = taxPercentage ;
    const amountWithoutTax = priceWithoutTax*100;
    const amountWithTax = priceWithTax*100;
    const tax = amountWithTax * (percentageTax/100) ;
    const amount = amountWithTax + amountWithoutTax + tax;
    const referencePay = reference;

    try {
      const data = await walletService.getToken();
      const token = data.token;
      const createTransaccion = {
        wallet_transaction_type: "Compra",
        wallet_transaction_coins: "0",
        wallet_transaction_amount: "1",
        wallet_transaction_payment_method: "Payphone",
        wallet_transaction_origen: "",
        billing: {
          name: factura.nombre,
          ci: factura.id ,
          phone: factura.telefono || "No Provee",
          email: factura.correo 
        }
      };
      const dataT = await walletService.createtransaction(
        localStorage.getItem("userId"),
        token,
        createTransaccion
      );
      transaction_id = dataT.data.wallet_transaction_id;
      sessionStorage.setItem('transactionData', JSON.stringify({
        transaction_id,
        token,
        reference,
        factura,
        amount,
        selectedArticulo
      }));
      await loadScript();
      setShowPaymentBox(true); // Set the state to show the payment box
      setTimeout(() => {

        const buttonBox = new window.PPaymentButtonBox({
          token: TOKEN_PAYPHONE,
          amount: amount,
          amountWithoutTax: amountWithoutTax,
          amountWithTax: amountWithTax,
          tax: tax,
          service: 0,
          tip: 0,
          storeId: STORE_ID_PAYPHONE,
          reference: referencePay,
          currency: 'USD',
          clientTransactionId: transaction_id,
          lang: 'es',
          responseType: 'postMessage'
        }).render('pp-button');
      }, 100); // Wait for the DOM to update
    } catch (error) {
      console.error('Error loading token or payment box:', error);
    }
  };

  return (
    <div>
      <button
      id='payphone-btn'
      onClick={handlePaymentButtonClick}
      className='hidden'
      >Pagar con PayPhone</button>
      {showPaymentBox && <div id="pp-button"></div>}
    </div>
  );
};

export default PayPhoneButton;

import React, { useState } from "react";
import "./adminPage.css";
import matchWorkingLogo from "../../assets/img/LOGOMW.png";
import EducationLevelForm from "../../components/list-data-admin/educationLevelForm";
import HiringReasonForm from "../../components/list-data-admin/hiringReasonForm";
import ReplaceReasonForm from "../../components/list-data-admin/replaceReasonForm";
import EmploymentTypeForm from "../../components/list-data-admin/employmentTypeForm";
import WokModeForm from "../../components/list-data-admin/workModeForm";
import WalletAdminForm from "../../components/walletAdminForm";
import TermsAdminPage from "../terms/termsConditions";
import EmailAdminForm from "../../components/emailAdminForm";
import EmailAdminFormWelcome from "../../components/emailAdminFormWelcome";
import MWServicesPage from "../mwServices/mwServices";
const showAlert = require("../../utilities/alerts");

const Tab = ({ label, isActive, onClick }) => (
  <div className={`tab ${isActive ? "active-tab" : ""}`} onClick={onClick}>
    {label}
  </div>
);

const Content = ({ children }) => <div className="content">{children}</div>;

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminCode, setAdminCode] = useState("");

  const correctAdminCode = "match-working-admin-access";

  const [showAdminSection, setShowAdminSection] = useState(false);
  const [showWalletSection, setShowWalletSection] = useState(false);
  const [showTermsSection, setShowTermsSection] = useState(false);
  const [showEmailSection, setShowEmailSection] = useState(false);
  const [showMWServicesSection, setShowMWServicesSection] = useState(false);

  const handleCardSectionClick = (section) => {
    if (section === "admin") {
      setShowAdminSection(true);
    } else if (section === "wallet") {
      setShowWalletSection(true);
    } else if (section === "terms") {
      setShowTermsSection(true);
    } else if (section === "mwServices") {
      setShowMWServicesSection(true);
    } else if (section === "email") {
      setShowEmailSection(true);
    }
    //Agregar otra pestaña de servicio de administrador (else if)
  };
  const handleBackButtonClick = () => {
    setShowAdminSection(false);
    setShowWalletSection(false);
    setShowTermsSection(false);
    setShowMWServicesSection(false);
    setShowEmailSection(false);
    //Agregar otra pestaña de servicio de administrador
  };

  const handleAdminCodeSubmit = () => {
    if (adminCode === correctAdminCode) {
      setIsAdmin(true);
      showAlert.success("¡Codigo correcto!");
    } else {
      showAlert.error("Código de administrador incorrecto. Acceso denegado.");
    }
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className=" ">
      {!isAdmin ? (
        <div className="centered-container">
          <label className="max-w-md bg-white rounded-lg shadow-lg p-6 mt-8 block text-sm font-bold mb-2">
            <strong>
              Ingresa el código de administrador:
              <span className="asterisk">*</span>
            </strong>
            <input
              type="password"
              name="request_PositionToHire"
              value={adminCode || ""}
              onChange={(e) => setAdminCode(e.target.value)}
              placeholder="xxxxx"
              className="block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
            />
            <button
              className="mt-4 bg-[#F0810F] hover:bg-[#edae6f] text-white font-bold py-2 px-4 rounded inline-block"
              onClick={handleAdminCodeSubmit}
            >
              Ingresar
            </button>
          </label>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-9">
              <div className="title">
                <h1 className="mb-4">Administrador</h1>
              </div>
              <div className="subtitle">
                <h3 className="mb-5">Administrador de datos</h3>
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-center  ">
              <img
                src={matchWorkingLogo}
                alt="Match Working Logo"
                style={{ maxHeight: "150px" }}
              />
            </div>

            {!showAdminSection && !showWalletSection && !showTermsSection && !showMWServicesSection && !showEmailSection && (
              <div className="grid grid-cols-2 gap-4 px-5">
                <div className="ml-5">
                  <div
                    className="card mb-4 "
                    onClick={() => {
                      handleCardSectionClick("admin");
                    }}
                  >
                    <div className="card-body ">
                      <h5 className="card-title">Admin</h5>
                      <p className="card-text">
                        Contenido de la primera tarjeta.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    className="card mb-4"
                    onClick={() => {
                      handleCardSectionClick("wallet");
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title">Wallet</h5>
                      <p className="card-text">
                        Contenido de la segunda tarjeta.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" ml-5 ">
                  <div
                    className="card mb-4 "
                    onClick={() => {
                      handleCardSectionClick("terms");
                    }}
                  >
                    <div className="card-body ">
                      <h5 className="card-title">Terms and Conditions</h5>
                      <p className="card-text">
                        Control de edición de términos y condiciones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    className="card mb-4"
                    onClick={() => {
                      handleCardSectionClick("mwServices");
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title">Matchworking Services</h5>
                      <p className="card-text">
                        Servicios de matchworking, gestión del carrusel de la página inicial
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ml-5">
                  <div
                    className="card mb-4"
                    onClick={() => {
                      handleCardSectionClick("email");
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title">Correos</h5>
                      <p className="card-text">
                        Administración de correos.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Agregar otra sección en administrador (otra pestaña) */}
              </div>

            )}
          </div>
          {showAdminSection && (
            <div>
              <div className="text-center pb-4">
                <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>
              <div className="flex gap-4 ml-16">
                <Tab
                  label="Niveles Educativos"
                  isActive={activeTab === 1}
                  onClick={() => handleTabClick(1)}
                />
                <Tab
                  label="Motivo de contrato"
                  isActive={activeTab === 2}
                  onClick={() => handleTabClick(2)}
                />
                <Tab
                  label="Motivo de reemplazo"
                  isActive={activeTab === 3}
                  onClick={() => handleTabClick(3)}
                />
                <Tab
                  label="Tipo de contrato"
                  isActive={activeTab === 4}
                  onClick={() => handleTabClick(4)}
                />
                <Tab
                  label="Modalidades de trabajo"
                  isActive={activeTab === 5}
                  onClick={() => handleTabClick(5)}
                />
                {/* Agrega más pestañas según sea necesario */}
              </div>
              <Content>
                {activeTab === 1 && (
                  <div>
                    <p>Niveles educativos</p>
                    <EducationLevelForm />
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    <p>Razones de contratación</p>
                    <HiringReasonForm />
                  </div>
                )}
                {activeTab === 3 && (
                  <div>
                    <p>Razones de reemplazo</p>
                    <ReplaceReasonForm />
                  </div>
                )}
                {activeTab === 4 && (
                  <div>
                    <p>Tipos de contrato</p>
                    <EmploymentTypeForm />
                  </div>
                )}
                {activeTab === 5 && (
                  <div>
                    <p>Modalidades de trabajo</p>
                    <WokModeForm />
                  </div>
                )}
                {/* Agrega más contenido según sea necesario */}
              </Content>
            </div>
          )}
          {showWalletSection && (
            <div>
              <div className="text-center pb-4">
                <button className="buttonStep" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>

              <WalletAdminForm />
            </div>
          )}
          {showTermsSection && (
            <div>
              <div className="text-center pb-4">
                <button className="buttonStep" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>
              <TermsAdminPage />
            </div>
          )}
          {showMWServicesSection && (
            <div>
              <div className="text-center pb-4">
                <button className="buttonStep" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>
              <MWServicesPage />
            </div>
          )}
          {showEmailSection && (
            <div>
              {/* <div className="text-center pb-4">
                <button className="buttonStep" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>
              <EmailAdminForm /> */}
               <div>
              <div className="text-center pb-4">
                <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleBackButtonClick}>
                  Regresar
                </button>
              </div>
              <div className="flex gap-4 ml-16">
                <Tab
                  label="Correo de Recuperación"
                  isActive={activeTab === 1}
                  onClick={() => handleTabClick(1)}
                />
                <Tab
                  label="Correo de Bienvenida"
                  isActive={activeTab === 2}
                  onClick={() => handleTabClick(2)}
                />
               
            
             
                {/* Agrega más pestañas según sea necesario */}
              </div>
              <Content>
                {activeTab === 1 && (
                  <div>
                    {/* <p>Correo</p> */}
                    <EmailAdminForm />
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    {/* <p>Correo 2</p> */}
                    <EmailAdminFormWelcome />
                  </div>
                )}
                
                {/* Agrega más contenido según sea necesario */}
              </Content>
            </div>
            </div>
          )}

          {/* Agregar el link de otra pestaña de Administrador */}
        </>
      )}
    </div>
  );
};

export default AdminPage;

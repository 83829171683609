import { ArrowBack } from '@mui/icons-material'
import mwLogo from '../../../assets/img/MATCHWORKING-07.png'
import useImagePreloader from '../../../hooks/useImagePreloader';
import { useViewDispatch } from '../BilleteraState/BilleteraContext';
const ReturnPlanButtonComponent = () => {
    useImagePreloader(mwLogo);
    const dispatchView = useViewDispatch()

    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='border-3 border-white rounded-2xl p-2 m-2 mx-0 
            w-[317px] md:w-[408px] lg:w-[555px]
            md:h-36 lg:h-44 
            hover:bg-[#FF8201] hover:scale-105 cursor-pointer transition-all duration-300 ease-in-out
            text-xl lg:text-3xl
        '
                onClick={() => dispatchView({ type: 'SET_VIEW', payload: "Planes" })}
            >
                <div className='flex flex-col items-center justify-center'>
                    <img
                        src={mwLogo}
                        alt="Matchworking Coin"
                        className='w-12 h-12'
                    />
                    <div className='text-center'>
                        ¿Quieres disfrutar de más beneficios?
                    </div>
                </div>
                <div className='flex flex-row items-center justify-center font-semiboldtext-center'>
                    <div className='text-center mr-1'>
                        <ArrowBack fontSize='12px' />
                    </div>
                    Conoce nuestros planes
                </div>
            </div>
        </div>
    )
}

export default ReturnPlanButtonComponent

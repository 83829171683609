// Pagina para Cv´s de postulantes
import React, { useState } from 'react';
import Update from "../../components/uploadCvPublic/Upload-document.js"

import FormCV from '../../components/CVPublicForm/Form';
import Cv from "../../components/RecommendationCv.js"

const CandidateForm = () => {
    const [step, setStep] = useState(1);
    const [candidate, setCandidate] = useState({
        candidateData: null,
        candidateID: null,
        recommendation: null,
    });
    const handleNext = () => {        
        setStep(prevStep => prevStep + 1);
    };

    const handlePrevious = () => {
        setStep(prevStep => prevStep - 1);
    };

    return (
        <div>
            {step === 1 && <Update onNext={handleNext} setCandidate={setCandidate} />}
            {step === 2 && <FormCV onNext={handleNext} candidate={candidate} setCandidate={setCandidate} />}
            {step === 3 && <Cv candidate={candidate} onPrevious={handlePrevious} />}
        </div>
    );
};

export default CandidateForm;
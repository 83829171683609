import { useState } from "react";
import { CanvasViewProfile } from "./CanvasViewProfile";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import Swal from "sweetalert2";
import adService from "../../services/profileService";
import { Switch } from "@mui/material";

export const ProfilePanel = ({ profileData, back }) => {
    //console.log(profileData)
    const [checked, setChecked] = useState(profileData.profile_status || false);
    const [profileControl, setProfileControl] = useState();
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    const handleCreateDOCX = async (profile) => {
        try {
            Swal.fire({
                title:
                    "Generando y decargando tu perfil",
                allowOutsideClick: false,
                allowEscapeKey: false,
                html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            await adService.wordGenerator(profile)
                .then(response => {
                    Swal.close()
                })
                .catch(error => {
                    console.error("Error:", error)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error
                    });
                })
        } catch (error) {
            console.error(error);
        }
    };

    const handleBack = () => {
        back(false)
    }

    const onSubmit = async () => {
        const profileSend = {
            ...profileData,
            profile_status: checked
        }
        console.log(profileSend)
    }    

    return (
        <div className="flex w-full py-4">
            <div className="w-full md:w-1/2 m-2 md:m-4 overflow-auto h-screen">
                <div className="w-fit transformation duration-300 hover:scale-105">
                    <a onClick={() => handleBack()}
                        className="flex flex-row p-2 item-center justify-center text text-center bg-white rounded-md cursor-pointer no-underline items-center text-black font-semibold"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size="lg" color='#000000' className="mr-2 cursor-pointer hover:text-amber-500 focus:text-amber-500" />
                        Volver
                    </a>
                </div>
                <div className="flex flex-col p-2 w-[80%] bg-gray-400 rounded-lg">
                    <p className="font-semibold text-xl">{profileData.profile_objectivePosition}</p>
                    Estado:
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        color="success"
                        inputProps={{ 'aria-label': 'controlled' }}
                    /> Activo
                    <button className="bg-white p-2 rounded-lg"
                        onClick={() => onSubmit()}
                    >Aceptar</button>
                </div>
            </div>
            <div className="w-full md:w-1/2 m-2 md:m-4 overflow-auto h-screen shadow-xl ">
                <CanvasViewProfile profile={profileData} />
            </div>
        </div>
    )
};
import { Article, ChangeCircle, Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useReadyToProcessBuyDispatch, useUploadFileDispatch } from '../BilleteraState/BilleteraContext';

const infoCuenta = {
    banco: "Produbanco",
    tipo: "Cuenta Pro Pyme",
    nroCuenta: "27059027271",
    nombre: "EMPLEAECUADOR S.A.S.",
    RUC: "1793212296001",
    correo: "david.moreno@match-working.com",
    celular: "0987656154"
}

const MainTransferenciaComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const dispatchReadyToProcessBuy = useReadyToProcessBuyDispatch()
    const dispatchFile = useUploadFileDispatch()

    useEffect(() => {
        return () => {
            dispatchFile({ type: "REMOVE_FILE" })
            dispatchReadyToProcessBuy({ type: "DISALLOWED" })
        }
    }, [])


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size > 2 * 1024 * 1024) {
            setError('El archivo no debe exceder los 2 MB.');
            setSelectedFile(null);
        } else if (file) {
            setError('');
            setSelectedFile(file);
            dispatchReadyToProcessBuy({ type: "ALLOWED" })
            dispatchFile({ type: "ADD_FILE", payload: file })
        }
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setError('');
        dispatchReadyToProcessBuy({ type: "DISALLOWED" })
        dispatchFile({ type: "REMOVE_FILE" })
    };

    return (
        <div className='flex flex-col
                items-center w-full md:w-[400px] text-3xl
                font-light py-3
                '>
            <div className='w-full text-left pl-5 border-b-2
        border-gray-200  mb-1
        '>
                Transferencia
            </div>
            <div className='p-4 w-[400px]'>
                <div className='font-extralight text-center 
                p-3 border-1 rounded-md sm:mt-2 md:mt-0
                bg-[#063852] text-white text-2xl
                '>
                    El tiempo de espera de confirmación de la transacción puede ser de hasta 2 (dos) horas en dias laborables
                </div>
                <div className='mt-2 font-bold mb-2 text-center
            '>
                    Datos de transferencia
                </div>
                <div className='p-6 max-w-lg mx-auto border-2'>
                    <div className='grid grid-cols-2 gap-4 text-left font-thin text-2xl leading-tight'>
                        <div className='col-span-2 text-center text-gray-700 font-semibold'>{infoCuenta.banco}</div>
                        <div className='text-gray-600 font-medium'>Tipo de cuenta:</div>
                        <div className='text-gray-600'>{infoCuenta.tipo}</div>
                        <div className='text-gray-600 font-medium'>#Cta:</div>
                        <div className='text-gray-600'>{infoCuenta.nroCuenta}</div>
                        <div className='text-gray-600 font-medium'>Nombre:</div>
                        <div className='text-gray-600'>{infoCuenta.nombre}</div>
                        <div className='text-gray-600 font-medium'>RUC:</div>
                        <div className='text-gray-600'>{infoCuenta.RUC}</div>
                        <div className='text-gray-600 font-medium'>Correo:</div>
                        <div className='text-gray-600 break-words'>{infoCuenta.correo}</div>
                        <div className='text-gray-600 font-medium'>Tlf:</div>
                        <div className='text-gray-600'>{infoCuenta.celular}</div>
                    </div>
                </div>

                <div className='my-3 text-center w-full'>
                    <form>
                        <div className="w-full flex flex-col items-center justify-center">
                            <label htmlFor="file-upload" className='text-lg font-thin text-center w-full mt-2 py-2'>
                                Cargar evidencia de transferencia en formato pdf o imagen.
                            </label>
                            <input
                                type="file"
                                id="file-upload"
                                name="file-upload"
                                accept="image/*,.pdf"
                                onChange={handleFileChange}
                                className="hidden"
                            />
                            <label
                                htmlFor="file-upload"
                                className="border-2 border-black 
                            text-black font-extralight 
                            p-2 rounded cursor-pointer
                            flex flex-row 
                            items-center justify-center
                            w-full my-2
                            hover:bg-[#d4d4d4]
                            hover:border-white
                            "
                            >
                                {
                                    selectedFile ? <ChangeCircle className='mr-1' fontSize='18' /> : <Article className='mr-1' fontSize='18' />

                                }
                                {
                                    selectedFile ? "Cambiar" : "Seleccionar"
                                } Archivo
                            </label>
                            {error && <div className="text-red-500 mt-1">{error}</div>}

                        </div>
                    </form>
                    {selectedFile && (
                        <div className="font-thin overflow-x-clip 
                    flex flex-row items-center 
                    justify-center mt-1 
                    h-6
                    ">
                            {selectedFile.type.startsWith('image/') ? (
                                <img
                                    src={URL.createObjectURL(selectedFile)}
                                    alt="Imagen seleccionada"
                                    className="mt-1 h-6 w-6"
                                />
                            ) : (
                                <Article className="mt-1 h-6 w-6" />
                            )}
                            <div className='truncate ml-1'>{selectedFile.name}</div>
                            <button onClick={handleDelete} className=" text-red-500">
                                <Delete />
                            </button>
                        </div>
                    )
                    }
                </div>
            </div>


        </div>
    )
}


export default MainTransferenciaComponent

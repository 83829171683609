export const actionTypes = {
    SET_PAGE: 'SET_PAGE',
    INCREMENT_PAGE: 'INCREMENT_PAGE',
    DECREMENT_PAGE: 'DECREMENT_PAGE',
};

export const setPage = (page) => ({
    type: actionTypes.SET_PAGE,
    payload: page,
});

export const incrementPage = () => ({
    type: actionTypes.INCREMENT_PAGE,
});

export const decrementPage = () => ({
    type: actionTypes.DECREMENT_PAGE,
});

export const NavPageReducer = (state , action) => {
    switch (action.type) {
        case actionTypes.SET_PAGE:
            return {
                ...state,
                actualPage: action.payload,
            };
        case actionTypes.INCREMENT_PAGE:
            return {
                ...state,
                actualPage: state.actualPage + 1,
            };
        case actionTypes.DECREMENT_PAGE:
            return {
                ...state,
                actualPage: state.actualPage - 1,
            };
        default:
            return state;
    }
};
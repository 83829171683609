import * as React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import userService from "../services/userService"; // Import the userService module
const showAlert = require("../utilities/alerts");
function EditUserInfoModal({ userData, open, onClose, onUpdate }) {
  const [updatedData, setUpdatedData] = useState({ ...userData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUpdate = async () => {
    try {
      await userService.updateUser(updatedData, localStorage.getItem('userId'));
      onUpdate(updatedData);
      showAlert.confirmation("Información actualizada correctamente");
      onClose();
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Adjusted width for responsiveness
          maxWidth: 400, // Max width for larger screens
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography className="flex justify-center" id="modal-modal-title" variant="h6" component="h2">
          Actualizar información
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <TextField
                autoComplete="user_names"
                name="user_names"
                required
                fullWidths
                id="user_names"
                value={updatedData.user_names || ""}
                onChange={handleInputChange}
                label="Nombre"
                className="w-full"
                type="text"
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="user_lastnames"
                name="user_lastnames"
                autoComplete="user_lastnames"
                value={updatedData.user_lastnames || ""}
                onChange={handleInputChange}
                label="Apellidos"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="user_cellPhone"
                name="user_cellPhone"
                autoComplete="user_cellPhone"
                value={updatedData.user_cellPhone || ""}
                onChange={handleInputChange}
                label="Número de teléfono"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="user_cargo"
                name="user_cargo"
                autoComplete="user_cargo"
                value={updatedData.user_cargo || ""}
                onChange={handleInputChange}
                label="Cargo de la empresa"
                type="text"
              />
            </Grid>
          </Grid>
          <Button
            onClick={handleUpdate}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Actualizar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditUserInfoModal;


import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');

const api_enviroment_dev = enviroment_dev.databaseUrl;
const GeneratorWord = {
  generatorRegulation: async (data) => {

    try {
      // Enviar datos al servidor para generar el documento
      const response = await axios.post(`${api_enviroment_dev}/regulations-generator/`, data);
      // console.log(response)
      return response.data;
      // Crear URL de objeto para el blob y descargar el archivo
      // const url = URL.createObjectURL(new Blob([response.data]));

      // return url;

    } catch (error) {
      console.error('Error al generar el documento:', error);
      throw error;
    }
  },
  generatorProfile: async (data) => {
    const dataExcel = data
    // console.log('profileDATA: ', dataExcel)
    const fileName = `${dataExcel.profileData.request_Department}_Vacante_${Date.now()}`
    const education = dataExcel.profileData.request_educationLevel
    const educationLevel = {
      cuartoNivel: "",
      tercerNivel: "",
      segundoNivel: "Bachiller"
    }
    if (education === "Cuarto Nivel") {
      educationLevel.cuartoNivel = "Cuarto Nivel"
      educationLevel.tercerNivel = "Licenciatura/Ingeniería, otras"
    } else if (education === "Tercer Nivel") {
      educationLevel.cuartoNivel = "No Aplica"
      educationLevel.tercerNivel = "TercerNivel"
    } else {
      educationLevel.cuartoNivel = "No Aplica"
      educationLevel.tercerNivel = "No Aplica"
    }
    // console.log('edución: ', educationLevel)
    const expAndDetail = JSON.parse(dataExcel.iaData.experiencia)
    // console.log('expDetail fila', expAndDetail)
    const responsibilities = JSON.parse(dataExcel.iaData.responsabilidades)
    // Inicializamos los objetos vacíos para funciones y tareas
    const funciones = {};
    const tareas = {};
    const habilidadesTecnicas = {};

    // Iteramos sobre cada elemento de la listaResponsabilidades
    responsibilities.listaResponsabilidades.forEach((responsabilidad, index) => {
      // Creamos las claves correspondientes en los objetos funciones y tareas
      funciones[`funcion${index + 1}`] = responsabilidad.funcion;
      tareas[`tareas${index + 1}`] = responsabilidad.tareas;
      habilidadesTecnicas[`habilidades${index + 1}`] = responsabilidad.habilidadesTecnicas;
    });

    // Ahora tienes cada atributo en una constante distinta en un solo objeto
    // console.log('responsabilidades', funciones, tareas, habilidadesTecnicas);
    const profile = {
      fileName: fileName,
      charge: dataExcel.profileData.request_PositionToHire,
      department: dataExcel.profileData.request_Department,
      country: dataExcel.profileData.request_WorkLocation.request_Country,
      city: dataExcel.profileData.request_WorkLocation.request_City,
      mision: dataExcel.iaData.mision,
      supervisorName: dataExcel.profileData.request_SupervisorName,
      supervisorCharge: dataExcel.profileData.request_SupervisorPosition,
      //educationLevel
      cuartoNivel: educationLevel.cuartoNivel,
      tercerNivel: educationLevel.tercerNivel,
      segundoNivel: educationLevel.segundoNivel,
      // experienceAndDetail: dataExcel.iaData.experiencia, //contiente los años de experiencia y el detalle
      experience: expAndDetail.timeExperience, //contiente los años de experiencia 
      expDetail: expAndDetail.detailOfExperience, //contiente el detalle del exp
      employmentType: dataExcel.profileData.request_EmploymentType,
      workMode: dataExcel.profileData.request_WorkMode,
      salaryMin: dataExcel.profileData.request_SalaryOffer.request_Range.request_Min,
      salaryMax: dataExcel.profileData.request_SalaryOffer.request_Range.request_Max,
      salaryVariable: `Salario Variable: ${dataExcel.profileData.request_infoExtraRequest_priceVariable}` || ' ',
      companyBenefits: dataExcel.profileData.request_CompanyBenefits, //contiene los posibles beneficios
      companyTools: dataExcel.profileData.request_CompanyTools,
      responsibilities: dataExcel.iaData.responsabilidades, //contiene la lista de responsabilidades
      //resposabilidades desglosadas

      funcion1: funciones.funcion1 || '--',
      funcion2: funciones.funcion2 || '--',
      funcion3: funciones.funcion3 || '--',
      funcion4: funciones.funcion4 || '--',

      tareas1: tareas.tareas1 || '--',
      tareas2: tareas.tareas2 || '--',
      tareas3: tareas.tareas3 || '--',
      tareas4: tareas.tareas4 || '--',

      habilidades1: habilidadesTecnicas.habilidades1 || '--',
      habilidades2: habilidadesTecnicas.habilidades2 || '--',
      habilidades3: habilidadesTecnicas.habilidades3 || '--',
      habilidades4: habilidadesTecnicas.habilidades4 || '--',

    }
    // console.log('Datos Cargo de Perfil: ', profile)
    try {
      // Enviar datos al servidor para generar el documento
      const response = await axios.post(`${api_enviroment_dev}/profile/`, profile, {
        responseType: 'blob', // Para recibir un blob como respuesta
      });
      // console.log(response)
      // Crear URL de objeto para el blob y descargar el archivo
      const url = URL.createObjectURL(new Blob([response.data]));
      // console.log(url)
      // setPdfUrl(url);
      // console.log('url-DOC', urlDos)

      return url;

    } catch (error) {
      console.error('Error al generar el documento:', error);
      throw error;
    }
  },
}
export default GeneratorWord;
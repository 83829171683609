import React from 'react';
import { Box, Step, Stepper, StepLabel, styled } from '@mui/material';
import { FaBackward, FaForward } from 'react-icons/fa';
import { IoReload } from 'react-icons/io5';

const steps = ['1', '2', '3'];

const CircleIconWrapper = styled('div')(({ theme, isActive }) => ({
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: isActive ? '#F0810F' : '#011A27',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 18,
}));

const PerfilCargoCircularNavigation = ({ page, setPage, disallowBack, processedComplete,handleProcessing }) => {

    const handleStep = (step) => () => {
        if (disallowBack && step < 3) return
        setPage(step);
    };

    return page === 4 ?
        (
            <div>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <button
                        onClick={handleStep(3)}
                        className='hover:scale-105 flex flex-row items-center justify-center gap-1 md:gap-3'>
                        <FaBackward /> Cargar más hojas
                    </button>

                    <button
                        onClick={handleProcessing}
                        className='hover:scale-105 bg-[#011A27] text-white px-2 flex flex-row items-center justify-center gap-1 md:gap-3'>
                        <IoReload /> Actualizar Ranking
                    </button>
                </Box>
            </div>
        )
        : (
            <>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                    <Stepper
                     activeStep={page}>
                        {steps.map((label, index) => (
                            <Step
                                key={label} 
                                >
                                <StepLabel
                                    StepIconComponent={() => (
                                        <CircleIconWrapper isActive={index + 1 === page}>
                                            {label}
                                        </CircleIconWrapper>
                                    )}
                                >
                                </StepLabel>
                            </Step>
                        ))}

                    </Stepper>
                   
                </Box>
                {
                        processedComplete &&
                        <button
                            onClick={handleStep(4)}
                            className='mt-4 md:translate-x-96
                        w-full right-0 flex flex-row items-center justify-center gap-3
                        hover:scale-105'>
                            Ver Ranking <FaForward />
                        </button>

                    }

            </>

        );
};

export default PerfilCargoCircularNavigation;

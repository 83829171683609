import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import emailConfigServiceW from '../services/emailWelcomwService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EmailAdminFormWelcome = () => {
  const [emailConfig, setEmailConfig] = useState({
    email: '',
    password: '',
    host: '',
    port: '',
    secure: false,
    provider: 'custom',
    htmlContent: ''
  });

  const [configId, setConfigId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const providers = [
    { name: 'Personalizado', host: '', port: '', secure: false },
    { name: 'Gmail', host: 'smtp.gmail.com', port: 465, secure: true },
    { name: 'Outlook', host: 'smtp.office365.com', port: 587, secure: false },
    { name: 'Yahoo', host: 'smtp.mail.yahoo.com', port: 465, secure: true },

  ];

  useEffect(() => {
    fetchEmailConfig();
  }, []);

  const fetchEmailConfig = async () => {
    try {
      const configs = await emailConfigServiceW.getAllEmailConfigsW();
      if (configs && configs.length > 0) {
        const config = configs[0];
        setEmailConfig({
          email: config.user,
          password: '', // No mostramos la contraseña por seguridad
          host: config.host,
          port: config.port,
          secure: config.secure,
          provider: 'custom',
          htmlContent: config.htmlContent || ''
        });
        setConfigId(config.ID);
      }
    } catch (error) {
      console.error('Error al obtener la configuración:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo obtener la configuración.',
        confirmButtonColor: '#d33',
      });
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmailConfig(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleProviderChange = (e) => {
    const selectedProvider = providers.find(p => p.name === e.target.value);
    if (selectedProvider) {
      setEmailConfig(prevState => ({
        ...prevState,
        host: selectedProvider.host,
        port: selectedProvider.port,
        secure: selectedProvider.secure,
        provider: e.target.value
      }));
    }
  };

  const handleQuillChange = (value) => {
    setEmailConfig(prevState => ({
      ...prevState,
      htmlContent: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const configData = {
      host: emailConfig.host,
      port: emailConfig.port,
      secure: emailConfig.secure,
      user: emailConfig.email,
      pass: emailConfig.password,
      htmlContent: emailConfig.htmlContent
    };

    try {
      if (configId) {
        // Actualizar configuración existente
        await emailConfigServiceW.updateEmailConfigW(configId, configData);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Configuración actualizada con éxito',
          confirmButtonColor: '#3085d6',
        });
      } else {
        // Crear nueva configuración
        const newConfig = await emailConfigServiceW.createEmailConfigW(configData);
        setConfigId(newConfig.ID);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Nueva configuración creada con éxito',
          confirmButtonColor: '#3085d6',
        });
      }
      fetchEmailConfig(); // Refrescar los datos después de guardar
    } catch (error) {
      console.error('Error al guardar la configuración:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al guardar la configuración',
        confirmButtonColor: '#d33',
      });
    }
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">

      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Correos de Recuperación de Contraseñas</h1>


      <h2 className="text-xl font-semibold mb-4 text-gray-700">Configuración de correo</h2>

      <div className="bg-blue-50 border-l-4 border-blue-400 text-blue-700 p-3 mb-4 text-sm" role="alert">
        <p className="font-medium">Nota importante:</p>
        <p>Al elegir el proveedor, los campos se autocompletarán. Se recomienda no modificar la configuración predeterminada.</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor="provider">
              Proveedor de Correo
            </label>
            <select
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              id="provider"
              name="provider"
              value={emailConfig.provider}
              onChange={handleProviderChange}
            >
              {providers.map(p => (
                <option key={p.name} value={p.name}>{p.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor="email">
              Correo electrónico
            </label>
            <input
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              id="email"
              type="email"
              name="email"
              value={emailConfig.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor="password">
            Contraseña
          </label>
          <input
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            id="password"
            type="password"
            name="password"
            value={emailConfig.password}
            onChange={handleChange}
            required
          />
        </div>

        <div className="bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 p-3 mb-4 text-sm" role="alert">
          <p className="font-medium">Recomendación de seguridad:</p>
          <p>Para Gmail o Yahoo!, use una "contraseña de aplicación" para mayor seguridad y no afectar la conexión.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="host">
              Host
            </label>
            <input
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              id="host"
              type="text"
              name="host"
              value={emailConfig.host}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="port">
              Puerto
            </label>
            <input
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              id="port"
              type="number"
              name="port"
              value={emailConfig.port}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="secure"
              checked={emailConfig.secure}
              onChange={handleChange}
              className="mr-2"
            />
            <span className="text-gray-700 text-sm font-bold">Conexión Segura</span>
          </label>
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Contenido HTML
          </label>
          <ReactQuill
            value={emailConfig.htmlContent}
            onChange={handleQuillChange}
            className="h-64"
          />
        </div>
        <br />

        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
            type="submit"
          >
            {configId ? 'Actualizar Configuración' : 'Guardar Configuración'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailAdminFormWelcome;

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UploadFileSection from "../components/uploadCvPublic/Upload-document";
import imgLoading from '../assets/img/LOGO_BAJA.jpeg'
import Swal from 'sweetalert2';
import { lambdaProfile } from "../enviroment/internal-lambdas";
import { CandidateForm } from "../components/CVPublicForm/CandidateForm.jsx";
import RecommendationCv from "../components/RecommendationCv.js"
import BInternational from '../assets/pilotTest/LogoBInternational.png';
import PPetrol from '../assets/pilotTest/LogoPP.png';
import LogoMatch from '../assets/img/MATCHWORKING-07.png';
import { ClosingApplication } from "../components/CVPublicForm/ClosingApplication.jsx";

export const PilotTest = () => {
    const params = useParams();
    const profileId = params.id
    const [position, setPosition] = useState(null)
    const [deprecated, setDeprecated] = useState(false);
    const [step, setStep] = useState(0);
    const [candidate, setCandidate] = useState({
        candidateData: null,
        candidateID: null,
        recommendation: null,
    });
    const location = useLocation().pathname;
    const basePath = location.split("/")[1];

    const getImagesByPath = () => {
        switch (basePath) {
            case "reclutamiento":
                return [
                    `${BInternational}`,
                ];
            case "pluspetrol":
                return [
                    `${PPetrol}`,
                ];
            default:
                return [];
        }
    };

    const images = getImagesByPath();

    useEffect(() => {
        const profileData = async () => {
            try {
                Swal.fire({
                    title: "Cargando...",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const profileAwait = await lambdaProfile.getById(profileId)
                if (profileAwait) {
                    setPosition(profileAwait.data.data)
                    Swal.close();
                }
            } catch (error) {
                console.log(error)
                Swal.close();
            }
        }
        profileData();

        // control de perfiles expirados
        if (basePath === "reclutamiento") {
            // console.log(expired.includes(profileId))
            setDeprecated(true)
        }
    }, [])

    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    const texts = {
        initialMsg: "Revisa y confirma tu información para considerarte en el proceso de selección",
        footer: "Si tienes algún problema puedes contactarte a: soporte@match-working.com",
        website: "www.match-working.com"
    }

    // Perfiles expirados
    const expired = [
        "ca1b2b946a2e13b2b83ceb5886d5307a",
        "ca1b2b946a2e13b2b83ceb5886d5307aasdasd",
        "ca1b2b946a2e13b2b83ceb588as412asd"
    ];


    return <div className="overflow-auto h-screen">
        {position ? (
            <div className="flex flex-col text-center h-fit">
                {deprecated ? (
                    <ClosingApplication />
                ) : (
                    <>
                        <div className="h-full">{step === 1 && <div className="flex items-center justify-center md:hidden">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Path-based image ${index + 1}`}
                                    className="max-h-24 ml-2 md:max-h-40 object-contain"
                                />
                            ))}
                        </div>}
                            <h3 className="mt-5">Estás aplicando al puesto:</h3>
                            <h1 className='font-bold'>{position.profile_positionToHire}</h1>
                            {step === 0 &&
                                <UploadFileSection onNext={handleNext} setCandidate={setCandidate} profileId={profileId} images={images} />
                            }
                            {step === 1 && <div>
                                <div className="hidden items-center justify-center md:flex flex-col">
                                    {images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Path-based image ${index + 1}`}
                                            className="max-h-24 ml-2 md:max-h-36 object-contain"
                                        />
                                    ))}
                                </div>
                                <p className="md:text-3xl mb-0 text-center px-2 mt-4">{texts.initialMsg}</p>
                                <CandidateForm onNext={handleNext} candidate={candidate} setCandidate={setCandidate} profileId={profileId} position={position} />
                            </div>
                            }
                            {step === 2 && <RecommendationCv candidate={candidate} />}
                        </div>
                        {step !== 3 &&
                            <footer className={`${step === 3 ? 'fixed bottom-0 left-0 w-full bg-[#f0810f] text-white z-10' : 'bottom-0 w-full bg-[#063852] text-white text-center'}`}>
                                <div className="flex justify-between items-center">

                                    {basePath === "reclutamiento" &&
                                        <img src={LogoMatch}
                                            alt="Logo MatchWorking"
                                            className="w-32"
                                        />}
                                    <div className="flex flex-col space-x-4 items-center mx-auto">

                                        <a className="text-white cursor-pointer decoration-transparent"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.match-working.com">{texts.website}</a>
                                        <div className="flex space-x-4">
                                            <a
                                                href="http://www.linkedin.com/company/matchworking/?viewAsMember=true"
                                                className="text-white hover:text-orange-600 cursor-pointer"
                                                // target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin text-3xl"></i>
                                            </a>
                                            <a
                                                href="http://www.tiktok.com/@matchworking"
                                                className="text-white hover:text-orange-600 cursor-pointer"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-tiktok text-3xl"></i>
                                            </a>
                                            {/* <a
                                    href="http://www.facebook.com/matchworking"
                                    className="text-white hover:text-orange-600 cursor-pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-facebook text-3xl"></i>
                                </a>
                                <a
                                    href="https://www.instagram.com/matchworking_ec/"
                                    className="text-white hover:text-orange-600 cursor-pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-instagram text-3xl"></i>
                                </a> */}
                                        </div>

                                    </div>
                                </div>
                            </footer>
                        }
                    </>
                )}
            </div>
        ) : (
            <div>Not found profile</div>
        )}
    </div>;
};
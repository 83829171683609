import BilleteraNavButtonComponent from "./BilleteraNavButtonComponent"
import { useNavPageValue, useViewDispatch, useViewValue } from "./BilleteraState/BilleteraContext";

const BilleteraNavComponent = ({ navOptions }) => {
    const actualView = useViewValue().actualView
    const disptachView = useViewDispatch()
    const navNumber = useNavPageValue().actualPage

    const handleSetActualView = (option) => {
        disptachView({ type: 'SET_VIEW', payload: option });
    };

    return (
        <div className={`flex sm:flex-row w-full
        ${navNumber===3 ? "" : "md:flex-col md:w-1/4 md:h-full"}
        items-start text-xl md:text-2xl lg:text-3xl
    `}>
            {
                navOptions.map(opc => (
                    <BilleteraNavButtonComponent
                        key={opc}
                        text={opc}
                        isActive={actualView === opc}
                        setActualOpcionPago={handleSetActualView}
                    />))
            }
        </div>
    )
}

export default BilleteraNavComponent

import React, { useMemo } from 'react'
import SubscriptionPlanComponent from './SubscriptionPlanComponent'
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import useImagePreloader from '../../../hooks/useImagePreloader';
import MWLogoBlanco from '../../../assets/img/MATCHWORKING-07.png'
import useWindowSize from '../../../hooks/useWindowsSize';
import { useNavPageDispatch, useSelectedArticuloDispatch, useSelectedArticuloValue } from '../BilleteraState/BilleteraContext';
import { incrementPage } from '../BilleteraState/NavigationNumberReducer';

const MainSubscriptionPlansComponent = ({ subscriptionData}) => {
    const { width, moreInfo, setMoreInfo } = useWindowSize(1024); 
    const preloadImage = useImagePreloader(MWLogoBlanco)
    const dispatchArticulo = useSelectedArticuloDispatch()
    const selected = useSelectedArticuloValue()
    const dispatchNavActualPage = useNavPageDispatch()

    const handleSelectOption = (plan) => {
        if (selected) {
            if (selected.producto === plan.wallet_service_name) {
                dispatchArticulo({type:"RESET"})
            } else {
                dispatchArticulo({type:"SET",payload:{
                    producto: plan.wallet_service_name,
                    precio: plan.wallet_service_price,
                    tipo: plan.wallet_service_type,
                    unidades: 1
                }})
                dispatchNavActualPage(incrementPage())
            }
        } else {
            dispatchArticulo({type:"SET",payload:{
                producto: plan.wallet_service_name,
                precio: plan.wallet_service_price,
                tipo: plan.wallet_service_type,
                unidades: 1
            }})
            dispatchNavActualPage(incrementPage())

        }
    }

    const subscriptionPlans = useMemo(() => 
        subscriptionData.map(plan => (
            <SubscriptionPlanComponent
                key={plan.wallet_service_id}
                data={plan}
                moreInfo={moreInfo}
                onClick={() => handleSelectOption(plan)}
                selected={selected}
                preloadedImage={preloadImage ? MWLogoBlanco : null}
            />
        )),
        [subscriptionData, moreInfo, selected, preloadImage]
    );

    const renderMoreInfoButton = () => {
        if (width >= 1024) return null;

        return (
            <button className='w-full bg-[#063852] col-span-2
                hover:bg-[#FF8201] text-white
                transition-all duration-300 ease-in-out 
                h-8'
                onClick={() => setMoreInfo(prev => !prev)}
            >
                {
                    !moreInfo
                        ? <div>
                            <KeyboardDoubleArrowDown />
                            Conocer más
                        </div>
                        : <div>
                            <KeyboardDoubleArrowUp />
                        </div>
                }
            </button>
        );
    };

    return (
        <div className={`grid grid-cols-2 
            w-full 
            h-full 
            m-0
            border-r-2 border-l-2 md:border-l-0 
            border-y-2 border-[#D9D9D9]
            `}>

            {subscriptionPlans}
            {renderMoreInfoButton()}

        </div>
    )
}

export default MainSubscriptionPlansComponent
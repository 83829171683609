// Guardian.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const Guardian = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem('logged'));

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default Guardian;

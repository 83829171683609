import React, { useEffect, useState } from 'react';
import './requirementPage.css';
import { PerfilCargoFormComponent } from '../../components/perfilCargoFormComponents/PerfilCargoFormComponent';
import { PptxGenerator } from '../../utilities/ad-pptx';
import Swal from 'sweetalert2';
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import { lambdaProfile } from '../../enviroment/internal-lambdas';
import { FaRegFileWord } from 'react-icons/fa';
import adService from '../../services/profileService';

const RequirementPage = () => {    
    const [currentDate, setCurrentDate] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [profile, setProfile] = useState(null);
    const steps = [
        {
            label: "Generar",
            step: 1,
        },
        {
            label: "Editar",
            step: 2,
        },
        {
            label: "Resultados",
            step: 3,
        },
    ];

    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 2);
    };
    
    useEffect(() => {
        const getFormattedDate = () => {
            const date = new Date();
            const options = { year: "numeric", month: "long", day: "numeric" };
            return date.toLocaleDateString("es-ES", options);
        };
        setCurrentDate(getFormattedDate());
    }, []);

    const getStepStyleClass = (step) => {
        const baseClass = "StepStyle";
        const completedClass = "completed";
        /*if(currentStep === 5){
          currentStep = 4;
        }*/
        return `${baseClass} ${currentStep >= step ? completedClass : ""}`;
    };
    if (profile) {
        Swal.fire({
            title:
                "Generando tu perfil..",
            allowOutsideClick: false,
            allowEscapeKey: false,
            html: `
            <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
            </div>
        `,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        lambdaProfile.create(profile)
            .then(response => {
                console.log('Respuesta del servidor:', response);                
                Swal.close()

            })
            .catch(error => {
                console.error("Error:", error)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error
                });
            })
    }

    const HandleCreateDOCX = async () => {
        try {
            Swal.fire({
                title:
                    "Generando y decargando tu perfil",
                allowOutsideClick: false,
                allowEscapeKey: false,
                html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            await adService.wordGenerator(profile)
                .then(response => {                    
                    Swal.close()
                })
                .catch(error => {
                    console.error("Error:", error)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error
                    });
                })
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="bg-cover">
            {/* style={{ backgroundImage: `url(${backgroundImage})` }}> */}
            <div className='md:h-screen'>
                <div className='row'>
                    <div className="col-md-9">
                        <div className='title'>
                            <h1 className="">Genera tu perfil de cargo fácilmente con la IA</h1>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="">
                        <div className="text-black p-5 rounded-lg">
                            <label>
                                <strong>Fecha de la solicitud:</strong> {currentDate}
                            </label>
                            <div className="MainContainer">
                                <div className="StepContainer">
                                    {steps.map(({ step, label }) => (
                                        <div className="StepWrapper" key={step}>
                                            <div className={getStepStyleClass(step)}>
                                                {currentStep > step ? (
                                                    <div className="CheckMark">L</div>
                                                ) : (
                                                    <div className="StepCount ">{step}</div>
                                                )}
                                            </div>
                                            <div className="StepsLabelContainer">
                                                <div className="StepLabel text-black" key={step}>
                                                    {label}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {currentStep === 1 && (
                                <>
                                    <div className='py-4'>
                                        <PerfilCargoFormComponent setProfile={setProfile} nextStep={nextStep} />
                                    </div>                                                                                                            
                                </>
                            )}
                        </div>
                    </div>
                    {currentStep === 3 && (
                        <div className='text-center'>
                            <h2 className='py-4'>Descargar perfil de cargo</h2>
                            <div className='flex-auto'>
                                <button onClick={HandleCreateDOCX} className="text-blue-500 hover:text-blue-700">
                                    <FaRegFileWord size={40} />
                                </button>
                            </div>
                            <h2 className='py-4'>Descargar publicación</h2>
                            <div className='flex justify-center'>
                                <PptxGenerator profile={profile} />
                            </div>
                            <div className="space"></div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="space"></div>
                                <button type="button" className="buttonStep" onClick={() => window.location.reload()}>
                                    Generar otro perfil de cargo
                                </button>
                            </div>
                        </div>
                    )}

                    ..

                </div>
            </div>



        </div>
    );
}

export default RequirementPage;
import React, { useState } from "react";
import "./walletPage.css";
import GeneralParamForm from "../../components/list-data-admin/walletGeneralParamsForm";
import CoinParamForm from "../../components/list-data-admin/walletCoinsParamsForm";
import ServiceParamForm from "../../components/list-data-admin/walletServicesParamsForm";
import DiscountParamForm from "../../components/list-data-admin/walletDiscountParamsForm";
const showAlert = require("../../utilities/alerts");

const Tab = ({ label, isActive, onClick }) => (
  <div className={`tab ${isActive ? "active-tab" : ""}`} onClick={onClick}>
    {label}
  </div>
);

const Content = ({ children }) => <div className="content">{children}</div>;

const WalletAdminParameterPage = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="">
      <div>
        <div className="flex gap-4">  
          <Tab
            label="Generales"
            isActive={activeTab === 1}
            onClick={() => handleTabClick(1)}
          />
          <Tab
            label="Tipos de monedas"
            isActive={activeTab === 2}
            onClick={() => handleTabClick(2)}
          />
          <Tab
            label="Tipos de servicios"
            isActive={activeTab === 3}
            onClick={() => handleTabClick(3)}
          />
          <Tab
            label="Tipos de descuentos"
            isActive={activeTab === 4}
            onClick={() => handleTabClick(4)}
          />

          {/* Agrega más pestañas según sea necesario */}
        </div>
        <Content>
          {activeTab === 1 && (
            <div>
              <p>Configuraciones generales de la wallet</p>
              <GeneralParamForm />
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <p>Tipos de monedas</p>
              <CoinParamForm />
            </div>
          )}
          {activeTab === 3 && (
            <div>
              <p>Tipos de servicios</p>
              <ServiceParamForm />
            </div>
          )}
          {activeTab === 4 && (
            <div>
              <p>Tipos de descuentos</p>
              <DiscountParamForm />
            </div>
          )}

          {/* Agrega más contenido según sea necesario */}
        </Content>
      </div>
    </div>
  );
};

export default WalletAdminParameterPage;

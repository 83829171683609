import { Close, ContactPage } from "@mui/icons-material"

const AcceptedFileComponent = ({ file, removeFile, loading}) => {
    const transformSizeUnits = (size) => {
        switch (true) {
            case undefined: return "";
            case size < 1024: return `${size} bytes`;
            case size < 1024 * 1024: return `${(size / 1024).toFixed(2)} KB`;
            case size < 1024 * 1024 * 1024: return `${(size / (1024 * 1024)).toFixed(2)} MB`;
            default: return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };

    const title = file.name ? `${file.name} (${transformSizeUnits(file.size)})`:"Procesando Archivo...";
    const error = file.error || "Error enviando Archivo"
    return (
        <li className="p-2 flex flex-row items-center w-full">
            <div className="p-1 flex items-center">
                <ContactPage />
            </div>
            <div className="w-full flex flex-col">
                <span className="mb-0 flex flex-row items-center justify-between text-sm md:text-md">
                    <div className="w-full overflow-clip">
                        {title}
                        {file.error && <p className="text-[#F0810F]">{error}</p>}
                    </div>
                    <button className="hover:scale-105"
                    disabled={loading} 
                    onClick={() => removeFile(file.id)}>
                        <Close sx={{ width: ` ${ loading ? "0%" : "75%"}` }} />
                    </button>
                </span>
                {file.error && <div style={{ height: '5px', width: `100%`, backgroundColor: '#F0810F' }}></div>}
                {file.processing && <div style={{ height: '5px', width: `100%`, backgroundColor: '#E6DF44', transition: 'width 0.5s'}}></div> }
                {file.success && <div style={{ height: '5px', width: `100%`, backgroundColor: '#63FC1A', transition: 'width 0.5s'}}></div> }

            </div>
        </li>
    );
}

export default AcceptedFileComponent;

import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../VariablesContext';
import axios from 'axios';
import Swal from 'sweetalert2';

const envLambda = 'https://j40yyu6c7b.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/by/user';

function HistoryServiceProfiles() {
  const [analizer, setAnalizer] = useState([]);
  const navigate = useNavigate();
  const { userId } = useUser();

  let selectedProfile_id = null

  const storedUserId = localStorage.getItem("userId");
  if (!storedUserId) {
    // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
    navigate("/");
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'America/Guayaquil'
    };
    return date.toLocaleDateString('es-ES', options);
  }
  function rankingTable(profile_id) {
    // selectedProfile_id = profile_id

    console.log(selectedProfile_id);
    // <RankingCVs id={profile_id} />

  }
  useEffect(() => {
    const fetchRegulations = async () => {
      Swal.fire({
        title: "Cargando perfiles...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            Swal.showLoading();
        },
    });
      try {
        const response = await axios.get(`${envLambda}/${userId}`);
        // return response.data;        

        console.log(response.data.data)
        setAnalizer(response.data.data);
        Swal.close();
      } catch (error) {
        console.error("Error al obtener los datos", error);
        Swal.close();
      }
    };
    fetchRegulations();
  }, [])
  // console.log(analizer)
  return (
    <div>      
        <div>
          <h2>Perfiles de cargo</h2>
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            <table className="w-full text-base md:text-lg text-left rtl:text-right text-gray-300 dark:text-gray-300">
              <thead className="text-sm md:text-base text-gray-400 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-300">
                <tr>
                  <th scope="col" className="px-4 py-4">N°</th>
                  {/* <th scope="col" className="px-6 py-4">Id de perfil</th> */}
                  <th scope="col" className="px-8 py-4">Nombre de cargo</th>
                  <th scope="col" className="px-8 py-4">Nombre de empresa</th>
                  <th scope="col" className="px-8 py-4">Experiencia</th>
                  <th scope="col" className="px-8 py-4">Ciudad</th>
                  <th scope="col" className="px-8 py-4 text-center">Acciones</th>
                  {/* wallet_service_id, para consultar el precio del producto generado */}
                </tr>
              </thead>
              <tbody>
                {analizer.length > 0 ? (
                  analizer.map((item, index) => (
                    <tr key={index}
                      className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                      <td className="px-6 py-4">{index + 1}</td>
                      {/* <td className="px-4 py-4">{item.profile_id || 'Sin documentos generados'}</td> */}
                      <td className="px-6 py-4">{item.profile_positionToHire || ''}</td>
                      <td className="px-6 py-4">{'Company Name'}</td>
                      <td className="px-6 py-4">{item.profile_experience.profile_timeExperience || ''}</td>
                      <td className="px-6 py-4">{item.profile_workLocation.profile_city || ''}</td>
                      <td className="px-6 py-4">
                        <a
                          onClick={() => rankingTable(item.profile_id)}
                          className="flex item-center justify-center text text-center "
                        >
                          <FontAwesomeIcon icon={faFileWord} size="2x" className="mr-2 cursor-pointer hover:text-amber-500 focus:text-amber-500" />

                        </a>
                      </td>
                    </tr>
                  ))) : (
                  <tr
                    className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                    <td colSpan="4" className="px-6 py-4 text-center">No hay datos disponibles</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>    
    </div>
  );
}

export default HistoryServiceProfiles;
import React, { useState } from 'react';

import './analize-CV.css';
import analizeService from "../../services/analizerService";
import Swal from "sweetalert2";

/*No coincide*/
const initialDataN = {
    perfil_cargo: {
        titulo: "Desarrollador de Java",
        descripcion: "Buscamos un desarrollador de Java con experiencia en el desarrollo de aplicaciones web y conocimientos sólidos de frameworks como Spring.",
        responsabilidades: [
            "Desarrollar y mantener aplicaciones web utilizando Java y frameworks asociados.",
            "Colaborar con otros miembros del equipo para diseñar y desarrollar nuevas funcionalidades.",
            "Realizar pruebas unitarias y de integración para garantizar la calidad del código."
        ],
        requisitos: {
            educacion: "Licenciatura en Informática, Ingeniería de Software o campo relacionado.",
            experiencia: "Al menos 2 años de experiencia en desarrollo de aplicaciones Java.",
            habilidades: [
                "Cursos de Java, Spring Framework, Hibernate, HTML, CSS, JavaScript.",
                "Capacidad para trabajar en equipo y comunicarse eficazmente."
            ]
        },
        ubicacion: "Manta, Ecuador"
    },
    perfil_candidato: {
        datos_demograficos: {
            nombre: "Luis Ramírez",
            edad: 35,
            genero: "Masculino",
            ubicacion: "Quito, Ecuador"
        },
        educacion: {
            titulo: "Culinaria Profesional",
            institucion: "Escuela de Gastronomía de Quito",
            grado: "Tercer Nivel",
            fecha_graduacion: "2012"
        },
        experiencia: [
            {
                puesto: "Chef de Cocina",
                empresa: "Restaurante de Alta Cocina 'Sabor Exquisito'",
                periodo: "2014 - 2024",
                responsabilidades: [
                    "Planificación y supervisión de la preparación de menús de alta cocina, garantizando la calidad y presentación de los platos.",
                    "Liderazgo y motivación del equipo de cocina, brindando orientación y capacitación según sea necesario.",
                    "Gestión eficaz del inventario de alimentos y control de los costos operativos de la cocina."
                ]
            },
            {
                puesto: "Chef de Partida",
                empresa: "Restaurante 'Gourmet Paradise'",
                periodo: "2012 - 2014",
                responsabilidades: [
                    "Preparación de platos gourmet bajo la supervisión del chef ejecutivo.",
                    "Colaboración en la creación de nuevos platos y menús.",
                    "Mantenimiento de altos estándares de higiene y seguridad alimentaria."
                ]
            }
        ],
        cursos: [
            {
                titulo: "Curso Avanzado de Alta Cocina",
                institucion: "Instituto Gastronómico Internacional",
                fecha_finalizacion: "2013"
            },
            {
                titulo: "Taller de Innovación Culinaria",
                institucion: "Escuela de Gastronomía Creativa",
                fecha_finalizacion: "2015"
            },
            {
                titulo: "Java avanzado y Spring",
                institucion: "ESPOL",
                fecha_finalizacion: "2015"
            }
        ]
    }
};

/*coincide a medias*/
const initialData = {
    perfil_cargo: {
        titulo: "Desarrollador de Java",
        descripcion: "Buscamos un desarrollador de Java con experiencia en el desarrollo de aplicaciones web y conocimientos sólidos de frameworks como Spring.",
        responsabilidades: [
            "Desarrollar y mantener aplicaciones web utilizando Java y frameworks asociados.",
            "Colaborar con otros miembros del equipo para diseñar y desarrollar nuevas funcionalidades.",
            "Realizar pruebas unitarias y de integración para garantizar la calidad del código."
        ],
        requisitos: {
            educacion: "Licenciatura en Informática, Ingeniería de Software o campo relacionado.",
            experiencia: "Al menos 2 años de experiencia en desarrollo de aplicaciones Java.",
            habilidades: [
                "Cursos de Java, Spring Framework, Hibernate, HTML, CSS, JavaScript.",
                "Capacidad para trabajar en equipo y comunicarse eficazmente."
            ]
        },
        ubicacion: "Manta, Ecuador"
    },
    perfil_candidato: {
        
            "datos_demograficos": {
              "nombre": "Juan Pérez",
              "edad": 28,
              "genero": "Masculino",
              "ubicacion": "Manta, Ecuador"
            },
            "educacion": {
              "titulo": "Ingeniería en Informática",
              "institucion": "Universidad de Manta",
              "grado": "Tercer Nivel",
              "fecha_graduacion": "2015"
            },
            "experiencia": [
              {
                "puesto": "Desarrollador de Java",
                "empresa": "Empresa de Desarrollo Tecnológico 'TechDev'",
                "periodo": "2016 - Presente",
                "responsabilidades": [
                  "Desarrollo y mantenimiento de aplicaciones web utilizando Java y Spring Framework.",
                  "Colaboración con el equipo para el diseño e implementación de nuevas funcionalidades.",
                  "Realización de pruebas unitarias e integración para asegurar la calidad del código."
                ]
              },
              {
                "puesto": "Desarrollador de Software",
                "empresa": "Compañía de Tecnología 'Innovación Digital'",
                "periodo": "2014 - 2016",
                "responsabilidades": [
                  "Desarrollo de aplicaciones empresariales utilizando Java y tecnologías relacionadas.",
                  "Participación en la planificación y ejecución de proyectos de desarrollo de software.",
                  "Colaboración con el equipo para resolver problemas técnicos y mejorar la eficiencia del desarrollo."
                ]
              }
            ],
            "cursos": [
              {
                "titulo": "Curso Avanzado de Spring Framework",
                "institucion": "Universidad Técnica Particular de Loja",
                "fecha_finalizacion": "2017"
              },
              {
                "titulo": "Curso de Desarrollo Web con Java EE",
                "institucion": "Instituto de Tecnología 'TechEdu'",
                "fecha_finalizacion": "2016"
              }
            ]
          
          
    }
};

/*coincide perfectamente*/
const initialDataP = {
    perfil_cargo: {
        titulo: "Desarrollador de Java",
        descripcion: "Buscamos un desarrollador de Java con experiencia en el desarrollo de aplicaciones web y conocimientos sólidos de frameworks como Spring.",
        responsabilidades: [
            "Desarrollar y mantener aplicaciones web utilizando Java y frameworks asociados.",
            "Colaborar con otros miembros del equipo para diseñar y desarrollar nuevas funcionalidades.",
            "Realizar pruebas unitarias y de integración para garantizar la calidad del código."
        ],
        requisitos: {
            educacion: "Licenciatura en Informática, Ingeniería de Software o campo relacionado.",
            experiencia: "Al menos 2 años de experiencia en desarrollo de aplicaciones Java.",
            habilidades: [
                "Cursos de Java, Spring Framework, Hibernate, HTML, CSS, JavaScript.",
                "Capacidad para trabajar en equipo y comunicarse eficazmente."
            ]
        },
        ubicacion: "Manta, Ecuador"
    },
    perfil_candidato: {
        "datos_demograficos": {
          "nombre": "María González",
          "edad": 32,
          "genero": "Femenino",
          "ubicacion": "Manta, Ecuador"
        },
        "educacion": {
          "titulo": "Ingeniería en Sistemas de Información",
          "institucion": "Universidad Politécnica Estatal del Litoral",
          "grado": "Tercer Nivel",
          "fecha_graduacion": "2012"
        },
        "experiencia": [
            {
              "puesto": "Desarrollador de Java",
              "empresa": "Empresa de Desarrollo Tecnológico 'TechDev'",
              "periodo": "2013 - Presente",
              "responsabilidades": [
                "Desarrollo y mantenimiento de aplicaciones web utilizando Java y Spring Framework.",
                "Implementación de soluciones utilizando frameworks como Hibernate para el acceso a bases de datos relacionales.",
                "Realización exhaustiva de pruebas unitarias e integración para garantizar la calidad del código.",
                "Colaboración estrecha con el equipo de diseño para implementar nuevas funcionalidades y optimizar el rendimiento de las aplicaciones.",
                "Investigación y adopción de las mejores prácticas de desarrollo y tecnologías emergentes en el ámbito de Java y desarrollo web."
              ]
            },
            {
              "puesto": "Desarrollador Full Stack",
              "empresa": "Consultora Tecnológica 'Innovación Digital'",
              "periodo": "2011 - 2013",
              "responsabilidades": [
                "Desarrollo de aplicaciones web de extremo a extremo utilizando Java, Spring Framework, HTML, CSS y JavaScript.",
                "Diseño e implementación de interfaces de usuario responsivas y accesibles.",
                "Colaboración con equipos multidisciplinarios para resolver problemas complejos y cumplir con los objetivos del proyecto.",
                "Participación en la planificación y ejecución de proyectos de desarrollo de software, siguiendo metodologías ágiles como Scrum."
              ]
            }
          ],
        "cursos": [
          {
            "titulo": "Curso Avanzado de Java y Spring Framework",
            "institucion": "Universidad Técnica Particular de Loja",
            "fecha_finalizacion": "2013"
          },
          {
            "titulo": "Curso de Desarrollo Frontend (HTML, CSS) Avanzado",
            "institucion": "Plataforma de Educación en Línea 'Udemy'",
            "fecha_finalizacion": "2012"
          },
          {
            "titulo": "Curso de Pruebas Unitarias e Integración Continua",
            "institucion": "Instituto de Tecnología 'TechEdu'",
            "fecha_finalizacion": "2014"
          },
          {
            "titulo": "Curso de Gestión de Proyectos de Software",
            "institucion": "Universidad Virtual 'Coursera'",
            "fecha_finalizacion": "2015"
          }
        ]
      }
      
      
};

function getColorClass(percentage) {
    if (percentage < 50) {
      return 'red';
    } else if (percentage >= 50 && percentage <= 75) {
      return 'orange';
    } else {
      return 'green';
    }
}
  
const AnalizeCVForm = () => {
    const [cargoData, setCargoData] = useState(initialDataP.perfil_cargo);
    const [candidatoData, setCandidatoData] = useState(initialDataP.perfil_candidato);
    const [resultados, setResultados] = useState(null);
    /*const [cargoData, setCargoData] = useState({
      titulo: "",
      descripcion: "",
      responsabilidades: [],
      requisitos: {
        educacion: "",
        experiencia: "",
        habilidades: []
      },
      ubicacion: ""
    });
  
    const [candidatoData, setCandidatoData] = useState({
      datos_demograficos: {
        nombre: "",
        edad: 0,
        genero: "",
        ubicacion: ""
      },
      educacion: {
        titulo: "",
        institucion: "",
        grado: "",
        fecha_graduacion: ""
      },
      experiencia: [],
      cursos: []
    });
  
    const [resultados, setResultados] = useState(null);*/
  
    const handleAnalyze = async () => {
      try {
        console.log(candidatoData);
        console.log(cargoData);
        const dataToAnalize = {
            "perfil_cargo":cargoData,
            "perfil_candidato":candidatoData
        };
        (async () => {
            try {
                Swal.fire({
                    title: "Estamos analizando el perfil...",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        console.log('La alerta se ha cerrado.');
                    }
                });
                
                const response = await analizeService.getAnalize(dataToAnalize);
                console.log("respuesta", response);
        
                // Convertir las cadenas de texto JSON en objetos JavaScript
                response.resultado.porcentajeExperiencia = JSON.parse(response.resultado.porcentajeExperiencia.replace(/```json\n|\n```/g, ''));
                response.resultado.porcentajeEducacion = JSON.parse(response.resultado.porcentajeEducacion.replace(/```json\n|\n```/g, ''));
                response.resultado.porcentajeCursos = JSON.parse(response.resultado.porcentajeCursos.replace(/```json\n|\n```/g, ''));
                response.resultado.porcentajeGeneral = JSON.parse(response.resultado.porcentajeGeneral.replace(/```json\n|\n```/g, ''));
        
                setResultados(response);
        
                Swal.close(); // Cierra la alerta después de completar el análisis
            } catch (error) {
                console.error('Error al analizar:', error);
                // Manejo de errores, por ejemplo, mostrar un mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un error al analizar el perfil. Por favor, inténtalo de nuevo más tarde.',
                });
            }
        })();
        
        
        
      } catch (error) {
        console.error('Error al analizar:', error);
      }
    };
  
    return (
        <div>
            <div className = "containerCV">
                <div className="column m-12">
                    <h2>Perfil de Cargo</h2>

                    <div className="input-group">
                        <label htmlFor="cargoTitulo" className='labelCampo' >Título del cargo:</label>
                        <input
                            id="cargoTitulo"
                            className='contentCampo'
                            type="text"
                            value={cargoData.titulo}
                            onChange={(e) => setCargoData({ ...cargoData, titulo: e.target.value })}
                            placeholder="Título del cargo"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="cargoDescripcion" className='labelCampo'>Descripción:</label>
                        <textarea
                            id="cargoDescripcion"
                            className='contentCampo'
                            value={cargoData.descripcion}
                            onChange={(e) => setCargoData({ ...cargoData, descripcion: e.target.value })}
                            placeholder="Descripción del cargo"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="cargoEducacion" className='labelCampo'>Educación requerida:</label>
                        <input
                            id="cargoEducacion"
                            className='contentCampo'
                            type="text"
                            value={cargoData.requisitos.educacion}
                            onChange={(e) => setCargoData({ ...cargoData, requisitos: { ...cargoData.requisitos, educacion: e.target.value } })}
                            placeholder="Educación requerida"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="cargoExperiencia" className='labelCampo'>Experiencia requerida:</label>
                        <input
                            id="cargoExperiencia"
                            className='contentCampo'
                            type="text"
                            value={cargoData.requisitos.experiencia}
                            onChange={(e) => setCargoData({ ...cargoData, requisitos: { ...cargoData.requisitos, experiencia: e.target.value } })}
                            placeholder="Experiencia requerida"
                        />
                    </div>

                    <div className="input-group">
                        <label className='labelCampo' >Responsabilidades: </label>
                        <ul>
                            {cargoData.responsabilidades.map((respon, index) => (
                                <li key={index}>
                                    <textarea
                                        type="text"
                                        className='contentCampo'
                                        value={respon}
                                        onChange={(e) => {
                                            const newResponsabilidades = [...cargoData.responsabilidades];
                                            newResponsabilidades[index] = e.target.value;
                                            setCargoData({ ...cargoData, responsabilidades: newResponsabilidades });
                                        }}
                                        style={{ width: "300%" }}
                                    />
                                </li>
                            ))}
                            <li>
                                <button onClick={() => setCargoData({ ...cargoData, responsabilidades: [...cargoData.responsabilidades, ""] })}>Agregar responsabilidad</button>
                            </li>
                        </ul>
                    </div>

                    <div className="input-group">
                        <label className='labelCampo'>Habilidades requeridas:</label>
                        <ul>
                            {cargoData.requisitos.habilidades.map((habilidad, index) => (
                                <li key={index} className='contentCampo'>
                                    <textarea
                                        type="text"
                                        
                                        value={habilidad}
                                        onChange={(e) => {
                                            const newHabilidades = [...cargoData.requisitos.habilidades];
                                            newHabilidades[index] = e.target.value;
                                            setCargoData({ ...cargoData, requisitos: { ...cargoData.requisitos, habilidades: newHabilidades } });
                                        }}
                                        style={{ width: "300%" }}
                                    />
                                </li>
                            ))}
                            <li>
                                <button onClick={() => setCargoData({ ...cargoData, requisitos: { ...cargoData.requisitos, habilidades: [...cargoData.requisitos.habilidades, ""] } })}>Agregar habilidad</button>
                            </li>
                        </ul>
                    </div>

                    <div className="input-group">
                        <label htmlFor="cargoUbicacion" className='labelCampo'>Ubicación del cargo:</label>
                        <input
                            id="cargoUbicacion"
                            className='contentCampo'
                            type="text"
                            value={cargoData.ubicacion}
                            onChange={(e) => setCargoData({ ...cargoData, ubicacion: e.target.value })}
                            placeholder="Ubicación del cargo"
                        />
                    </div>
                </div>

                <div className="column m-12">
                    <h2>Perfil del Candidato</h2>

                    <div className="input-group">
                        <label htmlFor="nombreCandidato" className='labelCampo'>Nombre del candidato:</label>
                        <input
                            id="nombreCandidato"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.datos_demograficos.nombre}
                            onChange={(e) => setCandidatoData({ ...candidatoData, datos_demograficos: { ...candidatoData.datos_demograficos, nombre: e.target.value } })}
                            placeholder="Nombre del candidato"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="edadCandidato" className='labelCampo'>Edad del candidato:</label>
                        <input
                            id="edadCandidato"
                            className='contentCampo'
                            type="number"
                            value={candidatoData.datos_demograficos.edad}
                            onChange={(e) => setCandidatoData({ ...candidatoData, datos_demograficos: { ...candidatoData.datos_demograficos, edad: e.target.value } })}
                            placeholder="Edad del candidato"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="generoCandidato" className='labelCampo'>Género del candidato:</label>
                        <input
                            id="generoCandidato"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.datos_demograficos.genero}
                            onChange={(e) => setCandidatoData({ ...candidatoData, datos_demograficos: { ...candidatoData.datos_demograficos, genero: e.target.value } })}
                            placeholder="Género del candidato"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="ubicacionCandidato" className='labelCampo'>Ubicación del candidato:</label>
                        <input
                            id="ubicacionCandidato"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.datos_demograficos.ubicacion}
                            onChange={(e) => setCandidatoData({ ...candidatoData, datos_demograficos: { ...candidatoData.datos_demograficos, ubicacion: e.target.value } })}
                            placeholder="Ubicación del candidato"
                        />
                    </div>

                    <h3>Educación:</h3>

                    <div className="input-group">
                        <label htmlFor="tituloAcademico" className='labelCampo'>Título académico:</label>
                        <input
                            id="tituloAcademico"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.educacion.titulo}
                            onChange={(e) => setCandidatoData({ ...candidatoData, educacion: { ...candidatoData.educacion, titulo: e.target.value } })}
                            placeholder="Título de educación"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="institucionEducativa" className='labelCampo'>Institución educativa:</label>
                        <input
                            id="institucionEducativa"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.educacion.institucion}
                            onChange={(e) => setCandidatoData({ ...candidatoData, educacion: { ...candidatoData.educacion, institucion: e.target.value } })}
                            placeholder="Institución educativa"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="gradoCandidato" className='labelCampo'>Grado del candidato:</label>
                        <input
                            id="gradoCandidato"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.educacion.grado}
                            onChange={(e) => setCandidatoData({ ...candidatoData, educacion: { ...candidatoData.educacion, grado: e.target.value } })}
                            placeholder="Grado obtenido"
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="fechaGraduacion" className='labelCampo'>Fecha de graduación:</label>
                        <input
                            id="fechaGraduacion"
                            className='contentCampo'
                            type="text"
                            value={candidatoData.educacion.fecha_graduacion}
                            onChange={(e) => setCandidatoData({ ...candidatoData, educacion: { ...candidatoData.educacion, fecha_graduacion: e.target.value } })}
                            placeholder="Fecha de graduación"
                        />
                    </div>

                    <h3>Experiencia Laboral:</h3>
                    <ul>
                        {candidatoData.experiencia.map((exp, index) => (
                            <li key={index}>
                                <div className="input-group">
                                    <label htmlFor={`puesto-${index}`} className='labelCampo'>Puesto:</label>
                                    <input
                                        id={`puesto-${index}`}
                                        className='contentCampo'
                                        type="text"
                                        value={exp.puesto}
                                        onChange={(e) => {
                                            const newExperiencia = [...candidatoData.experiencia];
                                            newExperiencia[index] = { ...exp, puesto: e.target.value };
                                            setCandidatoData({ ...candidatoData, experiencia: newExperiencia });
                                        }}
                                        placeholder="Puesto"
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor={`empresa-${index}`} className='labelCampo'>Empresa:</label>
                                    <input
                                        id={`empresa-${index}`}
                                        className='contentCampo'
                                        type="text"
                                        value={exp.empresa}
                                        onChange={(e) => {
                                            const newExperiencia = [...candidatoData.experiencia];
                                            newExperiencia[index] = { ...exp, empresa: e.target.value };
                                            setCandidatoData({ ...candidatoData, experiencia: newExperiencia });
                                        }}
                                        placeholder="Empresa"
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor={`periodo-${index}`} className='labelCampo'>Periodo:</label>
                                    <input
                                        id={`periodo-${index}`}
                                        className='contentCampo'
                                        type="text"
                                        value={exp.periodo}
                                        onChange={(e) => {
                                            const newExperiencia = [...candidatoData.experiencia];
                                            newExperiencia[index] = { ...exp, periodo: e.target.value };
                                            setCandidatoData({ ...candidatoData, experiencia: newExperiencia });
                                        }}
                                        placeholder="Periodo"
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor={`responsabilidades-${index}`} className='labelCampo'>Responsabilidades:</label>
                                    <ul>
                                        {exp.responsabilidades.map((respon, resIndex) => (
                                            <li key={resIndex}>
                                                <textarea
                                                    type="text"
                                                    className='contentCampo'
                                                    value={respon}
                                                    onChange={(e) => {
                                                        const newResponsabilidades = [...exp.responsabilidades];
                                                        newResponsabilidades[resIndex] = e.target.value;
                                                        const newExperiencia = [...candidatoData.experiencia];
                                                        newExperiencia[index] = { ...exp, responsabilidades: newResponsabilidades };
                                                        setCandidatoData({ ...candidatoData, experiencia: newExperiencia });
                                                    }}
                                                    style={{ width: "270%" }}
                                                />
                                            </li>
                                        ))}
                                        <li>
                                            <button onClick={() => {
                                                const newExperiencia = [...candidatoData.experiencia];
                                                newExperiencia[index] = { ...exp, responsabilidades: [...exp.responsabilidades, ""] };
                                                setCandidatoData({ ...candidatoData, experiencia: newExperiencia });
                                            }}>Agregar responsabilidad</button>
                                        </li>
                                    </ul>
                                </div>
                                <div> <hr/> </div>
                            </li>
                        ))}
                        <li>
                            <button onClick={() => setCandidatoData({ ...candidatoData, experiencia: [...candidatoData.experiencia, { puesto: "", empresa: "", periodo: "", responsabilidades: [""] }] })}>Agregar experiencia</button>
                        </li>
                    </ul>


                    <h3>Cursos:</h3>
                    <ul>
                        {candidatoData.cursos.map((curso, index) => (
                            <li key={index}>
                                <div className="curso-container">
                                    <div className="input-group">
                                        <label htmlFor={`tituloCurso-${index}`} className='labelCampo'>Título del curso:</label>
                                        <input
                                            id={`tituloCurso-${index}`}
                                            className='contentCampo'
                                            type="text"
                                            value={curso.titulo}
                                            onChange={(e) => {
                                                const newCursos = [...candidatoData.cursos];
                                                newCursos[index] = { ...curso, titulo: e.target.value };
                                                setCandidatoData({ ...candidatoData, cursos: newCursos });
                                            }}
                                            placeholder="Título del curso"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor={`institucionCurso-${index}`} className='labelCampo'>Institución:</label>
                                        <input
                                            id={`institucionCurso-${index}`}
                                            className='contentCampo'
                                            type="text"
                                            value={curso.institucion}
                                            onChange={(e) => {
                                                const newCursos = [...candidatoData.cursos];
                                                newCursos[index] = { ...curso, institucion: e.target.value };
                                                setCandidatoData({ ...candidatoData, cursos: newCursos });
                                            }}
                                            placeholder="Institución"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor={`fechaFinalizacion-${index}`} className='labelCampo'>Fecha de finalización:</label>
                                        <input
                                            id={`fechaFinalizacion-${index}`}
                                            className='contentCampo'
                                            type="text"
                                            value={curso.fecha_finalizacion}
                                            onChange={(e) => {
                                                const newCursos = [...candidatoData.cursos];
                                                newCursos[index] = { ...curso, fecha_finalizacion: e.target.value };
                                                setCandidatoData({ ...candidatoData, cursos: newCursos });
                                            }}
                                            placeholder="Fecha de finalización"
                                        />
                                    </div>
                                </div>
                                <div> <hr/> </div>
                            </li>
                            
                        ))}
                        <li>
                            <button onClick={() => setCandidatoData({ ...candidatoData, cursos: [...candidatoData.cursos, { titulo: "", institucion: "", fecha_finalizacion: "" }] })}>Agregar curso</button>
                        </li>
                    </ul>


                </div>

            </div>
            <div >
                <div className="button-container">
                    <button onClick={handleAnalyze} className="analyze-button">
                        Analizar
                    </button>
                </div>
            </div>
            <div className="containerCVResult">
                {resultados && (
                    <div className="results">
                        <h2>Resultados del Análisis</h2>
                        <div className="result-column center-div">
                            {resultados.resultado.porcentajeGeneral && (
                            <div className="result-item">
                                <h2 style={{ fontSize: '50px' }} className={`percentage ${getColorClass(resultados.resultado.porcentajeGeneral.porcentajeGeneral)}`}>
                                {resultados.resultado.porcentajeGeneral.porcentajeGeneral}%
                                </h2>
                            </div>
                            )}
                        </div>
                        <div className="result-columns">
                        {/* Columna para porcentajeExperiencia */}
                        <div className="result-column">
                            <h3>Porcentaje de Experiencia</h3>
                            {resultados.resultado.porcentajeExperiencia && (
                            <div className="result-item">
                                <span style={{ fontSize: '25px' }} className={`percentage ${getColorClass(resultados.resultado.porcentajeExperiencia.porcentaje_experiencia)} `}>
                                {resultados.resultado.porcentajeExperiencia.porcentaje_experiencia}%
                                </span>
                                <p><b>Motivo:</b> {resultados.resultado.porcentajeExperiencia.motivo}</p>
                                <p><b>Faltante:</b> {resultados.resultado.porcentajeExperiencia.faltante}</p>
                            </div>
                            )}
                        </div>
                        
                        {/* Columna para porcentajeEducacion */}
                        <div className="result-column">
                            <h3>Porcentaje de Educación</h3>
                            {resultados.resultado.porcentajeEducacion && (
                            <div className="result-item">
                                <span style={{ fontSize: '25px' }} className={`percentage ${getColorClass(resultados.resultado.porcentajeEducacion.porcentaje_educacion)}`}>
                                {resultados.resultado.porcentajeEducacion.porcentaje_educacion}%
                                </span>
                                <p><b>Motivo:</b> {resultados.resultado.porcentajeEducacion.motivo}</p>
                                <p><b>Faltante:</b> {resultados.resultado.porcentajeEducacion.faltante}</p>
                            </div>
                            )}
                        </div>
                        
                        {/* Columna para porcentajeCursos */}
                        <div className="result-column">
                            <h3>Porcentaje de Cursos</h3>
                            {resultados.resultado.porcentajeCursos && (
                            <div className="result-item">
                                <span style={{ fontSize: '25px' }} className={`percentage ${getColorClass(resultados.resultado.porcentajeCursos.porcentaje_cursos)}`}>
                                {resultados.resultado.porcentajeCursos.porcentaje_cursos}%
                                </span>
                                <p><b>Motivo:</b> {resultados.resultado.porcentajeCursos.motivo}</p>
                                <p><b>Faltante:</b> {resultados.resultado.porcentajeCursos.faltante}</p>
                            </div>
                            )}
                        </div>
                        </div>
                    </div>
                )}

            </div>
            
        </div>
      
    );
  }
  
  export default AnalizeCVForm;

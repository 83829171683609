import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '../services/userService';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";
import './StylesForm.css';
const showAlert = require('../utilities/alerts');

function Step2() {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0); // Estado para la fortaleza de la contraseña

    const [email, setEmail] = useState('');

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        setEmail(emailParam || '');
    }, [location.search]);

    const evaluatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 4) strength += 1;
        if (/[A-Za-z]/.test(password) && /\d/.test(password)) strength += 1;
        if (/[^A-Za-z0-9]/.test(password)) strength += 1;
        return strength;
    };

    const getPasswordStrengthText = () => {
        switch (passwordStrength) {
            case 1:
                return "Insegura";
            case 2:
                return "Casi segura";
            case 3:
                return "Segura";
            default:
                return "";
        }
    };

    const getPasswordStrengthColor = () => {
        switch (passwordStrength) {
            case 1:
                return "red";
            case 2:
                return "yellow";
            case 3:
                return "green";
            default:
                return "transparent";
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Validación de las contraseñas
        if (newPassword === confirmPassword) {
            userService.updatePassword(email, newPassword)
                .then(async data => {
                    showAlert.success("Contraseña actualizada");
                    navigate('/');
                })
                .catch(error => {
                    showAlert.error("No se pudo actualizar la contraseña");
                });
        } else {
            alert('Las contraseñas no coinciden.');
        }
    };

    const handlePasswordChange = (value) => {
        setNewPassword(value);
        setPasswordStrength(evaluatePasswordStrength(value));
    };

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center appBackground">
            <div className="bg-white p-8 rounded-lg shadow-md w-full sm:w-3/4 md:w-1/2 lg:w-1/3">
                <h1 className="text-2xl mb-4" style={{ color: '#011A27' }}>Recuperación de Contraseña</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="new-password" className="block text-gray-700 font-bold mb-2" style={{ color: '#011A27' }}>Nueva Contraseña</label>
                        <input
                            type="password"
                            style={{ color: '#011A27' }}
                            id="new-password"
                            name="new-password"
                            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                            required
                            value={newPassword}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            autoComplete="off"
                        />
                        <LinearProgress
                            variant="determinate"
                            value={(passwordStrength / 4) * 100}
                            sx={{
                                mt: 1,
                                height: 10,
                                width: "60%",
                                borderRadius: 50,
                                backgroundColor: "white",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor: getPasswordStrengthColor(),
                                },
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{ color: getPasswordStrengthColor(), mt: 1 }}
                        >
                            {getPasswordStrengthText()}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ color: "rgba(0, 0, 0, 0.6)", mt: 0, fontSize: "12px" }}
                        >
                            Se recomienda que la contraseña contenga un mínimo de cuatro
                            caracteres y que incluya una combinación de letras, números y
                            caracteres especiales.
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirm-password" className="block text-gray-700 font-bold mb-2" style={{ color: '#011A27' }}>Confirmar Contraseña</label>
                        <input
                            type="password"
                            style={{ color: '#011A27' }}
                            id="confirm-password"
                            name="confirm-password"
                            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="bg-[#F0810f] text-white px-4 py-2 rounded-md hover:bg-[#E6DF44]">Enviar</button>
                </form>
            </div>
        </div>
    );
}

export default Step2;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    padding-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}

.subtitle {
    margin-top: 0%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}

.content {
    margin-left: 5%;
    margin-right: 5%;
}

.matchWorkingLogo {
    display: flex;
    justify-content: right;
}

.matchWorkingLogo img {
    max-height: 150px;
    padding-top: 5%;
    padding-right: 15%;
}

/* Oculta el toolbar del visor de PDF */
.react-pdf__toolbar {
    display: none !important;
}


/* Estilos responsivos para pantallas más pequeñas */
@media (max-width: 768px) {
    .title {
        padding-top: 3%;
        margin-left: 3%;
        margin-right: 3%;
    }

    .subtitle {
        margin-left: 3%;
        margin-right: 3%;
    }

    .content {
        margin-left: 3%;
        margin-right: 3%;
    }

    .matchWorkingLogo img {
        max-height: 100px;
        padding-right: 10%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/regulations/regulationPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA,uCAAuC;AACvC;IACI,wBAAwB;AAC5B;;;AAGA,oDAAoD;AACpD;IACI;QACI,eAAe;QACf,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":[".title {\n    padding-top: 5%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n\n.subtitle {\n    margin-top: 0%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n\n.content {\n    margin-left: 5%;\n    margin-right: 5%;\n}\n\n.matchWorkingLogo {\n    display: flex;\n    justify-content: right;\n}\n\n.matchWorkingLogo img {\n    max-height: 150px;\n    padding-top: 5%;\n    padding-right: 15%;\n}\n\n/* Oculta el toolbar del visor de PDF */\n.react-pdf__toolbar {\n    display: none !important;\n}\n\n\n/* Estilos responsivos para pantallas más pequeñas */\n@media (max-width: 768px) {\n    .title {\n        padding-top: 3%;\n        margin-left: 3%;\n        margin-right: 3%;\n    }\n\n    .subtitle {\n        margin-left: 3%;\n        margin-right: 3%;\n    }\n\n    .content {\n        margin-left: 3%;\n        margin-right: 3%;\n    }\n\n    .matchWorkingLogo img {\n        max-height: 100px;\n        padding-right: 10%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

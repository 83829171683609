
export const ReadyToProcessBuyReducer = (state, action) => {
    switch (action.type) {
        case "ALLOWED":
            state = true
            return state;
        case "DISALLOWED":
            state = false
            return state;
        default:
            return false;
    }
};
import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/generatedRegulations";

const regulationService = {
    createRegulation: async (regulationData) => {
        try {
            const response = await axios.post(`${api_enviroment_dev}`, regulationData);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            // throw error;
        }
    },

    getAllRegulations: async () => {
        try {
            const response = await axios.get(`${api_enviroment_dev}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener el documento:', error);
            // throw error;
        }
    },

    getRegulationById: async (id) => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener el documento:', error);
            // throw error;
        }
    },

    getRegulationsByState: async (state) => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/state/${state}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener el documento:', error);
            // throw error;
        }
    },

    getByUserId: async (userid) => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/user/${userid}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener el documento:', error);
            // throw error;
        }
    },


    updateRegulation: async (id, regulationData) => {
        try {
            const response = await axios.put(`${api_enviroment_dev}/${id}`, regulationData);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    deleteRegulation: async (id) => {
        try {
            const response = await axios.delete(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    }
};
export default regulationService;

import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import listDataService from '../../services/listDataService';
import './listDataAdmin.css';

const ReplaceReasonForm = () => {
  const [listReplaceReason, setListReplaceReason] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('add'); // 'add' o 'edit'
  const [selectedRow, setSelectedRow] = useState(null);

  const [newReplaceReason, setNewReplaceReason] = useState({
    nivel: '',
    state: 'A'
  });
  const options = [
    { value: 'A', label: 'Activo' },
    { value: 'I', label: 'Inactivo' },
  ];

  const fetchData = async () => {
    try {
      const allReplaceReasons = await listDataService.getAllReplaceReason();
      setListReplaceReason(
        allReplaceReasons.map((item) => ({
          id: item.replaceReason_ID,
          nivel: item.replaceReason_content,
          state: item.replaceReason_state
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (row) => {
    // Configura el modal para la edición
    setModalAction('edit');
    setSelectedRow(row);
    setNewReplaceReason({
      nivel: row.nivel,
      state: row.state,
      // Agrega otros campos según tus necesidades
    });
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    // Configura el modal para agregar
    setModalAction('add');
    setSelectedRow(null);
    setNewReplaceReason({
      nivel: '',
      state: 'A'
    });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Cierra el modal y reinicia los campos del formulario
    setIsModalOpen(false);
    setModalAction('add');
    setSelectedRow(null);
    setNewReplaceReason({
      nivel: '',
      state: 'A',
      // Agrega otros campos según tus necesidades
    });
  };

  const handleSave = () => {
    if (modalAction === 'add') {
      //console.log('Agregar elemento:', newReplaceReason);
      const newReplaceReasonSend = {
        "replaceReason_content":newReplaceReason.nivel,
        "replaceReason_state":newReplaceReason.state
      };
      listDataService.createReplaceReason(newReplaceReasonSend)
      .then(async data => {
        //console.log("Creado ",data);
        fetchData();
      })
      .catch(error => {
        console.error('Error :', error);
      });

    } else if (modalAction === 'edit') {
        const newReplaceReasonSend = {
            "replaceReason_content":newReplaceReason.nivel,
            "replaceReason_state":newReplaceReason.state
        };
      //console.log('Editar elemento:', selectedRow.id, newReplaceReasonSend);
      listDataService.editReplaceReason(selectedRow.id,newReplaceReasonSend)
      .then(async data => {
        //console.log("Editado ",data);
        fetchData();
      })
      .catch(error => {
        console.error('Error :', error);
      });
      
    }

    // Cierra el modal y reinicia los campos del formulario
    handleModalClose();
    fetchData();
  };

  return (
    <div>
      <div className="d-flex justify-content-center mt-3">
        <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleAdd}>Agregar</button>
      </div>
      {loading ? (
        <div>Cargando...</div>
      ) : (
       <div  className="overflow-x-auto mt-4">
         <table className="table-auto border-collapse w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2">ID</th>
              <th className="border px-4 py-2">Motivo de reemplazo</th>
              <th className="border px-4 py-2">Estado</th>
              <th className="border px-4 py-2">Editar</th>
            </tr>
          </thead>
          <tbody>
            {listReplaceReason.map((row) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.nivel}</td>
                <td>{row.state}</td>
                <td>
                  <button onClick={() => handleEdit(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
      )}

      {/* Modal para agregar/editar */}
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        contentLabel={modalAction === 'add' ? 'Agregar Nuevo Motivo de Reemplazo' : 'Editar Motivo de Reemplazo'}
        className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto"  
      >
        <h2>{modalAction === 'add' ? 'Agregar Nuevo Motivo de Reemplazo' : 'Editar Motivo de Reemplazo'}</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="nivel"  className="block text-sm font-bold mb-2">Motivo:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="nivel"
              value={newReplaceReason.nivel}
              onChange={(e) => setNewReplaceReason({ ...newReplaceReason, nivel: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state"  className="block text-sm font-bold mb-2">Estado:</label>
            <select
             className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              value={newReplaceReason.state}
              onChange={(e) =>
                setNewReplaceReason({
                  ...newReplaceReason,
                  state: e.target.value,
                })
              }
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Agrega otros campos según tus necesidades */}
          <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>
            {modalAction === 'add' ? 'Agregar' : 'Guardar Cambios'}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default ReplaceReasonForm;

import React, { useState } from 'react';
import { cvFilesUrl } from "../../enviroment/enviroment-dev";
import Swal from 'sweetalert2';
import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import TermsModal from '../TermsModal';
import showAlert from '../../utilities/alerts';
import matchWorkingLogo from '../../assets/img/LOGOMW.png';
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import apiClient from '../../utilities/interceptorConfig';
import { MdOutlineEmail } from "react-icons/md";
import { useLocation } from 'react-router-dom';

const UploadFileSection = ({ onNext, setCandidate, profileId, images = [] }) => {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState();
    const [fileSize, setFileSize] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [dragActive, setDragActive] = useState(false);
    const [showTermsPopup, setShowTermsPopup] = useState(false);
    const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = useState(false);
    const [userAcceptances, setUserAcceptances] = useState({
        termsConditions: false,
        privacyPolicies: false
    });

    const location = useLocation().pathname;
    const basePath = location.split("/")[1];

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            processFile(file);
            setFile(file); // Guarda el archivo en el estado
        } else {
            showAlert.error("Archivo no válido. Solo se permiten archivos PDF o Word.");
        }
    };


    const handleDragOver = (event) => {
        event.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragActive(false);
        const file = event.dataTransfer.files[0];
        if (file && (file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            processFile(file);
            setFile(file); // Guarda el archivo en el estado
        } else {
            showAlert.error("Archivo no válido. Solo se permiten archivos PDF o Word.");
        }
    };

    const processFile = (file) => {
        setFileName(file.name);
        setFileSize((file.size / 1024 / 1024).toFixed(1)); // Convert size to MB
        simulateUpload(); // Comienza la simulación de la carga
    };

    const simulateUpload = () => {
        setUploadProgress(0); // Resetear el progreso
        const interval = setInterval(() => {
            setUploadProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 10; // Incrementa el progreso en 10%
            });
        }, 300); // Incremento cada 300 ms para simular la carga
    };

    const handleUploadNoCV = () => {
        if (!userAcceptances.termsConditions) {
            showAlert.error("Por favor, acepta los términos y condiciones.");
            return;
        } else if (!userAcceptances.privacyPolicies) {
            showAlert.error("Por favor, acepta las políticas de privacidad.");
            return;
        } else {
            onNext()
        }
    }

    const handleUpload = async () => {
        if (!file) {
            showAlert.error("No ha seleccionado ningún archivo o el formato no es válido")
            // alert("No file selected or file type is invalid.");
            return;
        }
        if (!userAcceptances.termsConditions) {
            showAlert.error("Por favor, acepta los términos y condiciones.");
            return;
        }
        if (!userAcceptances.privacyPolicies) {
            showAlert.error("Por favor, acepta las políticas de privacidad.");
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        try {
            Swal.fire({
                title:
                    "Cargando curriculum..",
                allowOutsideClick: false,
                allowEscapeKey: false,
                html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            const response = await apiClient.post(`${cvFilesUrl}uploadfile/cv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });
            // console.log('Cons', response);
            Swal.close()
            setCandidate({ ...setCandidate, candidateData: response.data })
            onNext()
        } catch (error) {
            Swal.close()
            showAlert.error("No ha seleccionado ningún archivo o el formato no es válido")

        }
    };

    const handleChange = (name, value) => {
        setUserAcceptances((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // console.log(`Updating ${value}`);
        //   if (!value) {
        //       showAlert.error("Debes aceptar los términos y condiciones antes de continuar.")
        //   }
    };
    const handleShowTermsPopup = () => {
        setShowTermsPopup(true);
    };

    const handleCloseTermsPopup = () => {
        setShowTermsPopup(false);
    };

    const handleShowPrivacyPolicyPopup = () => {
        setShowPrivacyPolicyPopup(true);
    };

    const handleClosePrivacyPolicyPopup = () => {
        setShowPrivacyPolicyPopup(false);
    };

    const texts = {
        msgContact: "Si tienes algún problema puedes contactarte a:",
        email: "soporte@match-working.com",
    }

    return (
        <div className={`${profileId ? 'flex justify-center mb-6' : 'fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] overflow-auto font-[sans-serif]'}`}>
            <div className="w-full max-w-lg bg-white rounded-lg relative" >
                <div className="d-flex align-items-center py-4 justify-content-center">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Path-based image ${index + 1}`}
                            className="max-h-20 ml-2 md:max-h-24 object-contain"
                        />
                    ))}
                    {basePath !== 'reclutamiento' &&
                        <img src={matchWorkingLogo} alt="Match Working Logo" className="max-h-20 ml-2 md:max-h-32 object-contain" />
                    }
                </div>
                <div className="flex items-center pb-3 " >
                    <div className="flex-1">
                        <h3 className="text-3xl text-center font-bold">Sube tu curriculum (CV) </h3>
                        <p className="text-gray-600 text-center text-base mt-1">Sube tu archivo en formato pdf o word</p>
                    </div>
                </div>

                <div
                    className={`rounded-lg border-2 border-dashed mt-6 ${dragActive ? 'bg-gray-100' : ''}`}
                    style={{ borderColor: '#E6DF44' }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className="min-h-[180px] flex flex-col items-center justify-center text-center cursor-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 mb-4 inline-block"
                            viewBox="0 0 32 32"
                            fill="#F0810F"
                        >
                            <path
                                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                            />
                            <path
                                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                            />
                        </svg>

                        <h4 className="text-sm text-gray-600">
                            Arrastra y Suelta o  <label htmlFor="chooseFile" className="text-[#F0810F] cursor-pointer">Elige el archivo</label> para subirlo
                        </h4>
                        <input type="file" id="chooseFile" className="hidden" onChange={handleFileChange} accept="application/pdf" />
                    </div>
                </div>

                {fileName && (
                    <div className="flex flex-col bg-[#E6DF44] p-4 rounded-lg mt-4">
                        <div className="flex">
                            <p className="text-xs text-gray-800 flex-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="w-5 mr-2 fill-current inline-block"
                                    fill="#F0810F"
                                >
                                    <path d="m433.798 106.268-96.423-91.222C327.119 5.343 313.695 0 299.577 0H116C85.673 0 61 24.673 61 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55V146.222c0-15.049-6.27-29.612-17.202-39.954zM404.661 120H330c-2.757 0-5-2.243-5-5V44.636zM396 482H116c-13.785 0-25-11.215-25-25V55c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z" />
                                    <path d="M363 200H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zm0 80H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-147.28 80H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                                </svg>
                                {fileName} <span className="ml-2">{fileSize} mb</span>
                            </p>
                        </div>

                        <div className="bg-gray-300 rounded-full w-full h-2 my-2">
                            <div className="h-full rounded-full bg-[#F0810F] flex items-center relative" style={{ width: `${uploadProgress}%` }}>
                                <span className="absolute text-xs right-0 bg-white w-2 h-2 rounded-full"></span>
                            </div>
                        </div>

                        <p className="text-xs text-gray-800 flex-1">{uploadProgress}% done</p>
                    </div>
                )}

                <div className='w-full mt-4'>
                    <Box xs={12}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={userAcceptances.termsConditions}
                                    onChange={(e) => handleChange("termsConditions", e.target.checked)}
                                    color="primary"
                                    name="termsConditions"
                                />}
                                label={
                                    <span>
                                        <Button

                                            onClick={handleShowTermsPopup}
                                            sx={{
                                                color: "#F0810F",
                                                textDecoration: "none",
                                                transition: "color 0.3s",
                                                fontSize: "0.7rem",

                                            }}
                                        >
                                            He leído y acepto los términos y condiciones
                                        </Button>
                                    </span>
                                }
                            />
                            {showTermsPopup && <TermsModal onClose={handleCloseTermsPopup} type="terms" />}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userAcceptances.privacyPolicies}
                                        onChange={(e) => handleChange("privacyPolicies", e.target.checked)}
                                        color="primary"
                                        name="privacyPolicies"
                                    />
                                }
                                label={
                                    <span>
                                        <Button
                                            onClick={handleShowPrivacyPolicyPopup}
                                            sx={{
                                                color: "#F0810F",
                                                textDecoration: "none",
                                                transition: "color 0.3s",
                                                fontSize: "0.7rem",
                                            }}
                                        >
                                            He leído y acepto las políticas de privacidad
                                        </Button>
                                    </span>
                                }
                            />
                            {showPrivacyPolicyPopup && <TermsModal onClose={handleClosePrivacyPolicyPopup} type="privacy" />}
                        </Grid>
                    </Box>
                </div>

                <div className="border-t pt-6 flex flex-col justify-between gap-4 mt-6" style={{ borderColor: '#E6DF44' }}>

                    <div className='flex gap-4'>
                        <button
                            type="button"
                            className="w-full px-4 py-2 rounded-lg text-gray-800 text-sm border-none outline-none tracking-wide bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                            onClick={handleUploadNoCV}
                        >
                            Continuar sin importar el curriculum (CV)
                        </button>  <button
                            type="button"
                            className={`w-full px-4 py-2 rounded-lg text-sm border-none outline-none tracking-wide ${file ? 'bg-[#F0810F] text-white hover:bg-[#ff8100]' : 'bg-gray-200 text-gray-800 hover:bg-gray-300 active:bg-gray-200'}`}
                            onClick={handleUpload}
                        >
                            Continuar
                        </button>
                    </div>
                    <span className='flex flex-row text-center mx-auto text-sm items-center'>
                        <a className='justify-center'><MdOutlineEmail className='text-4xl pr-2 text-black' /></a>
                        <div className='flex flex-col r'>
                            <p className='flex flex-col text-sm py-0 my-0 mb-0 pb-0'>
                                {texts.msgContact}
                            </p>
                            <p className='flex flex-col text-start text-sm py-0 my-0 mb-0 pb-0 font-bold'>{texts.email}</p>
                        </div>
                    </span>

                </div>
            </div>
        </div>
    );
};

export default UploadFileSection;

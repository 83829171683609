import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';
import recommendationCvService from '../services/recommendationCv';
import Swal from 'sweetalert2';

// Estilo de Markdown
const MarkdownWrapper = styled('div')({
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  overflowX: 'auto',
  whiteSpace: 'pre-wrap',
  color: '#333', 
  zIndex: 1, 
  position: 'relative', 
});

// Estilo fondo de pantalla
const Background = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '#fff', 
  zIndex: -1,
});

// Estilo del footer
const FooterStyled = styled('footer')({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#f0810f',
  color: '#fff',
  zIndex: 10, //el footer esté encima del contenido
});

const RecommendationCv = ({ candidate }) => {
  const [markdownContent, setMarkdownContent] = useState('');

  const candidateId = candidate.candidateID;
  console.log(candidateId)

  useEffect(() => {
    const fetchRecommendation = async () => {
      try {
        Swal.fire({
          title: "Generando recomendaciones..",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const data = await recommendationCvService.getRecommendationById(candidateId);
        console.log('Datos obtenidos:', data);

        if (data && data.cv_recomendations) {
          setMarkdownContent(data.cv_recomendations);
        } else {
          setMarkdownContent('No hay contenido disponible.');
        }
        Swal.close()
      } catch (error) {
        console.error('Error al cargar la recomendación:', error);
        Swal.close()
      }
    };

    fetchRecommendation();

    const loadBotpressScripts = () => {
      const script1 = document.createElement('script');
      script1.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js';
      script1.async = true;
      script1.onload = () => {
        const script2 = document.createElement('script');
        script2.src = 'https://mediafiles.botpress.cloud/16148785-e8e4-479e-9125-b0550b82ff15/webchat/config.js';
        script2.defer = true;
        script2.async = true;
        document.head.appendChild(script2);
      };

      document.head.appendChild(script1);
    };

    loadBotpressScripts();

    return () => {
      const script1 = document.querySelector('script[src="https://cdn.botpress.cloud/webchat/v1/inject.js"]');
      const script2 = document.querySelector('script[src="https://mediafiles.botpress.cloud/16148785-e8e4-479e-9125-b0550b82ff15/webchat/config.js"]');
      if (script1) script1.remove();
      if (script2) script2.remove();
    };
  }, [candidateId]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <div className='border-b border-[#F0810F] my-4'>
        <h1>¡¡Uff!! fue difícil, pero está listo, te deseamos éxito en tu búsqueda de empleo</h1>
      </div>
      <div className='pb-10 mb-40'>
      <Background />
      <Container maxWidth="lg" style={{ padding: '1px', position: 'relative', zIndex: 1 }}>
        <MarkdownWrapper>
          <ReactMarkdown>
            {markdownContent}
          </ReactMarkdown>
        </MarkdownWrapper>
      </Container>  
      </div>
      
      <FooterStyled>
        <div className="max-w-7xl mx-auto py-4 pt-10 px-4 sm:px-6 lg:px-8 flex justify-between items-center relative">
          <div className='flex flex-col'>
            <div className="text-center">
              <p className='font-semibold text-lg sm:text-lg md:text-3xl xl:text-2xl'>Encuéntranos:</p>
              <div className="flex space-x-4">
                <a href="https://match-working.com/"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fas fa-globe-americas" style={{ fontSize: '3rem' }}></i>
                </a>
                <a href="http://www.linkedin.com/company/matchworking/?viewAsMember=true"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin" style={{ fontSize: '3rem' }}></i>
                </a>
                <a href="http://www.tiktok.com/@matchworking"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-tiktok" style={{ fontSize: '3rem' }}></i>
                </a>
                <a href="http://www.facebook.com/matchworking"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook" style={{ fontSize: '3rem' }}></i>
                </a>
                <a href="https://www.instagram.com/matchworking_ec/"
                  className="text-white hover:text-orange-600"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" style={{ fontSize: '3rem' }}></i>
                </a>
              </div>
            </div>
          </div>

          <div className="hidden lg:block absolute inset-x-0 bottom-5 text-center">
            <p className="text-sm sm:text-sm md:text-lg xl:text-xl">© Copyright 2024 Matchworking</p>
          </div>

          <div className="hidden lg:block absolute bottom-5 right-5 text-center">
            <p className="text-sm sm:text-sm md:text-lg xl:text-xl">info@match.working.com</p>
          </div>
        </div>
      </FooterStyled>
    </div>
  );
};

export default RecommendationCv;

import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import userService from "../services/userService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import TermsModal from "./TermsModal";
import LinearProgress from '@mui/material/LinearProgress';

const showAlert = require("../utilities/alerts");

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://match-working.com/">
        MatchWorking
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

function evaluatePasswordStrength(password) {
  let strength = 0;
  if (password.length >= 4) strength += 1;
  if (/[A-Za-z]/.test(password) && /\d/.test(password)) strength += 1;
  if (/[^A-Za-z0-9]/.test(password)) strength += 1;
  return strength;
}

export default function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_names: "",
    user_lastnames: "",
    user_email: "",
    user_password: "",
    user_termsAccepted: false,
    user_privacyAccepted: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { user_names, user_lastnames, user_email, user_password,user_termsAccepted, user_privacyAccepted} = formData;    
    if (!user_names || !user_lastnames || !user_email || !user_password) {
      showAlert.error("Por favor complete todos los campos.");
      return;
    }


    if (!user_termsAccepted|| !user_privacyAccepted) {
      showAlert.error("Por favor, acepta los términos y condiciones.");
      return;
    }
    try {
      await userService.createUser(formData);
      showAlert.success("Perfil creado exitosamente").then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error completo:", error);
      if (error.message === 'El correo electrónico ya existe') {
        console.log("No se puede crear el usurio")
      } else {
        showAlert.error("Error al crear el usuario." + error.message);
      }
    }


  };

  const handleChange = (name, value) => {
    console.log(`Updating ${name} to ${value}`); 
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleShowTermsPopup = () => {
    setShowTermsPopup(true);
  };

  const handleCloseTermsPopup = () => {
    setShowTermsPopup(false);
  };

  const handleShowPrivacyPolicyPopup = () => {
    setShowPrivacyPolicyPopup(true);
  };

  const handleClosePrivacyPolicyPopup = () => {
    setShowPrivacyPolicyPopup(false);
  };

  const passwordStrength = evaluatePasswordStrength(formData.user_password);
  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
        return "green";
      default:
        return "transparent";
    }
  };

  const getPasswordStrengthText = () => {
    switch (passwordStrength) {
      case 1:
        return "Insegura";
      case 2:
        return "Casi segura";
      case 3:
        return "Segura";
      default:
        return "";
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#F0810F" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              color: "#011A27",
            }}
          >
            Regístrate
          </Typography> 
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={formData.user_names || ""}
                  onChange={(e) => handleChange("user_names", e.target.value)}
                  autoComplete="user_names"
                  name="user_names"
                  required
                  fullWidth
                  id="user_names"
                  label="Nombre"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "none",  // Elimina el borde al estar enfocado
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#011A27",  // Color del label cuando no está enfocado
                      "&.Mui-focused": {
                        color: "#011A27",  // Color del label cuando está enfocado
                      },
                    },
                    "& .MuiInputBase-input": {
                      "&:focus": {
                        boxShadow: "none",  // Elimina cualquier sombra
                        outline: "none",  // Elimina el borde de enfoque
                      },
                    },
                  
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={formData.user_lastnames || ""}
                  onChange={(e) =>
                    handleChange("user_lastnames", e.target.value)
                  }
                  required
                  fullWidth
                  id="user_lastnames"
                  label="Apellido"
                  name="user_lastnames"
                  autoComplete="user_lastnames"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "none",  // Elimina el borde al estar enfocado
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#011A27",  // Color del label cuando no está enfocado
                      "&.Mui-focused": {
                        color: "#011A27",  // Color del label cuando está enfocado
                      },
                    },
                    "& .MuiInputBase-input": {
                      "&:focus": {
                        boxShadow: "none",  // Elimina cualquier sombra
                        outline: "none",  // Elimina el borde de enfoque
                      },
                    },
                  
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={formData.user_email || ""}
                  onChange={(e) => handleChange("user_email", e.target.value)}
                  required
                  fullWidth
                  id="user_email"
                  label="Correo electrónico"
                  name="user_email"
                  autoComplete="user_email"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "none",  // Elimina el borde al estar enfocado
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#011A27",  // Color del label cuando no está enfocado
                      "&.Mui-focused": {
                        color: "#011A27",  // Color del label cuando está enfocado
                      },
                    },
                    "& .MuiInputBase-input": {
                      "&:focus": {
                        boxShadow: "none",  // Elimina cualquier sombra
                        outline: "none",  // Elimina el borde de enfoque
                      },
                    },
                  
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={formData.user_password || ""}
                  onChange={(e) =>
                    handleChange("user_password", e.target.value)
                  }
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "none",  // Elimina el borde al estar enfocado
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#011A27",  // Color del label cuando no está enfocado
                      "&.Mui-focused": {
                        color: "#011A27",  // Color del label cuando está enfocado
                      },
                    },
                    "& .MuiInputBase-input": {
                      "&:focus": {
                        boxShadow: "none",  // Elimina cualquier sombra
                        outline: "none",  // Elimina el borde de enfoque
                      },
                    },
                  
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={togglePasswordVisibility}
                        sx={{ color: "#F0810F" }}
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          className="text-[#F0810f] hover:text-[#E6DF44] cursor-pointer"
                        />
                      </Button>
                    ),
                  }}
                />
                <LinearProgress
                  variant="determinate"
                  value={(passwordStrength / 3) * 100}
                  sx={{
                    mt: 1,
                    height: 10,
                    width: "60%",
                    borderRadius: 50,
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: getPasswordStrengthColor(),
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ color: getPasswordStrengthColor(), mt: 1 }}
                >
                  {getPasswordStrengthText()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(0, 0, 0, 0.6)", mt: 0, fontSize: "12px" }}
                >
                  Se recomienda que la contraseña contenga un mínimo de cuatro
                  caracteres y que incluya una combinación de letras, números y
                  caracteres especiales.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox
                    checked={formData.user_termsAccepted}
                    onChange={(e) => handleChange("user_termsAccepted", e.target.checked)}
                    color="primary"
                    required
                  />}
                  label={
                    <span>
                      <Button

                        onClick={handleShowTermsPopup}
                        sx={{
                          color: "#F0810F",
                          textDecoration: "none",
                          transition: "color 0.3s",
                          fontSize: "0.800rem",
                        }}
                      >
                        He leído y acepto los términos y condiciones de uso
                      </Button>
                    </span>
                  }
                />
                {showTermsPopup && <TermsModal onClose={handleCloseTermsPopup} type="terms"/>}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.user_privacyAccepted}
                      onChange={(e) => handleChange("user_privacyAccepted", e.target.checked)}
                      color="primary"
                      required
                      name="privacy"
                    />
                  }
                  label={
                    <span>
                      <Button
                        onClick={handleShowPrivacyPolicyPopup}
                        sx={{
                          color: "#F0810F",
                          textDecoration: "none",
                          transition: "color 0.3s",
                          fontSize: "0.800rem",
                        }}
                      >
                        He leído y acepto las políticas de privacidad
                      </Button>
                    </span>
                  }
                />
                {showPrivacyPolicyPopup && <TermsModal onClose={handleClosePrivacyPolicyPopup} type="privacy"/>}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#F0810F",
                color: "white",
                "&:hover": {
                  backgroundColor: "#E6DF44",
                },
              }}
            >
              Guardar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="/"
                  variant="body2"
                  sx={{
                    textDecoration: "none",
                    color: "#F0810F",
                    ":hover": {
                      textDecoration: "underline",
                      color: "#E6DF44",
                    },
                  }}
                >
                  ¿Ya tengo una cuenta? Log in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { lambdaCandidate } from "../../enviroment/internal-lambdas";
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import Swal from "sweetalert2";
import showAlert from "../../utilities/alerts";
import Select from "react-select";
import { useEffect, useState } from "react";
import listDataService from "../../services/listDataService";
import { MdOutlineEmail } from "react-icons/md";

const fixAcademicFormation = (data) => {
    // Verificamos si el dato es un array
    if (!Array.isArray(data)) {
        if (typeof data === 'object' && data !== null) {
            // Si es un objeto, lo convertimos en un array
            return [data];
        } else {
            console.error("El dato proporcionado no es un array ni un objeto válido.");
            return [];
        }
    }

    // Verificamos si el array contiene un objeto con claves numéricas
    if (data.length === 1 && typeof data[0] === 'object' && data[0] !== null) {
        const item = data[0];
        const formations = [];

        // Iteramos sobre las propiedades del objeto
        for (const key in item) {
            if (typeof item[key] === 'object' && item[key] !== null) {
                const { candidate_degree, candidate_institution_name, candidate_education_level, candidate_graduation_date } = item[key];

                // Verificamos que el objeto tenga las propiedades requeridas
                if (candidate_degree && candidate_institution_name && candidate_education_level && candidate_graduation_date &&
                    typeof candidate_graduation_date === 'object' &&
                    candidate_graduation_date.day !== undefined &&
                    candidate_graduation_date.month !== undefined &&
                    candidate_graduation_date.year !== undefined) {
                    formations.push(item[key]); // Agregamos el objeto al array de formaciones
                }
            }
        }

        return formations; // Devolvemos el array de formaciones
    }

    // Verificamos si el array contiene solo objetos
    const formations = data.map(item => {
        if (typeof item === 'object' && item !== null) {
            // Verificamos que el objeto tenga las propiedades requeridas
            const { candidate_degree, candidate_institution_name, candidate_education_level, candidate_graduation_date } = item;

            if (candidate_degree && candidate_institution_name && candidate_education_level && candidate_graduation_date &&
                typeof candidate_graduation_date === 'object' &&
                candidate_graduation_date.day !== undefined &&
                candidate_graduation_date.month !== undefined &&
                candidate_graduation_date.year !== undefined) {
                return item; // Retornamos el objeto tal cual si es válido
            }
        }
        // Si no es un objeto válido, retornamos un objeto vacío o puedes manejarlo de otra manera
        return {
            candidate_degree: "Desconocido",
            candidate_institution_name: "Desconocido",
            candidate_education_level: "Desconocido",
            candidate_graduation_date: {
                month: 1,
                day: 1,
                year: 2025
            }
        };
    });

    return formations;
};


export const CandidateForm = ({ profileId, setCandidate, candidate, onNext, position }) => {
    const [educationLevels, setEducationLevels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const candidateData = candidate?.candidateData;    
    const formation = fixAcademicFormation(candidateData?.candidate_academic_formation) || [{}];    
    const { register, handleSubmit, control, formState: { errors }, getValues, setError, clearErrors } = useForm({
        defaultValues: {
            candidate_bucket_s3: candidateData?.candidate_bucket_s3 || '',
            candidate_photo: '',
            candidate_personal_data: candidateData?.candidate_personal_data || {},
            candidate_job_position_profile: profileId.toString(),
            candidate_experience: candidateData?.candidate_experience || [{}],
            candidate_academic_formation: formation || [{}],
            candidate_supplementary_education: candidateData?.candidate_supplementary_education || [{}],
            candidate_skills: candidateData?.candidate_skills || [],
            candidate_languages: candidateData?.candidate_languages || [{}],
            candidate_references: candidateData?.candidate_references || [{}],
            candidate_termsAccepted: true
        },
    });
    //const city = watch("candidate_personal_data.candidate_address.candidate_city")
    const { profile_workLocation } = position

    const city = getValues("candidate_personal_data.candidate_address.candidate_city");

    const texts = {
        msgContact: "Si tienes algún problema puedes contactarte a:",
        email: "soporte@match-working.com",
    }

    const educationStates = [
        { value: "Egresado", label: "Egresado" },
        { value: "En curso", label: "En curso" },
        { value: "Finalizado", label: "Finalizado" },
    ];

    useEffect(() => {
        const education = async () => {
            const response = await listDataService.getAllEducationLevelByState("A");
            if (response) {
                const levels = response.map((reason) => reason.educationLevel_content)
                const transformedLevels = levels.map(level => ({
                    value: level,
                    label: level
                }));
                setEducationLevels(transformedLevels);
            }
        }
        education();
    }, []);

    const { fields: experienceFields, append: addExperience, remove: removeExperience } = useFieldArray({
        control,
        name: "candidate_experience",
    });

    const { fields: educationFields, append: addEducation, remove: removeEducation } = useFieldArray({
        control,
        name: "candidate_academic_formation",
    });

    const { fields: courseFields, append: addCourse, remove: removeCourse } = useFieldArray({
        control,
        name: "candidate_supplementary_education",
    });

    const { fields: skillFields, append: addSkill, remove: removeSkill } = useFieldArray({
        control,
        name: "candidate_skills",
    });

    const { fields: languageFields, append: addLanguage, remove: removeLanguage } = useFieldArray({
        control,
        name: "candidate_languages",
    });

    const { fields: referenceFields, append: addReference, remove: removeReference } = useFieldArray({
        control,
        name: "candidate_references",
    });

    const languageLevel = [
        { value: "Nativo", label: "Nativo" },
        { value: "Avanzado", label: "Avanzado" },
        { value: "Intermedio", label: "Intermedio" },
        { value: "Básico", label: "Básico" }
    ]

    function ResponsibilitiesSection({ control, register, experienceIndex }) {
        const { fields: responsibilityFields, append: addResponsibility, remove: removeResponsibility } =
            useFieldArray({
                control,
                name: `candidate_experience.${experienceIndex}.candidate_responsibilities`,
            });
        const validateResponsibilities = () => {
            const responsibilities = getValues(`candidate_experience.${experienceIndex}.candidate_responsibilities`);
            if (responsibilities.length < 3) {
                setError(`candidate_experience.${experienceIndex}.candidate_responsibilities`, {
                    type: "manual",
                    message: "Debe haber al menos 3 responsabilidades.",
                });
            } else {
                clearErrors(`candidate_experience.${experienceIndex}.candidate_responsibilities`);
            }
        };
        return (
            <div className="mt-4 text-start">
                <h5 className="text-start font-semibold">Responsabilidades
                </h5>
                {errors?.candidate_experience?.[experienceIndex]?.candidate_responsibilities && (
                    <span className="text-red-500 text-sm w-full">
                        {errors.candidate_experience[experienceIndex].candidate_responsibilities.message}
                    </span>
                )}
                {responsibilityFields.map((field, index) => (
                    <div key={field.id} className="flex items-center gap-2 mt-2">
                        <textarea
                            type="text"
                            {...register(
                                `candidate_experience.${experienceIndex}.candidate_responsibilities.${index}`, {
                                required: 'Debe haber al menos 3 responsabilidades.',
                                minLength: {
                                    value: 3,
                                    message: 'Debe haber al menos 3 responsabilidades.'
                                }
                            })}
                            className="w-full border-1 border-gray-400 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            type="button"
                            onClick={() => {
                                removeResponsibility(index)
                                validateResponsibilities();
                            }}
                            className="bg-red-600 ml-2 p-3 text-center justify-items-center text-white rounded-md hover:bg-red-500"
                        >
                            <FaTrash size={20} />
                        </button>
                    </div>

                ))}
                <button
                    type="button"
                    onClick={() => {
                        addResponsibility(" ")
                        validateResponsibilities();
                    }}
                    className="bg-[#F0810f] text-white text-base md:text-xl p-2 px-4 mt-2 rounded-md font-semibold hover:bg-[#E6DF44]"
                >
                    Agregar Responsabilidad
                </button>
            </div>
        );
    }

    function AchievementsSection({ control, register, experienceIndex }) {
        const { fields: achievementFields, append: addAchievement, remove: removeAchievement } =
            useFieldArray({
                control,
                name: `candidate_experience.${experienceIndex}.candidate_achievements`,
            });

        return (
            <div className="mt-4 text-start">
                <h5 className="text-start font-semibold">Logros</h5>
                {achievementFields.map((field, index) => (
                    <div key={field.id} className="flex items-center gap-2 mt-2">
                        <textarea
                            type="text"
                            {...register(
                                `candidate_experience.${experienceIndex}.candidate_achievements.${index}`, {
                                required: 'Debes llenar el campo',
                                minLength: {
                                    value: 3,
                                    message: 'Debe llenar el campo'
                                }
                            }
                            )}
                            placeholder={`Logro ${index + 1}`}
                            className="w-full border-1 border-gray-400 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            type="button"
                            onClick={() => removeAchievement(index)}
                            className="bg-red-600 ml-2 p-3 text-center justify-items-center text-white rounded-md hover:bg-red-500"
                        >
                            <FaTrash size={20} />
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={() => addAchievement(" ")}
                    className="bg-[#F0810f] text-white text-xl p-2 px-4 mt-2 rounded-md font-semibold hover:bg-[#E6DF44]"
                >
                    Agregar Logro
                </button>
            </div>
        );
    }

    const formatDate = (dateObj) => {
        if (!dateObj || !dateObj.year || !dateObj.month || !dateObj.day) {
            return "";
        }
        const year = dateObj.year;
        const month = String(dateObj.month).padStart(2, "0");
        const day = String(dateObj.day).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const parseDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split("-").map(Number);
        return { year, month, day };
    };

    const IconDelete = ({ w, h, color }) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={w}
                height={h}
                viewBox="0 0 30 30">
                <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                    fill={color || "white"}
                ></path>
            </svg>
        )
    }

    const onSubmit = async (data) => {
        if (educationFields.length === 0) {
            setError(`candidate_academic_formation`, {
                type: "manual",
                message: "Debe agregar al menos un nivel de educación.",
            });
            return;
        }
        const formattedData = {
            ...data,
            candidate_experience: data.candidate_experience.map((experience) => ({
                ...experience,
                candidate_period: {
                    start_date: {
                        day: experience?.candidate_period?.start_date?.day || '',
                        month: experience?.candidate_period?.start_date?.month || '',
                        year: experience?.candidate_period?.start_date?.year || '',
                    },
                    end_date: {
                        day: experience?.candidate_period?.end_date?.day || '',
                        month: experience?.candidate_period?.end_date?.month || '',
                        year: experience?.candidate_period?.end_date?.year || '',
                    }
                },
            })),
            candidate_supplementary_education: data.candidate_supplementary_education.map((course) => ({
                ...course,
                course_completion_date: {
                    day: course?.course_completion_date?.day,
                    month: course?.course_completion_date?.month,
                    year: course?.course_completion_date?.year,
                },
            })),
            candidate_academic_formation: data.candidate_academic_formation.map((education) => ({
                ...education,
                candidate_graduation_date: {
                    day: education?.candidate_graduation_date?.day,
                    month: education?.candidate_graduation_date?.month,
                    year: education?.candidate_graduation_date?.year,
                },
            })),
        };

        if (city.toLowerCase() !== profile_workLocation.profile_city.toLowerCase()) {
            console.log('Ciudad incorrecta');

            try {
                // Muestra el cuadro de diálogo de confirmación y espera la respuesta
                const result = await Swal.fire({
                    title: "La vacante a la que aplicas está en una ciudad distinta a la tuya. ",
                    text: "¿Estas dispuesto a cambiarte de residencia?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#011A27",
                    cancelButtonColor: "#FF8201",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar"
                });

                if (!result.isConfirmed) {
                    console.log('El usuario decidió no continuar.');
                    return;
                }
            } catch (error) {
                console.error('Error al mostrar el cuadro de diálogo:', error);
                return;
            }
        }
        console.log('formattedData', formattedData)
        try {
            if (!formattedData) return;
            if (isLoading) return; // Evitar múltiples envíos si ya está cargando
            setIsLoading(true); // Activar estado de carga
            const candidateEmail = { email: data.candidate_personal_data?.candidate_email }
            const existingCandidate = await lambdaCandidate.existingCv(profileId, candidateEmail)
            if (existingCandidate.data.data.exists === false) {
                try {
                    Swal.fire({
                        title: "Generando recomendaciones.",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        html: `
                                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                                </div>
                            `,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const response = await lambdaCandidate.create(formattedData);
                    Swal.fire("Formulario enviado exitosamente!", "", "success");
                    setCandidate({ candidateID: response.data.candidate_id });
                    onNext();
                } catch (error) {
                    Swal.close();
                    console.error('Error al crear candidato:', error.response ? error.response.data : error.message);
                    showAlert.error("Error al crear candidato");
                    setIsLoading(false)
                } finally {
                    setIsLoading(false);
                }
            } else {
                console.log('El candidato ya se postuló al cargo')
                showAlert.error("El candidato ya se postuló al cargo")
                setIsLoading(false)
                return;
            }
        } catch (error) {
            console.error('Error al verificar candidato:', error);
            showAlert.error("Error al verificar candidato")
            setIsLoading(false)
            return;
        }

    };

    const customStyles = {
        container: (base) => ({
            ...base,
            width: "100%",
            textAlign: "start",
        }),
        control: (base) => ({
            ...base,
            borderColor: "#9ca3af"
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "rgb(59 130 246)" : "white", // bg-blue-500
            color: state.isSelected ? "white" : "black", // text-white
            "&:hover": {
                backgroundColor: "rgb(229 231 235)", // hover:bg-gray-200
            },
        }),
    };

    return (
        <div className="flex flex-col h-full p-2 sm:p-8 md:m-4">
            <form className="flex flex-col p-4 md:p-8" onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-3xl text-start font-semibold py-4 ">Datos Personales</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_full_names.candidate_first_name", {
                                required: "Campo requerido",
                                pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" }
                            })}
                            id="firstName"
                            placeholder="Nombre"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="firstName"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Nombre
                        </label>
                        <span className="text-red-600 text-xs text-start">{errors.candidate_personal_data?.candidate_full_names?.candidate_first_name?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_full_names.candidate_last_name", {
                                required: "Campo requerido",
                                pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" }
                            })}
                            id="candidate_last_name"
                            placeholder="Apellido"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_last_name"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Apellido
                        </label>
                        <span className="text-red-600 text-xs text-start">{errors.candidate_personal_data?.candidate_full_names?.candidate_last_name?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_identification", {
                                required: "La cédula es requerida",
                                minLength: {
                                    value: 10,
                                    message: "Ingresa una identificación válida"
                                },
                                maxLength: {
                                    value: 13,
                                    message: "Ingresa una identificación válida"
                                }
                            })}
                            id="candidate_identification"
                            placeholder="Identificación"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_identification"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Identificación
                        </label>
                        <span className="text-red-600 text-xs text-start">{errors.candidate_personal_data?.candidate_identification?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_phone", {
                                required: "Campo requerido",
                                minLength: {
                                    value: 10,
                                    message: "Ingrese un teléfono válido"
                                },
                                maxLength: {
                                    value: 20,
                                    message: "Excedió el número de caracteres permitidos"
                                },
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: "Solo puede ingresar números"
                                }
                            })}
                            id="candidate_phone"
                            placeholder="Teléfono"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_phone"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Teléfono
                        </label>
                        <span className="text-red-600 text-xs">{errors.candidate_personal_data?.candidate_phone?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_address.candidate_city", {
                                required: "Campo requerido",
                                pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" }
                            })}
                            id="candidate_city"
                            placeholder="Ciudad"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_city"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Ciudad
                        </label>
                        <span className="text-red-600 text-xs">{errors.candidate_personal_data?.candidate_address?.candidate_city?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_address.candidate_country", {
                                required: "Campo requerido",
                                pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" }
                            })}
                            id="candidate_country"
                            placeholder="País"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_country"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            País
                        </label>
                        <span className="text-red-600 text-xs">{errors.candidate_personal_data?.candidate_address?.candidate_city?.message}</span>
                    </div>
                    <div className="relative text-start">
                        <input
                            {...register("candidate_personal_data.candidate_email", {
                                required: "Campo requerido",
                            })}
                            id="candidate_email"
                            type="email"
                            placeholder="Correo Electrónico"
                            className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                        />
                        <label
                            htmlFor="candidate_email"
                            className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                        >
                            Correo
                        </label>
                        <span className="text-red-600 text-xs">{errors.candidate_personal_data?.candidate_email?.message}</span>
                    </div>
                </div>
                <h2 className="text-3xl text-start font-semibold py-4 ">Experiencia</h2>
                {experienceFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row mb-6 border-b-2 border-gray-300">
                        <div className="grid grid-cols-2 gap-4 mb-4 w-full">
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_experience.${index}.candidate_company_name`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`candidate_company_name-${index}`}
                                    placeholder="Nombre de Empresa"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`candidate_company_name-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Empresa
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_experience ? errors.candidate_experience[index]?.candidate_company_name?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_experience.${index}.candidate_position`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`candidate_position-${index}`}
                                    placeholder="Posición"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`candidate_position-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Posición
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_experience ? errors.candidate_experience[index]?.candidate_position?.message : ''}</span>
                            </div>
                            <fieldset className="flex flex-col md:flex-row justify-between">
                                <legend className="text-start text-base md:text-base text-gray-500 my-auto font-medium">Fecha de inicio:</legend>
                                <Controller
                                    control={control}
                                    name={`candidate_experience.${index}.candidate_period.start_date`}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            value={formatDate(field.value)}
                                            onChange={(e) => {
                                                const dateObj = parseDate(e.target.value);
                                                field.onChange(dateObj);
                                            }}
                                            className="w-full border-1 border-gray-400 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    )}
                                />
                                <legend className="text-start text-base md:text-base text-gray-500 md:ml-4 my-auto font-medium">Fecha de finalización:</legend>
                                <Controller
                                    control={control}
                                    name={`candidate_experience.${index}.candidate_period.end_date`}
                                    rules={{
                                        validate: (value) => {
                                            const currentJob = getValues(`candidate_experience.${index}.currentJob`);
                                            if (value && currentJob) {
                                                return "No puede seleccionar una fecha y marcar el checkbox al mismo tiempo.";
                                            }
                                            if (!value && !currentJob) {
                                                return "Debe seleccionar una fecha o marcar el checkbox.";
                                            }
                                            return true;
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type="date"
                                                value={formatDate(field.value)}
                                                onChange={(e) => {
                                                    const dateObj = parseDate(e.target.value);
                                                    field.onChange(dateObj);
                                                }}
                                                className="w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </>
                                    )}
                                />
                            </fieldset>
                            <label className="flex my-auto text-base md:text-xl">
                                Actualmente trabajo aquí:
                                <input
                                    className="w-5 h-5 mx-2 my-auto border-1 rounded-sm focus:outline-none focus:ring-0"
                                    type="checkbox"
                                    {...register(`candidate_experience.${index}.currentJob`, {
                                        validate: (value) => {
                                            const endDate = getValues(`candidate_experience.${index}.candidate_period.end_date`);
                                            if (value && endDate) {
                                                return "No puede seleccionar una fecha y marcar el checkbox al mismo tiempo.";
                                            }
                                            if (!value && !endDate) {
                                                return "Debe seleccionar una fecha o marcar el checkbox.";
                                            }
                                            return true;
                                        },
                                    })}
                                />
                            </label>
                            <span className="text-red-500 text-sm w-full col-span-2">
                                {errors.candidate_experience ? errors?.candidate_experience[index]?.currentJob?.message : ''}
                            </span>
                            <ResponsibilitiesSection
                                control={control}
                                register={register}
                                experienceIndex={index}
                            />
                            <AchievementsSection
                                control={control}
                                register={register}
                                experienceIndex={index}
                            />
                        </div>
                        <button className="bg-red-600 p-2 ml-0 sm:ml-2 h-9 text-center justify-items-center text-white font-bold rounded-md hover:bg-red-500"
                            type="button" onClick={() => removeExperience(index)}>
                            <IconDelete w={15} h={15} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => addExperience({})}>
                    Agregar Experiencia
                </button>

                <h2 className="text-3xl text-start font-semibold py-4 ">Educación</h2>
                <span className="text-red-600 text-base text-start">
                    {errors.candidate_academic_formation ? errors.candidate_academic_formation.message : ''}
                </span>
                {educationFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row mb-6 border-b-2 border-gray-300">
                        <div className="grid grid-cols-2 gap-4 mb-4 w-full">
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_academic_formation.${index}.candidate_degree`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`candidate_degre-${index}`}
                                    placeholder="Título"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`candidate_degre-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Título
                                </label>
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_academic_formation ? errors?.candidate_academic_formation[index]?.candidate_degre?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                {/* <input
                                    {...register(`candidate_academic_formation.${index}.candidate_education_level`, {
                                        required: "Campo requerido",
                                        pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" }
                                    })}
                                    id={`candidate_education_level-${index}`}
                                    placeholder="Nivel Educativo"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                /> */}
                                <Controller
                                    name={`candidate_academic_formation.${index}.candidate_education_level`}
                                    control={control}
                                    rules={{ required: "Este campo es requerido" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={educationLevels}
                                            placeholder="Educación"
                                            value={educationLevels.find(option => option.value === field.value)}
                                            onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                        />
                                    )}
                                />
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_academic_formation ? errors?.candidate_academic_formation[index]?.candidate_education_level?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_academic_formation.${index}.candidate_institution_name`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`candidate_institution_name-${index}`}
                                    placeholder="Institución"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`candidate_institution_name-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Institución
                                </label>
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_academic_formation ? errors?.candidate_academic_formation[index]?.candidate_institution_name?.message : ''}</span>
                            </div>
                            <fieldset className="flex flex-col md:flex-row justify-between">
                                <legend className="text-start text-base md:text-xl text-gray-500 my-auto font-medium">Fecha de finalización:</legend>
                                <Controller
                                    control={control}
                                    name={`candidate_academic_formation.${index}.candidate_graduation_date`}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            value={formatDate(field.value)}
                                            onChange={(e) => {
                                                const dateObj = parseDate(e.target.value);
                                                field.onChange(dateObj);
                                            }}
                                            className="w-full border-1 border-gray-400 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    )}
                                />
                            </fieldset>
                            <div className="flex flex-col md:flex-row justify-between">
                                <legend className="text-start text-base md:text-xl text-gray-500 my-auto font-medium">Estado:</legend>
                                <Controller
                                    name={`candidate_academic_formation.${index}.candidate_education_state`}
                                    control={control}
                                    rules={{ required: "Este campo es requerido" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={educationStates}
                                            placeholder="Educación"
                                            styles={customStyles}
                                            className="w-full text-start"
                                            value={educationStates.find(option => option.value === field.value)}
                                            onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                        />
                                    )}
                                />
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_academic_formation ? errors?.candidate_academic_formation[index]?.candidate_education_state?.message : ''}</span>
                            </div>
                        </div>
                        <button className="bg-red-600 p-2 ml-0 sm:ml-2 h-9 font-bold text-center justify-items-center text-white rounded-md hover:bg-red-500"
                            type="button" onClick={() => removeEducation(index)} disabled={educationFields.length === 1}>
                            <IconDelete w={15} h={15} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => {
                        addEducation({})
                        clearErrors('candidate_academic_formation')
                    }}>
                    Agregar Educación
                </button>
                <h2 className="text-3xl text-start font-semibold py-4 ">Cursos</h2>
                {courseFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row mb-6 border-b-2 border-gray-300">
                        <div className="grid grid-cols-2 gap-4 mb-4 w-full">
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_supplementary_education.${index}.course_title`, {
                                        required: "Campo requerido"
                                    })}
                                    id={`course_title-${index}`}
                                    placeholder="Título"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`course_title-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Título
                                </label>
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_supplementary_education ? errors?.candidate_supplementary_education[index]?.course_title?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_supplementary_education.${index}.course_institution_name`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`course_institution_name-${index}`}
                                    placeholder="Institución del curso"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`course_institution_name-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Institución
                                </label>
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_supplementary_education ? errors?.candidate_supplementary_education[index]?.course_institution_name?.message : ''}</span>
                            </div>
                            <fieldset className="flex flex-col md:flex-row justify-between">
                                <legend className="text-start text-base md:text-xl text-gray-500 md:ml-4 my-auto font-medium">Fecha de finalización:</legend>
                                <Controller
                                    control={control}
                                    name={`candidate_supplementary_education.${index}.course_completion_date`}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            value={formatDate(field.value)}
                                            onChange={(e) => {
                                                const dateObj = parseDate(e.target.value);
                                                field.onChange(dateObj);
                                            }}
                                            className="w-full border-1 border-gray-400 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    )}
                                />
                            </fieldset>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_supplementary_education.${index}.course_duration`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`course_duration-${index}`}
                                    placeholder="Duración"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`course_duration-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Duración
                                </label>
                                <span className="text-red-600 text-xs text-start">{errors?.candidate_supplementary_education ? errors?.candidate_supplementary_education[index]?.course_duration?.message : ''}</span>
                            </div>
                        </div>
                        <button className="bg-red-600 p-2 ml-0 sm:ml-2 h-9 font-bold text-center justify-items-center text-white rounded-md hover:bg-red-500"
                            type="button" onClick={() => removeCourse(index)}>
                            <IconDelete w={15} h={15} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => addCourse({})}>
                    Agregar Curso
                </button>

                <h2 className="text-3xl text-start font-semibold py-4 ">Aptitudes</h2>
                {skillFields && skillFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row">
                        <div className="relative text-start mb-4 w-full">
                            <input
                                {...register(`candidate_skills.${index}`, {
                                    required: "Campo requerido",
                                    minLength: {
                                        value: 3,
                                        message: 'Debe llenar el campo'
                                    }
                                })}
                                id={`candidate_skills-${index}`}
                                placeholder="Habilidad"
                                className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                            />
                            <label
                                htmlFor={`candidate_skills-${index}`}
                                className="absolute left-3 -top-3 bg-white px-1 text-gray-500 
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                            >
                                Habilidad
                            </label>
                            <span className="text-red-600 text-xs text-start">{errors?.candidate_skills ? errors?.candidate_skills[index]?.message : ''}</span>
                        </div>
                        <button className="bg-red-600 mb-4 ml-2 text-center justify-items-center text-white rounded-md p-3 hover:bg-red-500"
                            type="button" onClick={() => removeSkill(index)}>
                            <FaTrash size={20} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => addSkill(" ")}>
                    Agregar Habilidad
                </button>
                <h2 className="text-3xl text-start font-semibold py-4 ">Idiomas</h2>
                {languageFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row mb-6 border-b-2 border-gray-300">
                        <div className="grid grid-cols-2 gap-4 mb-4 w-full">
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_languages.${index}.institution`, {
                                        pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" },
                                        minLength: {
                                            value: 3,
                                            message: 'Debe llenar el campo'
                                        }
                                    })}
                                    id={`institution-${index}`}
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`institution-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Institución
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_languages ? errors.candidate_languages[index]?.institution?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_languages.${index}.language`, {
                                        pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" },
                                    })}
                                    id={`language-${index}`}
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`language-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Idioma
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_languages ? errors.candidate_languages[index]?.language?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <Controller
                                    name={`candidate_languages.${index}.proficiency`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={languageLevel}
                                            placeholder="Educación"
                                            value={languageLevel.find(option => option.value === field.value)}
                                            onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                        />
                                    )}
                                />
                                <span className="text-red-600 text-xs">{errors.candidate_languages ? errors.candidate_languages[index]?.proficiency?.message : ''}</span>
                            </div>
                        </div>
                        <button className="bg-red-600 p-2 ml-0 sm:ml-2 h-9 font-bold text-center justify-items-center text-white rounded-md hover:bg-red-500"
                            type="button" onClick={() => removeLanguage(index)}>
                            <IconDelete w={15} h={15} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => addLanguage({})}>
                    Agregar Idioma
                </button>
                <h2 className="text-3xl text-start font-semibold py-4 ">Referencias Profesionales</h2>
                {referenceFields.map((field, index) => (
                    <div key={field.id} className="flex flex-col md:flex-row mb-6 border-b-2 border-gray-300">
                        <div className="grid grid-cols-2 gap-4 mb-4 w-full">
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_references.${index}.reference_name`, {
                                        required: "Campo requerido",
                                        pattern: { value: /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ,.\s]+$/, message: "Solo puede ingresar letras y espacios" },
                                        minLength: {
                                            value: 3,
                                            message: 'Debe llenar el campo'
                                        }
                                    })}
                                    id={`reference_name-${index}`}
                                    placeholder="Nombres"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`reference_name-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Nombres
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_references ? errors.candidate_references[index]?.reference_name?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_references.${index}.company_name`, {
                                        required: "Campo requerido",
                                    })}
                                    id={`company_name-${index}`}
                                    placeholder="Empresa"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`company_name-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Empresa
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_references ? errors.candidate_references[index]?.company_name?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_references.${index}.phone`, {
                                        required: "Campo requerido",
                                        minLength: {
                                            value: 10,
                                            message: "Ingrese un teléfono válido"
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "Excedió el número de caracteres permitidos"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Solo puede ingresar números"
                                        }
                                    })}
                                    id={`phone-${index}`}
                                    placeholder="Teléfono de Referencia"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`phone-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                                    transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Teléfono
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_references ? errors.candidate_references[index]?.phone?.message : ''}</span>
                            </div>
                            <div className="relative text-start">
                                <input
                                    {...register(`candidate_references.${index}.reference_position`, {
                                        required: "Campo requerido",
                                        minLength: {
                                            value: 3,
                                            message: 'Debe llenar el campo'
                                        }
                                    })}
                                    id={`reference_position-${index}`}
                                    placeholder="Posición de Referencia"
                                    className="peer w-full border-1 border-gray-400 rounded-md px-3 py-3 text-sm placeholder-transparent focus:outline-none focus:ring-1 focus:border-blue-500"
                                />
                                <label
                                    htmlFor={`reference_position-${index}`}
                                    className="absolute left-3 -top-3 bg-white px-1 text-gray-500 text-sm md:text-base
                            transition-all peer-placeholder-shown:top-4 peer-not-placeholder-shown:-top-5 peer-focus:-top-3 peer-not-placeholder-shown:text-blue-500"
                                >
                                    Posición / Cargo
                                </label>
                                <span className="text-red-600 text-xs">{errors.candidate_references ? errors.candidate_references[index]?.reference_position?.message : ''}</span>
                            </div>
                        </div>
                        <button className="bg-red-600 p-2 ml-0 sm:ml-2 h-9 font-bold text-center justify-items-center text-white rounded-md hover:bg-red-500"
                            type="button" onClick={() => removeReference(index)}>
                            <IconDelete w={15} h={15} />
                        </button>
                    </div>
                ))}
                <button className="bg-[#F0810f] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#E6DF44]"
                    type="button" onClick={() => addReference({})}>
                    Agregar Referencia
                </button>
                <button
                    className="bg-[#063852] my-4 text-white text-xl rounded-md p-3 font-semibold hover:bg-[#011A27]"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Cargando...' : 'Enviar'}
                </button>
            </form>
            <span className='flex flex-row text-center mx-auto text-sm items-center'>
                <a className='justify-center'><MdOutlineEmail className='text-4xl pr-2 text-black' /></a>
                <div className='flex flex-col r'>
                    <p className='flex flex-row text-sm py-0 my-0 mb-0 pb-0 gap-1'>
                        {texts.msgContact} <strong>{texts.email}</strong>
                    </p>
                    {/* <p className='flex flex-col text-start text-sm py-0 my-0 mb-0 pb-0'>{texts.email}</p> */}
                </div>
            </span>

        </div>
    );
};
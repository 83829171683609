//import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import NewPassword from "./components/NewPassword";
import { Routes, Route } from "react-router-dom";
import ProfileForm from "./components/ProfileForm";
import FormCompanyPage from "./pages/company/FormCompanyPage";
import RequirementPage from "./pages/requirements/requirementPage";
import RegulationPage from "./pages/regulations/regulationPage"
import HistoryServicePage from "./pages/historySevice/historySevice.js";

import AnalizerPage from "./pages/analizer/analizerPage";

import AdminPage from "./pages/admin/adminPage";
import Guardian from "./Guardian/Guardian";
import UserProfile from "./components/UserProfile";
import PerfilCargoPage from "./pages/perfildecargo/PerfilCargoPage";
import InformacionPostulante from "./pages/rankingCVs/informacionPostulante.jsx";
import CompaniesPage from "./pages/companyPanel/companyPanelPage.js";
import Header from "./components/Header.js";
import MainContainer from "./components/maincontainer.js";
import CandidateForm from "./pages/candidatesForm/candidatePage.js";

import PositionRequest from "./pages/candidatesForm/positionRequest.js";
import { PilotTest } from "./pages/PilotTest.jsx";
import { Pluspetrol } from "./pages/Pluspetrol.jsx";


function App() {
  return (
    
    <Routes>
      <Route path="/" element={<LogIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/analize" element={<AnalizerPage />} />
      <Route path="/form-cv" element={<CandidateForm />}/>      
      <Route path="/form-cv/:id" element={<PositionRequest />}/> 
      <Route path="/reclutamiento/:id" element={<PilotTest />}/> 
      <Route path="/pluspetrol/:id" element={<Pluspetrol  />}/> 
      <Route
        path="/*"
        element={
          <Guardian >
            <MainContainer>
            <Header/>
            <Routes>
              <Route path="/requirement-page" element={<RequirementPage />} />
              <Route path="/profile-form" element={<ProfileForm/>} />
              <Route path="/company-page" element={<FormCompanyPage />} />
              <Route path="/admin-page" element={<AdminPage />} />
              <Route path='/requirement-page' element={<RequirementPage />} />
              <Route path='/regulation-page' element={<RegulationPage />} />              
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/perfil-cargo-form" element={<PerfilCargoPage/>} />
              <Route path="/informacionPostulante" element={<InformacionPostulante />}/>
              <Route path="/history-page" element={<HistoryServicePage />}/>
              <Route path="/companies" element={<CompaniesPage />}/>              
              {/* Otras rutas protegidas */}
            </Routes>
            </MainContainer>
          </Guardian>
        }
      />
    </Routes>
  );
}
export default App;
import walletService from '../services/walletService';
const showAlert = require('./alerts');

const walletFunctions = {

  async servicePayment(serviceId,token, userData) {

      let dataService = await walletService.getServiceData(serviceId, token);
      // console.log('dataS',dataService)
      if(dataService.success){
        let monedasAmount= dataService.data.wallet_service_price;
        const precio = parseInt(monedasAmount) * 2;

        const createTransaccion = {
          "wallet_transaction_type": "Consumo",
          "wallet_transaction_coins": monedasAmount,
          "wallet_transaction_amount": precio,
          "wallet_transaction_payment_method": "MW Coins",
          "wallet_transaction_origen": "",
          "billing": {
            name: userData.user_names || "",
            ci: userData.user_ci || "12313123",
            phone: userData.user_phone || "No Provee",
            email: userData.user_email || ""
          }
        };
        console.log(createTransaccion)
        const data = await walletService.createtransaction(localStorage.getItem("userId"), token, createTransaccion);
        const transaction_id = data.data.wallet_transaction_id;
        return showAlert
        .confirmation(
          "Confirmar compra por "+monedasAmount+ " MW coins.",
          ""
        )
        .then(async (result) => {
          
          if(result){
            const updateTransaction = await walletService.updateStateTransaction(transaction_id,token,{wallet_transaction_confirmed:true});
            
            if(!updateTransaction.success){
              showAlert.error("No se pudo actualizar el estado de la transacción.");
              return false;
            }
            else{
              const recargaJson = {
                "coinsToConsume": monedasAmount,
                "transaction_id": ""+transaction_id,
                "origen": "compra Servicio",
                "transaction_by": localStorage.getItem("userId")
              };
              const recargaResponse = await walletService.buyService(localStorage.getItem("userId"),token,recargaJson);
              console.log(recargaResponse)              
              if(!recargaResponse.success){
                console.log(recargaResponse.message)
                const message = `No se pudo hacer la compra del servico. ${recargaResponse.message}`
                showAlert.error(message);
                return false;
              }
              else{
                showAlert.success("Se ha hecho la compra exitosamente!");
                return true;
              }         
            }
          }
          
        });
      }else{
        return false;
      }
  },
  

};

export default walletFunctions;
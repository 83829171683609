const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.iaBaseUrl;
const api_mvp = enviroment_dev.databaseUrl

const adService = {

  // Método para obtener datos de la publicidad
  async getProfileData(data) {
    const response = await fetch(`${api_enviroment_dev}/${"profile-data"}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // Método para obtener ejemplo de mision al cargo
  async getMision(data) {
    const response = await fetch(`${api_enviroment_dev}/${"profile-data"}/${"mision"}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // Método para obtener listados de cargos
  async getListaCargos(keys, cant) {
    const data = {
      "palabrasClaves": keys,
      "cantidad": cant
    }
    const response = await fetch(`${api_enviroment_dev}/${"admin"}/${"lista-cargos"}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(data),
    });
    return response.json();
  },

  //Consumo de endpoint para obtener sugerencia de campo de perfil de cargo
  async getSuggestions(data) {
    const response = await fetch(`${api_enviroment_dev}/${"profile-data"}/${"suggestions"}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // Generador de perfiles de cargo: PPTX & DOCX
  async wordGenerator(data) {
    const response = await fetch(`${api_mvp}/profile-word`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      console.log('FileName', contentDisposition)
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
        : 'Perfil-de-cargo.docx';

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; 
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.log('Error al descargar el archivo Word');
    }
  },
  // Generador Qr
  async qrGenerator(data) {
    const response = await fetch(`${api_mvp}/profile-qr`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ link: data }),
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'QRCode-Profile.png';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.log('Error al descargar código Qr');
    }
  },


};

export default adService;
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev=enviroment_dev.databaseUrl+"/users";


const userService = {
  // Método para obtener lista de requerimientos
  async getUserList() {
    const response = await fetch(`${api_enviroment_dev}`);
    return response.json();
  },

  // Método para obtener requerimientos por id del request
  async getAutUser(data) {
    const response = await fetch(`${api_enviroment_dev}/${"by/email/"}`,{
      method: 'POST',
      headers: {  
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  // // Método para obtener requerimientos por id del usuario
  // async getRequirementByUserId(id) {
  //   const response = await fetch(`${api_enviroment_dev}/${"by/user/"}${id}`);
  //   return response.json();
  // },

  // Método para crear datos este
  async createUser(data) {
    const response = await fetch(`${api_enviroment_dev}`, {
      method: 'POST',
      headers: {  
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok){
      const errorData = await response.json();
      if (response.status === 400 && errorData.message === "El correo electrónico ya existe"){
        throw new Error("El correo email ya existe")
      }
      throw new Error(errorData.message)
    }
    return response.json();
  },

  // Método para obterner usuario by id
  async getUserId(id) {
    if(!id) return
    const response = await fetch(`${api_enviroment_dev}/${id}`);
    return response.json();
  },

  // Método para actualizar usuario
  async updatePassword(email,password) {
    
    const data ={"email":email, "user_password":password};
    //console.log(data);
    const response = await fetch(`${api_enviroment_dev}/admin/update-password`, {
      method: 'PUT',
      headers: {  
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  //actualizar al usuario 
  async updateUser(userData, id) {
    const response = await fetch(`${api_enviroment_dev}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    return response.json();
  }
};

export default userService;

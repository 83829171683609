import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import regulationService from '../../services/regulations';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../VariablesContext';
import Swal from 'sweetalert2';

function HistoryServiceRegulation() {
  const [regulations, setRegulations] = useState([]);
  const navigate = useNavigate();
  const { userId } = useUser();
  const [sortOrder, setSortOrder] = useState("asc");

  const storedUserId = localStorage.getItem("userId");
  if (!storedUserId) {
    // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
    navigate("/");
  }

  const sortData = () => {
    return [...regulations].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'America/Guayaquil'
    };
    return date.toLocaleDateString('es-ES', options);
  }

  useEffect(() => {
    const fetchRegulations = async () => {
      Swal.fire({
        title: "Cargando reglamentos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const fetchData = await regulationService.getByUserId(userId);
        setRegulations(fetchData);
        Swal.close();
      } catch (error) {
        console.error("Error al obtener los datos", error);
        Swal.close();
      }
    };
    fetchRegulations();
  }, [])
  return (
    <div>
      <h2>Reglamentos</h2>
      <div className='overflow-x-auto shadow-md sm:rounded-lg'>
        <table className="w-full text-base md:text-lg text-left rtl:text-right text-gray-300 dark:text-gray-300">
          <thead className="text-sm md:text-base text-gray-400 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-300">
            <tr>
              <th scope="col" className="px-8 py-4">N°</th>
              <th scope="col" className="px-8 py-4">Nombre del Reglamento</th>
              <th scope="col"
                onClick={toggleSortOrder}
                className="px-8 py-4 cursor-pointer">
                Fecha de Generación {sortOrder === "asc" ? "▲" : "▼"}</th>
              <th scope="col" className="px-8 py-4 text-center">Acciones</th>
              {/* wallet_service_id, para consultar el precio del producto generado */}
            </tr>
          </thead>
          <tbody>
            {regulations.length > 0 ? (
              sortData().map((item, index) => (
                <tr key={index}
                  className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className="px-6 py-4">{item.regulation_additionalData?.fileName || item.regulation_name || 'Sin documentos generados'}</td>
                  <td className="px-6 py-4">{formatDate(item.createdAt) || ''}</td>
                  <td className="px-6 py-4">
                    <a href={item.regulation_uri}
                      className="flex item-center justify-center text text-center "
                    >
                      <FontAwesomeIcon icon={faFileWord} size="2x" className="mr-2 cursor: 'pointer' text-amber-500 hover:text-green-700 focus:text-green-700" />

                    </a>
                  </td>
                </tr>
              ))) : (
              <tr
                className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700">
                <td colSpan="4" className="px-6 py-4 text-center">No hay datos disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HistoryServiceRegulation;
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import userService from '../../../services/userService'
import { CircularProgress } from '@mui/material'
import { useFacturaDetailDispatch, useFacturaDetailValue, useFacturaValidDispatch } from '../BilleteraState/BilleteraContext'

const FacturationFormDataComponent = () => {
    const factData = useFacturaDetailValue()
    const [formData, setFormData] = useState(factData)

    const dispatchFactura = useFacturaDetailDispatch();
    const dispatchFacturaValid = useFacturaValidDispatch()

    const [errors, setErrors] = useState({})
    const [setDataUsuarios] = useState({})
    const [loading, setLoading] = useState(false)

    const hasErrors = useCallback((errors) => {
        return Object.keys(errors).length > 0;
    }, []);

    const isFormValid = useMemo(() => {
        const { nombre, correo, id } = formData;
        return (
            nombre.trim() !== '' &&
            correo.trim() !== '' &&
            id.trim() !== '' &&
            !hasErrors(errors)
        );
    }, [formData, errors, hasErrors]);

    const validateField = useCallback((name, value) => {
        let errorMsg = '';
        switch (name) {
            case 'nombre':
                if (value.trim() === '') {
                    errorMsg = 'El nombre es obligatorio';
                }
                break;
            case 'correo':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    errorMsg = 'El correo no es válido';
                }
                break;
            case 'telefono':
                if (value.trim() !== '' && !/^(\d{7}|\d{10})$/.test(value)) {
                    errorMsg = 'El teléfono no es válido. Debe contener 7 o 10 dígitos';
                }
                break;
            case 'id':
                if (value.trim() === '') {
                    errorMsg = 'La Céd/RUC es obligatoria';
                } else if (!/^\d+$/.test(value)) {
                    errorMsg = 'La Céd/RUC debe contener solo números';
                } else if (value.length !== 10 && value.length !== 13) {
                    errorMsg = 'La Céd/RUC debe tener 10 o 13 números';
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (errorMsg) {
                newErrors[name] = errorMsg;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    }, []);
    

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const storedUserId = localStorage.getItem("userId");
            if (storedUserId) {
                try {
                    const userData = await fetchUserData(storedUserId);
                    setFormData({
                        ...formData,
                        nombre: userData.user_names + " " + userData.user_lastnames || "",
                        correo: userData.user_email || ""
                    });
                } catch (error) {
                    console.error("Error al obtener los datos del usuario", error);
                }
            }
        };
        fetchData();
        setLoading(false);
    }, []);

    const validateFormData = useCallback(() => {
        Object.keys(formData).forEach(field => {
            validateField(field, formData[field]);
        });
    }, [formData, validateField]);

    useEffect(() => {
        validateFormData()
        dispatchFactura({
            type: "ACTUALIZAR",
            payload: {
                ...formData
            }
        });
    }, [formData,dispatchFactura,validateFormData])

    useEffect(() => {
        if (isFormValid) {
            dispatchFacturaValid({ type: "ALLOWED" });
        } else {
            dispatchFacturaValid({ type: "DISALLOWED" });
        }
    }, [formData, errors,dispatchFacturaValid,isFormValid]);


    const fetchUserData = async (userId) => {
        try {
            const data = await userService.getUserId(userId);
            setDataUsuarios(data);
            return data;
        } catch (error) {
            console.error("Error al obtener los datos del usuario", error);
            throw error;
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
        validateField(name, value)

    }

    return loading ?
        (
            <div className='flex flex-col items-center justify-center'>
                <CircularProgress />
            </div>
        )
        : (
            <div className='flex flex-col items-center justify-center overflow-y-scroll 
            my-4 md:px-2 lg:px-8
            md:py-12
            text-2xl w-full 
            sm:border-r-0
            md:border-r-2 border-[#D4d4d4]
            '>
                <div className='font-bold text-center my-2 text-3xl
                '>Datos de facturación</div>
                <form noValidate
                    className='flex flex-col items-center justify-center gap-6'
                >
                    {/**Nombre */}
                    <div className='flex flex-row items-center justify-around
                    w-full font-thin
                '>
                        <label
                            htmlFor='nombre'
                            className='w-1/3 text-left mr-6'>Nombre</label>
                        <input
                            type="text"
                            id="nombre"
                            name="nombre"
                            value={formData.nombre}
                            className='w-2/3 font-normal text-2xl'
                            onChange={handleChange}
                            placeholder='John Doe'
                        />
                    </div>
                    {errors.nombre && <span className="text-red-500 text-lg w-full flex
                flex-col items-center justify-center my-1
                ">{errors.nombre}</span>}
                    {/**Correo */}
                    <div className='flex flex-row items-center justify-around
                    w-full font-thin 
                '>
                        <label
                            htmlFor='correo'
                            className='w-1/3 text-left mr-6 '>Correo</label>
                        <input
                            type="email"
                            id="correo"
                            name="correo"
                            value={formData.correo}
                            className='w-2/3 font-normal text-2xl'
                            onChange={handleChange}
                            placeholder='johndoe@email.com'
                        />
                    </div>
                    {errors.correo && <span className="text-red-500 text-lg w-full flex
                flex-col items-center justify-center my-1
                ">{errors.correo}</span>}

                    {/*Teléfono  */}
                    <div className='flex flex-row items-center justify-around
                    w-full font-thin 
                '>
                        <label
                            htmlFor='telefono'
                            className='w-1/3 text-left mr-6'>Teléfono</label>
                        <input
                            type="tel"
                            id="telefono"
                            name="telefono"
                            value={formData.telefono}
                            className='w-2/3 font-normal text-2xl'
                            onChange={handleChange}
                            placeholder='2777777'
                        />
                    </div>
                    {errors.telefono && <span className="text-red-500 text-lg w-full flex
                flex-col items-center justify-center my-1
                ">{errors.telefono}</span>}

                    {/*Cédula/RUC*/}
                    <div className='flex flex-row items-center justify-around
                    w-full font-thin 
                '>
                        <label
                            htmlFor='id'
                            className='w-1/3 text-left mr-6'>CED/RUC</label>
                        <input
                            type="text"
                            id="id"
                            name="id"
                            value={formData.id}
                            className='w-2/3 font-normal  text-2xl'
                            onChange={handleChange}
                            placeholder='0100xxxxxx001'
                        />
                    </div>
                    {errors.id && <span className="text-red-500 text-lg w-full flex
                flex-col items-center justify-center my-1
                ">{errors.id}</span>}

                </form>
            </div>
        )
}

export default FacturationFormDataComponent

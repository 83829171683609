import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import termsService from '../../services/termsService';
import mwServices from '../../services/mw_servicesS';

Modal.setAppElement('#root');

const TermsTable = () => {
  const [termsList, setTermsList] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [allServices, setAllServices] = useState([]);

  const getTerms = async () => {
    const allTerms = await termsService.getTermsList();
    const formattedTerms = allTerms.map((item) => ({
      id: item.terms_id,
      serviceName: item.terms_service_name,
      state: item.terms_state,
      creationDate: item.terms_creationDate,
      updateDate: item.terms_updateDate
    }));
    setTermsList(formattedTerms);
  };

  useEffect(() => {
    const getServices = async () => {
      const services = await mwServices.getAll();
      setAllServices(services);
    };
    getServices();
    getTerms();
  }, []);

  const handleEditClick = (term) => {
    setSelectedTerm(term);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedTerm(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    const updateTerms = {
      terms_service_name: selectedTerm.serviceName,
      terms_state: selectedTerm.state,

    }
    // console.log(selectedTerm.id,updateTerms)
    termsService.updateRegulation(selectedTerm.id, updateTerms)
      .then(async data => {
        console.log("Editado ", data);
        getTerms();
      })
      .catch(error => {
        console.error('Error :', error);
      });

    // console.log(updateTerms);
    // Actualizar términos en la lista localmente (opcional, puedes volver a hacer fetch para mayor precisión)
    setTermsList(termsList.map(term => term.id === selectedTerm.id ? selectedTerm : term));
    handleModalClose();
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Términos y Condiciones</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Id</th>
            <th className="py-2 px-4 border-b">Nombre del Servicio</th>
            <th className="py-2 px-4 border-b">Estado</th>
            <th className="py-2 px-4 border-b">Fecha de Creación</th>
            <th className="py-2 px-4 border-b">Fecha de Actualización</th>
            <th className="py-2 px-4 border-b">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {termsList.map((row) => (
            <tr key={row.id}>
              <td className="py-2 px-4 border-b">{row.id}</td>
              <td className="py-2 px-4 border-b">{row.serviceName}</td>
              <td className="py-2 px-4 border-b">{row.state === 'A' ? 'Activo' : 'Inactivo'}</td>
              <td className="py-2 px-4 border-b">{new Date(row.creationDate).toLocaleString()}</td>
              <td className="py-2 px-4 border-b">{new Date(row.updateDate).toLocaleString()}</td>
              <td className="py-2 px-4 border-b">
                <button onClick={() => handleEditClick(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={showModal}
        onRequestClose={handleModalClose}
        contentLabel="Editar Término"
        className="bg-white rounded-lg shadow-lg max-w-md w-full p-4"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
      >
        {selectedTerm && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Editar Términos y Condiciones</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Nombre del Servicio</label>
              <select
                className="w-full px-3 py-2 border rounded"
                value={selectedTerm.serviceName}
                onChange={(e) =>
                  setSelectedTerm({ ...selectedTerm, serviceName: e.target.value })
                }
              >
                <option value="">Seleccione un servicio</option>
                {allServices.map((service) => (
                  <option key={service.mw_services_ID} value={service.mw_services_name}>
                    {service.mw_services_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Estado</label>
              <select
                className="w-full px-3 py-2 border rounded"
                value={selectedTerm.state}
                onChange={(e) =>
                  setSelectedTerm({ ...selectedTerm, state: e.target.value })
                }
              >
                <option value="A">Activo</option>
                <option value="I">Inactivo</option>
              </select>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                onClick={handleModalClose}
              >
                Cancelar
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={handleSave}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TermsTable;

const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/companies";

const companyService = {
  async createCompany(data) {
    const response = await fetch(`${api_enviroment_dev}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },
  async getCompanyId(id) {
    const response = await fetch(`${api_enviroment_dev}/${id}`);
    return response.json();
  },
  async putCompanyId(id, data) {
    try {
      const response = await fetch(`${api_enviroment_dev}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Error al actualizar');
      }

      const res = await response.json();
      return res;
    } catch (error) {
      console.error('Error al actualizar:', error);
      throw error;
    }
  },
  async delete(id ) {
    try {
      const response = await fetch(`${api_enviroment_dev}/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },        
      });

      if (!response.ok) {
        throw new Error('Error al eliminar');
      }

      const res = await response.json();
      return res;
    } catch (error) {
      console.error('Error al eliminar:', error);
      throw error;
    }
  },

};

export default companyService;
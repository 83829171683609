// src/components/AdminPanel.js
import React, { useState, useEffect } from 'react';
import termsService from '../../services/termsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import showAlert from '../../utilities/alerts';

const TermsEdit = (id) => {
    const [terms, setTerms] = useState('');
    let termsID = id.id;
    // console.log('id', termsID)
    const fetchTerms = async () => {
        const data = await termsService.getTermsByID(termsID);
        // console.log(data)
        setTerms(data.terms_text || '');
        //agregar pantalla de carga
    };
    const updateTerms = async () => {
        let newData = {
            terms_text: terms,
            tersm_state: "A"
        }
        const updateTerms = await termsService.updateRegulation(termsID, newData)
        return updateTerms;


    };

    useEffect(() => {
        fetchTerms();

    }, [termsID]);



    const handleUpdate = async () => {
        const res = updateTerms();
        if (res) {
            showAlert.success("Los términos y condiciones se han actualizado correctamente.");
        } else {
            showAlert.error("Los términos y condiciones no se han actualizado.");
        }

        //SweetAlert
        // alert('Términos y condiciones actualizados');
    };


    return (
        <div className="p-6">
            <h1 className="text-lg font-bold mb-4">Panel de edición</h1>
            <ReactQuill
                theme="snow"
                value={terms}
                onChange={(content) => setTerms(content)}
                className="h-64 mb-4"
            />
            {/* <Editor
                // apiKey=""  
                value={terms}
                onEditorChange={(content) => setTerms(content)}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                }}
            /> */}
            <button
                className="mt-4 bg-green-600 text-white py-2 px-4 rounded"
                onClick={handleUpdate}
            >
                Actualizar
            </button>
        </div>
    );
};

export default TermsEdit;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title{
    padding-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}
.subtitle{
    margin-top: 0%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}
.content{
    margin-left: 5%;
    margin-right: 5%;
}
.matchWorkingLogo{
    display: flex;
    justify-content: right;
}
.matchWorkingLogo img{
    max-height: 150px;
    padding-top: 5%;
    padding-right: 15%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/company/formCompanyPages.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["\n.title{\n    padding-top: 5%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n.subtitle{\n    margin-top: 0%;\n    margin-left: 5%;\n    margin-right: 5%;\n    text-align: left;\n}\n.content{\n    margin-left: 5%;\n    margin-right: 5%;\n}\n.matchWorkingLogo{\n    display: flex;\n    justify-content: right;\n}\n.matchWorkingLogo img{\n    max-height: 150px;\n    padding-top: 5%;\n    padding-right: 15%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

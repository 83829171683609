// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.pdf-container {
    position: relative;
}

.react-pdf__Document,
.react-pdf__Page {
    width: 100%;
}

.react-pdf__Page {
    display: block;
    margin: 0 auto;
}

/* Oculta el toolbar del visor de PDF */
.react-pdf__toolbar {
    display: none !important;
}
.toolbar {
    display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/regulationComponents/GenerateRegulation.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA,uCAAuC;AACvC;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B","sourcesContent":["/* App.css */\n.pdf-container {\n    position: relative;\n}\n\n.react-pdf__Document,\n.react-pdf__Page {\n    width: 100%;\n}\n\n.react-pdf__Page {\n    display: block;\n    margin: 0 auto;\n}\n\n/* Oculta el toolbar del visor de PDF */\n.react-pdf__toolbar {\n    display: none !important;\n}\n.toolbar {\n    display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaClipboard } from 'react-icons/fa';
import adService from '../services/profileService';


const CopyLink = ({ link }) => {
    const [copied, setCopied] = useState(false);
    
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };
    
    const handleCreateQR = async () => {
        await adService.qrGenerator(link)
    };

    return (
        <div className='h-8 flex justify-end'>
            <div className="flex relative" onClick={handleCreateQR}>
                <CopyToClipboard text={link} onCopy={handleCopy}>
                    <button className="flex items-center">
                        <FaClipboard className="text-white text-2xl cursor-pointer hover:text-sky-300 transition-all duration-300" />
                        <span className='py-2'>Obtener enlace</span>
                    </button>
                </CopyToClipboard>
                {copied && (
                    <div className="absolute top-8 left-1/2 mb-3.5 pb-1 -translate-x-1/2 scale-100 translate-y-0 text-white bg-sky-400 opacity-100 text-xs font-semibold rounded-md px-2 py-1">
                        Copiado
                    </div>
                )}
            </div>
        </div>
    );
};

export default CopyLink;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CanvasPerfilCargo from '../CanvasPerfilCargo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const JobProfile = ({ profile, setSuggestProfile }) => {
    const { experience, responsibilities, suggestions, mission, competencies } = profile;
    const [level, title] = Object.entries(suggestions.estudiosRequeridos).find(
        ([key, value]) => value !== 'No Aplica'
    );
    const [openSection, setOpenSection] = useState(null);
    const [loading, setLoading] = useState(false)
    const [profileData, setProfileData] = useState({
        position: {
            name: suggestions.nombreCargo || '',
            country: 'Ecuador' || '',
            department: suggestions.departamento || '',
            city: suggestions.ubicacion.ciudad || '',
            chief: suggestions.cargoJefe || '',
        },
        mission: mission || '',
        requirements: {
            experienceTime: experience.timeExperience || '',
            experienceDetail: experience.detailOfExperience || '',
        },
        education: {
            level: transformString(level) || '',
            title: title || '',
        },
        workingConditions: {
            contract: suggestions.contrato || '',
            modality: suggestions.modalidad || '',
            salary: suggestions.salarioReferencial || '',
        },
        benefits: suggestions.beneficios || [], // array
        tools: suggestions.herramientas || [], //array
        responsibilities: responsibilities.listaResponsabilidades || [], //array
        technicalSkills: competencies.competenciasTecnicas || [],
        personalSkills: competencies.competenciasPersonales || [],
        languages: {
            language: suggestions.idiomas.idioma || '',
            level: suggestions.idiomas.nivel || '',
        },
        courses: suggestions.cursos, // array
    });
    const instance = axios.create();
    useEffect(() => {
        setSuggestProfile(profileData)
        // console.log('datos cambiados', profileData)
    }, [profileData]);

    function transformString(input) {
        let spacedString = input.replace(/([A-Z])/g, ' $1');

        let result = spacedString
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase());

        return result.trim();
    }

    const handleChange = (field, index, subfield, value) => {
        setProfileData((prevState) => {
            // Array de objetos
            if (typeof index === 'number') {
                if (subfield !== undefined) {
                    // Array de objetos con subcampo
                    const updatedField = prevState[field].map((item, idx) =>
                        idx === index ? { ...item, [subfield]: value } : item
                    );
                    return { ...prevState, [field]: updatedField };
                } else {
                    // Array simple   
                    return {
                        ...prevState,
                        [field]: prevState[field].map((item, i) => (i === index ? value : item))
                    };

                }
            } else {
                if (subfield !== null) {
                    // Objeto con subcampo
                    return {
                        ...prevState,
                        [field]: {
                            ...prevState[field],
                            [subfield]: value
                        }
                    };
                } else {
                    // Campo - Valor
                    return {
                        ...prevState,
                        [field]: value
                    };
                }
            }
        });
    };
    const handleChangeArrays = (field, index, value) => {
        setProfileData((prevState) => ({
            ...prevState,
            [field]: prevState[field].map((item, i) => (i === index ? value : item))
        }));
    };

    const handleAdd = (field) => {
        setProfileData((prevState) => ({
            ...prevState,
            [field]: [...prevState[field], ''] // Agrega un nuevo elemento vacío al campo especificado
        }));
    };

    const handleAddResponsibilities = (field) => {
        setProfileData((prevState) => ({
            ...prevState,
            [field]: [...prevState[field], { funcion: '', tareas: '', resultados: '' }]
        }));
    };

    const handleRemove = (field, index) => {
        setProfileData((prevState) => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index) // Elimina el elemento en el índice especificado del campo especificado
        }));
    };


    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <div className="flex flex-col md:flex-row w-full mx-auto text-black">
            <div className="w-full md:w-1/2 p-8 overflow-auto h-screen">
                <div>
                    <h1 className="text-xl font-bold mb-4 text-white">Genera tu perfil de cargo</h1>
                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('perfil')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Perfil de cargo
                            <span>{openSection === 'perfil' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'perfil' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <div className="mb-2">
                                    <label className="block font-bold">Nombres del cargo:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.position.name}
                                        onChange={(e) => handleChange('position', null, 'name', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">País:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.position.country}
                                        onChange={(e) => handleChange('position', null, 'country', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Departamento:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.position.department}
                                        onChange={(e) => handleChange('position', null, 'department', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Ciudad:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.position.city}
                                        onChange={(e) => handleChange('position', null, 'city', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Cargo del jefe:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.position.chief}
                                        onChange={(e) => handleChange('position', null, 'chief', e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('mision')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Misión del cargo
                            <span>{openSection === 'mision' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'mision' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <div className="mb-2">
                                    <label className="block font-bold">Misión:</label>
                                    <textarea
                                        className="w-full border p-2 min-h-32"
                                        value={profileData.mission}
                                        onChange={(e) => handleChange('mission', null, null, e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('requisitos')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Requisitos para el cargo
                            <span>{openSection === 'requisitos' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'requisitos' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <div className="mb-2">
                                    <label className="block font-bold">Tiempo de experiencia:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.requirements.experienceTime}
                                        onChange={(e) => handleChange('requirements', null, 'experienceTime', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Detalle de experiencia:</label>
                                    <textarea
                                        className="w-full border p-2"
                                        value={profileData.requirements.experienceDetail}
                                        onChange={(e) => handleChange('requirements', null, 'experienceDetail', e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('educacion')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Educación
                            <span>{openSection === 'educacion' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'educacion' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <div className="mb-2">
                                    <label className="block font-bold">Nivel de educación:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={transformString(profileData.education.level)}
                                        onChange={(e) => handleChange('education', null, 'level', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Título:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.education.title}
                                        onChange={(e) => handleChange('education', null, 'title', e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('condiciones')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Condiciones de trabajo
                            <span>{openSection === 'condiciones' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'condiciones' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <div className="mb-2">
                                    <label className="block font-bold">Tipo de contrato:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.workingConditions.contract}
                                        onChange={(e) => handleChange('workingConditions', null, 'contract', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Modalidad:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.workingConditions.modality}
                                        onChange={(e) => handleChange('workingConditions', null, 'modality', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Salario:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.workingConditions.salary}
                                        onChange={(e) => handleChange('workingConditions', null, 'salary', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Beneficios:</label>
                                    {profileData.benefits.map((benefit, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                className="w-full border p-2 mr-2"
                                                value={benefit}
                                                onChange={(e) => handleChangeArrays('benefits', index, e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => handleRemove('benefits', index)}
                                                className="flex item-center justify-center text text-center "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    size="2x"
                                                    className="mr-2 text-red-500 cursor-pointer transition-transform duration-200 hover:scale-125"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => handleAdd('benefits')}
                                        className="bg-amber-500 text-white p-2 mt-2"
                                    >
                                        Agregar Beneficio
                                    </button>
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Herramientas:</label>
                                    {profileData.tools.map((tool, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                className="w-full border p-2 mr-2"
                                                value={tool}
                                                onChange={(e) => handleChangeArrays('tools', index, e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => handleRemove('tools', index)}
                                                className="flex item-center justify-center text text-center "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    size="2x"
                                                    className="mr-2 text-red-500 cursor-pointer transition-transform duration-200 hover:scale-125"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => handleAdd('tools')}
                                        className="bg-blue-500 text-white p-2 mt-2"
                                    >
                                        Agregar Herramienta
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('responsabilidades')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Responsabilidades de cargo
                            <span>{openSection === 'responsabilidades' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'responsabilidades' && (
                            <div className="p-4 bg-gray-100 text-black">
                                <table className="w-full border">
                                    <thead>
                                        <tr>
                                            <th className="border px-2 py-1">Funciones</th>
                                            <th className="border px-2 py-1">Tareas</th>
                                            <th className="border px-2 py-1">Resultados</th>
                                            <th className="border px-2 py-1">''</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profileData.responsibilities.map((responsibilitie, index) => (
                                            <tr key={index}>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        className="w-full border p-2"
                                                        value={responsibilitie.funcion}
                                                        onChange={(e) => handleChange('responsibilities', index, 'funcion', e.target.value)}
                                                    ></textarea>
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        className="w-full border p-2"
                                                        value={responsibilitie.tareas}
                                                        onChange={(e) => handleChange('responsibilities', index, 'tareas', e.target.value)}
                                                    ></textarea>
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        className="w-full border p-2"
                                                        value={responsibilitie.resultados}
                                                        onChange={(e) => handleChange('responsibilities', index, 'resultados', e.target.value)}
                                                    ></textarea>
                                                </td>
                                                <td>
                                                    <span                                                   
                                                        onClick={() => handleRemove('responsibilities', index)}
                                                        className="flex item-center justify-center text text-center "
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                            size="2x"
                                                            className="mr-2 text-red-500 cursor-pointer transition-transform duration-200 hover:scale-125"
                                                        />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        <button
                                            type="button"
                                            onClick={() => handleAddResponsibilities('responsibilities')}
                                            className="bg-blue-500 text-white p-2 mt-2"
                                        >
                                            Agregar responsabilidades
                                        </button>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('competencias')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center">
                            Competencias
                            Responsabilidades de cargo
                            <span>{openSection === 'competencias' ? '▲' : '▼'}</span>
                        </button>

                        {openSection === 'competencias' && (
                            <div className="p-4 bg-gray-100">
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr>
                                            <th className="py-2 px-4 border-b">Tipo</th>
                                            <th className="py-2 px-4 border-b">Nivel</th>
                                            <th className="py-2 px-4 border-b">Definición</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profileData.technicalSkills.map((skill, index) => (
                                            <tr key={index}>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        type="text"
                                                        className="w-full border p-2"
                                                        value={skill.nombre}
                                                        onChange={(e) => handleChange('technicalSkills', index, 'nombre', e.target.value)}
                                                    ></textarea>
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <input
                                                        className="w-full border p-2"
                                                        value={skill.nivel}
                                                        onChange={(e) => handleChange('technicalSkills', index, 'nivel', e.target.value)}
                                                    ></input>
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        className="w-full border p-2"
                                                        value={skill.definicion}
                                                        onChange={(e) => handleChange('technicalSkills', index, 'definicion', e.target.value)}
                                                    ></textarea>
                                                </td>
                                            </tr>
                                        ))}
                                        {profileData.personalSkills.map((skill, index) => (
                                            <tr key={index}>
                                                <td className="border px-2 py-1">
                                                    <input
                                                        type="text"
                                                        className="w-full border p-2"
                                                        value={skill.nombre}
                                                        onChange={(e) => handleChange('personalSkills', index, 'nombre', e.target.value)}
                                                    />
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <input
                                                        className="w-full border p-2"
                                                        value={skill.nivel}
                                                        onChange={(e) => handleChange('personalSkills', index, 'nivel', e.target.value)}
                                                    ></input>
                                                </td>
                                                <td className="border px-2 py-1">
                                                    <textarea
                                                        className="w-full border p-2"
                                                        value={skill.definicion}
                                                        onChange={(e) => handleChange('personalSkills', index, 'definicion', e.target.value)}
                                                    ></textarea>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <button
                            onClick={() => toggleSection('capacitacion')}
                            className="w-full font-bold text-left py-2 px-4 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
                        >
                            Capacitación adicional
                            <span>{openSection === 'capacitacion' ? '▲' : '▼'}</span>
                        </button>
                        {openSection === 'capacitacion' && (
                            <div className="p-4 bg-gray-100">
                                <div className="mb-2">
                                    <label className="block font-bold">Idiomas:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.languages.language}
                                        onChange={(e) => handleChange('languages', null, 'language', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Nivel:</label>
                                    <input
                                        type="text"
                                        className="w-full border p-2"
                                        value={profileData.languages.level}
                                        onChange={(e) => handleChange('languages', null, 'level', e.target.value)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold">Cursos:</label>
                                    {profileData.courses.map((course, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                className="w-full border p-2 mr-2"
                                                value={course}
                                                onChange={(e) => handleChangeArrays('courses', index, e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => handleRemove('courses', index)}
                                                className="flex item-center justify-center text text-center "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    size="2x"
                                                    className="mr-2 text-red-500 cursor-pointer transition-transform duration-200 hover:scale-125"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => handleAdd('courses')}
                                        className="bg-blue-500 text-white p-2 mt-2"
                                    >
                                        Agregar Curso
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <div className="w-full md:w-1/2 m-2 md:m-4 overflow-auto h-screen">
                <CanvasPerfilCargo editedProfile={profileData} />
            </div>
        </div>

    );
};

export default JobProfile;

import React, { useState, useEffect } from "react";
import matchWorkingLogo from "../../assets/img/LOGOMW.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../VariablesContext";
import userService from "../../services/userService";
import regulationTypeService from "../../services/regulationsTypes";
import TermsModal from "../TermsModal";

const showAlert = require("../../utilities/alerts");

const SelectRegulation = ({
  regulationData,
  setRegulationData,
  onNextStep,
}) => {
  const [regulations, setRegulations] = useState([]);
  // console.log('regulations', regulations)
  const navigate = useNavigate();
  const { userId } = useUser();
  // console.log(userId)
  const [dataUsuarios, setDataUsuarios] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  // console.log('selected:', selectedItems)
  // Estado para controlar la ventana emergente de Terms & Conditions (open/close)
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    const fetchRegulations = async () => {
      try {
        Swal.fire({
          title: "Cargando...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const regulationsData =
          await regulationTypeService.getRegulationsByState(true);
        setRegulations(regulationsData);
        Swal.close();
      } catch (error) {
        console.error("Error al obtener los reglamentos:", error);
        showAlert.error("Se ha producido un error, ingresa nuevamente");
        navigate("/");
        // window.location.reload()
        // Swal.close();
      }
    };
    fetchRegulations();
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
      navigate("/");
    } else {
      const fetchUserData = async () => {
        try {
          const dataUser = await userService.getUserId(
            localStorage.getItem("userId")
          );
          // console.log(dataUser);
          // Actualiza el estado con los datos del usuario
          setDataUsuarios(dataUser);
        } catch (error) {
          console.error("Error al obtener los datos del usuario", error);
        }
      };
      // Llama a la función para obtener los datos del usuario
      fetchUserData();
    }
  }, []);
  if (!userId) {
    // Si no hay userId, no renderizar el componente
    return null;
  }
  // Función para actualizar el estado de terminos y condiciones del usuario en este servicio: terms_conditions_regulations
  const acceptTerms = async () => {
    const updatedUser = {
      ...dataUsuarios,
      user_acceptances: {
        ...dataUsuarios.user_acceptances,
        terms_conditions_regulations: termsAccepted,
      },
    };
    try {
      const data = await userService.updateUser(updatedUser, userId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNext = () => {
    // Redireccionar a otra página si se seleccionó al menos un checkbox
    if (selectedItems.length > 0 && termsAccepted) {
      setRegulationData({
        ...regulationData,
        selectedRegulation: selectedItems,
      }); //Guarda Información de los documentos generados en los props
      acceptTerms();
      onNextStep();
      //actualizar usuario con la aceptación de términos y condiciones
    } else if (!termsAccepted) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Debes aceptar los términos y condiciones para continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          setTermsAccepted(true);
        }
      });
    } else {
      // alert('Debes seleccionar al menos una opción');
      showAlert.error("Selecciona al menos un reglamento");
    }
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.some((prevItem) => prevItem.slug === item.slug)) {
        return prevItems.filter((prevItem) => prevItem.slug !== item.slug);
      } else {
        return [...prevItems, item];
      }
    });
  };
  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };
  // Función para abrir la ventana emergente de términos y condiciones
  const handleOpenTermsPopup = () => {
    setShowTermsPopup(true);
  };

  // Función para cerrar la ventana emergente de términos y condiciones
  const handleCloseTermsPopup = () => {
    setShowTermsPopup(false);
    setTermsAccepted(true);
  };

  return (
    <div className="h-fit w-full max-w-96 text-white ">
      <div className="d-flex align-items-center justify-content-center  ">
        <img
          src={matchWorkingLogo}
          alt="Match Working Logo"
          style={{ maxHeight: "200px" }}
        />
      </div>
      <div className="bg-opacity-95 bg-[#011A27] shadow-lg rounded-lg pb-8 px-6 pt-5">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Escoge tu reglamento
        </h1>

        {regulations.map((item) => (
          <div key={item.slug} className="flex items-center mb-4">
            <div className="flex items-center space-x-3">
              <input
                id={`checkbox-${item.slug}`}
                type="checkbox"
                className="w-5 h-5 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500 dark:focus:ring-amber-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
                checked={selectedItems.some(
                  (selectedItem) => selectedItem.slug === item.slug
                )}
                onChange={() => handleCheckboxChange(item)}
              />
              <label
                htmlFor={`checkbox-${item.slug}`}
                className="text-lg font-medium cursor-pointer align-middle"
              >
                {item.regulation_name}
              </label>
            </div>
          </div>
        ))}

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="w-5 h-5 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500 dark:focus:ring-amber-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
            checked={termsAccepted}
            onChange={handleTermsChange}
          />
          <label className="ml-3 text-sm font-medium">
            He leído y acepto los{" "}
            <a
              className="text-blue-500 hover:text-blue-300 cursor-pointer"
              onClick={handleOpenTermsPopup}
            >
              términos y condiciones
            </a>
          </label>
        </div>

        <div className="text-center">
          <button
            onClick={handleClickNext}
            className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-6 rounded transition duration-300"
          >
            Siguiente
          </button>
        </div>

        <div>
          {showTermsPopup && <TermsModal onClose={handleCloseTermsPopup} />}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default SelectRegulation;

import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import walletListDataService from '../../services/walletListDataService';
import walletService from '../../services/walletService';
import './listDataAdmin.css';

const CoinParamForm = () => {
  const [listGeneralParam, setListGeneralParam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('add'); // 'add' o 'edit'
  const [selectedRow, setSelectedRow] = useState(null);
  const [tokenUser, setTokenUser] = useState("");

  const [newGeneralParams, setNewGeneralParams] = useState({
    coin:'',
    coin_value:'',
    coin_exchange:'',
    coin_exchange_value:'',
    state:'A'
  });
  const options = [
    { value: 'A', label: 'Activo' },
    { value: 'I', label: 'Inactivo' },
  ];

  const loadToken = async () =>{
    const data = await walletService.getToken();
    return data.token;
  }
  const fetchData = async (token) => {
    try {
        /*const responseExample =[
            {             
              "wallet_coin_currency":"MW Coin",
              "wallet_coin_value":"1",
              "wallet_coin_exchange_rate":"Dollar",
              "wallet_coin_exchange_value":"2",
              "wallet_coin_state":"A",
              "wallet_coin_creationDate":"2024-03-22T00:20:53.919+00:00",
              "wallet_coin_updateDate":"2024-03-22T00:20:53.919+00:00",
              "wallet_coin_id":1
            }
        ]*/
       
      const allCoinsParams = (await walletListDataService.getAllCoins(token)).data; 

      setListGeneralParam(
        allCoinsParams.map((item) => ({
          id: item.wallet_coin_id,
          coin: item.wallet_coin_currency,
          coin_value: item.wallet_coin_value,
          coin_exchange: item.wallet_coin_exchange_rate,
          coin_exchange_value:item.wallet_coin_exchange_value,
          state: item.wallet_coin_state,
          creationDate:item.wallet_coin_creationDate,
          updateDate:item.wallet_coin_updateDate
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const token = await loadToken();
      setTokenUser(token);
      fetchData(token);
    };

    fetch();
    
  }, []);

  const handleEdit = (row) => {
    // Configura el modal para la edición
    setModalAction('edit');
    setSelectedRow(row);
    setNewGeneralParams({
        coin: row.coin,
        coin_value: row.coin_value,
        coin_exchange: row.coin_exchange,
        coin_exchange_value: row.coin_exchange_value,
        state: row.state
    });
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    // Configura el modal para agregar
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      coin:'',
      coin_value:'',
      coin_exchange:'',
      coin_exchange_value:'',
      state:'A'
    });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Cierra el modal y reinicia los campos del formulario
    setIsModalOpen(false);
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      coin:'',
      coin_value:'',
      coin_exchange:'',
      coin_exchange_value:'',
      state:'A'
    });
  };

  const handleSave = () => {
    if (modalAction === 'add') {
      const newCoinParam = {             
        "wallet_coin_currency":newGeneralParams.coin,
        "wallet_coin_value":newGeneralParams.coin_value,
        "wallet_coin_exchange_rate":newGeneralParams.coin_exchange,
        "wallet_coin_exchange_value":newGeneralParams.coin_exchange_value,
        "wallet_coin_state":newGeneralParams.state
      };
      
      walletListDataService.createCoin(tokenUser,newCoinParam)
      .then(async data => {
        //console.log("Creado ",data);
        fetchData(tokenUser);
      })
      .catch(error => {
        console.error('Error :', error);
      });

    } else if (modalAction === 'edit') {
      const updateCoinParam = {             
        "wallet_coin_currency":newGeneralParams.coin,
        "wallet_coin_value":newGeneralParams.coin_value,
        "wallet_coin_exchange_rate":newGeneralParams.coin_exchange,
        "wallet_coin_exchange_value":newGeneralParams.coin_exchange_value,
        "wallet_coin_state":newGeneralParams.state
      };
      
      walletListDataService.editCoin(selectedRow.id,tokenUser,updateCoinParam)
      .then(async data => {
        //console.log("Editado ",data);
        fetchData(tokenUser);
      })
      .catch(error => {
        console.error('Error :', error);
      });
      
    }

    // Cierra el modal y reinicia los campos del formulario
    handleModalClose();
    fetchData(tokenUser);
  };

  return (
    <div>
      <div className="d-flex justify-content-center mt-3">
        <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleAdd}>Agregar</button>
      </div>
      {loading ? (
        <div>Cargando...</div>
      ) : (
       <div className="overflow-x-auto mt-4">
         <table className="table-auto border-collapse w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2">ID</th>
              <th className="border px-4 py-2">Moneda</th>
              <th className="border px-4 py-2">Valor</th>
              <th className="border px-4 py-2">Moneda de cambio</th>
              <th className="border px-4 py-2">Valor (Moneda de cambio)</th>
              <th className="border px-4 py-2">Estado</th>
              <th className="border px-4 py-2">Fecha de creación</th>
              <th className="border px-4 py-2">Fecha de actualización</th>
              <th className="border px-4 py-2">Editar</th>
            </tr>
          </thead>
          <tbody>
            {listGeneralParam.map((row) => (
              <tr key={row.id} className="bg-gray-100">
                <td className="border px-4 py-2">{row.id}</td>
                <td className="border px-4 py-2">{row.coin}</td>
                <td className="border px-4 py-2">{row.coin_value}</td>
                <td className="border px-4 py-2">{row.coin_exchange}</td>
                <td className="border px-4 py-2">{row.coin_exchange_value}</td>
                <td className="border px-4 py-2">{row.state}</td>
                <td className="border px-4 py-2">{row.creationDate}</td>
                <td className="border px-4 py-2">{row.updateDate}</td>
                <td>
                  <button onClick={() => handleEdit(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}

      {/* Modal para agregar/editar */}
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        contentLabel={modalAction === 'add' ? 'Agregar Nuevo Parámetro' : 'Editar Parámetro'}
        className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto" 
      >
        <h2 className="text-lg font-semibold mb-4">{modalAction === 'add' ? 'Agregar Nuevo Parámetro' : 'Editar Parámetro'}</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Moneda:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="coin"
              value={newGeneralParams.coin}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, coin: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Valor moneda:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="coin_value"
              value={newGeneralParams.coin_value}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, coin_value: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Moneda de cambio:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="coin_exchange"
              value={newGeneralParams.coin_exchange}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, coin_exchange: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Valor (Moneda de cambio):</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="coin_exchange_value"
              value={newGeneralParams.coin_exchange_value}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, coin_exchange_value: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-bold mb-2">Estado:</label>
            <select
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              value={newGeneralParams.state}
              onChange={(e) =>
                setNewGeneralParams({
                  ...newGeneralParams,
                  state: e.target.value,
                })
              }
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Agrega otros campos según tus necesidades */}
          <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"onClick={handleSave}>
            {modalAction === 'add' ? 'Agregar' : 'Guardar Cambios'}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default CoinParamForm;

import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import walletService from "../services/walletService";
import walletListDataService from "../services/walletListDataService";
import './AlertStyles.css'

import mwLogoMain from "../assets/img/LOGOMW.png"
import mwCoin from "../assets/img/mwc.jpg"

import { transactionOptions } from "../utilities/billeteraViews";
import { payOptions } from "../utilities/billeteraViews";

import BilleteraNavComponent from "./BIlleteraComponents/BilleteraNavComponent";
import MainSubscriptionPlansComponent from "./BIlleteraComponents/PlanesComponents/MainSubscriptionPlansComponent";
import MainRecargasComponent from "./BIlleteraComponents/RecargasComponents/MainRecargasComponent";
import ButtonsComponents from "./BIlleteraComponents/ButtonsSectionComponents/ButtonsComponents";
import MainFacturationComponent from "./BIlleteraComponents/FacturacionSectionComponents/MainFacturationComponent";
import MainPayphoneComponent from "./BIlleteraComponents/PayphoneSectionComponents/MainPayphoneComponent";
import MainTransferenciaComponent from "./BIlleteraComponents/TransferenciaSectionComponents/MainTransferenciaComponent";
import useImagePreloader from "../hooks/useImagePreloader";
import CompraDetailFacturaComponent from "./BIlleteraComponents/CompraDetailSectionComponents/CompraDetailFacturaComponent";

import {
  useFacturaDetailValue,
  useNavPageDispatch,
  useNavPageValue,
  useSelectedArticuloDispatch,
  useSelectedArticuloValue,
  useTokenDispatch,
  useUploadFileValue,
  useViewDispatch,
  useViewValue
} from "./BIlleteraComponents/BilleteraState/BilleteraContext";

import { setPage } from "./BIlleteraComponents/BilleteraState/NavigationNumberReducer";

import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

function ModalWallet({ isOpen, onClose, coinsTotal }) {

  const [token, setToken] = useState("");
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueCoin, setValueCoin] = useState("");

  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [showNotification, setShowNotification] = useState(false)
  const [showNotificationFieldsWithErrors, setShowNotificationFieldsWithErrors] = useState(false)
  const [showNotificationSuccessTransfer, setShowNotificationSuccessTransfer] = useState(false)

  const [showConfirmation, setShowConfirmation] = useState(false)

  const [recargasData, setRecargasData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const navNumber = useNavPageValue()
  const uploadedFile = useUploadFileValue()
  const factura = useFacturaDetailValue()
  const actualView = useViewValue().actualView
  const selectedProduct = useSelectedArticuloValue()

  const dispatchNavPage = useNavPageDispatch()
  const dispatchView = useViewDispatch()
  const dispatchToken = useTokenDispatch()
  const dispatchArticulo = useSelectedArticuloDispatch()

  const recargasCoinImageLoaded = useImagePreloader(mwCoin)

  const fetchPurchaseHistory = useCallback(async () => {
    try {
      const history = await walletService.getPurchaseHistory(
        localStorage.getItem("userId"),
        token
      );
      setPurchaseHistory(history.data.wallet_balance_history);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching purchase history:", error);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const loadToken = async () => {
      const data = await walletService.getToken();
      setToken(data.token);
      dispatchToken({ type: "SET", payload: data.token })
      setValueCoin((await walletListDataService.getCoinById("1", data.token)).data[0].wallet_coin_exchange_value);
    };
    loadToken();

    if (showPurchaseHistory) {
      fetchPurchaseHistory();
    }
  }, [showPurchaseHistory, fetchPurchaseHistory]);

  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const planesPromise = walletListDataService.getPlanes(token);
        const recargasPromise = walletListDataService.getRecargasValues(token);

        const [planesResponse, recargasResponse] = await Promise.all([planesPromise, recargasPromise]);

        setSubscriptionData(planesResponse);
        setRecargasData(recargasResponse[0].wallet_general_recommended_values);
      } catch (error) {
        setSubscriptionData([]);
        setRecargasData([]);
      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [token]);

  const handleOpenPurchaseHistory = () => {
    setShowPurchaseHistory(true);
  };

  const handleClosePurchaseHistory = () => {
    setShowPurchaseHistory(false);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    setLoading(true)
    const emailGeneralAprobador = await walletListDataService.getGeneralById("2", token);
    let imageUrl = ""
    switch (selectedProduct.tipo) {
      case "MW Coin":
        const amountCoins = selectedProduct.unidades
        const precioCoins = selectedProduct.precio
        const createTransaccionCoins = {
          wallet_transaction_type: "Compra",
          wallet_transaction_coins: amountCoins,
          wallet_transaction_amount: precioCoins,
          wallet_transaction_payment_method: "Transferencia",
          wallet_transaction_origen: "",
          billing: {
            name: factura.nombre,
            ci: factura.id,
            phone: factura.telefono || "No Provee",
            email: factura.correo
          }
        };
        const coinsTransaction = await walletService.createtransaction(
          localStorage.getItem("userId"),
          token,
          createTransaccionCoins
        );
        const coins_transaction_id = coinsTransaction.data.wallet_transaction_id;

        try {
          const responseImage = await walletService.uploadTransactionDocument(
            coins_transaction_id,
            token,
            uploadedFile
          );
          if (responseImage.code === "200") {
            imageUrl = responseImage.imageUrl
          }
        } catch (error) {
          console.log("Error Image Upload:", error)
          imageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCxGQVzKOTP_JnxnF0QVeHzdRe6pxb5Bk0EUSS3atB7Q&s"
        }

        const correoMWCoins = {
          emailAprobador: emailGeneralAprobador.data[0].wallet_general_value,
          cantidadMWCoins: amountCoins,
          montoTotal: precioCoins,
          descripcionSolicitud: "Compra de monedas - Transferencia",
          transaccionId: coins_transaction_id,
          transferenciaImg: imageUrl,
          origen: "",
          billingMail: factura.correo,
          billingName: factura.nombre,
          billingCi: factura.id,
          billingPhone: factura.telefono || "No Provee",
        };

        await walletService.sendPurchaseEmail(
          localStorage.getItem("userId"),
          token,
          correoMWCoins
        );
        handleClose()
        setShowNotificationSuccessTransfer(true)
        break;
      case "subscription":

        //Endpoint restrictivo a envio de monedas
        const amountCoinsSubscripcion = 0
        const precioSubscripcion = selectedProduct.precio
        const createTransaccionSubscripcion = {
          wallet_transaction_type: "Compra",
          wallet_transaction_coins: amountCoinsSubscripcion,
          wallet_transaction_amount: precioSubscripcion,
          wallet_transaction_payment_method: "Transferencia",
          wallet_transaction_origen: "",
          billing: {
            name: factura.nombre,
            ci: factura.id,
            phone: factura.telefono || "No Provee",
            email: factura.correo
          }
        };

        const subscriptionTransaction = await walletService.createtransaction(
          localStorage.getItem("userId"),
          token,
          createTransaccionSubscripcion
        );
        const subscription_transaction_id = subscriptionTransaction.data.wallet_transaction_id;

        try {
          const responseImage = await walletService.uploadTransactionDocument(
            subscription_transaction_id,
            token,
            uploadedFile
          );
          if (responseImage.code === "200") {
            imageUrl = responseImage.imageUrl
          }
        } catch (error) {
          console.log("Error Image Upload:", error)
          imageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCxGQVzKOTP_JnxnF0QVeHzdRe6pxb5Bk0EUSS3atB7Q&s"
        }

        const correoSuscripcion = {
          emailAprobador: emailGeneralAprobador.data[0].wallet_general_value,
          cantidadMWCoins: amountCoinsSubscripcion,
          montoTotal: precioSubscripcion,
          descripcionSolicitud: `Compra de ${selectedProduct.producto} - Transferencia`,
          transaccionId: subscription_transaction_id,
          transferenciaImg: imageUrl,
          origen: "",
          billingMail: factura.correo,
          billingName: factura.nombre,
          billingCi: factura.id,
          billingPhone: factura.telefono || "No Provee",
        };

        await walletService.sendPurchaseEmail(
          localStorage.getItem("userId"),
          token,
          correoSuscripcion
        );
        handleClose()
        setShowNotificationSuccessTransfer(true)
        break;
      default:
        break;
    }
    setLoading(false)

  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleClose = () => {
    dispatchArticulo({ type: "RESET" })
    sessionStorage.removeItem('transactionData')
    dispatchNavPage(setPage(1))
    dispatchView({ type: 'SET_VIEW', payload: "Planes" });
    onClose()
  }

  const renderComponent = React.useMemo(() => {
    if (loading) return null;

    switch (navNumber.actualPage) {
      case 1:
        return (
          <>
            <BilleteraNavComponent
              navOptions={transactionOptions}
            />
            {actualView === "Planes" && (
              <MainSubscriptionPlansComponent
                subscriptionData={subscriptionData}
              />
            )}
            {actualView === "Recargas" && (
              <MainRecargasComponent
                preloadedImage={mwCoin}
                recargasData={recargasData}
              />
            )}
          </>
        );
      case 2:
        return <MainFacturationComponent />;
      case 3:
        return (
          <div className="">
            <BilleteraNavComponent
              navOptions={payOptions}
            />
            <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 ">
              <CompraDetailFacturaComponent />
              {actualView === "Payphone" && <MainPayphoneComponent />}
              {actualView === "Transferencia" && <MainTransferenciaComponent />}
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [loading, recargasData, subscriptionData, navNumber, actualView]);

  //Notificaciones - Sweet Alert
  useEffect(() => {
    showNotification && Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "No se ha seleccionado ningún producto. Selecciona un plan de suscripción o una recarga para continuar.",
      confirmButtonColor: "#011A27",
    }).then((result) => {
      setShowNotification(false)
    })

    showNotificationFieldsWithErrors && Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Por favor, completa la información solicitada para continuar con la transacción.",
      confirmButtonColor: "#011A27",
    }).then((result) => {
      setShowNotificationFieldsWithErrors(false)
    })

    showNotificationSuccessTransfer && Swal.fire({
      title: "Tu transferencia se ha procesado exitosamente.",
      text: "Por favor, espera un momento mientras un revisor valida la información. Tu producto se activará una vez se verifique.",
      icon: "success",
      confirmButtonColor: "#011A27",
    }).then((result) => {
      setShowNotificationSuccessTransfer(false)
    })

    showConfirmation && Swal.fire({
      title: "Confirmación de compra",
      text: ` ¿Está seguro de comprar ${selectedProduct.producto} por 
              $${selectedProduct.precio}?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#011A27",
      cancelButtonColor: "#FF8201",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirm()
      } else (
        handleCancel()
      )
    })

  }, [showNotification, showNotificationFieldsWithErrors, showNotificationSuccessTransfer, showConfirmation, selectedProduct])


  return (
    <>
      {/* Modal Principal */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        className="bg-gray-500 bg-opacity-75 
        flex flex-col items-center justify-center p-4"
      >
        <div className={`bg-white rounded-lg shadow-xl p-2 
        ${navNumber.actualPage === 3 ? "md:w-[800px]" : "w-[400px] md:w-[600px] lg:w-[800px]"} flex flex-col
        overflow-y-auto md:overflow-y-clip 
        `}>
          {/* Modal Header */}
          <div className="w-full flex flex-col md:flex-row items-center justify-between sm:mb-2 md:mb-0">
            <div className="flex flex-row items-center">
              <img
                src={mwLogoMain}
                alt="MatchWorking Logo"
                className="w-20 h-20"
              />
              <h2 className="text-4xl font-medium italic">
                Billetera
              </h2>
            </div>
            {
              navNumber.actualPage === 3 && <>
                <div className="text-3xl font-medium italic text-[#063852]">
                  Método de pago
                </div>
              </>
            }
            <div>
              <div className="text-xl flex flex-row items-center text-right 
                mb-0 font-extrabold">
                <div className="font-normal mr-1">
                  SALDO ACTUAL :
                </div>
                {coinsTotal} MC
              </div>
            </div>
          </div>

          {/**Modal Body */}
          <div className={"flex flex-col md:flex-row items-center "}>
            {
              loading && recargasCoinImageLoaded
                ? (
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <img
                      src={mwLogoMain}
                      alt="MatchWorking Logo"
                      className="w-24 h-24  "
                    />
                    <CircularProgress />
                    <div className="font-light text-2xl mb-2">Cargando/Procesando Información. Por favor, espere.</div>
                  </div>
                )
                : (
                  <>
                    {renderComponent}
                  </>
                )
            }
          </div>
          {/**Footer Modal Section */}
          {
            !loading && recargasCoinImageLoaded && <div className="pt-1">
              <ButtonsComponents
                setShowNotification={setShowNotification}
                setShowNotificationFieldsWithErrors={setShowNotificationFieldsWithErrors}
                setShowConfirmation={setShowConfirmation}
              />
            </div>
          }

        </div>
      </Modal>

      {/* Ver historial */}
      {/* <Modal
        open={showPurchaseHistory}
        onClose={handleClosePurchaseHistory}
        className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4"
      >
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-4xl w-full">
          <h2 className="text-xl font-semibold mb-4">Historial de Compras</h2>
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <div className="overflow-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID transacción
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Monedas actuales
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Monedas solicitadas
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Tipo de transacción
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha de la solicitud
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {purchaseHistory.map((row, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.transaction_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.transaction_coins_before}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.transaccion_coins}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.transaction_origen}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {row.transaction_date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-4">
            <button
              onClick={handleClosePurchaseHistory}
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-md shadow-lg transition duration-300 ease-in-out"
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default ModalWallet;

import { useState, useEffect } from 'react';

const useWindowSize = (breakpoint) => {
    const [state, setState] = useState({
        width: window.innerWidth,
        moreInfo: window.innerWidth >= breakpoint
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setState({
                width,
                moreInfo: width >= breakpoint
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint]);

    return {
        ...state,
        setMoreInfo: (value) => setState(prev => ({ ...prev, moreInfo: value }))
    };
};

export default useWindowSize;
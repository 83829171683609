
import React from 'react'
import FacturationFormDataComponent from './FacturationFormDataComponent'
import CompraDetailComponent from '../CompraDetailSectionComponents/CompraDetailComponent'

const MainFacturationComponent = () => {
    return (
        <div className='grid md:grid-cols-2 
            w-full h-full 
            m-0 px-3 text-center
            '>
            <FacturationFormDataComponent/>
            <CompraDetailComponent/>

        </div>
    )
}

export default MainFacturationComponent
import React, { useCallback } from 'react'
import BottomNavButtonComponent from './BottomNavButtonComponent'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import WhatsappButtonComponent from './WhatsappButtonComponent'
import { useFacturaValidValue, useNavPageDispatch, useNavPageValue, useReadyToProcessBuyValue, useSelectedArticuloValue, useViewDispatch } from '../BilleteraState/BilleteraContext'
import { decrementPage, incrementPage } from '../BilleteraState/NavigationNumberReducer'

const ButtonsComponents = ({
  setShowConfirmation,
  setShowNotification,
  setShowNotificationFieldsWithErrors }) => {
  const selected = useSelectedArticuloValue()
  const {actualPage} = useNavPageValue()
  const dispatchNavActualPage = useNavPageDispatch()
  const isReadyToProcessBuy = useReadyToProcessBuyValue()
  const dispatchView = useViewDispatch()
  const facturaValid = useFacturaValidValue()

  const handleBack = useCallback(() => {
    if (actualPage > 0) {
      dispatchNavActualPage(decrementPage());
      if (actualPage === 2) {
        dispatchView({ type: 'SET_VIEW', payload: "Planes" });
      }
    }
  }, [actualPage,dispatchNavActualPage,dispatchView]);

  const handleNext = useCallback(() => {
    if (actualPage === 1 && !selected.producto) {
      setShowNotification(true);
      return;
    }
    if (actualPage === 2) {
      if(!facturaValid){
        setShowNotificationFieldsWithErrors(true)
        return;
      }else{
        dispatchView({ type: 'SET_VIEW', payload: "Payphone" });
      }
    }
    if (actualPage < 3) {
      dispatchNavActualPage(incrementPage());
    }

    if (actualPage === 3) {
      if (isReadyToProcessBuy) {
        setShowConfirmation(true);
      } else {
        setShowNotificationFieldsWithErrors(true);
      }
    }
  }, [
    actualPage,
    selected,
    dispatchNavActualPage,
    setShowConfirmation,
    setShowNotification,
    setShowNotificationFieldsWithErrors,
    isReadyToProcessBuy,
    dispatchView,
    facturaValid
  ]);

  return (
    <div className='flex flex-row items-center justify-between mt-1'>
      <BottomNavButtonComponent
        text={"Anterior"}
        icon={<ArrowBackIos />}
        actualPage={actualPage}
        handleClick={handleBack}
      />
      <WhatsappButtonComponent />
      <BottomNavButtonComponent
        text={"Continuar"}
        icon={<ArrowForwardIos />}
        actualPage={actualPage}
        handleClick={handleNext}
      />
    </div>
  )
}

export default ButtonsComponents

export const FileUploadReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_FILE':
            state = action.payload
            return state
        case 'REMOVE_FILE':
            state = null
            return state
        default:
            return state;
    }
};


import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import walletListDataService from '../../services/walletListDataService';
import walletService from '../../services/walletService';
import './listDataAdmin.css';

const ServiceParamForm = () => {
  const [listGeneralParam, setListGeneralParam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('add'); // 'add' o 'edit'
  const [selectedRow, setSelectedRow] = useState(null);
  const [tokenUser, setTokenUser] = useState("");

  const [newGeneralParams, setNewGeneralParams] = useState({
    name: '',
    price: '',
    currency: '',
    state: 'A',
    type: '',
    promotional_price: ''
  });
  const options = [
    { value: 'A', label: 'Activo' },
    { value: 'I', label: 'Inactivo' },
  ];

  const loadToken = async () => {
    const data = await walletService.getToken();
    return data.token;
  }
  const fetchData = async (token) => {
    try {
      /*const responseExample =[
          {                          
            "wallet_service_name":"Generador de Perfiles",
            "wallet_service_price":10,
            "wallet_service_currency":"MW Coin",
            "wallet_service_state":"A",
            "wallet_service_creationDate":"2024-04-17T00:48:49.791+00:00",
            "wallet_service_updateDate":"2024-04-17T00:48:49.791+00:00",
            "wallet_service_id":1
          }
      ]*/

      const allServicesParams = (await walletListDataService.getAllServices(token)).data;
      setListGeneralParam(
        allServicesParams.map((item) => ({
          id: item.wallet_service_id,
          name: item.wallet_service_name,
          price: item.wallet_service_price,
          currency: item.wallet_service_currency,
          state: item.wallet_service_state,
          type: item.wallet_service_type,
          promotional_price: item.wallet_service_promotional_price,
          creationDate: item.wallet_service_creationDate,
          updateDate: item.wallet_service_updateDate
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const token = await loadToken();
      setTokenUser(token);
      fetchData(token);
    };

    fetch();
  }, []);

  const handleEdit = (row) => {
    // Configura el modal para la edición
    setModalAction('edit');
    setSelectedRow(row);
    setNewGeneralParams({
      name: row.name,
      price: row.price,
      currency: row.currency,
      state: row.state,
      type: row.type,
      promotional_price: row.promotional_price
    });
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    // Configura el modal para agregar
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      name: '',
      price: '',
      currency: '',
      state: 'A',
      type: '',
      promotional_price: ''
    });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Cierra el modal y reinicia los campos del formulario
    setIsModalOpen(false);
    setModalAction('add');
    setSelectedRow(null);
    setNewGeneralParams({
      name: '',
      price: '',
      currency: '',
      state: 'A',
      type: '',
      promotional_price: ''
    });
  };

  const handleSave = () => {
    if (modalAction === 'add') {
      //console.log('Agregar elemento:', newGeneralParams);
      const newServiceParam = {
        "wallet_service_name": newGeneralParams.name,
        "wallet_service_price": newGeneralParams.price,
        "wallet_service_currency": newGeneralParams.currency,
        "wallet_service_state": newGeneralParams.state,
        "wallet_service_type": newGeneralParams.type,
        "wallet_service_promotional_price": newGeneralParams.promotional_price
      };

      walletListDataService.createService(tokenUser, newServiceParam)
        .then(async data => {
          //console.log("Creado ",data);
          fetchData(tokenUser);
        })
        .catch(error => {
          console.error('Error :', error);
        });

    } else if (modalAction === 'edit') {
      const newServiceParam = {
        "wallet_service_name": newGeneralParams.name,
        "wallet_service_price": newGeneralParams.price,
        "wallet_service_currency": newGeneralParams.currency,
        "wallet_service_state": newGeneralParams.state,
        "wallet_service_type": newGeneralParams.type,
        "wallet_service_promotional_price": newGeneralParams.promotional_price
      };
      //console.log('Editar elemento:', selectedRow.id, newGeneralParams);
      walletListDataService.editService(selectedRow.id, tokenUser, newServiceParam)
        .then(async data => {
          //console.log("Editado ",data);
          fetchData(tokenUser);
        })
        .catch(error => {
          console.error('Error :', error);
        });

    }

    // Cierra el modal y reinicia los campos del formulario
    handleModalClose();
    fetchData(tokenUser);
  };

  return (
    <div>
      <div className="d-flex justify-content-center mt-3">
        <button className="bg-[#F0810F]  w-fit mb-2 text-white px-4 py-2 rounded-lg" onClick={handleAdd}>Agregar</button>
      </div>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div className="overflow-x-auto mt-4">
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">Nombre</th>
                <th className="border px-4 py-2">Precio General</th>
                <th className="border px-4 py-2">Moneda</th>
                <th className="border px-4 py-2">Estado</th>
                <th className="border px-4 py-2">Tipo de Suscripción</th>
                <th className="border px-4 py-2">Precio Promocional</th>
                <th className="border px-4 py-2">Fecha de creación</th>
                <th className="border px-4 py-2">Fecha de actualización</th>
                <th className="border px-4 py-2">Editar</th>
              </tr>
            </thead>
            <tbody>
              {listGeneralParam.map((row) => (
                <tr key={row.id} className="bg-gray-100">
                  <td className="border px-4 py-2">{row.id}</td>
                  <td className="border px-4 py-2">{row.name}</td>
                  <td className="border px-4 py-2">{row.price}</td>
                  <td className="border px-4 py-2">{row.currency}</td>
                  <td className="border px-4 py-2">{row.state}</td>
                  <td className="border px-4 py-2">{row.type}</td>
                  <td className="border px-4 py-2">{row.promotional_price}</td>
                  <td className="border px-4 py-2">{row.creationDate}</td>
                  <td className="border px-4 py-2">{row.updateDate}</td>
                  <td className="border px-4 py-2">
                    <button onClick={() => handleEdit(row)}><i className="fa-solid fa-pen-to-square flex justify-center"></i></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal para agregar/editar */}
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        contentLabel={modalAction === 'add' ? 'Agregar Nuevo Parametro' : 'Editar Parametro'}
        className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto"
      >
        <h2 className="text-lg font-semibold mb-4">{modalAction === 'add' ? 'Agregar Nuevo Parametro' : 'Editar Parametro'}</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Nombre:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="name"
              value={newGeneralParams.name}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, name: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Precio General:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="price"
              value={newGeneralParams.price}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, price: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nivel" className="block text-sm font-bold mb-2">Moneda:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="currency"
              value={newGeneralParams.currency}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, currency: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-bold mb-2">Estado:</label>
            <select
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              value={newGeneralParams.state}
              onChange={(e) =>
                setNewGeneralParams({
                  ...newGeneralParams,
                  state: e.target.value,
                })
              }
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="type" className="block text-sm font-bold mb-2">Tipo de Suscripción:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="type"
              value={newGeneralParams.type}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, type: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="promotional_price" className="block text-sm font-bold mb-2">Precio Promocional:</label>
            <input
              type="text"
              className="block w-full border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:border-blue-500"
              id="promotional_price"
              value={newGeneralParams.promotional_price}
              onChange={(e) => setNewGeneralParams({ ...newGeneralParams, promotional_price: e.target.value })}
            />
          </div>



          {/* Agrega otros campos según tus necesidades */}
          <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>
            {modalAction === 'add' ? 'Agregar' : 'Guardar Cambios'}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default ServiceParamForm;

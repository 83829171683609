import { useState } from 'react';
import satisfactionSurveyService from '../services/satisfactionSurveyService';

const SatisfactionSurvey = (data) => {
    const [qualification, setQualification] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const handleMouseEnter = (rating) => {
        setHoverRating(rating);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const handleClick = (rating) => {
        setQualification(rating);
    };

    const handleSubmit = () => {
        // Envío de calificación
        const rating = {
            _id: data.data._id || "00000000",
            user_names:data.data.user_names || "Anónimo",
            user_lastnames:data.data.user_names || "Anónimo",            
            user_ID:data.data.user_ID || data.data
        }
        console.log('rating', rating)
        const surveyData = { serviceName: window.location.href, serviceRating: qualification, user_Rating: rating }
        // console.log('Data:', surveyData);
        const survey = async () => {
            try {
                const dataSurvey = await satisfactionSurveyService.createSurvey(surveyData);
                // console.log(dataSurvey);
            } catch (error) {
                console.error("Error al calificar el servicio", error);
            }
        };
        survey();

        setIsVisible(false);
    };
    const handleClose = () => {
        setIsVisible(false); // Ocultar el componente al hacer clic en el botón de "Calificar"
    };

    if (!isVisible) {
        return null; // No renderizar el componente si no está visible
    }


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
    <div className="bg-white p-8 rounded-xl shadow-2xl max-w-sm w-full">
        <h2 className="text-2xl text-center text-gray-800 font-extrabold mb-6">Califica nuestro servicio</h2>
        
        <div className="flex items-center justify-center space-x-2 mb-6">
            {[1, 2, 3, 4, 5].map((rating) => (
                <button
                    key={rating}
                    onClick={() => handleClick(rating)}
                    onMouseEnter={() => handleMouseEnter(rating)}
                    onMouseLeave={handleMouseLeave}
                    className={`text-4xl transition-transform duration-200 transform hover:scale-125 ${rating <= qualification || rating <= hoverRating ? 'text-yellow-400' : 'text-gray-300'}`}
                >
                    {rating <= qualification || rating <= hoverRating ? '⭐' : '✩'}
                </button>
            ))}
        </div>

        <div className="text-center">
            <button
                onClick={handleSubmit}
                disabled={qualification === 0}
                className={`mt-6 w-full py-3 rounded-lg text-lg font-semibold text-white transition-colors duration-300 ${qualification === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#063852] hover:bg-[#063752a7]'}`}
            >
                Calificar
            </button>
        </div>
    </div>
</div>


    );
};

export default SatisfactionSurvey;

import { Link } from 'react-router-dom';
import matchWorkingLogo from '../../assets/img/LOGOMW.png';

export const ClosingApplication = () => {
    const texts = {
        title: "🚨 Cierre de postulación 🚨",
        subtitle: "Esta vacante ya no se encuentra disponible  :(",
        message: "Lo sentimos esta vacante ya no se encuentra disponible, pero puedes consultar más vacantes disponibles para ti en nuestra página de vacantes",
        farewellMessage: "Te invitamos a estar atento/a a futuras oportunidades en nuestra empresa. ¡Gracias por tu interés y éxito en tu camino profesional!",
        observations: "Para más información, contáctanos a:",
        button: "Consulta otras vacantes"
    }
    return (
        <div className="flex flex-col items-center justify-center bg-gray-100 min-h-screen">
            <div className="relative mt-5 sm:mt-0">
                <div className="w-8 h-8 bg-[#011A27] rounded-full shadow-md"></div>
            </div>
            <div className="relative flex justify-center">
                <div className="absolute w-0.5 sm:w-1 h-28 sm:h-40 bg-black transform rotate-45 origin-top-left"></div>
                <div className="absolute w-0.5 sm:w-1 h-28 sm:h-40 bg-black transform -rotate-45 origin-top-right"></div>
            </div>
            <div className="mt-20 sm:mt-28 z-10 w-[80%] sm:w-[30%] md:w-[30%] lg:[30%] bg-orange-500 text-white text-center rounded-3xl shadow-md px-12 py-4">
                <p className="text-2xl md:text-5xl font-bold">{texts.subtitle}</p>
            </div>
            <p className="mt-6 text-center text-1xl sm:text-4xl text-[#011A27] w-[80%]">
                {texts.message}
            </p>

            <Link to="https://match-working.com/vacantes-disponibles/" className='w-[80%] sm:w-[30%]'>
                <button
                    className="mt-6 w-full py-2 bg-[#063852] text-base sm:text-3xl font-medium text-white rounded-full hover:bg-[#011A27]">
                    {texts.button}
                </button>
            </Link>
            <div className="ml-auto">
                <img
                    src={matchWorkingLogo}
                    alt="Logo"
                    className="w-36 sm:w-60"
                />
            </div>
        </div>
    );
}
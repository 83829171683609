import axios from 'axios';
import apiClient from '../utilities/interceptorConfig';
//LOGIN = /login  & /authenticate
const login = "https://edk24lzi2l.execute-api.sa-east-1.amazonaws.com/api/v2/internal"

//PROFILE = /create & /{profile_id} & /update/{profile_id}
const profile = "https://94znuaryt4.execute-api.sa-east-1.amazonaws.com/api/v2/internal/profile"

//ANALYSIS = /create & /search/{analysis_id}  &  /search/profile/{profileID}  &  /search/{profile_id}/{candidate_id}
const analysis = "https://hyth5spef5.execute-api.sa-east-1.amazonaws.com/api/v2/internal/analizer/analysis"

//CV  = /candidate/create  &  /candidate/{candidate_id}  &  /candidates/profile/{candidate_job_position_profile}
const candidate = "https://cxe1kxs38l.execute-api.sa-east-1.amazonaws.com/api/v2/internal/analizer"

//CV without jobID
const candidateWOjobid = "https://xquscx548g.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/candidate/create"

const lambdaLogin = {
    newUser: async (body) => {
        try {
            const response = await axios.post(`${login}/login`, body);
            return response;
        } catch (error) {
            console.error('Error al crear usuario:', error);
        }
    },
    authToken: async (body) => {
        try {
            const response = await axios.post(`${login}/authenticate`, body);            
            return response;
        } catch (error) {
            console.error('Error al generar token:', error);
        }
    },

};

const lambdaProfile = {
    create: async (body) => {
        try {
            const response = await apiClient.post(`${profile}/create`, body);
            return response.data;
        } catch (error) {
            console.error('Error al crear perfil:', error);
        }
    },
    getById: async (id) => {
        try {
            const response = await apiClient.get(`${profile}/${id}`);
            if (response.data) {                
                return response;
            }
        } catch (error) {
            console.error('Error al obtener perfil:', error);
            throw error;
        }
    },
    put: async (id, body) => {
        try {
            const response = await apiClient.put(`${profile}/update/${id}`, body);
            return response;
        } catch (error) {
            console.error('Error al crear perfil:', error);
        }
    },

};

const lambdaAnalysis = {
    create: async (body) => {
        try {
            const response = await apiClient.post(`${analysis}/create`, body);
            return response;
        } catch (error) {
            console.error('Error al crear documento:', error);
        }
    },
    getById: async (id) => {
        try {
            const response = await apiClient.get(`${analysis}/search/${id}`);
            return response;
        } catch (error) {
            console.error('Error al obtener documento:', error);
            throw error;
        }
    },
    getByProfile: async (id) => {
        try {
            const response = await apiClient.get(`${analysis}/search/profile/${id}`);
            if (response.data) {                
                return response;
            }
        } catch (error) {
            console.error('Error al obtener documento:', error);            
        }
    },
    getByProfileCandidate: async (profileId, candidateId) => {
        try {
            const response = await apiClient.get(`${analysis}/search/${profileId}/${candidateId}`);
            return response;
        } catch (error) {
            console.error('Error al obtener documento:', error);
            throw error;
        }
    },
    generate: async (body) => {
        try {
            const response = await apiClient.post(`${analysis}/generate/cv`, body);
            return response;
        } catch (error) {
            console.error('Error al obtener documento:', error);
        }
    },

};

const lambdaCandidate = {
    create: async (body) => {
        try {
            const response = await apiClient.post(`${candidate}/candidate/create`, body);
            return response.data;
        } catch (error) {
            console.error('Error al crear el candidato:', error);
        }
    },
    getById: async (id) => {
        try {
            const response = await apiClient.get(`${candidate}/candidate/${id}`);
            return response.data.data;
        } catch (error) {
            console.error('Error al obtener candidato:', error);
            throw error;
        }
    },
    getByProfile: async (id) => {
        try {
            const response = await apiClient.get(`${candidate}/candidates/profile/${id}`);
            return response;
        } catch (error) {
            console.error('Error al obtener documento:', error);
            throw error;
        }
    },
    createWOjobId: async (body) => {
        try {
            const response = await apiClient.post(candidateWOjobid, body);
            return response;
        } catch (error) {
            console.error('Error al crear el candidato:', error);
        }
    },
};
export {
    lambdaLogin,
    lambdaProfile,
    lambdaAnalysis,
    lambdaCandidate
};
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl;//+ "/companies";

async function getUserToCompanyByUserId(userId) {
    try {
        const response = await fetch(`${api_enviroment_dev}/company-registrations/${userId}`);
    /*  if (!response.success) {
            throw new Error('Error al obtener la información de empresas por usuario ID');
        }*/
       
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error al obtener la información de empresa por usuario ID2:', error);
        throw error; // Lanza el error para que el llamador lo maneje
    }
}

async function postUserToCompanyByUserId(data){
    try {
        const response = await fetch(`${api_enviroment_dev}/company-registrations/`,{
            method: 'POST',
            headers: {  
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
          return response.json();
    } catch (error) {
        console.error('Error al obtener la información de empresas por usuario ID:', error);
        throw error; // Lanza el error para que el llamador lo maneje
    }
    

}

export {getUserToCompanyByUserId, postUserToCompanyByUserId};
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.walletUrl + "/list";

const walletListDataService = {
    /*******   COINS    **** */
    async createCoin(token, data) {
        const response = await fetch(`${api_enviroment_dev}/coins`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getAllCoins(token) {
        const response = await fetch(`${api_enviroment_dev}/coins/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async getCoinById(id, token) {
        const response = await fetch(`${api_enviroment_dev}/coins/by/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async editCoin(id, token, data) {
        const response = await fetch(`${api_enviroment_dev}/coins/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    /*******   SERVICE    **** */
    async createService(token, data) {
        const response = await fetch(`${api_enviroment_dev}/services`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getAllServices(token) {
        const response = await fetch(`${api_enviroment_dev}/services/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async editService(id, token, data) {
        const response = await fetch(`${api_enviroment_dev}/services/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    /*******   DISCOUNT TYPE    **** */
    async createDiscount(token, data) {
        const response = await fetch(`${api_enviroment_dev}/discounts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getAllDiscounts(token) {
        const response = await fetch(`${api_enviroment_dev}/discounts/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async editDiscount(id, token, data) {
        const response = await fetch(`${api_enviroment_dev}/discounts/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    /*******   GENERAL    **** */
    async createGeneral(token, data) {
        const response = await fetch(`${api_enviroment_dev}/general`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getAllGenerals(token) {
        const response = await fetch(`${api_enviroment_dev}/general/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async getGeneralById(id, token) {
        const response = await fetch(`${api_enviroment_dev}/general/by/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    },

    async editGeneral(id, token, data) {
        const response = await fetch(`${api_enviroment_dev}/general/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getPlanes(token) {
        const response = await fetch(`${api_enviroment_dev}/services/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json()
        const returnData = await data.data.filter(d=>d.wallet_service_type === 'subscription') || []
        return returnData
    },

    async getRecargasValues(token) {
        const response = await fetch(`${api_enviroment_dev}/general/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json()
        const returnData = data.data.filter(d=>d.wallet_general_name === 'MW Coins Recomendadas') || []
        return returnData
        
    }

};

export default walletListDataService;
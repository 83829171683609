import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCog,
  faSignOutAlt,
  faAlignJustify,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from ".././VariablesContext";
import logo from "../components/assets/img/logoMatchworkingSinFondo.png";
import backgroundHeader from "../assets/img/fondomw.png";

import CarWallet from "./carWallet";
import userService from "../services/userService";
import { getUserToCompanyByUserId } from "../services/companyUserValidationService";
import mwServices from "../services/mw_servicesS";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [dataUser, setDataUser] = useState("");
  const [services, setServices] = useState([]);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { setLoggedInUserId } = useUser();
  const { setLoggedGuardian } = useUser();
  const { userId } = useUser();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setServicesMenuOpen(false);
  };
  
  const servicesToggleMenu = () => {
    setServicesMenuOpen(!servicesMenuOpen);
    setIsMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setServicesMenuOpen(false);
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      navigate("/");
    } else if (userId !== null) {
      const fetchUserData = async () => {
        try {
          const dataUser = await userService.getUserId(userId);
          setDataUser(dataUser.user_names);
        } catch (error) {
          console.error("Error al obtener los datos del usuario", error);
        }
      };
      fetchUserData();
    }

    const getServices = async () => {
      try {
        const data = await mwServices.getByState();
        setServices(data);
      } catch (error) {
        console.error("Error al obtener los datos del usuario", error);
      }
    };
    userId && getServices();
  }, [navigate, userId]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const LogOut = () => {
    setLoggedInUserId("");
    setLoggedGuardian(false);
    localStorage.removeItem("userId");
    navigate("/");
  };

  function userProfile() {
    navigate("/user-profile");
  }

  function userHistory() {
    navigate("/history-page");
  }

  const onclickFormCompany = async () => {
    const userToCompany = await getUserToCompanyByUserId(userId);
    if (userToCompany.success) {
      navigate("/requirement-page");
    } else {
      navigate("/company-page");
    }
  };

  return (
    <header
      className="fixed top-0 left-0 right-0 w-full z-50 px-4 md:px-10 flex justify-between items-center rounded-lg shadow-lg bg-white/30 backdrop-blur-lg"
      style={{ backgroundImage: `url(${backgroundHeader})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <Link to="/profile-form">
        <img className="h-20" src={logo} alt="Logo-MatchWorking" />
      </Link>
      
      <div className="step-3">
        <CarWallet />
      </div>
      
      <div className="relative">
        <div className="flex items-center">
          <span className="hidden md:inline m-2 md:m-4 font-bold text-lg md:text-2xl">
            {dataUser || ""}
          </span>
          <button className="step-4 focus:outline-none pr-2" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faUser} size="lg" className="h-5 md:h-7" />
          </button>
          <div className="m-1 pl-3 md:m-2 border-l-2 border-gray-900">
            <FontAwesomeIcon
              icon={faAlignJustify}
              size="lg"
              className="h-5 md:h-7 cursor-pointer"
              onClick={servicesToggleMenu}
            />
            {servicesMenuOpen && (
              <div ref={menuRef} className="absolute right-0 mt-2 w-48 md:w-52 font-semibold bg-slate-100 rounded-lg shadow-lg">
                <ul>
                  {services.map((item, index) => (
                    <li key={index} className="border-t border-gray-700 pr-4">
                      <button
                        className="text-start text-blue-950 pt-2 md:py-1 text-sm md:text-lg"
                        onClick={() => {
                          if (item.mw_services_ID === 2) {
                            onclickFormCompany();
                          } else {
                            navigate(item.mw_services_slug);
                          }
                        }}
                      >
                        {item.mw_services_name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {isMenuOpen && (
          <div ref={menuRef} className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg">
            <ul>
              <li className="border-b border-gray-700">
                <button onClick={userProfile} className="block py-2 text-blue-950">
                  <FontAwesomeIcon icon={faUser} className="mr-2 h-5" />
                  Mi Cuenta
                </button>
              </li>
              <li className="border-b border-gray-700 text-blue-950">
                <button onClick={userHistory} className="block py-2">
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                  Mi historial
                </button>
              </li>
              <li>
                <button onClick={LogOut} className="block py-2 text-red-700 focus:outline-none">
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "";

const suscriptionService = {

    async addSuscriptionPlan(
        userId,
        data
    ) {
        const response = await fetch(`${api_enviroment_dev}/user/${userId}/suscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    async getSuscriptionPlan(
        userId
    ) {
        const response = await fetch(`${api_enviroment_dev}/user/${userId}/suscription`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json();
    },


};

export default suscriptionService;

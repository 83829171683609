const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev=enviroment_dev.iaBaseUrl;



const analizeService = {
  
  // Método para obtener analisis del perfiñ
  async getAnalize(data) {
    const response = await fetch(`${api_enviroment_dev}/${"analizador/perfil"}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },


};

export default analizeService;

import { FactCheck, LocationCity, Person, School } from '@mui/icons-material';

import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { lambdaProfile } from '../../enviroment/internal-lambdas'
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';
import listDataService from '../../services/listDataService';
import { useUser } from '../../VariablesContext';
import { getUserToCompanyByUserId } from '../../services/companyUserValidationService';
import adService from '../../services/profileService';
import showAlert from '../../utilities/alerts';
import JobProfile from './PerfilCargoFormEdit';
import walletService from '../../services/walletService';
import walletFunctions from '../../utilities/wallet-function';
import userService from '../../services/userService';
import suscriptionService from '../../services/suscriptionService';
import companyService from '../../services/companyService';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export const PerfilCargoFormComponent = ({ setPage, setProfile, id, setProfileUpdate, nextStep }) => {
    const navigate = useNavigate();
    const { userId } = useUser();
    const [userData, setUserData] = useState("");
    const [listCompany, setListCompany] = useState([]);
    const [cities, setCities] = useState([]);    
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [suggest, setSuggest] = useState(false);
    const [listEducationLevel, setListEducationLevel] = useState([]);    
    const [profileData, setProfileData] = useState(null);    
    const [suggestData, setSuggestData] = useState({
        Ciudad: "",
        request_PositionToHire: "",
        tamañoEmpresa: "",
        educationLevel: "",
        tiempoExperiencia: "",
        company_ID: ""
    });    
    const [employees, setEmployees] = useState('');
    const [suggestProfile, setSuggestProfile] = useState("");
    const [tokenUser, setTokenUser] = useState("");
    const location = useLocation().pathname;
    useEffect(() => {
        const fetchCountries = async () => {            
            try {
              await axios.post("https://countriesnow.space/api/v0.1/countries/cities",{country:"Ecuador"})
                .then(response => {
                    if (response.data.data) {
                        setCities(response.data.data);                                       
                      } else {
                        setCities([]);
                      }
                }).catch(error => {
                    console.error("Error:", error)                    
                })
            } catch (error) {
              console.error("Error fetching cities:", error);
            }
          };
               
        //education level
        const allEducationLevels = async () => {
            const education = await listDataService.getAllEducationLevelByState("A");
            setListEducationLevel(
                education.map(item => item.educationLevel_content)
            );
        }    
        if (userId) {
            const userCompany = async () => {
                Swal.fire({
                    title: "Cargando....",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const userToCompany = await getUserToCompanyByUserId(userId);
                if (userToCompany.success) {
                    const companyDetailsArray = userToCompany.companyDetailsArray;
                    const listCompanyArray = companyDetailsArray.map((company) => {
                        return {
                            company_ID: company.company_ID,
                            company_name: company.company_tradename,
                        };
                    });
                    // console.log('companies', listCompanyArray)
                    setListCompany(listCompanyArray);
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Sin empresas registradas',
                        text: 'Si aún no ha registrado una empresa, registre una ahora..',
                    });


                }

            }
            userCompany();
        }
        fetchCountries()
        allEducationLevels()
    }, [userId]);

    useEffect(() => {
        if (id) {
            modifyProfile(id)
        }
        const fetchUserData = async () => {
            try {
                const user = await userService.getUserId(userId);
                // console.log('datauser', user);
                // Actualiza el estado con los datos del usuario
                setUserData(user);
            } catch (error) {
                console.error("Error al obtener los datos del usuario", error);
            }
        };
        // Llama a la función para obtener los datos del usuario

        const loadToken = async () => {
            const data = await walletService.getToken();
            return data.token;
        }

        const fetchData = async () => {
            const token = await loadToken();
            // loadTotalCoinsByUser(token);
            setTokenUser(token);
        };
        fetchData();
        fetchUserData();
    }, [userId]);

    const handleChangeSuggest = (e) => {
        const { name, value } = e.target;
        setSuggestData({
            ...suggestData,
            [name]: value
        });        
    };

    const handleSelectSuggest = (selectedOption, actionMeta) => {        
        setSuggestData({
            ...suggestData,
            [actionMeta.name]: selectedOption ? selectedOption.value : null,
        });                
    };

    const validateSuggest = () => {
        const { 
            request_PositionToHire, 
            tiempoExperiencia, 
            Ciudad, 
            educationLevel, 
            company_ID 
        } = suggestData;
    
        const fields = [
            { key: 'empresa', value: company_ID, message: 'Escoger una empresa es requerido' },
            { key: 'puesto', value: request_PositionToHire, message: 'El puesto o cargo es requerido' },
            { key: 'tiempoExperiencia', value: tiempoExperiencia, message: 'El tiempo de experiencia es requerida' },
            { key: 'education', value: educationLevel, message: 'Escoger un nivel de educación es requerido' },
            { key: 'ciudad', value: Ciudad, message: 'La ciudad de la ubicación es requerida' },
        ];
        
        let newErrors = {};
    
        for (let field of fields) {
            if (!field.value) {
                newErrors[field.key] = field.message;
                setErrors(newErrors);
                return false; 
            }
        }
    
        setErrors({});  
        return true;
    };    
    useEffect(() => {
        validateSuggest();
    }, [suggestData]); 

    const optionsCompany = listCompany.map((company) => ({
        value: company.company_ID,
        label: company.company_name,
    }));
    const handleSubmit = (e) => {
        e.preventDefault();        

        /**
         * TODO: 
           profile_createdAt, 
           profile_updatedAt, 
           profile_companyID, 
           profile_date, 
           profile_hiringReason, 
           profile_employmentType
           profile_supervisorName, 
           profile_supervisorPosition, 
           profile_workMode,
           profile_sector
           profile_companyRange,
           profile_salaryOffer,
           profile_companyBenefits,
           profile_objectivePosition,
           profile_observations
         */
        setLoading(true);

        const sendData = {
            "profile_user_id": userId + '',
            "profile_mission": suggestProfile.mission,
            "profile_experience": {
                "profile_timeExperience": suggestProfile.requirements.experienceTime,
                "profile_detailOfExperience": suggestProfile.requirements.experienceDetail
            },
            "profile_education": suggestProfile.education,
            "profile_positionToHire": suggestProfile.position.name,
            "profile_responsibilities": {
                "profile_listOfResponsibilities": suggestProfile.responsibilities
            },
            "profile_workLocation": {
                "profile_country": suggestProfile.position.country,
                "profile_city": suggestProfile.position.city
            },
            "profile_department": suggestProfile.position.department,
            "profile_createdAt": "",
            "profile_updatedAt": "",
            "profile_companyID": suggestData.company_ID,
            "profile_date": "",
            "profile_hiringReason": "Vacante libre", //Expansión del equipo**
            "profile_employmentType": suggestProfile.workingConditions.contract,
            "profile_supervisorName": "Nombre del Supervisor Inmediato",
            "profile_supervisorPosition": suggestProfile.position.chief,
            "profile_workMode": suggestProfile.workingConditions.modality,
            "profile_sector": "",
            "profile_companyRange": employees, //# de empleados
            "profile_salaryOffer": suggestProfile.workingConditions.salary,
            "profile_companyBenefits": suggestProfile.benefits,
            "profile_companyTools": suggestProfile.tools,
            "profile_objectivePosition": suggestProfile.position.name,
            "profile_competencies": {
                "technicalSkills": suggestProfile.technicalSkills,
                "personalSkills": suggestProfile.personalSkills
            },
            "profile_courses": suggestProfile.courses,
            "profile_observations": suggestProfile.languages.language,
        }
        if (location !== '/requirement-page') {
            setProfile(sendData) // envia datos a padre            
            setPage(2);
            setSubmitted(true);
            setLoading(false);
        } else {
            nextStep()
            setProfile(sendData)
        }
    };

    function getCategory(key) {
        const employeeCategories = {
            one_Employee: "emprendimiento",
            one_to_nine_Employees: "microempresa",
            ten_to_fortyNine_Employees: "pequeña empresa",
            fifty_to_oneHundredNintyNine_Employees: "mediana empresa",
            more_twoHundred_Employees: "gran empresa"
        };
        return employeeCategories[key] || "Mediana empresa";
    }

    const handleSuggest = async (e) => {
        e.preventDefault();
        const companyemployees = await companyService.getCompanyId(suggestData.company_ID) //obtiene datos de compania                   
        setEmployees(companyemployees.company_number_employees)
        const category = getCategory(companyemployees.company_number_employees);
        if (validateSuggest()) {
            let data = {
                Ciudad: suggestData.Ciudad,
                request_PositionToHire: suggestData.request_PositionToHire,
                tamañoEmpresa: category,
                estudiosRequeridos: suggestData.educationLevel,
                tiempoExperiencia: suggestData.tiempoExperiencia
            }
            payment(data) // Pago de servicio y generacion de Perfil de Cargo con Ia
            console.log('se hizo las sugerencias')
        }

    }

    function cleanJsonString(input) {
        return input
            .replace(/```json/g, "")
            .replace(/```/g, "")
            .replace(/\\\\"/g, "\"")
            .replace(/\\\\n/g, "")
            .replace(/\\"/g, "\"")
            .replace(/\\n/g, "")
            .replace(/\\\"/g, "\"");
    }

    function convertCamelCase(input) {
        const words = input.split(' '); // Dividir la cadena en palabras
        const joinwords = words[0].toLowerCase() + words[1]; // Convertir la primera palabra a minúscula
        return joinwords
    }

    const generateSuggestion = async (data) => {
        let attempts = 0;
        const maxAttempts = 3;
        while (attempts < maxAttempts) {
            try {
                Swal.fire({
                    title: "Generando perfil de cargo...",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const res = await adService.getProfileData(data);
                // console.log(res);
                if (res) {
                    const suggestProfileData = {
                        experience: res.experiencia ? JSON.parse(res.experiencia) : null,
                        responsibilities: res.responsabilidades ? JSON.parse(res.responsabilidades) : null,
                        suggestions: res.sugerencias ? JSON.parse(cleanJsonString(res.sugerencias)) : null,
                        mission: res.mision || null,
                        competencies: res.competencias ? JSON.parse(cleanJsonString(res.competencias)) : null
                    };
                    console.log(suggestProfileData);
                    setProfileData(suggestProfileData);
                    setSuggest(true);
                    Swal.close();
                    break;

                }
            } catch (e) {
                attempts++;
                console.error(` Intento ${attempts} failed: Volviendo a generar:`, e);
                if (attempts >= maxAttempts) {
                    showAlert.error("Ocurrió un error al generar el perfil de cargo, vuelve a intentarlo");
                    Swal.close();
                }
            }
        }
    }

    const payment = async (data) => {
        try {
            const { currentSuscription } = await suscriptionService.getSuscriptionPlan(userId)
            if (currentSuscription.name) {
                generateSuggestion(data)
            } else {
                await walletFunctions.servicePayment("1", tokenUser, userData) // ID del servicio con inf de pago, token, userData(para notificacion)
                    .then(async (response) => {
                        if (response) {
                            generateSuggestion(data)
                        }
                    })
            }
        } catch (error) {
            console.error("Error:", error);
            showAlert.error(
                "Ocurrió un error en la operación. Por favor, vuelve a intentarlo más tarde."
            );
        }
    }

    const modifyProfile = async (profile_id) => {
        console.log(profile_id);
        Swal.fire({
            title: "Cargando perfil de cargo",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        try {
            const response = await lambdaProfile.getById(profile_id);
            // const response = await axios.get(`${lambdaProfile}/${profile_id}`);            
            const resData = response.data.data
            const modifiedProfile = {
                experience: {
                    'timeExperience': resData.profile_experience.profile_timeExperience,
                    'detailOfExperience': resData.profile_experience.profile_detailOfExperience
                },
                responsibilities: { 'listaResponsabilidades': resData.profile_responsibilities.profile_listOfResponsibilities },
                suggestions: {
                    'beneficios': resData.profile_companyBenefits,
                    'cargoJefe': resData.profile_supervisorPosition,
                    'contrato': resData.profile_employmentType,
                    'cursos': resData.profile_courses,
                    'departamento': resData.profile_department,
                    'estudiosRequeridos': { [convertCamelCase(resData.profile_education.level)]: resData.profile_education.title }, //level:TercerNivel, title:Licenctiatura
                    'herramientas': resData.profile_companyTools,
                    'idiomas': {
                        'idioma': resData.profile_observations,
                        'nivel': 'Intermedio'
                    },
                    'modalidad': resData.profile_workMode,
                    'nombreCargo': resData.profile_positionToHire,
                    'salarioReferencial': resData.profile_salaryOffer,
                    'ubicacion': { 'ciudad': resData.profile_workLocation.profile_city }
                },
                mission: resData.profile_mission,
                competencies: {
                    'competenciasTecnicas': resData.profile_competencies.personalSkills,
                    'competenciasPersonales': resData.profile_competencies.technicalSkills
                }
            };
            setProfileData(modifiedProfile)            
            setProfileUpdate(true);
            setSuggest(true);
            Swal.close();
        } catch (error) {
            showAlert.error("Ocurrió un error al mostrar el perfil de cargo.");
            Swal.close();
        }

    }
    return (
        <>
            {!suggest ? (
                <div className="font-sans rounded-md flex justify-center mx-auto bg-[#011A27] shadow-sm text-white sm:w-full md:w-3/5 text-wrap break-words p-6 ">

<form onSubmit={handleSuggest} className="flex flex-col items-left bg-[#011A27] text-white p-6 pb-2 rounded-lg w-full space-y-6" noValidate>

                        <h1 className="my-3 italic text-2xl md:text-3xl lg:text-4xl">Generar perfil de cargo</h1>

                        <div className="flex flex-col w-full space-y-6">

                            <div className='flex flex-col w-full'>
                                <h3 className='flex flex-row items-center'><School className='mr-2' />Escoge tu empresa</h3>

                                <label className="label">
                                <Select
    name="company_ID"
    value={optionsCompany.find(option => option.value === suggestData.company_ID) || "Cargando.."}
    onChange={handleSelectSuggest}
    options={optionsCompany}
    className="rounded-md text-black w-full"
    isSearchable
    placeholder="Buscar empresa..."
/>

                                </label>
                                {errors.empresa && <p className="text-red-500 text-xs">{errors.empresa}</p>}
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
                                <div className='flex flex-col w-full'>
                                    <h3 className='flex flex-row items-center'><Person className='mr-2' />Cargo</h3>
                                    <input
    disabled={submitted}
    name="request_PositionToHire"
    value={suggestData.request_PositionToHire}
    onChange={handleChangeSuggest}
    className="rounded-md outline-none text-black p-2 w-full"
    placeholder='Desarrollador de JAVA'
/>

                                    {errors.puesto && <p className="text-red-500 text-xs">{errors.puesto}</p>}
                                </div>
                                <div className='flex flex-col w-full'>
                                    <h3 className='flex flex-row items-center'><FactCheck className='mr-2' />Experiencia</h3>
                                    <input
                                        name="tiempoExperiencia"
                                        disabled={submitted}
                                        value={suggestData.tiempoExperiencia}
                                        onChange={handleChangeSuggest}
                                        className="input-number rounded-md outline-none text-black p-2"
                                        placeholder='Mínimo 2 años...'
                                    />
                                    {errors.tiempoExperiencia && <p className="text-red-500 text-xs">{errors.tiempoExperiencia}</p>}
                                </div>
                                <div className="flex flex-col w-full">
                                    <h3 className='flex flex-row items-center'><School className='mr-2' />Nivel educativo</h3>
                                    <label className="label">
                                        <Select
                                            name="educationLevel"
                                            value={
                                                suggestData.educationLevel
                                                    ? {
                                                        value: suggestData.educationLevel,
                                                        label: suggestData.educationLevel,
                                                    }
                                                    : undefined
                                            }                                            
                                            onChange={handleSelectSuggest}
                                            options={listEducationLevel.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))}
                                            className="inputSearch bg-[#011A27]"
                                            placeholder="Seleccionar nivel..."
                                        />
                                    </label>
                                    {errors.education && <p className="text-red-500 text-xs">{errors.education}</p>}
                                </div>
                                {/* <div className='flex flex-col w-full'>
                                    <h3 className='flex flex-row items-center'><LocationCity className='mr-2' />Ciudad</h3>                    
                                    <input
                                        name="Ciudad"
                                        disabled={submitted}
                                        value={suggestData.Ciudad}
                                        onChange={handleChangeSuggest}
                                        className="input-number rounded-md outline-none text-black p-2"
                                        placeholder='Quito'
                                    />
                                    {errors.ciudad && <p className="text-red-500 text-xs">{errors.ciudad}</p>}
                                </div> */}
                                <div className="flex flex-col w-full">
                                    <h3 className='flex flex-row items-center'><LocationCity className='mr-2' />Ciudad</h3>
                                    <label className="label text-black">
                                        <Select
                                            name="Ciudad"
                                            value={
                                                suggestData.Ciudad
                                                    ? {
                                                        value: suggestData.Ciudad,
                                                        label: suggestData.Ciudad,
                                                    }
                                                    : undefined
                                            }
                                            onChange={handleSelectSuggest}
                                            options={cities.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))}
                                            className="inputSearch bg-[#011A27]"
                                            placeholder="Seleccionar ciudad..."
                                        />
                                    </label>
                                    {errors.ciudad && <p className="text-red-500 text-xs">{errors.ciudad}</p>}
                                </div>
                            </div>
                        </div>
                        <button
    className={`w-full bg-[#F0810F] text-white text-2xl rounded-md p-3 font-semibold hover:scale-105 transition-transform duration-300 ${submitted ? 'cursor-not-allowed opacity-75' : ''}`}
    type="submit"
    disabled={Object.keys(errors).length > 0 || loading || submitted}
>

                            {
                                loading
                                    ? <CircularProgress style={{ color: 'white', fontSize: 72 }} />
                                    : "Generar perfil"
                            }
                        </button>
                        <div className="buttonSection ">                            
                                <button
                                    type="button"
                                    className="w-full bg-[#F0810F] text-2xl font-semibold rounded-md p-3"
                                    onClick={() => navigate("/company-page")}
                                    >
                                    Registrar otra empresa
                                </button>                            
                        </div>
                    </form>
                </div>
            ) : (
                <div className="flex flex-col bg-[#011A27] shadow-sm rounded-md min-h-screen p-10">
    <div className="font-sans flex justify-center mx-auto text-white w-full text-wrap break-words">
        <JobProfile profile={profileData} setSuggestProfile={setSuggestProfile} />
    </div>
    <div className="mt-4 flex justify-center">
        <button
            className="w-full bg-[#F0810F] text-white text-2xl rounded-md p-3 mb-2 font-semibold hover:scale-105 transition-transform duration-300"
            type="submit"
            onClick={handleSubmit}
        >
            Siguiente
        </button>
    </div>
</div>


            )}
        </>
    );

}

import React, { useState, useEffect } from "react";
import  HistoryUser from "../../components/HistoryUser/HistoryUser"; 
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../../assets/img/fondomw.png";

const HistoryServicePage = () => {
  const navigate = useNavigate();
  const [itemsFromDynamicList, setItemsFromDynamicList] = useState([]);

  const handleItemsChange = (newItems) => {
    setItemsFromDynamicList(newItems);
  };

  useEffect(() => {
    //console.log(itemsFromDynamicList);
  }, [itemsFromDynamicList]);

  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="row">        
        <div className="col-md-9">
          <div className="title">
            <h1 className="mb-4">Historial de tus servicios</h1>
          </div>       
        </div>
      </div>
      <div className="w-full">
        <div className="d-md-flex justify-content-center align-items-center w-full">
          <div className="">
            <div>
              <HistoryUser />
            </div>
          {/*<DynamicList onItemsChange={handleItemsChange}/>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryServicePage;

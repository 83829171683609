import React, { useState, useEffect } from "react";
import walletService from '../services/walletService';
import WalletAdminParameterPage from '../pages/wallet/walletAdminParamPage'
import walletListDataService from "../services/walletListDataService";
import suscriptionService from "../services/suscriptionService";
const showAlert = require("../utilities/alerts");

const Tab = ({ label, isActive, onClick }) => (
  <div
    className={`tab ${isActive ? 'active-tab' : ''}`}
    onClick={onClick}
  >
    {label}
  </div>
);


const Content = ({ children }) => <div className="content">{children}</div>;

const WalletAdminForm = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [transactionNumber, setTransactionNumber] = useState("");
  const [token, setToken] = useState("");
  const [valueCoin, setValueCoin] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await loadToken();
        setToken(response);
        setValueCoin((await walletListDataService.getCoinById("1", response)).data[0].wallet_coin_exchange_value);
      } catch (error) {
        // Manejar errores 
      }
    }

    fetchData();

  }, []);

  const loadToken = async () => {
    const data = await walletService.getToken();
    return data.token;
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const transactionData = await walletService.getTransactionData(transactionNumber, token);
    if (!transactionData.success) {
      showAlert.error("La transacción no fue exitosa.");
      return;

    }
    const transInfo = transactionData.data[0];

    if (transInfo.wallet_transaction_confirmed) {
      showAlert.error("La transacción ya ha sido reclamada");
      return;
    }
    if(transInfo.wallet_transaction_coins === "0") {
      showAlert.error("Transacción no correspondiente a recarga de monedas. Revise la sección de Activación de Plan de Suscripción")
      return;
    }

    const recargaJson = {
      "coinsToConsume": transInfo.wallet_transaction_coins,
      "transaction_id": "" + transactionNumber,
      "origen": "compra monedas",
      "transaction_by": localStorage.getItem("userId")
    };

    const mensaje = `
      Se hará la recarga de monedas al usuario ${transInfo.wallet_transaction_user_id}, con los siguientes datos:\n\
      -Cantidad: ${transInfo.wallet_transaction_coins} MW Coins\n\
      -Precio por Moneda: $ ${valueCoin}\n\
      -Monto Total: $${transInfo.wallet_transaction_amount}\n\
      -Fecha de compra: ${transInfo.wallet_transaction_creationDate}
    `;




    showAlert
      .confirmation(
        "Confirmar recarga",
        mensaje
      )
      .then(async (result) => {

        if (result) {
          const updateTransaction = await walletService.updateStateTransaction(transactionNumber, token, { wallet_transaction_confirmed: true });

          if (!updateTransaction.success) {
            showAlert.error("No se pudo actualizar el estado de la transacción.");
          }
          else {
            const planResponse = await walletService.rechargeCoins(transInfo.wallet_transaction_user_id, token, recargaJson);
            if (!planResponse.success) {
              showAlert.error("No se pudo hacer la recarga de monedas.");
            }
            else {
              showAlert.success("Se ha hecho la recarga exitosamente!");
            }
          }
        }

      });

    setTransactionNumber("");
  };

  const handleSuscriptionSubmit = async (event) => {
    event.preventDefault();
    const transactionData = await walletService.getTransactionData(transactionNumber, token);
    if (!transactionData.success) {
      showAlert.error("La transacción no fue exitosa.");
      return;

    }
    const transInfo = transactionData.data[0];

    if (transInfo.wallet_transaction_confirmed) {
      showAlert.error("La transacción ya ha sido reclamada");
      return;
    }

    let selectedPlan = ""
    switch (transInfo.wallet_transaction_amount) {
      case "200":
        selectedPlan = "Suscripción Premium"
        break;
      case "400":
        selectedPlan = "Suscripción Platinum"
        break;
      default:
        break;
    }

    const activacionPlanJson = {
      name: selectedPlan,
    };

    const mensajePlan = `
      Se hará la activación de un plan de suscripción al usuario ${transInfo.wallet_transaction_user_id}, con los siguientes datos:\n\
      -Plan: ${selectedPlan}\n\
      -Fecha de compra: ${transInfo.wallet_transaction_creationDate}
    `;

    showAlert
      .confirmation(
        "Activar Plan",
        mensajePlan
      )
      .then(async (result) => {

        if (result) {
          const updateTransaction = await walletService.updateStateTransaction(transactionNumber, token, { wallet_transaction_confirmed: true });

          if (!updateTransaction.success) {
            showAlert.error("No se pudo actualizar el estado de la transacción.");
          }
          else {
            const planResponse = await suscriptionService.addSuscriptionPlan(
              transInfo.wallet_transaction_user_id,
              activacionPlanJson
            )
            if (!planResponse.user_actual_suscription) {
              showAlert.error("No se pudo hacer la activación del plan.");
            }
            else {
              showAlert.success("Se ha hecho la activación adecuadamente");
            }
          }
        }

      });

    setTransactionNumber("");
  }

  return (
    <div>
      <h2 className="ml-16">Formulario de Administración de Cartera</h2>
      <div className="tabs-container">
        <div className="tabs">
          <Tab
            label="Recarga de monedas"
            isActive={activeTab === 1}
            onClick={() => handleTabClick(1)}
          />

        </div>
        <div className="tabs bg-[#063852] text-white ">
          <Tab
            label="Parámetros Wallet"
            isActive={activeTab === 2}
            onClick={() => handleTabClick(2)}
          />

        </div>
        <div className="tabs bg-[#063852] text-white ">
          <Tab
            label="Activación Suscripción"
            isActive={activeTab === 3}
            onClick={() => handleTabClick(3)}
          />

        </div>
      </div>
      <Content>
        {activeTab === 1 &&
          <div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="transactionNumber">Número de Transacción:</label>
                <input
                  type="text"
                  className="form-control"
                  id="transactionNumber"
                  value={transactionNumber}
                  onChange={(event) => setTransactionNumber(event.target.value)}
                  required
                />
              </div>
              <br></br>
              <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
          </div>
        }
        {activeTab === 2 &&
          <div>
            <p>Menú parámetros</p>
            <WalletAdminParameterPage />
          </div>
        }
        {activeTab === 3 &&
          <div>
            <h1>Activación de plan de suscripción</h1>
            <form onSubmit={handleSuscriptionSubmit}>
              <div className="form-group">
                <label htmlFor="transactionNumber">Número de Transacción:</label>
                <input
                  type="text"
                  className="form-control"
                  id="transactionNumber"
                  value={transactionNumber}
                  onChange={(event) => setTransactionNumber(event.target.value)}
                  required
                />
              </div>
              <br></br>
              <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
          </div>
        }
      </Content>

    </div>
  );
};

export default WalletAdminForm;

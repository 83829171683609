import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/mw_services";

const mwServices = {
    create: async (body) => {
        try {
            const response = await axios.post(`${api_enviroment_dev}`, body);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    getAll: async () => {
        try {
            const response = await axios.get(`${api_enviroment_dev}`);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    getById: async (id) => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    getByState: async () => {
        const state = 'Activo' //Consulta por defecto un estado de 'Activo'
        try {
            const response = await axios.get(`${api_enviroment_dev}/state/${state}`);
            // console.log('data',response.data)
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    update: async (id, body) => {
        try {
            const response = await axios.put(`${api_enviroment_dev}/${id}`, body);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    },

    delete: async (id) => {
        try {
            const response = await axios.delete(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error al generar el documento:', error);
            throw error;
        }
    }
};
export default mwServices;

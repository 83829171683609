import axios from 'axios';
import apiClient from '../utilities/interceptorConfig';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.cvFilesUrl + "cv-recomendations";

const recommendationCvService = {
    // Traer recomendaciones de la ia por id
    getRecommendationById: async (cv_id) => {
        try {
            const response = await apiClient.get(`${api_enviroment_dev}/${cv_id}`);
            return response.data;
        } catch (error) {
            console.error('Error completo:', error);
            if (error.response) {
                console.error('Datos de la respuesta de error:', error.response.data);
                console.error('Estado de la respuesta de error:', error.response.status);
                console.error('Cabeceras de la respuesta de error:', error.response.headers);
            }
            throw error;  
        }
    }
};

export default recommendationCvService;
import React, { useState, useEffect, useRef } from 'react';
import companyService from '../../services/companyService'
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import Axios from 'axios';
import { validateRUC } from "../../utilities/Validation";
const showAlert = require("../../utilities/alerts");

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {          
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'America/Guayaquil'
    };
    return date.toLocaleDateString('es-ES', options);
  }

function UpdateCompany({ onBack, data, id }) {
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: data.company_WorkLocation.company_Country, label: data.company_WorkLocation.company_Country });
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState({ value: data.company_WorkLocation.company_City, label: data.company_WorkLocation.company_City });
    const [countryAlert, setCountryAlert] = useState(false);
    const [cityAlert, setCityAlert] = useState(false);
    const [file, setFile] = useState(null);
    
    // console.log(data)
    const initialValues = {
        company_ruc: data.company_ruc.toString() || "",
        company_tradename: data.company_tradename || "",
        company_description: data.company_description || "",
        company_businessname: data.company_businessname || "",
        company_economic_activity: data.company_economic_activity || "",
        company_email: data.company_email || "",
        company_phone: data.company_phone || "",
        company_creationdate: formatDate(data.company_creationdate) || "",
        company_website: data.company_website || "",
        company_number_employees: data.company_number_employees || "",
        company_type: data.company_type || "",
        company_subtype: data.company_subtype || "",
        company_Logo: data.company_Logo || "",
        company_WorkLocation: {
            company_Country: data.company_WorkLocation.company_Country,
            company_City: data.company_WorkLocation.company_City,
        },
        company_address: data.company_address || "",

    };
    
    const [values, setValue] = useState(initialValues);
    console.log(values)

    const rucRef = useRef(null);
    const tradenameRef = useRef(null);
    const descriptionRef = useRef(null);
    const businessnameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const creationDateRef = useRef(null);
    const websiteRef = useRef(null);
    const employeesRef = useRef(null);
    const typeRef = useRef(null);
    const subtypeRef = useRef(null);
    const countryRef = useRef(null);
    const cityRef = useRef(null);
    const addressRef = useRef(null);
    const logoRef = useRef(null);

    const fetchCountries = async () => {
        try {
            const response = await Axios.get(
                "https://countriesnow.space/api/v0.1/countries"
            );
            setCountries(response.data.data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    useEffect(() => {
        fetchCountries(); // Fetch countries when the component mounts
        showAlert.success("A continuación se mostrarán campos requeridos y necesarios para registrar tu empresa - companía, estos datos facilitarán la generación y visualización de distintos servicios que puedes adquirir.");
    }, []);

    const fetchCities = (selectedCountry) => {
        const selectedCountryData = countries.find(
            (country) => country.country === selectedCountry
        );
        if (selectedCountryData) {
            setCities(selectedCountryData.cities);
        } else {
            setCities([]);
        }
    };
    const handleCountryChange = (selectedOption) => {
        setCountryAlert(!selectedOption);
        setSelectedCountry(selectedOption);
        const countryValue = selectedOption ? selectedOption.value : null;
        

        setValue((prevValues) => ({
            ...prevValues,
            company_WorkLocation: {
                ...prevValues.company_WorkLocation,
                company_Country: countryValue,
            },
        }));
        fetchCities(countryValue);
    };

    const handleCityChange = (selectedOption) => {
        setCityAlert(!selectedOption);
        setSelectedCity(selectedOption);

        const cityValue = selectedOption ? selectedOption.value : null;

        setValue((prevValues) => ({
            ...prevValues,
            company_WorkLocation: {
                ...prevValues.company_WorkLocation,
                company_City: cityValue,
            },
        }));
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            showAlert.error(
                "Por favor, seleccione un archivo con extensión jpg o png."
            );
            return;
        }

        const fileUrl = URL.createObjectURL(selectedFile);
        // console.log(fileUrl)
        setValue((prevValues) => ({
            ...prevValues,
            company_Logo: fileUrl,
        }));
        setFile(selectedFile);
    };
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#F0810F" : "transparent",
            color: state.isFocused ? "#00000" : "#011A27",
        }),
    };

    const resetForm = () => {
        setValue(initialValues);
        setFile(null);
        setSelectedCountry(null);
        setCities([]);
        setSelectedCity(null);
        setCountryAlert(false);
        setCityAlert(false);
    };

    const handleForm = async (event) => {
        event.preventDefault();

        //console.log("Formulario enviado", values);

        const requiredFields = [
            { fieldName: "company_ruc", label: "RUC de la Empresa" },
            { fieldName: "company_tradename", label: "Nombre de la Empresa" },
            { fieldName: "company_description", label: "¿Qué actividades realiza la Empresa?" },
            { fieldName: "company_businessname", label: "Razón Social de la Empresa" },
            // { fieldName: "company_economic_activity", label: "Actividad económica de la Empresa" },
            { fieldName: "company_email", label: "Email de la Empresa" },
            { fieldName: "company_phone", label: "Contacto de la Empresa" },
            { fieldName: "company_creationdate", label: "Fecha de creación de la Empresa" },
            // { fieldName: "company_website", label: "Sitio Web" },
            { fieldName: "company_number_employees", label: "Selecciona el rango de Empleados" },
            { fieldName: "company_type", label: "Tipo de Empresa" },
            { fieldName: "company_subtype", label: "Subtipo de Empresa" },
            { fieldName: "company_Logo", label: "Logo Empresarial" },
            { fieldName: "company_address", label: "Dirección de la empresa" },
        ];

        for (const field of requiredFields) {
            if (!values[field.fieldName]) {
                showAlert.error(`Por favor, complete el campo ${field.label}.`);
                switch (field.fieldName) {
                    case "company_ruc":
                        rucRef.current.focus();
                        break;
                    case "company_tradename":
                        tradenameRef.current.focus();
                        break;
                    case "company_description":
                        descriptionRef.current.focus();
                        break;
                    case "company_businessname":
                        businessnameRef.current.focus();
                        break;
                    case "company_email":
                        emailRef.current.focus();
                        break;
                    case "company_phone":
                        phoneRef.current.focus();
                        break;
                    case "company_creationdate":
                        creationDateRef.current.focus();
                        break;
                    case "company_number_employees":
                        employeesRef.current.focus();
                        break;
                    case "company_type":
                        typeRef.current.focus();
                        break;
                    case "company_subtype":
                        subtypeRef.current.focus();
                        break;
                    case "company_Logo":
                        logoRef.current.focus();
                        break;
                    case "company_address":
                        logoRef.current.focus();
                        break;

                    default:
                        break;
                }
                return;
            }
        }
        if (!values.company_Logo) {
            showAlert.error(
                `Por favor, complete el campo Logo Empresarial o seleccione otro logo.`
            );
            logoRef.current.focus();
            return;
        }
        if (!selectedCountry) {
            showAlert.error("Por favor, seleccione un país.");
            countryRef.current.focus();
            return;
        }

        if (!selectedCity) {
            showAlert.error("Por favor, seleccione una ciudad.");
            cityRef.current.focus();
            return;
        }

        const requireRUC = validateRUC(values.company_ruc);

        if (!requireRUC.isValid) {
            showAlert.error(requireRUC.errorMessage);
            return;
        }

        //console.log(values);
        showAlert.confirmation("Gracias", "Presiona Acepto").then((result) => {
            if (result) {
                companyService
                    .putCompanyId(id,values) /// actualizar empresa reemplazar createCompany
                    .then(async (data) => {
                        setFile(null);
                        resetForm();
                        showAlert.success("Empresa actualizada exitosamente.");

                        onBack(); //regresar al componente

                    })
                    .catch((error) => {
                        console.error("Error", error);
                        showAlert.error(
                            "Error al conectar con el servidor. Por favor, inténtelo de nuevo más tarde."
                        );
                    });
            }
        });
    };
    const numberEmployeesOptions = [
        { value: "one_Employee", label: "1 Empleado(Emprendimiento)" },
        { value: "one_to_nine_Employees", label: "De 1 a 9 Empleados" },
        { value: "ten_to_fortyNine_Employees", label: "De 10 a 49 Empleados" },
        { value: "fifty_to_oneHundredNintyNine_Employees", label: "De 50 a 199 Empleados", },
        { value: "more_twoHundred_Employees", label: "De 200 o más Empleados" },
    ];
    const companyTypeOptions = [
        {
            value: "A",
            label: "AGRICULTURA, GANADERÍA, SILVICULTURA Y PESCA",
        },
        {
            value: "B",
            label: "EXPLOTACIÓN DE MINAS Y CANTERAS",
        },
        {
            value: "C",
            label: "INDUSTRIAS MANUFACTURERAS",
        },
        {
            value: "D",
            label: "SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIREACONDICIONADO",
        },
        {
            value: "E",
            label: "DISTRIBUCIÓN DE AGUA; ALCANTARILLADO, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO",
        },
        {
            value: "F",
            label: "CONSTRUCCIÓN"
        },
        {
            value: "G",
            label: "COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS",
        },
        {
            value: "H",
            label: "TRANSPORTE Y ALMACENAMIENTO"
        },
        {
            value: "I",
            label: "ACTIVIDADES DE ALOJAMIENTO Y DE SERVICIO DE COMIDAS",
        },
        {
            value: "J",
            label: "INFORMACIÓN Y COMUNICACIÓN.",
        },
        {
            value: "K",
            label: "ACTIVIDADES FINANCIERAS Y DE SEGUROS"
        },
        {
            value: "L",
            label: "ACTIVIDADES INMOBILIARIAS"
        },
        {
            value: "M",
            label: "ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS"
        },
        {
            value: "N",
            label: "ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO",
        },
        {
            value: "O",
            label: "ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
        },
        {
            value: "P",
            label: "ENSEÑANZA"
        },
        {
            value: "Q",
            label: "ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL",
        },
        {
            value: "R",
            label: "ARTES, ENTRETENIMIENTO Y RECREACIÓN"
        },
        {
            value: "S",
            label: "OTRAS ACTIVIDADES DE SERVICIOS"
        },
        {
            value: "T",
            label: "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO",
        },
        {
            value: "U",
            label: "ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES",
        },

    ];
    const companySubTypeOptions = [
        {
            literal: "A", value: "A01",
            label: "AGRICULTURA, GANADERÍA, CAZA Y ACTIVIDADES DE SERVICIOS CONEXAS."
        },
        {
            literal: "A", value: "A02",
            label: "SILVICULTURA Y EXTRACCIÓN DE MADERA"
        },
        {
            literal: "A", value: "A03",
            label: "PESCA Y ACUICULTURA"
        },
        {
            literal: "B", value: "B05",
            label: "EXTRACCIÓN DE CARBÓN DE PIEDRA Y LIGNITO."
        },
        {
            literal: "B", value: "B06",
            label: "EXTRACCIÓN DE PETRÓLEO CRUDO Y GAS NATURAL."
        },
        {
            literal: "B", value: "B07",
            label: "EXTRACCIÓN DE MINERALES METALÍFEROS"
        },
        {
            literal: "B", value: "B08",
            label: "EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS"
        },
        {
            literal: "B", value: "B09",
            label: "ACTIVIDADES DE SERVICIOS DE APOYO PARA LA EXPLOTACIÓN DE MINAS Y CANTERAS"
        },
        {
            literal: "C", value: "C10",
            label: "ELABORACIÓN DE PRODUCTOS ALIMENTICIOS"
        },
        {
            literal: "C", value: "C11",
            label: "ELABORACIÓNDE BEBIDAS"
        },
        {
            literal: "C", value: "C12",
            label: "ELABORACIÓN DE PRODUCTOS DE TABACO"
        },
        {
            literal: "C", value: "C13",
            label: "FABRICACIÓN DE PRODUCTOS TEXTILES"
        },
        {
            literal: "C", value: "C14",
            label: "FABRICACIÓN DE PRENDAS DE VESTIR"
        },
        {
            literal: "C", value: "C15",
            label: "FABRICACIÓN DE CUEROS Y PRODUCTOS CONEXOS"
        },
        {
            literal: "C", value: "C16",
            label: "PRODUCCIÓN DE MADERA Y FABRICACIÓN DE PRODUCTOS DE MADERA Y CORCHO,EXCEPTO MUEBLES; FABRICACIÓN DE ARTÍCULOS DE PAJA Y DE MATERIALES TRENZABLES"
        },
        {
            literal: "C", value: "C17",
            label: "FABRICACIÓN DE PAPEL Y DE PRODUCTOS DE PAPEL"
        },
        {
            literal: "C", value: "C18",
            label: "IMPRESIÓN Y REPRODUCCIÓN DE GRABACIONES"
        },
        {
            literal: "C", value: "C19",
            label: "FABRICACIÓN DE COQUE Y DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO"
        },
        {
            literal: "C", value: "C20",
            label: "FABRICACIÓN DE SUBSTANCIAS Y PRODUCTOS QUÍMICOS"
        },
        {
            literal: "C", value: "C21",
            label: "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO"
        },
        {
            literal: "C", value: "C22",
            label: "FABRICACIÓN DE PRODUCTOS DE CAUCHO Y PLÁSTICO"
        },
        {
            literal: "C", value: "C23",
            label: "FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS"
        },
        {
            literal: "C", value: "C24",
            label: "FABRICACIÓN DE METALES COMUNES."
        },
        {
            literal: "C", value: "C25",
            label: "FABRICACIÓN DE PRODUCTOS ELABORADOS DE METAL,EXCEPTO MAQUINARIA Y EQUIPO"
        },
        {
            literal: "C", value: "C26",
            label: "FABRICACIÓN DE PRODUCTOS DE INFORMÁTICA, ELECTRÓNICA Y ÓPTICA"
        },
        {
            literal: "C", value: "C27",
            label: "FABRICACIÓN DE EQUIPO ELÉCTRICO"
        },
        {
            literal: "C", value: "C28",
            label: "FABRICACIÓN DEMAQUINARIA YEQUIPOON.C.P."
        },
        {
            literal: "C", value: "C29",
            label: "FABRICACIÓN DE VEHÍCULOS AUTOMOTORES, REMOLQUES Y SEMIREOQUES"
        },
        {
            literal: "C", value: "C30",
            label: "FABRICACIÓN DE OTROS TIPOS DE EQUIPOS DE TRANSPORTE."
        },
        {
            literal: "C", value: "C31",
            label: "FABRICACIÓN DE MUEBLES."
        },
        {
            literal: "C", value: "C32",
            label: "OTRAS INDUSTRIAS MANUFACTURERAS."
        },
        {
            literal: "C", value: "C33",
            label: "REPARACIÓN E INSTALACIÓN DE MAQUINNARIA Y EQUIPO."
        },
        {
            literal: "D", value: "D35",
            label: "SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICCIONADO"
        },
        {
            literal: "E", value: "E36",
            label: "CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA"
        },
        {
            literal: "E", value: "E37",
            label: "EVACUACIÓN DE AGUAS RESIDUALES"
        },
        {
            literal: "E", value: "E38",
            label: "RECOLECCIÓN, TRATAMIENTO Y ELIMINACIÓN DE DESECHOS, RECUPERACIÓN DE MATERIALES"
        },
        {
            literal: "E", value: "E39",
            label: "ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS"
        },
        {
            literal: "F", value: "F41",
            label: "CONSTRUCCIÓN DE EDIFICIOS"
        },
        {
            literal: "F", value: "F42",
            label: "OBRAS DE IGNIERÍA CIVIL"
        },
        {
            literal: "F", value: "F43",
            label: "ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN"
        },
        {
            literal: "G", value: "G45",
            label: "COMERCIO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS"
        },
        {
            literal: "G", value: "G46",
            label: "COMERCIO AL POR MAYOR, EXCEPTO EL DE VEHÍCULOS AUTOMOTORES Y MOTOOCICLETAS"
        },
        {
            literal: "G", value: "G47",
            label: "COMERCIO AL POR MENOR, EXCEPTO EL DE VEHÍCULO SAUTOMOTORES Y MOTTOCICLETAS"
        },
        {
            literal: "H", value: "H49",
            label: "TRANSPORTE POR VÍA TERRESTRE Y POR TUBERÍAS"
        },
        {
            literal: "H", value: "H50",
            label: "TRANSPORTE POR VÍA ACUÁTICA"
        },
        {
            literal: "H", value: "H51",
            label: "TRANSPORTE PORVÍA AÉREA"
        },
        {
            literal: "H", value: "H52",
            label: "ALMACENAMIENTO Y ACTIVIDADES DE APOYO AL TRANSPORTE"
        },
        {
            literal: "H", value: "H53",
            label: "ACTIVIDADES POSTALES Y DE MENSAJERÍA"
        },
        {
            literal: "I", value: "I55",
            label: "ACTIVIDADES DE ALOJAMIENTO"
        },
        {
            literal: "I", value: "I56",
            label: "SERVICIO DE ALIMENTO Y BEBIDA"
        },
        {
            literal: "J", value: "J58",
            label: "ACTIVIDADES DE PUBLICACIÓN"
        },
        {
            literal: "J", value: "J59",
            label: "ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN, GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA"
        },
        {
            literal: "J", value: "J60",
            label: "ACTIVIDADES DE PROGRAMACIÓN Y TRANSMISIÓN"
        },
        {
            literal: "J", value: "J61",
            label: "TELECOMUNICACIONES"
        },
        {
            literal: "J", value: "J62",
            label: "PROGRAMACIÓN INFORMÁTICA, CONSULTORÍA DE INFORMÁTICA Y ACTIVIDADES CONEXAS"
        },
        {
            literal: "J", value: "J63",
            label: "ACTIVIDADES DE SERVICIOS DE INFORMACIÓN."
        },
        {
            literal: "K", value: "K64",
            label: "ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES"
        },
        {
            literal: "K", value: "K65",
            label: "SEGUROS, REASEGUROS Y FONDOS DE PENSIONES, EXCEPTO LOS PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA"
        },
        {
            literal: "K", value: "K66",
            label: "ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS"
        },
        {
            literal: "L", value: "L68",
            label: "ACTIVIDADES INMOBILIARIAS"
        },
        {
            literal: "M", value: "M69",
            label: "ACTIVIDADES JURÍDICAS Y DE CCONTABILIDAD"
        },
        {
            literal: "M", value: "M70",
            label: "ACTIVIDADES DE OFICINAS PRINCIPALES; ACTIVIDADES DE CONSULTORÍA DE GESTIÓN"
        },
        {
            literal: "M", value: "M71",
            label: "ACTIVIDADES DE ARQUITECTURA E INGENIERÍA; ENSAYOS Y ANÁLISIS TÉCNICOS"
        },
        {
            literal: "M", value: "M72",
            label: "INVESTIGACIÓN CIENTÍFICA Y DESARROLLO"
        },
        {
            literal: "M", value: "M73",
            label: "PUBLICIDAD Y ESTUDIOS DE MERCADO"
        },
        {
            literal: "M", value: "M74",
            label: "OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS"
        },
        {
            literal: "M", value: "M75",
            label: "ACTIVIDADES VETERINARIAS."
        },
        {
            literal: "N", value: "N77",
            label: "ACTIVIDADES DE ALQUILER Y ARRENDAMIENTO"
        },
        {
            literal: "N", value: "N78",
            label: "ACTIVIDADES DE EMPLEO"
        },
        {
            literal: "N", value: "N79",
            label: "ACTIVIDADES DE AGENCIAS DE VIAJES, OPERADORES TURÍSTICOS, SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS"
        },
        {
            literal: "N", value: "N80",
            label: "ACTIVIDADES DE SEGURIDAD E INVESTIGACIÓN"
        },
        {
            literal: "N", value: "N81",
            label: "ACTIVIDADES DE SERVICIOS A EDIFICIOS Y PAISAJISMO"
        },
        {
            literal: "N", value: "N82",
            label: "ACTIVIDADES ADMINISTRATIVAS Y DE APOYO DE OFICINA Y OTRAS ACTIVIDADES DE APOYO A LAS EMPRESAS"
        },
        {
            literal: "I", value: "O84",
            label: "ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA"
        },
        {
            literal: "P", value: "P85",
            label: "ENSEÑANZA."
        },
        {
            literal: "Q", value: "Q86",
            label: "ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA"
        },
        {
            literal: "Q", value: "Q87",
            label: "ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES"
        },
        {
            literal: "Q", value: "Q88",
            label: "ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO"
        },
        {
            literal: "R", value: "R90",
            label: "ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO"
        },
        {
            literal: "R", value: "R91",
            label: "ACTIVIDADES DE BIBLIOTECAS, ARCHIVOS, MUSEOS Y OTRAS ACTIVIDADES CULTURALES"
        },
        {
            literal: "R", value: "R92",
            label: "ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS"
        },
        {
            literal: "R", value: "R93",
            label: "ACTIVIDADES DEPORTIVAS, DE ESPARCIMIENTO Y RECREATIVAS"
        },
        {
            literal: "S", value: "S94",
            label: "ACTIVIDADES DE ASOCIACIONES"
        },
        {
            literal: "S", value: "S95",
            label: "REPARACIÓN DE COMPUTADORES Y DE EFECTOS PERSONALES Y ENSERES DOMÉSTICOS"
        },
        {
            literal: "S", value: "S96",
            label: "OTRAS ACTIVIDADE DE SERVICIOS PERSONALES"
        },
        {
            literal: "T", value: "T97",
            label: "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO"
        },
        {
            literal: "T", value: "T98",
            label: "ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO"
        },
        {
            literal: "U", value: "U99",
            label: "ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES"
        },

    ]

    const handleInputChange = (event, name, isSelect) => {
        const value = isSelect ? event.value : event.target.value;
        setValue({
            ...values,
            [name]: value,
        });
    };


    return (
        <>            
            <form
                onSubmit={handleForm}
                className="d-md-flex justify-content-center align-items-center "
            >
                <div className="container custom-container text-white bg-[#011A27] p-5 rounded-lg">
                    <div className="row">
                        <div className="col-lg-6 col-12 p-1">
                            <div className="mb-3">
                                <label className="mb-1">
                                    Ruc de la Empresa <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="number"
                                    name="company_ruc"
                                    value={values.company_ruc}
                                    placeholder="1111111111001"
                                    ref={rucRef}
                                    onChange={(e) => handleInputChange(e, "company_ruc")}
                                    title="Ruc de la empresa - empresa a registrar"

                                />
                            </div>
                            <div className="mb-3 ">
                                <label className="mb-1">
                                    Razón Social{" "}
                                    <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="text"
                                    name="company_businessname"
                                    value={values.company_businessname}
                                    placeholder="EMPLEAECUADOR S.A.S."
                                    ref={businessnameRef}
                                    onChange={(e) => handleInputChange(e, "company_businessname")}
                                    title="Razón social/Nombre legal completo de la empresa - empresa a registrar, tal como se registró en la Superintendecia de Compañias, Valores Y Seguros (SuperCias). Podra ver su uso responsable en datos informativos como: tus reglamentos..."
                                />
                            </div>
                            <div className="mb-3 ">
                                <label className="mb-1">
                                    Nombre comercial (Nombre de la Empresa) <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="text"
                                    name="company_tradename"
                                    value={values.company_tradename}
                                    placeholder="MatchWorking"
                                    ref={tradenameRef}
                                    onChange={(e) => handleInputChange(e, "company_tradename")}
                                    title="Nombre de tu empresa - empresa a registrar"
                                />
                            </div>
                            <div className="mb-3 ">
                                <label className="mb-1">
                                    ¿Qué actividades realiza?{" "}
                                    <label className="required">*</label>
                                </label>
                                <textarea
                                    className="form-control text-black placeholder-gray-400"
                                    name="company_description"
                                    value={values.company_description}
                                    placeholder="Nos enfocamos a conectar buscadores de empleo con reclutadores"
                                    ref={descriptionRef}
                                    onChange={(e) => handleInputChange(e, "company_description")}
                                    title="Actividad/es que realiza la empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: tus reglamentos..."
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Email empresarial de contacto<label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="email"
                                    name="company_email"
                                    value={values.company_email}
                                    placeholder="matchworking@matchworking.ec"
                                    ref={emailRef}
                                    onChange={(e) => handleInputChange(e, "company_email")}
                                    title="Correo de la empresa - empresa a registrar"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Contacto de la Empresa <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="number"
                                    name="company_phone"
                                    value={values.company_phone}
                                    placeholder="099999999"
                                    ref={phoneRef}
                                    onChange={(e) => handleInputChange(e, "company_phone")}
                                    title=" Contacto de la empresa - empresa a registrar"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Fecha de creación <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="text"
                                    name="company_creationdate"
                                    value={values.company_creationdate}
                                    placeholder="01/01/2000"
                                    ref={creationDateRef}
                                    onChange={(e) => handleInputChange(e, "company_creationdate")}
                                    title="Fecha de creación de la empresa - empresa a registrar"
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-12 p-1">
                            <div className="mb-3">
                                <label className="mb-1">
                                    SitioWeb
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="text"
                                    name="company_website"
                                    value={values.company_website}
                                    placeholder="match-working.com"
                                    ref={websiteRef}
                                    onChange={(e) => handleInputChange(e, "company_website")}
                                    title="Sitio web de la empresa - empresa a registrar (Opcional)"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Selecciona el rango <label className="required"
                                    >*</label>
                                </label>
                                <Select
                                    options={numberEmployeesOptions}
                                    value={
                                        numberEmployeesOptions.find(
                                            (option) =>
                                                option.value === values.company_number_employees
                                        ) || null
                                    }
                                    onChange={(selectedOption) =>
                                        handleInputChange(
                                            selectedOption,
                                            "company_number_employees",
                                            true
                                        )
                                    }
                                    placeholder="El rango de Empleados"
                                    title="Rango de empleados de la empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: tus reglamentos..."
                                    ref={employeesRef}
                                    isSearchable
                                    styles={customStyles}
                                />
                            </div>
                            <div className="mb-md-6 mb-lg-6 mb-6">
                                <label className="mb-1">
                                    Actividad económica de la Empresa <label className="required">*</label>
                                </label>
                                <Select
                                    options={companyTypeOptions}
                                    value={
                                        companyTypeOptions.find(
                                            (option) => option.value === values.company_type
                                        ) || null
                                    }
                                    onChange={(selectedOption) =>
                                        handleInputChange(selectedOption, "company_type", true)
                                    }
                                    placeholder="Seleccione su tipo de Empresa"
                                    title="Tipo de empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: tus reglamentos..."
                                    ref={typeRef}
                                    isSearchable
                                    styles={customStyles}
                                />
                            </div>
                            {values.company_type && <div className="mb-md-6 mb-lg-6 mb-6">
                                <label className="mb-1">
                                    Subactividad económica de la Empresa <label className="required">*</label>
                                </label>
                                <Select
                                    options={companySubTypeOptions.filter(option => option.literal === values.company_type)}
                                    value={
                                        companySubTypeOptions.find(
                                            (option) => option.value === values.company_subtype
                                        ) || null
                                    }
                                    onChange={(selectedOption) =>
                                        handleInputChange(selectedOption, "company_subtype", true)
                                    }
                                    placeholder="Seleccione su subtipo de Empresa"
                                    title="Subtipo de empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: tus reglamentos..."
                                    ref={subtypeRef}
                                    isSearchable
                                    styles={customStyles}
                                />
                            </div>
                            }
                            <div className="mb-3">
                                <label className="mb-1">
                                    País
                                    <label className="required">*</label>
                                </label>
                                <Select
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    options={countries.map((country) => ({
                                        value: country.country,
                                        label: country.country,
                                    }))}
                                    placeholder="Seleccione su país..."
                                    ref={countryRef}
                                    className="inputSearch"
                                    styles={customStyles}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Ciudad
                                    <label className="required">*</label>
                                </label>
                                <Select
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    options={cities.map((city) => ({
                                        value: city,
                                        label: city,
                                    }))}
                                    placeholder="Seleccione su Ciudad..."
                                    ref={cityRef}
                                    className="inputSearch"
                                    styles={customStyles}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Dirección de la companía <label className="required">*</label>
                                </label>
                                <input
                                    className="form-control text-black placeholder-gray-400"
                                    type="text"
                                    name="company_address"
                                    value={values.company_address}
                                    placeholder="Av. Siempre Viva"
                                    ref={addressRef}
                                    onChange={(e) => handleInputChange(e, "company_address")}
                                    title="Dirección de empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: tus reglamentos,..."
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-1">
                                    Logo Empresarial<label className="required">*</label>
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    name="company_logo"
                                    ref={logoRef}
                                    onChange={handleFileChange}
                                    title="Logo de empresa - empresa a registrar. Podrá ver su uso responsable en datos informativos como: cargos de perfil,..."
                                />
                                <br></br>
                                {values.company_Logo && (
                                    <img
                                        src={values.company_Logo}
                                        alt="Logo de la empresa"
                                        className="preview-logo"
                                        style={{ width: '200px', height: '170px', justifyContent: 'center', alignItems: 'center' }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-center space-x-4">
                            {" "}
                            <button className="button rounded-lg" onClick={onBack}
                            >
                                Regresar
                            </button>
                            <button type="submit" className="button rounded-lg">
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>

    )
}

export default UpdateCompany
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import userService from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../VariablesContext";
import SatisfactionSurvey from "../Satisfaction_survey";

//Crear enlace de descarga automática WORD"
// const downloadDocument = (url, filename) => {
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = filename;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);

// };

const FilesRegulation = ({ regulationData, setRegulationData, onNextStep }) => {
  const [dataUsuarios, setDataUsuarios] = useState("");
  const navigate = useNavigate();
  const { userId } = useUser();
  // const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [siteBlocked, setSiteBlocked] = useState(false);

  const handleSurveyClose = () => {
    // setDownloadEnabled(true);
    setShowSurvey(false);
  };
  const handleOverlayClick = () => {
    setSiteBlocked(false); // Desbloquear el sitio cuando se hace clic en el overlay
    setShowSurvey(true);
  };
  useEffect(() => {
    const blockSite = () => {
      setSiteBlocked(true); // Bloquear el sitio
    };
    blockSite();
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
      navigate("/");
    } else {
      const fetchUserData = async () => {
        try {
          const dataUser = await userService.getUserId(userId);
          // console.log(dataUser);
          // Actualiza el estado con los datos del usuario.
          setDataUsuarios(dataUser);
        } catch (error) {
          console.error("Error al obtener los datos del usuario", error);
        }
      };
      // Llama a la función para obtener los datos del usuario
      fetchUserData();
    }
  }, [userId]);
  if (!userId) {
    // Si no hay userId, no renderizar el componente
    return null;
  }

  const handleClickNext = () => {
    setRegulationData({ ...regulationData });
    // console.log('Datos de GenerateRegulation desde Pay:', regulationData.generatedData);
    // console.log(JSON.stringify(regulationData.selectedRegulation));
    window.location.reload();
  };
  const data = regulationData.generatedData || [];
  // console.log('dataTable:', data)
  const generatedData = data;

  const RegulationTable = ({ data }) => {
    return (
      <div className="mt-3">
        <h2>Tus documentos</h2>
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-300 dark:text-gray-300">
            <thead className="text-xs text-gray-400 uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3">
                  N°
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre del Reglamento
                </th>
                <th scope="col" className="px-6 py-3">
                  Fecha de Generación
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Url Descarga
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className="bg-gray-600 border-b dark:bg-gray-600 dark:border-gray-700"
                >
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className="px-6 py-4">{item.nameRegulation}</td>
                  <td className="px-6 py-4">{item.date}</td>
                  <td className="px-6 py-4">
                    {/* <a
                                            onClick={() => downloadDocument(item.url, 'ReglamentoGeneradoconMatchworking.docx')}
                                            className="flex item-center justify-center text text-center ">
                                            <FontAwesomeIcon icon={faFileWord} size="2x" className="mr-2 cursor: 'pointer' hover:text-amber-500 focus:text-amber-500" />
                                        </a> */}
                    <a
                      href={item.url}
                      className="flex items-center justify-center text-center cursor-pointer hover:text-amber-500 focus:text-amber-500"
                    >
                      <FontAwesomeIcon
                        icon={faFileWord}
                        size="2x"
                        className="mr-2"
                      />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full max-h-screen w-full max-w-screen pb-8 px-3">
      {/* <div className="d-flex align-items-center justify-content-center  ">
                <img src={matchWorkingLogo} alt="Match Working Logo" style={{ maxHeight: '150px' }} />
            </div> */}
      <div>
        {/* <h1>Hola Files Regulation</h1> */}
        <RegulationTable data={generatedData} />
      </div>
      <div className="max-w-sm mx-auto p-2">
        <div className="text text-center px-1">
          <button
            type="submit"
            onClick={handleClickNext}
            className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-3 rounded"
          >
            Volver al inicio
          </button>
        </div>
        <div>
          {showSurvey && (
            <SatisfactionSurvey
              data={dataUsuarios}
              onClose={handleSurveyClose}
            />
          )}
        </div>
      </div>
      {siteBlocked && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0)",
            zIndex: 9999,
          }}
          onClick={handleOverlayClick}
        ></div>
      )}
    </div>
  );
};

export default FilesRegulation;

const ACTIONS = {
    SET_VIEW: 'SET_VIEW',
};

export const BilleteraViewReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_VIEW:
            return {
                ...state,
                actualView: action.payload,
            };
        default:
            return state;
    }
};
import axios from "axios";
import { credentials } from "../enviroment/enviroment-dev";
import { lambdaLogin } from "../enviroment/internal-lambdas";

let token = null;

const generateToken = async () => {
    try {
        const response = await lambdaLogin.authToken(credentials);
        const newToken = response.data.token;
        if (newToken) {
            token = newToken;
            return token;
        }
    } catch (error) {
        console.error('Error al refrescar el token:', error);
    }
    return null;
};

const apiClient = axios.create();

apiClient.interceptors.request.use(
    async (config) => {
        // await generateToken();
        console.log('Adding token:', token);
        if (token == null){
            const newToken = await generateToken();
            config.headers.Authorization = `Bearer ${newToken}`;
        } else {
            config.headers.Authorization = `Bearer ${token}`;
        }        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor de respuestas
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Verificar si es el primer intento
        if (!originalRequest._retry) {
            originalRequest._retry = true;
            // Generar un nuevo token
            const newToken = await generateToken();
            console.log('Retrying with new token:', newToken);
            // Actualizar el token en los headers
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            // Reintentar la solicitud con el nuevo token
            return axios(originalRequest);
        }

        return Promise.reject(error);
    }
);

export default apiClient;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: inline-block;
    margin-bottom: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .tab {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .tab.active-tab {
    background-color: #F0810F; /* Color de fondo cuando la pestaña está activa */
    color: #fff; /* Color del texto cuando la pestaña está activa */
    border-radius: 5px;
  }
  
  .content {
    /*border: 1px solid #ccc;*/
    border-radius: 5px;
    padding: 1rem;
  }

  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* O ajusta la altura según tus necesidades */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/wallet/walletPage.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,oBAAoB;IACpB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,iDAAiD;IAC5E,WAAW,EAAE,kDAAkD;IAC/D,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,6CAA6C;EAC9D","sourcesContent":[".tabs {\n  display: inline-block;\n    margin-bottom: 1rem;\n    margin-left: 4rem;\n    margin-right: 4rem;\n  }\n  .tab {\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .tab.active-tab {\n    background-color: #F0810F; /* Color de fondo cuando la pestaña está activa */\n    color: #fff; /* Color del texto cuando la pestaña está activa */\n    border-radius: 5px;\n  }\n  \n  .content {\n    /*border: 1px solid #ccc;*/\n    border-radius: 5px;\n    padding: 1rem;\n  }\n\n  .centered-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* O ajusta la altura según tus necesidades */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

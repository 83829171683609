const Swal = require('sweetalert2');

export const validateRUC = (ruc) => {
   
    if (ruc.length !== 13) {
      return {
        isValid: false,
        errorMessage: "El RUC debe tener 13 dígitos.",
      };
    }
  
    // Verificar que todos los caracteres sean dígitos
    if (!/^\d+$/.test(ruc)) {
      return (message) => {
        Swal.fire({
          
         
          isValid: false,
          errorMessage: "El RUC solo debe contener dígitos numéricos.",

        })
       
      };
    }

   
  
    return { isValid: true };
  };

  
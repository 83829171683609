// Pagina para Cv del candidato en puesto especifico
import React, { useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom';
import Upload from "../../components/uploadCvPublic/Upload-document.js"
import Cv from "../../components/RecommendationCv.js"
import PositionForm from '../../components/CVPublicForm/PositionForm.js';
import CanvasPosition from '../../components/CVPublicForm/CanvasPosition.js';
import { lambdaProfile } from '../../enviroment/internal-lambdas.js';
import Swal from 'sweetalert2';
import imgLoading from '../../assets/img/LOGO_BAJA.jpeg'
import { CandidateForm } from '../../components/CVPublicForm/CandidateForm.jsx';

const PositionRequest = () => {
    const params = useParams();
    console.log('profileId', params.id)
    const profileId = params.id
    const [step, setStep] = useState(1);
    const [candidate, setCandidate] = useState({
        candidateData: null,
        candidateID: null,
        recommendation: null,
    });
    const [position, setPosition] = useState(null)
    const [profileOK, setProfileOK] = useState(false)

    useEffect(() => {
        const profileData = async () => {
            try {
                Swal.fire({
                    title: "Cargando...",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    html: `
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <img src="${imgLoading}" alt="MatchWorking Loading" style="width: 200px; height: 200px;" />
                </div>
            `,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const profileAwait = await lambdaProfile.getById(profileId)
                if (profileAwait) {
                    setPosition(profileAwait.data.data)
                    setProfileOK(true)
                    Swal.close();
                }
            } catch (error) {
                console.log(error)
                Swal.close();
            }
        }
        profileData();        
    }, [])

    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    const handlePrevious = () => {
        setStep(prevStep => prevStep - 1);
    };    

    return (
        <>
            {profileOK ? (
                <div>
                    <div className='text-center mt-5 mb-2'>
                        <h3>Estás aplicando al puesto:</h3>
                        <h1 className='font-bold'>{position.profile_positionToHire}</h1>
                    </div>                    
                    {step === 1 &&
                        <div className="flex flex-col md:flex-row w-full mx-auto text-black overflow-hidden">
                            <div className="w-full pb-0 p-8">
                                <CanvasPosition data={position} />
                            </div>
                            <div className="w-full mt-0 m-2 md:m-4">
                                <Upload onNext={handleNext} setCandidate={setCandidate} profileId={profileId} />
                            </div>
                        </div>
                    }
                    {step === 2 && 
                    <CandidateForm onNext={handleNext} candidate={candidate} setCandidate={setCandidate} profileId={profileId} position={position} />
                    }
                    {step === 3 && <Cv candidate={candidate} onPrevious={handlePrevious} />}
                </div>
            ) : (
                <div>
                    Not Found
                </div>
            )}

        </>

    );
};

export default PositionRequest;
import React from 'react'
import { useSelectedArticuloValue, useViewValue } from '../BilleteraState/BilleteraContext'

const BottomNavButtonComponent = ({ text, icon, actualPage, handleClick }) => {
    const actualView = useViewValue().actualView
    const actualProducto = useSelectedArticuloValue()
    const handleButtonClick = () => {
        handleClick()
    }
    return (
        <div className={`bg-[#FF8201] text-white px-4 py-1
            rounded-sm font-semibold hover:opacity-75 
            md:text-2xl lg:text-3xl
            ${actualPage === 1 && text==="Anterior" ? "hidden" : ""}
            ${actualPage === 1 && text==="Continuar" && !actualProducto.producto  ? "hidden" : ""}
            ${actualPage === 3 && text==="Continuar" && actualView==="Payphone" ? "hidden" : ""}
            cursor-pointer
            transition-all duration-300 ease-in-out
        `}
        onClick={handleButtonClick}
        >
            <div className='block md:hidden'>
                {icon}
            </div>
            <div className='hidden md:block'>
                {text}
            </div>
        </div>
    )
}

export default BottomNavButtonComponent

import axios from 'axios';
const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev=enviroment_dev.databaseUrl;

const satisfactionSurveyService = {
  createSurvey: async (surveyData) => {
    try {
      const response = await axios.post(`${api_enviroment_dev}/satisfactionSurvey`, surveyData);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  getAllSurveys: async () => {
    try {
      const response = await axios.get(`${api_enviroment_dev}/satisfactionSurvey`);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

};
export default satisfactionSurveyService;

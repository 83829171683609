import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import "./GenerateRegulation.css"
import { useUser } from "../../VariablesContext";
import { useNavigate } from 'react-router-dom';
import { DocumentInternal, DocumentSecurity } from '../../utilities/regulation-pdf-generator';
import GeneratorDocument from '../../utilities/generatorWord';
import walletFunctions from '../../utilities/wallet-function';
import regulationService from '../../services/regulations';
import userService from "../../services/userService";
import walletService from '../../services/walletService';

import dayjs from 'dayjs';

import Swal from "sweetalert2";
import suscriptionService from '../../services/suscriptionService';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const esLocale = require('dayjs/locale/es');
dayjs.locale(esLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Guayaquil');

const showAlert = require("../../utilities/alerts");

const GenerateRegulation = ({ regulationData, setRegulationData, onNextStep, onPreviousStep }) => {
    const navigate = useNavigate();
    const { userId } = useUser();
    const [userData, setUserData] = useState("");

    const [dataLink, setDataLink] = useState(false);
    const [showAdditionalData, setShowAdditionalData] = useState([]);
    const [dataCompany, setDataCompany] = useState('');
    const [generateRegulation, setGenerateRegulation] = useState('');
    const [slugRegulation, setSlugRegulation] = useState('');
    // console.log(slugRegulation)
    const [selectedRegulation, setSelectedRegulation] = useState('');
    // console.log(selectedRegulation)
    const [regulation, setRegulation] = useState('');
    const [awsFileName, setAwsFileName] = useState(['']);


    const [payments, setPayment] = useState('');

    const [coinsTotalUser, setCoinsTotalUser] = useState();
    const [tokenUser, setTokenUser] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            const token = await loadToken();
            loadTotalCoinsByUser(token);
            setTokenUser(token);
        };

        fetchData();
    }, [coinsTotalUser]);

    useEffect(() => {
        // Muestra el mensaje de carga al iniciar el proceso        
        const storedUserId = localStorage.getItem("userId");
        if (!storedUserId) {
            // Si no hay un userId en el almacenamiento local, redireccionar a la página de inicio
            navigate("/");
        } else {
            const fetchUserData = async () => {
                try {
                    const user = await userService.getUserId(userId);
                    // console.log('datauser', user);
                    // Actualiza el estado con los datos del usuario
                    setUserData(user);
                } catch (error) {
                    console.error("Error al obtener los datos del usuario", error);
                }
            };
            // Llama a la función para obtener los datos del usuario
            fetchUserData();
        }
    }, [navigate, userId])
    const loadToken = async () => {
        const data = await walletService.getToken();
        return data.token;
    }

    const loadTotalCoinsByUser = async (token) => {
        try {
            const data = await walletService.getTotalCoinsById(localStorage.getItem("userId"), token);
            setCoinsTotalUser(data.data.totalCoins || data.data.wallet_balance_user_id);

        } catch (error) {
            console.error("Error al obtener los datos del usuario", error);
        }
    }

    const regulationProcess = async (regulations) => {
        for (const obj of regulations) {
            try {

                const result = await regulationService.createRegulation(obj);
                console.log('Resultado:', result);
            } catch (error) {
                console.error('Error al registrar el reglamento');
            }
        }
    }

    const handleClickNext = async () => {

        setRegulationData({ ...regulationData });

        const resultados = [];
        const generatedRegulations = [];
        //Guarda datos generados para mostrarlo en repo personal del cliente
        for (let i = 0; i < showAdditionalData.length; i++) {
            const nameRegulation = showAdditionalData[i];
            const url = dataLink[i];
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('es-ES');
            const date = formattedDate
            // console.log(`Nombre del reglamento: ${nameRegulation}`);
            // console.log(`URL del blob: ${url}`);
            resultados.push({ nameRegulation, url, date });

            //data para guardar el reglamento
            const user_ID = userId
            const regulation_name = showAdditionalData[i]
            const company_ID = dataCompany.company_ID
            const company_name = dataCompany.company_tradename
            const regulation_state = 'Generado'
            const regulation_uri = dataLink[i]
            const wallet_service_id = selectedRegulation[i].wallet_service_id
            const regulation_additionalData = { fileName: awsFileName[i] }

            // console.log(data)
            generatedRegulations.push({ user_ID, regulation_name, company_ID, company_name, regulation_state, regulation_uri, wallet_service_id, regulation_additionalData });
        }
        const regulations = generatedRegulations
        console.log(regulations)
        setRegulation(regulations)

        const documents = resultados
        setRegulationData({ ...regulationData, generatedData: documents });

        try {
            // console.log('item seleccionado',payments) obtiene el id de servicio para el pago
            const { currentSuscription } = await suscriptionService.getSuscriptionPlan(userId)
            console.log(currentSuscription)
            if (currentSuscription.name) {
                // si tiene una sub, tiene generacion de reglamentos ilimitado (por el momento)
                // console.log('compraste con sub c:')
                onNextStep();
                // Llama a la función para registrar el reglamento generado
                await regulationProcess(regulations);
            } else {
                // console.log('compraste con coins c:')
                await walletFunctions.servicePayment(payments, tokenUser, userData)
                    .then(async (response) => {
                        if (response) {
                            onNextStep();
                            // Llama a la función para registrar el reglamento generado
                            await regulationProcess(regulations);
                            // Si fue exitoso el pago pasa a la siguiente acción                                            
                        }
                    })
                    .catch((err) => {
                        console.error("Error en el pago:", err); // Captura el error de la promesa aquí
                    });
            }
        } catch (error) {
            console.error("Error:", error);
            showAlert.error(
                "Ocurrió un error en la operación. Por favor, vuelve a intentarlo."
            );
        }

    };
    const payment = (serviceID) => {
        if (serviceID.length > 1) {
            setPayment("5")
        } else {
            setPayment(serviceID)
        }
    }
    useEffect(() => {

        const formData = regulationData.formData
        const dataCompany = formData[0]
        const regulations = formData[1]
        // console.log(formData)
        // console.log('regulationsINFO: ',regulations)
        setSelectedRegulation(regulations)
        const regulationName = regulations.map((data) => data.regulation_name).filter(Boolean);
        // console.log('regulations', regulations)
        // console.log('dataCompan', dataCompany)
        setShowAdditionalData(regulationName)
        setDataCompany(dataCompany)

        const regulationSlug = regulations.map((data) => data.slug).filter(Boolean);
        setSlugRegulation(regulationSlug)

        //Obtención de información de pago del servicio:
        const paymentService = regulations.map((data) => data.wallet_service_id).filter(Boolean);
        // console.log('itemSeleccionado', paymentService)
        payment(paymentService)
        // setPayment(paymentService)


        const dataRegulation = {
            companyName: dataCompany.company_tradename.toUpperCase(),
            city: dataCompany.company_WorkLocation.company_City,
            businessName: dataCompany.company_businessname,
            economicActivity: dataCompany.company_economic_activity,
            address: dataCompany.company_address,
            workCenters: dataCompany.company_WorkCenters,
            ruc: dataCompany.company_ruc,
            idResponsible: dataCompany.company_AdditionalData.idResponsible,
            nameResponsible: dataCompany.company_AdditionalData.nameResponsible,
            chargeResponsible: dataCompany.company_AdditionalData.chargeResponsible.toUpperCase(),
            idSST: dataCompany.company_AdditionalData.idSST,
            nameSST: dataCompany.company_AdditionalData.nameSST,
            chargeSST: dataCompany.company_AdditionalData.chargeSST.toUpperCase(),
        }
        setGenerateRegulation(dataRegulation)
        // console.log(dataRegulation)
    }, [regulationData.formData])

    async function regulationsType() {
        regulation.forEach(async (obj) => {
            try {
                // Llama al servicio 
                const result = await regulationService.createRegulation(obj);
                // console.log('Resultado:', result);
            } catch (error) {
                console.error('Error al registrar reglamento:', error);
            }
        });
    }

    const generateDocuments = async () => {
        const dataGenerated = [];
        const filenames = [];
        for (const data of slugRegulation) {
            // console.log(data)
            try {
                Swal.fire({
                    title: "Estamos generando tu reglamento....",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });                
                const body = {
                    ...generateRegulation,
                    date: dayjs().format('DD [de] MMMM [del] YYYY'),
                    fileName: `${generateRegulation.companyName}_${data}${Date.now()}`,
                    docName: data
                }
                filenames.push(body.fileName);
                // const readFile = generateRegulation.docName = data
                // console.log('boooodyyy:', generateRegulation)
                const docGenerate = await GeneratorDocument.generatorRegulation(body);
                // console.log('data usestate', docGenerate)
                dataGenerated.push(docGenerate);
                Swal.close();
            } catch (error) {
                console.log(error)
                Swal.close();
                showAlert.error(
                    "Lo sentimos no pudimos generar tu reglamento. Por favor, vuelve a intentarlo."
                );
                throw error; // Lanza el error para que pueda ser manejado posteriormente
            }
        }
        // console.log('data usestate', dataGenerated)
        setAwsFileName(filenames)
        console.log('filenames', filenames)
        return dataGenerated;
    }

    useEffect(() => {        
        const dataPromise = generateDocuments()

        dataPromise.then((resultado) => {
            // Aquí puedes acceder al valor resuelto de la promesa
            // console.log("Valor resuelto:", resultado);

            setDataLink(resultado)
        }).catch((error) => {
            // Maneja cualquier error que ocurra durante la resolución de la promesa
            console.error("Error:", error);
        });
        // generateVisualizer(showAdditionalData)

    }, [slugRegulation]);        
    const handlePreviousStep = (e) => {
        e.preventDefault();
        const dataSelected = { regulationData }        
        onPreviousStep();        
    }
    
    return (
        <div className="h-full md:h-screen w-full max-w-screen bg-[#011A27] bg-opacity-95 text-white shadow-lg rounded-lg pb-8 px-4 py-6">
    {/* Título centrado */}
    <div className="text-center p-4">
        <h1 className="text-2xl md:text-3xl font-semibold">Pre-Visualiza tu documento</h1>
    </div>

    {/* Contenedor responsive para los PDF */}
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
        {/* Documento 1: Reglamento Interno de Trabajo */}
        {showAdditionalData.includes("Reglamento Interno de Trabajo") && (
            <div className="pdf-container border border-[#F0810F] rounded-lg shadow-md p-4">
                <h2 className="text-lg font-semibold mb-2">Reglamento Interno de Trabajo</h2>
                <PDFViewer showToolbar={false} style={{ width: '100%', height: '70vh' }}>
                    <DocumentInternal data={generateRegulation} />
                </PDFViewer>
            </div>
        )}

        {/* Documento 2: Reglamento Interno de Higiene y Seguridad Ocupacional */}
        {showAdditionalData.includes("Reglamento Interno de Higiene y Seguridad Ocupacional") && (
            <div className="pdf-container border border-[#F0810F] rounded-lg shadow-md p-4">
                <h3 className="text-lg font-semibold mb-2">Reglamento Interno de Higiene y Seguridad Ocupacional</h3>
                <PDFViewer showToolbar={false} style={{ width: '100%', height: '70vh' }}>
                    <DocumentSecurity data={generateRegulation} />
                </PDFViewer>
            </div>
        )}
    </div>

    {/* Botones centrados */}
    <div className="max-w-sm mx-auto p-4 flex justify-center space-x-4">
        <button
            onClick={handlePreviousStep}
            className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
        >
            Volver y generar
        </button>
        
        <button
            type="submit"
            onClick={handleClickNext}
            className="bg-amber-600 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
        >
            Ver Completo
        </button>
    </div>
</div>

    );
}

export default GenerateRegulation;
import React, { useEffect, useState } from 'react'
import ArticuloCompraDetailComponent from './ArticuloCompraDetailComponent';
import { useSelectedArticuloValue } from '../BilleteraState/BilleteraContext';

const CompraDetailComponent = () => {
  const selected = useSelectedArticuloValue()
  const [total, setTotal] = useState(0);
  const [articulo, setArticulo] = useState(selected);
  const [descuento] = useState(0);

  useEffect(() => {
  }, [descuento])
  

  useEffect(() => {
    setArticulo(selected)
    setTotal(selected.precio - (selected.precio * descuento))
  }, [selected,descuento])

  return (
<div className='w-full p-2 font-light 
        flex flex-col items-center justify-center
        px-2 text-3xl py-6
        '>
        <div className='w-full text-left pl-5 border-b-2
        border-gray-200  mb-1
        '>
          Información de pago
        </div>
        <div className='font-bold font my-1'>
          Detalle de Pedido
        </div>

        <div className='p-4 grid gap-2 w-full text-2xl'>

          <div className='border-2 border-black rounded-lg           
          w-full sm:mt-2 md:mt-0 '>

            <div className=''>
              <div className='flex-grow h-40 px-4 py-4'>
                <ArticuloCompraDetailComponent
                  nombre={articulo.producto}
                  precio={articulo.precio}
                />
              </div>

              <div className='flex flex-row items-center justify-between
                py-4 bg-[#063852] text-white
                border-t-2 border-black w-full h-6 bottom-0 mt-1 px-4
              '>
                <div>TOTAL</div>
                <div className='font-bold'>${total}</div>
              </div>
            </div>

          </div>
        </div>


      </div>

  )
}

export default CompraDetailComponent

export const SuscriptionReducer = (state, action) => {
    switch (action.type) {
        case "SET":
            state = {
                ...action.payload
            }
            return state;
        case "UNSET":
            state = {}
            return state;
        default:
            return false;
    }
};
import React, { useState } from "react";
import TermsEdit from "../../components/list-data-admin/termsConditionsText";
import TermsTable from "../../components/list-data-admin/termsConditionsForm";


const Tab = ({ label, isActive, onClick }) => (
    <div className={`tab ${isActive ? "active-tab" : ""}`} onClick={onClick}>
        {label}
    </div>
);

const Content = ({ children }) => <div className="content">{children}</div>;

const TermsAdminPage = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    return (
        <div className="">
            <div>
                <div className="flex gap-4 ml-5">
                    <Tab
                        label="Listado"
                        isActive={activeTab === 0}
                        onClick={() => handleTabClick(0)}
                    />
                    <Tab
                        label="Nuevo usuario registrado"
                        isActive={activeTab === 1}
                        onClick={() => handleTabClick(1)}
                    />
                    <Tab
                        label="Generación de reglamentos"
                        isActive={activeTab === 2}
                        onClick={() => handleTabClick(2)}
                    />
                    <Tab
                        label="Analizador de hojas de vida"
                        isActive={activeTab === 3}
                        onClick={() => handleTabClick(3)}
                    />
                    <Tab
                        label="Perfil de cargo"
                        isActive={activeTab === 4}
                        onClick={() => handleTabClick(4)}
                    />
                       <Tab
                        label="Políticas de Privacidad"
                        isActive={activeTab === 5}
                        onClick={() => handleTabClick(5)}
                    />


                    {/* Agrega más pestañas según sea necesario */}
                </div>
                <Content>
                    {activeTab === 0 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Lista de Términos y condiciones</h1>
                                <TermsTable/>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Nuevo usuario registrado</h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Generación de reglamentos</h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )}
                    {activeTab === 3 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Analizador de hojas de vida</h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )}
                    {activeTab === 4 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Perfil de cargo</h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )}
                     {activeTab === 5 && (
                        <div>
                            <h1 className="text-2xl font-bold">
                                Políticas de Privacidad
                            </h1>
                            <TermsEdit id={activeTab} />
                        </div>
                    )}


                    {/* Agrega más contenido según sea necesario */}
                </Content>
            </div>
        </div>
    );
};

export default TermsAdminPage;


import axios from 'axios';

const enviroment_dev = require('../enviroment/enviroment-dev');
const api_enviroment_dev = enviroment_dev.databaseUrl + "/terms-conditions";


const termsService = {

    createNewTerms: async (newData) => {
        try {
            const response = await axios.post(`${api_enviroment_dev}/`, newData);
            return response.data;
        } catch (error) {
            console.log(error.response.data.message);
        }
    },

    // metodo para obtener lista de los terminos y condiciones
    getTermsList: async () => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/`);
            return response.data;
        } catch (error) {
            console.log(error.response.data.message);
        }
    },
    // metodo para obtener los terminos y condiciones de un servicio
    getTermsByID: async (id) => {
        try {
            const response = await axios.get(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.log(error.response.data.message);
        }
    },

    updateRegulation: async (id, newData) => {
        // console.log('newData', newData)
        try {
            const response = await axios.put(`${api_enviroment_dev}/${id}`, newData);
            // console.log(response.data);
            return true;
        } catch (error) {
            console.log(error.response.data.message);
            return false;
        }
    },

    deleteTerms: async (id) => {
        try {
            const response = await axios.delete(`${api_enviroment_dev}/${id}`);
            return response.data;
        } catch (error) {
            console.log(error.response.data.message);
        }
    }
};

export default termsService;
